<template lang="pug">
.retailer-registraion
  .retailer-personal-info(v-if="index==1")
    .retailer-personal-info__header
      .retailer-personal-info__dot-container
        .retailer-personal-info__dot-container--dot-filled
        .retailer-personal-info__dot-container--dot-unfilled
        .retailer-personal-info__dot-container--dot-unfilled
      span.retailer-personal-info__header--title {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.helloThere')}}
      span.retailer-personal-info__header--subtitle {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.personalInfoMsg')}}

    .retailer-personal-info__form
      .columns
        .column.is-6.retailer-personal-info__form-field
          .field
            .control
              label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.firstName')}}
              input.input.retailer-personal-info__form--input(
                v-model.trim="$v.user.givenName.$model"
               :class="{ 'is-danger': $v.user.givenName.$error}"
                  type="text" 
                :placeholder="$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.firstName')")
              span.help.is-danger(v-if="$v.user.givenName.$error") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.firstNameRequired')}}
        .column.is-6.retailer-personal-info__form-field
          .field
            .control
              label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.lastName')}}
              input.input.retailer-personal-info__form--input(
                v-model.trim="$v.user.familyName.$model"
                :class="{ 'is-danger': $v.user.familyName.$error }"
                type="text"
                :placeholder="$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.lastName')")
              span.help.is-danger(v-if="$v.user.familyName.$error") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.lastNameRequired')}}
      .columns
        .column.is-6.retailer-personal-info__form-field
          .field
            .control
              label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.createPassword')}}
              input.input.retailer-personal-info__form--input(
                v-model.trim="$v.user.password.$model"
                :class="{ 'is-danger': $v.user.password.$error}"
                type="password"
                placeholder="Password"
                @input="user.upperCaseAndNumberValidation=false")
              span.help.is-danger(v-if="$v.user.password.$error && !$v.user.password.required") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.passwordRequired')}}
              span.help.is-danger(v-if="$v.user.password.$error && !$v.user.password.minLength") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.passwordMinLength10')}}
              span.help.is-danger(v-if="user.upperCaseAndNumberValidation") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.passwordPattern')}}
        .column.is-6.retailer-personal-info__form-field
          .field
            .control
              label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.confirmPassword')}}
              input.input.retailer-personal-info__form--input(
                v-model.trim="$v.user.confirmPassword.$model"
                :class="{ 'is-danger': $v.user.confirmPassword.$error}"
                type="password" placeholder="Password")
              span.help.is-danger(v-if="$v.user.confirmPassword.$error && !$v.user.confirmPassword.required") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.confirmPasswordRequired')}}
              span.help.is-danger(v-if="!$v.user.confirmPassword.sameAsPassword && user.confirmPassword.length") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.passwordsMustMatch')}}
      .columns
        //- .column.is-6.retailer-personal-info__form-field
          .field
            .control
              label.retailer-personal-info__form--label Registration Code
              input.input.retailer-personal-info__form--input(type="text" placeholder="Registration Code *")
          p.registraion-code-message * You should have recieved a registration code from the Unfiltered Experience. Please contact them if you have any question
        .column.is-6.retailer-personal-info__form-field
          .field
            .control
              label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.emailAddress')}}
              input.input.retailer-personal-info__form--input(
                @input="duplicatedEmail=false"
                v-model.trim="$v.user.email.$model"
                :class="{ 'is-danger': $v.user.email.$error || duplicatedEmail}"
                type="text"
                :placeholder="$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.emailAddress')")
              span.help.is-danger(v-if="$v.user.email.$error && !$v.user.email.required") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.emailRequired')}}
              span.help.is-danger(v-if="$v.user.email.$error && !$v.user.email.email") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.invalidEmail')}}
              span.help.is-danger(v-if="duplicatedEmail") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.duplicateEmail')}}
    
  .retailer-personal-info(v-if="index==2")
    .retailer-personal-info__header
      .retailer-personal-info__dot-container
        .retailer-personal-info__dot-container--dot-filled
        .retailer-personal-info__dot-container--dot-filled
        .retailer-personal-info__dot-container--dot-unfilled
      span.retailer-personal-info__header--title {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.tellUsAboutRetailer')}}
      span.retailer-personal-info__header--subtitle {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.completeProfileMessage')}}
    .retailer-personal-info__form
      .columns
        .column.is-6.retailer-personal-info__form-field
          .field.retailer-personal-info__field
            .control
              label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.retailerName')}}
              input.input.retailer-personal-info__form--input(
                @input="duplicatedName=false"
                v-model.trim="$v.organization.name.$model"
                :class="{ 'is-danger': $v.organization.name.$error || duplicatedName}"
                type="text"
                :placeholder="$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.retailerName')")
              span.help.is-danger(v-if="$v.organization.name.$error && !$v.organization.name.required") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.organizationNameRequired')}}
              span.help.is-danger(v-show="duplicatedName") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.duplicatename')}}
          .field.retailer-personal-info__field
            .control
              label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.retailerWebsite')}}
              input.input.retailer-personal-info__form--input(
                v-model.trim="$v.organization.website.$model"
                :class="{ 'is-danger': $v.organization.website.$error}"
                type="text"
                :placeholder="$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.retailerWebsite')")
              span.help.is-danger(v-if="$v.organization.website.$error && !$v.organization.website.required") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.websiteRequired')}}
          .field.retailer-personal-info__field
            .control
              label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.describeYourRetailer')}}
              textarea.textarea.retailer-personal-info__form--text-area(
                v-model.trim="$v.organization.about.$model"
                :class="{ 'is-danger': $v.organization.about.$error}"
                type="text"
                :placeholder="$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.describeRetailerPlaceholder')"
                )
              span.help.is-danger(v-if="$v.organization.about.$error && !$v.organization.about.required") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.descriptionRequired')}}
        .column.is-6.retailer-personal-info__form-field
          .field.retailer-personal-info__field
            .control
              label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.streetAddress')}}
              input.input.retailer-personal-info__form--input(
                v-model.trim="$v.headquarters.streetAddressLine_1.$model"
                :class="{ 'is-danger': $v.headquarters.streetAddressLine_1.$error}"
                type="text" placeholder="Street Address")
              span.help.is-danger(v-if="$v.headquarters.streetAddressLine_1.$error && !$v.headquarters.streetAddressLine_1.required") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.streetAddressRequired')}}
          .field.retailer-personal-info__field
            .control
              label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.suiteBldgFloor')}}
              input.input.retailer-personal-info__form--input(
                v-model.trim="headquarters.streetAddressLine_2"
                type="text" placeholder="Suite, Bldg, Floor (Optional)")
          .field.retailer-personal-info__field
            .control
              label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.city')}}
              input.input.retailer-personal-info__form--input(
                v-model.trim="$v.headquarters.city.$model"
                :class="{ 'is-danger': $v.headquarters.city.$error}"
                type="text" placeholder="City")
              span.help.is-danger(v-if="$v.headquarters.city.$error && !$v.headquarters.city.required") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.cityRequired')}}
          .columns
            .column.is-6
              .field
                .control
                  label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.stateRegion')}}
                  input.input.retailer-personal-info__form--input(
                    v-model.trim="$v.headquarters.region.$model"
                    :class="{ 'is-danger': $v.headquarters.region.$error}"
                    type="text" placeholder="State/Region")
                  span.help.is-danger(v-if="$v.headquarters.region.$error && !$v.headquarters.region.required") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.stateOrRegionRequired')}}
            .column.is-6
              .field
                .control
                  label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.postalCode')}}
                  input.input.retailer-personal-info__form--input(
                    v-model.trim="$v.headquarters.postalCode.$model"
                    :class="{ 'is-danger': $v.headquarters.postalCode.$error}"
                    type="text" placeholder="Postal Code")
                  span.help.is-danger(v-if="$v.headquarters.postalCode.$error && !$v.headquarters.postalCode.required") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.postalCodeRequired')}}
          .field.retailer-personal-info__field
            .control
              label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.country')}}
              .select.is-fullwidth
                select(v-model.trim="$v.headquarters.country.id.$model" :class="{ 'is-danger': $v.headquarters.country.$error}")
                  option(v-for="country in countries" :key="country.id" :value="country.id") {{ country.name }}
                .retailer-personal-info__select-placeholder(v-if="!headquarters.country.id") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.locations')}}
              span.help.is-danger(v-if="$v.headquarters.country.id.$error") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.countryRequired')}}
          label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.locations')}}
          .columns(v-for="(location, index) in $v.locations.$each.$iter")
            //- .column.is-12(v-show="index > 0")
              a.register__add-location-button(@click="removeLocation(index)") {{$t('Auth.Register.deleteLocation')}}
            .column.is-6
              .field.retailer-personal-info__field
                .control
                    
                  .select.is-fullwidth(:class="{ 'is-danger': location.country.id.$error }")
                    select(v-model="location.country.id.$model")
                      option(v-for="country in countries" :key="country.id" :value="country.id") {{ country.name }}
                    .retailer-personal-info__select-placeholder(v-if="showPlaceholder") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.country')}}
                  span.help.is-danger(v-if="location.country.id.$error") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.locationRequired')}}
            .column.is-6
              .field.retailer-personal-info__field
                .control
                  input.input.retailer-personal-info__form--input(
                    :class="{ 'is-danger': location.count.$error }"
                    v-model.number.trim="location.count.$model"
                    type="number" placeholder="No. of Locations")
                  span.help.is-danger(v-if="location.count.$error && !location.count.required") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.numberRequired')}}
                  span.help.is-danger(v-if="location.count.$error && !location.count.positiveNumber") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.numberMustPositive')}}
            //- .register__add-location-button(@click="addLocation()") + {{$t('Auth.Register.addAnotherLocation')}}
  .retailer-personal-info(v-if="index==3")
    .retailer-personal-info__header
      .retailer-personal-info__dot-container
        .retailer-personal-info__dot-container--dot-filled
        .retailer-personal-info__dot-container--dot-filled
        .retailer-personal-info__dot-container--dot-filled
      span.retailer-personal-info__header--title {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.tellUsTerms')}}
      span.retailer-personal-info__header--subtitle {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.completeProfileMessage')}}
    .retailer-personal-info__form
      .columns
        .column.is-6.retailer-personal-info__form-field
          .field.retailer-personal-info__field
            .control
              label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.paymentTerms')}}
              span.terms-tooltip
                RegisterPopup(v-bind:text="$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.netTermsQn')")
              .select.is-fullwidth(:class="{'is-danger': $v.retailerTerms.paymentTerm.id.$error }")
                select(v-model="$v.retailerTerms.paymentTerm.id.$model")
                  option(v-for="paymentTerm in paymentTerms" :key="paymentTerm.id" :value="paymentTerm.id") {{ paymentTerm.name }}
                span.help.is-danger(v-if="$v.retailerTerms.paymentTerm.id.$error") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.paymentTermsRequired')}}
          .columns
            .column.is-6
              .field
                label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.marketingCoOp')}}
                span.terms-tooltip
                  RegisterPopup(v-bind:text="$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.marketingCoopInvoiceQn')")
                .control.has-icons-right
                  input.input.retailer-personal-info__form--input(
                    v-model.number.trim="$v.retailerTerms.marketingCoOp.$model"
                    :class="{'is-danger': $v.retailerTerms.marketingCoOp.$error}"
                    type="number")
                  span.icon.is-right.percentage-icon %
                  span.help.is-danger(v-if="$v.retailerTerms.marketingCoOp.$error") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.marketingCoopRequired')}}
            .column.is-6
              .field
                label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.damagesBudget')}}
                span.terms-tooltip
                  RegisterPopup(v-bind:text="$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.damagesBudgetInvoiceQn')")
                .control.has-icons-right
                  input.input.retailer-personal-info__form--input(
                    v-model.number.trim="$v.retailerTerms.damagesBudget.$model"
                    :class="{'is-danger': $v.retailerTerms.damagesBudget.$error}"
                    type="number"
                  )
                  span.icon.is-right.percentage-icon %
                  span.help.is-danger(v-if="$v.retailerTerms.damagesBudget.$error") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.Validations.damagesBudgetRequired')}}
          .column
            label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.compliance')}}
            span.terms-tooltip
              RegisterPopup(v-bind:text="$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.complianceReqQn')")
            .retailer-personal-info__terms-checkbox
              span.retailer-personal-info__terms-checkbox-icon(@click="retailerTerms.productTicketingRequired = !retailerTerms.productTicketingRequired")
                img.retailer-personal-info__terms-checkbox-icon--checked(v-if="retailerTerms.productTicketingRequired" src="@/assets/img/inputs/checked.png")
                img.retailer-personal-info__terms-checkbox-icon--unchecked(v-else src="@/assets/img/checkbox.png")
              span.retailer-personal-info__terms-checkbox-text {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.productTicketing')}}
            .retailer-personal-info__terms-checkbox
              span.retailer-personal-info__terms-checkbox-icon(@click="retailerTerms.ediRequired = !retailerTerms.ediRequired")
                img.retailer-personal-info__terms-checkbox-icon--checked(v-if="retailerTerms.ediRequired" src="@/assets/img/inputs/checked.png")
                img.retailer-personal-info__terms-checkbox-icon--unchecked(v-else src="@/assets/img/checkbox.png")
              span.retailer-personal-info__terms-checkbox-text {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.retailerEDI')}}
            .retailer-personal-info__terms-checkbox
              span.retailer-personal-info__terms-checkbox-icon(@click="retailerTerms.routingGuideRequired = !retailerTerms.routingGuideRequired")
                img.retailer-personal-info__terms-checkbox-icon--checked(v-if="retailerTerms.routingGuideRequired" src="@/assets/img/inputs/checked.png")
                img.retailer-personal-info__terms-checkbox-icon--unchecked(v-else src="@/assets/img/checkbox.png")
              span.retailer-personal-info__terms-checkbox-text {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.vendorGuide')}}
        .column.is-6.retailer-personal-info__form-field
          .column
            label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.acceptsOverseasShipment')}}
            span.terms-tooltip
              RegisterPopup(v-bind:text="$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.acceptShipmentQn')")
            .control.retailer-personal-info__radio-field
              label.retailer-personal-info__radio.retailer-personal-info__radio-container
                input(type='radio' name='seaShipment' value="true" v-model="retailerTerms.acceptsOverseasFreight")
                span.retailer-personal-info__radio-checkmark
                span.retailer-personal-info__radio-label Yes
              label.retailer-personal-info__radio.retailer-personal-info__radio-container
                input(type='radio' name='seaShipment' value="false" v-model="retailerTerms.acceptsOverseasFreight")
                span.retailer-personal-info__radio-checkmark
                span.retailer-personal-info__radio-label No
          .column
            label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.usShippping')}}
            span.terms-tooltip
              RegisterPopup(v-bind:text="$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.partyPaysShipmentQn')")
            .control.retailer-personal-info__radio-field
              label.retailer-personal-info__radio.retailer-personal-info__radio-container
                input(type='radio' name='usRetailerShipping' value="brand" v-model="retailerTerms.usDomesticShippingObligation")
                span.retailer-personal-info__radio-checkmark
                span.retailer-personal-info__radio-label Brand
              label.retailer-personal-info__radio.retailer-personal-info__radio-container
                input(type='radio' name='usRetailerShipping' value="retailer" v-model="retailerTerms.usDomesticShippingObligation")
                span.retailer-personal-info__radio-checkmark
                span.retailer-personal-info__radio-label Retailer
          .column
            label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.testers')}}
            span.terms-tooltip
              RegisterPopup(v-bind:text="$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.partyResponsibleForCostTestersQn')")
            .control.retailer-personal-info__radio-field
              label.retailer-personal-info__radio.retailer-personal-info__radio-container
                input(type='radio' name='testers' value="brand" v-model="retailerTerms.testersObligation")
                span.retailer-personal-info__radio-checkmark
                span.retailer-personal-info__radio-label Brand
              label.retailer-personal-info__radio.retailer-personal-info__radio-container
                input(type='radio' name='testers' value="retailer" v-model="retailerTerms.testersObligation")
                span.retailer-personal-info__radio-checkmark
                span.retailer-personal-info__radio-label Retailer
          .column
            label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.fixtures')}}
            span.terms-tooltip
              RegisterPopup(v-bind:text="$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.partyResponsibleForCostFixturesQn')")
            .control.retailer-personal-info__radio-field
              label.retailer-personal-info__radio.retailer-personal-info__radio-container
                input(type='radio' name='fixtures' value="brand" v-model="retailerTerms.fixturesObligation")
                span.retailer-personal-info__radio-checkmark
                span.retailer-personal-info__radio-label Brand
              label.retailer-personal-info__radio.retailer-personal-info__radio-container
                input(type='radio' name='fixtures' value="retailer" v-model="retailerTerms.fixturesObligation")
                span.retailer-personal-info__radio-checkmark
                span.retailer-personal-info__radio-label Retailer
          .column
            label.retailer-personal-info__form--label {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.signage')}}
            span.terms-tooltip
              RegisterPopup(v-bind:text="$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.partyResponsibleForCostSignage')")
            .control.retailer-personal-info__radio-field
              label.retailer-personal-info__radio.retailer-personal-info__radio-container
                input(type='radio' name='signage' value="brand" v-model="retailerTerms.signageObligation")
                span.retailer-personal-info__radio-checkmark
                span.retailer-personal-info__radio-label Brand
              label.retailer-personal-info__radio.retailer-personal-info__radio-container
                input(type='radio' name='signage' value="retailer" v-model="retailerTerms.signageObligation")
                span.retailer-personal-info__radio-checkmark
                span.retailer-personal-info__radio-label Retailer


  .retailer-registraion__buttons
    .agree-terms(v-if="index==3")
      img.agree-terms__icon(v-show="!isAgreeTerms" src="@/assets/img/checkbox.png" @click="isAgreeTerms=true")
      img.agree-terms__icon-checked(v-show="isAgreeTerms" src="@/assets/img/inputs/checked.png" @click="isAgreeTerms=false")
      .agree-terms__text
      span I agree to the
      span.agree-terms__text-link(@click="navigateToTerms") Terms & Conditions
      span and
      span.agree-terms__text-link(@click="navigateToPrivacy") Privacy Policy
    button.next-button(v-if="index<3" @click="next") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.next')}}
    button.next-button(
      v-if="index==3"
      @click="submit"
      :disabled="!isAgreeTerms"
      :class="{'disabled-button' : !isAgreeTerms}") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.submitApplication')}}
    a.back-button(v-if="index>1" @click="back") {{$t('Components.Unfiltered.Retailer.RetailerRegistrationForm.back')}}
            

    
</template>

<script>
import { mapActions } from "vuex"
import { required, sameAs, email, minLength } from "vuelidate/lib/validators"
import RegisterPopup from "@/components/registration/RegisterPopup"

export default {
  components: {
    RegisterPopup
  },
  data() {
    return {
      isAgreeTerms: false,
      index: 1,
      role: "retailer",
      duplicatedEmail: false,
      duplicatedName: false,
      user: {
        givenName: "",
        familyName: "",
        email: "",
        password: "",
        confirmPassword: "",
        upperCaseAndNumberValidation: false
      },
      organization: {
        name: "",
        website: "",
        about: ""
      },
      headquarters: {
        streetAddressLine_1: "",
        streetAddressLine_2: "",
        city: "",
        region: "",
        postalCode: "",
        country: {
          id: ""
        },
        hq: true,
        defaultShipping: false
      },
      locations: [{ country: { id: "" }, count: "" }],
      retailerTerms: {
        usDomesticShippingObligation: "brand",
        testersObligation: "brand",
        fixturesObligation: "brand",
        signageObligation: "brand",
        paymentTerm: { id: "" },
        marketingCoOp: "",
        damagesBudget: "",
        ediRequired: false,
        acceptsOverseasFreight: true,
        routingGuideRequired: false,
        productTicketingRequired: false
      }
    }
  },
  validations: {
    user: {
      givenName: {
        required
      },
      familyName: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(10)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    },
    organization: {
      name: {
        required
      },
      website: {
        required
      },
      about: {
        required
      }
    },
    headquarters: {
      streetAddressLine_1: { required },
      city: { required },
      region: { required },
      postalCode: { required },
      country: {
        id: { required }
      }
    },
    locations: {
      $each: {
        country: { id: { required } },
        count: {
          required,
          positiveInteger(count) {
            return /^[1-9]\d*$/gm.test(count)
          }
        }
      }
    },
    retailerTerms: {
      paymentTerm: { id: { required } },
      marketingCoOp: { required },
      damagesBudget: { required }
    }
  },
  computed: {
    showPlaceholder() {
      return this.locations[0].country.id == ""
    },
    countries() {
      return this.$store.getters["reference/countries"]
    },
    paymentTerms() {
      return this.$store.getters["reference/paymentTerms"]
    }
  },
  methods: {
    ...mapActions("user", ["signUp"]),
    ...mapActions("reference", ["setReference"]),
    passwordCheck() {
      if (this.user.password.search(/[A-Z]/) == -1 || this.user.password.search(/[0-9]/) == -1) {
        return true
      }
    },
    next() {
      if (this.index == 1) {
        this.$v.user.$touch()
        if (this.$v.user.$anyError) {
          return
        }
        if (this.passwordCheck()) {
          this.user.upperCaseAndNumberValidation = true
          return
        }
      }
      if (this.index == 2) {
        this.$v.organization.$touch()
        this.$v.headquarters.$touch()
        this.$v.locations.$touch()
        if (
          this.$v.organization.$anyError ||
          this.$v.headquarters.$anyError ||
          this.$v.locations.$anyError
        ) {
          return
        }
      }
      this.index = this.index + 1
    },
    back() {
      this.index = this.index - 1
    },
    async submit() {
      this.$v.retailerTerms.$touch()
      if (this.$v.retailerTerms.$anyError) {
        return
      }
      const { user, organization, role, headquarters, retailerTerms, locations } = this
      const payload = { user }
      payload[role] = organization
      payload[role]["addresses"] = [headquarters]
      payload[role]["terms"] = retailerTerms
      payload[role]["locationCounts"] = locations
      this.duplicatedName = false
      this.duplicatedEmail = false
      try {
        await this.signUp(payload)
        this.$router.push({ name: "retailerVerifyEmail" })
      } catch (error) {
        this.registerInProgress = false
        if (error.response.status === 400) {
          const errors = error.response.data.underlying.map(error => error.error)

          this.duplicatedEmail = errors.includes("user_exists")
          this.duplicatedName = errors.includes("client_exists")
        }
        if (this.duplicatedEmail) {
          this.index = 1
        }
        if (this.duplicatedName) {
          this.index = 2
        }
      }
    },
    addLocation() {
      this.locations.push({ country: { id: "" }, count: "" })
    },
    removeLocation(index) {
      return this.locations.splice(index, 1)
    },
    navigateToTerms() {
      this.$router.push({ name: "terms" })
    },
    navigateToPrivacy() {
      this.$router.push({ name: "privacyPolicy" })
    }
  }
}
</script>

<style lang="sass">
.retailer-registraion
  padding: 50px
  width: 100%

  &__buttons
    display: flex
    margin-top: 25px
    flex-direction: column
    align-items: center

.retailer-personal-info
  &__header
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 25px
    color: #212B36

    &--title
      font-size: 28px
      color: #333333

    &--subtitle
      font-size: 16px
      color: #333333

  &__form-field
    padding-right: 16px
    padding-left: 16px

  &__field
    margin-bottom: 24px !important

  &__form
    &--label
      font-size: 14px
      color: #212B36
    &--text-area
      height: 293px

    .registraion-code-message
      font-size: 10px

  &__terms-checkbox
    position: relative
    display: flex

  &__terms-checkbox-icon
    position: absolute
    left: 0
    cursor: pointer

  &__terms-checkbox-icon--checked
    right: 3px
    top: 3px
    width: 18px
    height: 18px

  &__terms-checkbox-icon--unchecked
    width: 18px
    height: 18px

  &__terms-checkbox-text
    margin-left: 25px
    font-size: 14px
    color: #212B36

  &__radio-field
    padding-bottom: 15px

  &__radio + &__radio
    margin-left: 3em !important

  &__radio-container
    position: relative
    padding-left: 25px
    margin-top: 10px
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    input
      position: absolute
      opacity: 0
      cursor: pointer

  &__radio-label
    font-size: 14px
    color: #000000

  &__radio-checkmark
    position: absolute
    top: 3px
    left: 0
    height: 14px
    width: 14px
    background: #FFFFFF
    border-radius: 50%
    border: 1px solid #E5E5E5

  &__radio-container input:checked ~ &__radio-checkmark, .checkmark-box
    background-color: #FFFFFF

  &__radio-checkmark:after
    content: ""
    position: absolute
    display: none

  &__radio-container input:checked ~ &__radio-checkmark:after
    display: block

  &__radio-container input:checked ~ .checkmark-box:after
    display: block

  &__radio-container &__radio-checkmark:after
    top: 3px
    left: 3px
    width: 6px
    height: 6px
    border-radius: 50%
    background: #000000

  &__radio-container .checkmark-box:after
    top: 3px
    left: 3px
    width: 6px
    height: 6px
    border-radius: 2px
    background: #000000
    padding-left: 20px

  &__select-placeholder
    position: relative
    padding-left: 20px
    bottom: 30px
    color: #b4b4b4
    width: 0px
    font-size: 14px

  &__dot-container
    display: flex
    margin-bottom: 12px

    &--dot-filled
      height: 8px
      width: 8px
      background-color: #000000
      margin: 0 4px
      border-radius: 50px

    &--dot-unfilled
      height: 8px
      width: 8px
      margin: 0 4px
      background-color: #DBDBDB
      border-radius: 50px

.next-button
  background-color: #FF6132
  width: 335px
  height: 55px
  border-radius: 30px
  color: #FFFFFF
  font-size: 16px

.back-button
  margin-top: 20px
  color: #161616
  font-size: 14px
  font-weight: 300
  text-decoration: underline

.percentage-icon
  color: #000000 !important

.agree-terms
  display: flex
  align-items: center
  font-size: 10px
  color: #000000
  margin-bottom: 5px

  &__icon
    margin-right: 10px
    height: 16px
    width: 16px
    cursor: pointer

  &__icon-checked
    margin-right: 10px
    height: 16px
    width: 16px
    cursor: pointer
  &__text-link
    text-decoration: underline
    cursor: pointer
    margin: 0 3px
.disabled-button
  opacity: 0.5

.terms-tooltip
  margin-left: 5px
</style>
