<template lang="pug">
.guest-payment-component__main-content
  section.payment--form
    p.form--title.heading--5 {{ formTitle }}
    p.form--content.body--normal {{ text }}
    .payment--methods
      .label payment methods
      img(src="@/assets/webflowAssets/Payment/method.svg" alt="Methods")
    b-field
      .columns
        .column
          b-input(v-model="firstName" placeholder="First Name")
        .column
          b-input(v-model="lastName" placeholder="Last Name")
    b-field
      b-input(v-model="cardNumber" placeholder="Card Number")
    b-field
      .columns
        .column
          b-input(v-model="cvvNumber" placeholder="CVV")
        .column
          a-date-picker(v-model="date" format="MM/YY" @change="onChangeShipDate" :picker="'month'" placeholder="MM/YY")
    .promo--code
      b-field(label="promo code" :type="showCodeText.type" :message="showCodeText.message")
        b-input(v-model="promoCode" @input="noPromoCode" placeholder="Insert Code")
      span.body--small.remove--code(@click="removeCode") REMOVE CODE
      span.apply-coupon-code-check(v-if="showApply" @click="applyCode") APPLY
    .total--cost
      span Subscription Cost:
      span ${{ price }} annually
    .form--footer
      my-button(variant="is-text" label="Cancel" size="is-large" @onClick="cancelPayment")
      my-button(variant="is-primary" label="Confirm" size="is-large" @onClick="submitPayment")
</template>

<script>
import MyButton from "@/components/webflowComponent/Button"
import AES from "@/common/AES"
import Utils from "@/common/Utils"
import moment from "moment"
export default {
  name: "Payment",
  components: {
    MyButton
  },
  props: {
    // firstName: {
    //   type: String
    // },
    // lastName: {
    //   type: String
    // },
    // cardNumber: {
    //   type: String
    // },
    // promoCode: {
    //   type: String
    // },
    // price: {
    //   type: Number,
    //   default: 2000
    // }
    brandId: {
      type: String,
      default: () => undefined
    }
  },
  data() {
    return {
      formTitle: "Add Your Payment Details",
      text: "To activate your subscription at the end of your 21-day trial.",
      activeTab: 0,
      firstName: "",
      lastName: "",
      cardNumber: "",
      cvvNumber: "",
      promoCode: "",
      date: null,
      price: 2000,
      showApply: true,
      codeText: [
        {
          type: "is-success",
          message: "This promo code is valid"
        },
        {
          type: "is-danger",
          message: "This promo code is invalid"
        }
      ],
      showCodeText: {},
      amount: 0,
      couponAmount: 0,
      expirationDate: null
    }
  },
  computed: {
    paymentMethodsData() {
      return this.$store.getters["brandPayment/cardInfoList"]
    }
  },
  watch: {
    paymentMethodsData() {
      console.log(this.paymentMethodsData)
      if (this.paymentMethodsData && this.paymentMethodsData.length > 0) {
        let defaultCard = this.paymentMethodsData.find(item => item.default === true)
        if (defaultCard) {
          let names = defaultCard.cardHolderName.split(" ")
          let firsts = ""
          if (names && names.length > 0) {
            for (let i = 0; i < names.length - 1; i++) {
              firsts += names[i]
              if (i < names.length - 2) {
                firsts += " "
              }
            }
          }
          this.firstName = firsts
          this.lastName = names[names.length - 1]
          this.cardNumber = defaultCard.cardNumber
          this.cvvNumber = defaultCard.cardCode
          this.expirationDate = defaultCard.expirationDate
          this.date = moment(this.expirationDate, "MM/YY")
        }
      }
    }
  },
  methods: {
    cancelPayment() {
      this.$emit("cancelPayment")
    },
    onChangeShipDate(value, dateStr) {
      console.log("date", value, dateStr)
      console.log(this.date)
      this.expirationDate = dateStr.split("/").join("")
    },
    submitPayment() {
      const params = {
        promiseCoupon: {
          code: this.promoCode,
          amount: this.couponAmount
        }
      }
      let isNeedAddCard = true
      if (this.paymentMethodsData && this.paymentMethodsData.length > 0) {
        let card = this.paymentMethodsData.filter(item => item.cardNumber === this.cardNumber)
        if (card && card.length > 0) {
          isNeedAddCard = false
        }
      }
      console.log(this.brandId)
      if (isNeedAddCard) {
        if (this.brandId) {
          const cardInfo = {
            cardNumber: this.cardNumber,
            cardCode: this.cvvNumber,
            expirationDate: this.expirationDate,
            cardHolderName: `${this.firstName} ${this.lastName}`,
            default: this.paymentMethodsData && this.paymentMethodsData.length > 0 ? false : true
          }
          this.$store
            .dispatch("brandPayment/updateCardInfo", {
              brandId: this.brandId,
              cardInfo
            })
            .then(response => {
              if (response.error) {
                // add failed
                this.showErrorInfo(response.developerMessage)
              } else {
                this.$emit("submitPayment", params)
              }
            })
            .catch(() => {
              this.showErrorInfo(this.$t("Components.Brands.Payment.addCardFailed"))
            })
        } else {
          params["brandCreditCardInformation"] = [
            {
              cardNumber: AES.encrypt(this.cardNumber),
              cardCode: AES.encrypt(this.cvvNumber),
              expirationDate: AES.encrypt(this.expirationDate),
              cardHolderName: `${this.firstName} ${this.lastName}`,
              default: this.paymentMethodsData && this.paymentMethodsData.length > 0 ? false : true
            }
          ]
          if (this.checkCardInfo()) {
            this.$emit("submitPayment", params)
          }
        }
      } else {
        this.$emit("submitPayment", params)
      }
    },
    showErrorInfo(message) {
      if (message) {
        this.$message.error(message)
      }
    },
    checkCardInfo() {
      if (!this.cardNumber) {
        let info = this.$t("Components.Brands.Payment.cardNumberEmpty")
        this.$message.error(info)
        return false
      }
      if (!this.date) {
        let info = this.$t("Components.Brands.Payment.expirationDateEmpty")
        this.$message.error(info)
        return false
      }
      if (!this.cvvNumber) {
        let info = this.$t("Components.Brands.Payment.cvvEmpty")
        this.$message.error(info)
        return false
      }
      if (this.cvvNumber.length > 4 || this.cvvNumber.length < 3) {
        this.$message.error(" CVV length should be 3 or 4")
        return false
      }
      if (!Utils.checkCardNumber(this.cardNumber)) {
        // check number
        let info = this.$t("Components.Brands.Payment.cardNumberError")
        this.$message.error(info)
        return false
      }
      // if (!this.checkExpiredDate(this.date)) {
      //   let info = "The expiration date should like 01/2022 !"
      //   this.$message.error(info)
      //   return false
      // }
      // if (Utils.isDateExpired(this.date)) {
      //   let info = this.$t("Components.Brands.Payment.expiredDateExpiredError")
      //   this.$message.error(info)
      //   return false
      // }
      if (!Utils.checkCardCode(this.cvvNumber)) {
        let info = this.$t("Components.Brands.Payment.cardCodeError")
        this.$message.error(info)
        return false
      }
      return true
    },
    // checkExpiredDate(dateString) {
    //   let regResult = /(0[1-9]|1[0-2])\/([0-9]{4})/.test(dateString)
    //   if (regResult) {
    //     let year = dateString.split('/')[1]
    //     return year < 100
    //   }
    //   return regResult
    // },
    removeCode() {
      this.showApply = true
      this.promoCode = ""
      this.showCodeText = {}
      this.price = 2000
    },
    async noPromoCode() {
      if (!this.promoCode) {
        await this.applyCode()
      }
    },
    applyCode() {
      let params = {
        couponCode: this.promoCode
      }
      this.$store
        .dispatch("adminConsole/getCouponSignUpValue", params)
        .then(res => {
          this.showApply = false
          this.showCodeText = this.codeText[0]
          if (res) {
            if (res.isPercentage) {
              const number = res.amount / 100
              this.amount = this.price * number
            } else {
              this.amount = res.amount
            }
            // this.price = 2000 - this.amount
            this.price = Math.round((2000 - this.amount) * 100) / 100
            this.couponAmount = res.amount
          }
        })
        .catch(e => {
          console.log(e)
          this.showApply = false
          this.amount = 0
          this.price = Math.round((2000 - this.amount) * 100) / 100
          // this.promoCode = ""

          if (this.promoCode) {
            this.showCodeText = this.codeText[1]
          } else {
            this.removeCode()
          }
        })
    }
  }
}
</script>

<style lang="scss">
.guest-payment-component__main-content {
  p {
    margin-bottom: 0;
  }
  .input,
  .textarea,
  .input:hover,
  .textarea:hover,
  .input:focus,
  .textarea:focus {
    border: 1px solid #f15a42;
    box-sizing: border-box;
    border-radius: 50px;
    padding: 16px 24px;

    font-size: 20px;
    line-height: 22px;
    color: #000000;

    box-shadow: none;
    height: fit-content;
  }
  .payment--form .form--footer {
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
    text-align: center;
  }

  .payment--form .form--footer button + button {
    margin-left: 25px;
  }

  .payment--form .form--title {
    text-align: left;
    color: #1a1818;
    font-size: 25px;
  }

  .payment--form .form--content {
    margin-bottom: 34px;
    font-size: 20px;
  }

  .payment--form .promo--code {
    position: relative;
  }
  .apply-coupon-code-check {
    position: absolute;
    right: 23px;
    top: 35px;
    font-size: 20px;
    color: #f15a42;
    cursor: pointer;
  }

  .payment--form .promo--code .field label {
    font-family: Niveau Grotesk;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #000000;
  }

  .payment--form .remove--code {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .total--cost {
    padding: 17px;
    font-family: "Mr Eaves Mod OT";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    background-color: rgba(241, 90, 66, 0.1);
    border-radius: 100px;
    color: black;
  }

  .total--cost span:nth-child(2) {
    font-weight: 400;
    margin-left: 16px;
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .ant-calendar-picker-input.ant-input {
    width: 100%;
    border: 1px solid #f15a42;
    box-sizing: border-box;
    border-radius: 50px;
    padding: 12px 24px;
    font-size: 20px;
  }
  .ant-input {
    height: auto;
  }
  .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #f15a42;
  }
  .control.has-icons-right .icon.is-right {
    top: 8px;
  }
}

@media screen and (max-width: 767px) {
  .payment--form .form--content {
    max-width: 300px;
  }
}
</style>
