<template>
  <div ref="editAddProductDialog" class="product-training-edit-add-dialog__main">
    <a-modal
      v-model="isShow"
      width="960px"
      dialog-class="adminProductTrainingDialog"
      :footer="null"
      :get-container="() => $refs.editAddProductDialog"
      @cancel="closeDialog"
    >
      <div slot="title" style="text-align: center;">
        Product Training Details
      </div>
      <a-row>
        <a-col :span="12" style="padding-right: 10px;">
          <a-row class="training-product-edit__row-margin">
            <a-col :span="4">Brand:</a-col>
            <a-col :span="20">{{ product && product.brand ? product.brand.name : "" }}</a-col>
          </a-row>
          <a-row class="training-product-edit__row-margin">
            <a-col :span="4">Name:</a-col>
            <a-col :span="20">{{ product ? product.name : "" }}</a-col>
          </a-row>
          <a-row class="training-product-edit__row-margin">
            <a-col :span="4">SKU:</a-col>
            <a-col :span="20">{{ product ? product.sku : "" }}</a-col>
          </a-row>
          <a-row class="training-product-edit__row-margin">
            <a-col :span="4">UPC:</a-col>
            <a-col :span="20">{{
              product && product.barcodes && product.barcodes.length > 0
                ? product.barcodes[0].code
                : ""
            }}</a-col>
          </a-row>
          <a-row class="training-product-edit__row-margin">
            <a-col :span="4">MSRP:</a-col>
            <a-col :span="20">${{ product ? product.msrpUsd : "" }}</a-col>
          </a-row>
          <a-row class="training-product-edit__row-margin">
            <a-col :span="4">Category:</a-col>
            <a-col :span="8">{{ product && product.category ? product.category.name : "" }}</a-col>
            <a-col :span="12" style="text-align: right">
              <span style="margin-right: 10px;">Hero</span>
              <a-switch v-model="product.training.hero" @change="huandleChangeValue" />
            </a-col>
          </a-row>
          <a-row class="training-product-edit__row-margin">
            <div>{{ this.$t("Training.brandTraining.trainingFormTips.stepLabel") }}</div>
            <a-select
              v-model="form.trainingSteps"
              style="width: 100%;"
              mode="multiple"
              :show-arrow="true"
              :label-in-value="true"
              placeholder="Select up to 2"
              @deselect="huandleChangeValue"
              @blur="huandleChangeValue"
              @change="handleChange($event, 2)"
            >
              <a-select-option v-for="(steps, index) in step" :key="index" :value="steps.id">
                {{ steps.description }}
              </a-select-option>
            </a-select>
          </a-row>
          <a-row class="training-product-edit__row-margin">
            <div>{{ this.$t("Training.brandTraining.trainingFormTips.useForLabel") }}</div>
            <a-select
              v-model="form.trainingBestUsedFors"
              mode="multiple"
              style="width: 100%;"
              :show-arrow="true"
              :label-in-value="true"
              placeholder="Select up to 2"
              @deselect="huandleChangeValue"
              @blur="huandleChangeValue"
              @change="handleChange($event, 2)"
            >
              <a-select-option v-for="(item, index) in bestUsedFor" :key="index" :value="item.id">
                {{ item.description }}
              </a-select-option>
            </a-select>
          </a-row>
          <a-row class="training-product-edit__row-margin">
            <div>{{ this.$t("Training.brandTraining.trainingFormTips.crossSellersLabel") }}</div>
            <a-select
              v-model="form.trainingCrossSellers"
              mode="multiple"
              style="width: 100%;"
              :show-arrow="true"
              :label-in-value="true"
              :max-tag-count="3"
              placeholder="Select up to 3 (Optional)"
              @deselect="huandleChangeValue"
              @blur="huandleChangeValue"
              @change="handleChange($event, 3)"
            >
              <a-select-option
                v-for="(item, index) in getCrossSellers"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-row>
          <a-row>
            <div>How To Demo:</div>
            <a-textarea
              v-model="howToDemo"
              class="textarea-style"
              :placeholder="tipsText"
              :max-length="250"
              :auto-size="{ minRows: 5, maxRows: 5 }"
              @blur="changeValue"
            />
            <span class="training-product-edit__number-text">{{ howToDemo.length || 0 }}/250</span>
          </a-row>
        </a-col>
        <!---right column-->
        <a-col :span="12" style="padding-left: 10px;">
          <div class="vide-image-content-two">
            <div class="video-content-left">
              <p>Videos</p>
              <div class="content-two-style">
                <div
                  v-for="(item, index) in imgAssetsList.filter(
                    video => video.asset && video.asset.contentType.includes('video')
                  )"
                  :key="index"
                >
                  <div
                    v-if="item.hasNoUrl"
                    class="video-style-main no-video-style"
                    @click="openVideoDialog(index)"
                  >
                    <span class="retailer-icon-style">+</span>
                  </div>
                  <div v-else class="video-style-main has-video-style">
                    <span class="close-icon" @click="closeVideo(item, index)"
                      ><a-icon type="close"
                    /></span>
                    <div @click="clickToVideo(item, index)">
                      <img
                        class="public-start"
                        width="21"
                        height="21"
                        src="../../assets/training_public_video.jpg"
                        alt=""
                      />
                      <video
                        width="auto"
                        height="65"
                        style="height: 35px !important;margin-top: 16px; margin-left: 2px"
                      >
                        <source :src="item.asset.url" type="video/mp4" />
                        Your browser does not support the HTML5 video tag.
                      </video>
                    </div>
                  </div>
                </div>
              </div>
              <p style="margin-top: 8px;font-size: 8px">
                Videos must be 500MB or less In .mpeg, .wmv
              </p>
            </div>
            <div class="video-content-left image-content-right">
              <p>Images</p>
              <div class="content-two-style">
                <div
                  v-for="(item, index) in imgAssetsList.filter(
                    video => video.asset && video.asset.contentType.includes('image')
                  )"
                  :key="index"
                >
                  <div
                    v-if="item.hasNoUrl"
                    class="video-style-main no-video-style"
                    :class="noImageUrl ? 'no-image-style' : ''"
                    @click="openImageDialog(index)"
                  >
                    <span class="retailer-icon-style">+</span>
                  </div>
                  <div v-else class="video-style-main has-video-style">
                    <span class="close-icon" @click="closeImage(item, index)"
                      ><a-icon type="close"
                    /></span>
                    <div
                      style="text-align: center; height: 65px"
                      @click="clickToViewImages(item, index)"
                    >
                      <img :src="item.asset.url" alt="" style="height: 62px;border-radius: 7px" />
                      <!--                      <a-image style="width: 69px; height: 65px;" :src="item.asset.url"/>-->
                    </div>
                  </div>
                </div>
              </div>
              <p style="margin-top: 8px;font-size: 8px">
                Images must be 20MB or less In JPEG，PNG，GIF，TIFF
              </p>
            </div>
          </div>
          <!--      <a-row class="training-product-edit__row-margin">-->
          <!--        <a-col :span="12">-->
          <!--          Videos-->
          <!--        </a-col>-->
          <!--        <a-col :span="12">-->
          <!--          Images-->
          <!--        </a-col>-->
          <!--        <a-col :span="12">-->
          <!--          <a-row>-->
          <!--            <div class="detail-video-info">-->
          <!--              <div v-if="videoUrl" class="video-style" @click="clickToVideo">-->
          <!--                &lt;!&ndash;            <img class="public-start" src="../../../../assets/training_public_video.jpg" alt="" />&ndash;&gt;-->
          <!--                <img class="public-start" src="../../assets/training_public_video.jpg" alt="" />-->
          <!--                &lt;!&ndash;          <span class="public-start background-style"></span>&ndash;&gt;-->
          <!--                <img class="video-img" :src="imageUrl" alt="" />-->
          <!--              </div>-->
          <!--              <div class="add-second-video">-->
          <!--                <a-upload-->
          <!--                  v-model="secondVideo"-->
          <!--                  name="avatar"-->
          <!--                  list-type="picture-card"-->
          <!--                  class="avatar-uploader"-->
          <!--                  :show-upload-list="false"-->
          <!--                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"-->
          <!--                  :before-upload="beforeUpload"-->
          <!--                  @change="handleChange"-->
          <!--                >-->
          <!--                </a-upload>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            &lt;!&ndash;            <a-button&ndash;&gt;-->
          <!--            &lt;!&ndash;              :class="uploadBtn ? 'upload-success' : 'upload-video-style'"&ndash;&gt;-->
          <!--            &lt;!&ndash;              style="margin-left: 10px;"&ndash;&gt;-->
          <!--            &lt;!&ndash;              @click="openDialog"&ndash;&gt;-->
          <!--            &lt;!&ndash;            >&ndash;&gt;-->
          <!--            &lt;!&ndash;              <a-icon v-if="uploadBtn" type="check-circle" />&ndash;&gt;-->
          <!--            &lt;!&ndash;              <a-icon v-else type="upload" />&ndash;&gt;-->
          <!--            &lt;!&ndash;              {{ uploadText }}&ndash;&gt;-->
          <!--            &lt;!&ndash;            </a-button>&ndash;&gt;-->
          <!--          </a-row>-->
          <!--          <a-row style="margin-top: 8px;font-size: 8px">-->
          <!--            Videos must be 500MB or less In .abc, .abc, .abc-->
          <!--          </a-row>-->
          <!--        </a-col>-->
          <!--        <a-col :span="12">-->
          <!--          <a-row>-->
          <!--            <img :src="productImg" alt="" style="width: 69px; height: 65px;" />-->
          <!--          </a-row>-->
          <!--        </a-col>-->
          <!--      </a-row>-->
          <a-row class="training-product-edit__row-margin key-benefits-info-style">
            <div>{{ this.$t("Training.brandTraining.prodEditCardTips.keyBenefits") }}</div>
            <ul id="benefits">
              <li v-for="item in product.newBenefits" :key="item.id">
                <i></i>
                <p>{{ item.name }}</p>
              </li>
            </ul>
          </a-row>
          <a-row class="training-product-edit__row-margin">
            <div class="demo-tip-style">
              {{ this.$t("Training.brandTraining.trainingFormTips.demoTipsLabel") }}
            </div>
            <a-textarea
              v-model="demoTips"
              :max-length="250"
              class="textarea-style"
              :placeholder="demoText"
              :auto-size="{ minRows: 5, maxRows: 6 }"
              @blur="changeValue"
            />
            <span class="training-product-edit__number-text">{{ demoTips.length || 0 }}/250</span>
          </a-row>
        </a-col>
      </a-row>
      <div class="product-training-button">
        <p v-if="isNewTraining" class="training-save-draft-button" @click="saveDraft">Save Draft</p>
        <p class="training-cancel" @click="closeDialog">Cancel</p>
        <p class="training-public-button" @click="submitProduct">Publish</p>
      </div>
    </a-modal>
    <uploadVideo
      ref="uploadVideo"
      :product="product"
      @handleUploadInfo="handleUploadInfo"
    ></uploadVideo>
    <editVideo
      ref="editUploadVideo"
      :product="product"
      @handleEditVideoInfo="handleEditImageInfo"
    ></editVideo>
    <a-modal
      :visible="modalVisible"
      :footer="null"
      :destroy-on-close="true"
      :body-style="{ padding: 0 }"
      @cancel="handleCancel"
    >
      <a-icon slot="closeIcon" type="close-circle" class="modal-icon-cancel" />
      <div v-if="videoUrl && videoUrl.length !== 0" class="video-main">
        <video id="video1" ref="videoM" controls>
          <source id="videoSrc" :src="videoUrl" type="video/mp4" />
          Your browser does not support the HTML5 video tag.
        </video>
      </div>
    </a-modal>
    <a-modal
      :visible="showImageVisible"
      :footer="null"
      :destroy-on-close="true"
      :body-style="{ padding: 0 }"
      @cancel="handleImgCancel"
    >
      <a-icon slot="closeIcon" type="close-circle" class="modal-icon-cancel" />
      <div style="width: 300px; min-height: 300px; line-height: 300px; margin: 0 auto">
        <img :src="modalImage" alt="" style="width: 100%; height: auto" />
      </div>
    </a-modal>
  </div>
</template>
<script>
import trainingClient from "@/api/training-client"
import { mapState, mapActions, mapGetters } from "vuex"
import uploadVideo from "@/components/training/upLoadVideoDialog"
import editVideo from "@/components/training/editVideoDialog"
import { checkArary } from "@/utils/validate"
export default {
  components: {
    uploadVideo,
    editVideo
  },
  data() {
    return {
      product: {
        training: {
          hero: false
        },
        newBenefits: []
      },
      isShow: false,
      sendParams: {},
      isNewTraining: false,
      uploadBtn: false,
      modalVisible: false,
      sendInfo: {},
      howToDemo: "",
      demoTips: "",
      sendFormTraining: {},
      trainingVideos: {},
      secondTrainingVideos: {},
      trainingVideosList: [], // Trainingvideo list
      form: {
        trainingSteps: [],
        trainingBestUsedFors: [],
        trainingCrossSellers: []
      },
      videoUrl: null,
      imageUrl: null,
      secondVideo: null,
      defaultNoVideo: {
        hasNoUrl: true,
        asset: {
          url: "",
          contentType: "video"
        },
        title: ""
      },
      defaultNoimg: {
        hasNoUrl: true,
        asset: {
          url: "",
          contentType: "image"
        },
        title: ""
      },
      imgAssetsList: [],
      imgVideoNumber: 0,
      imgImageNumber: 0,
      showImageVisible: false,
      modalImage: "",
      noImageUrl: false
    }
  },
  computed: {
    ...mapState({
      bestUsedFor: state => state.reference.productBestUsedFor,
      step: state => state.reference.productStep,
      userId: state => state.user.businessId
    }),
    ...mapGetters({
      getCrossSellers: "training/getCrossSellers"
    }),
    tipsText() {
      return this.$t("Training.brandTraining.textareaDemoInfo")
    },
    demoText() {
      return this.$t("Training.brandTraining.textareaTipsInfo")
    },
    productImg() {
      const list = this.product.images.filter(item => item.main === true)
      return list[0].asset.url
    },
    uploadText() {
      return this.uploadBtn
        ? "Replace Video"
        : this.$t("Training.brandTraining.trainingFormTips.uploadVideoLabel")
    }
  },
  watch: {
    modalVisible() {
      let video = document.getElementById("video1")
      if (video) {
        if (this.modalVisible === false) {
          video.pause()
        } else {
          video.play()
        }
      }
    }
    // product(newValue, old) {
    //   console.log(old)
    //   // console.log(newValue)
    //   this.init(newValue)
    // }
  },
  // async mounted() {
  //   this.init(this.product)
  //   await this.getRefData()
  //   await this.getProductTraining({
  //     brand: this.userId,
  //     "have-training": "have-training",
  //     _limit: 999
  //   })
  // },
  methods: {
    ...mapActions({
      getRefData: "reference/setReference",
      getProductTraining: "training/getProductTraining"
    }),
    async openDialog(data, isNewTraining) {
      // this.noImageUrl = false
      this.product = JSON.parse(JSON.stringify(data))
      this.isShow = true
      this.isNewTraining = isNewTraining
      this.init(data)
      await this.getRefData()
      await this.getProductTraining({
        brand: this.userId,
        "have-training": "have-training",
        _limit: 999
      })
    },
    closeDialog() {
      this.isShow = false
      this.product = {
        training: {
          hero: false
        },
        newBenefits: []
      }
    },
    submitProduct() {
      // 2022-6-29: There is no error without adding pictures
      // const imgResult =
      //   (this.sendParams.trainingAssets &&
      //     this.sendParams.trainingAssets.filter(item =>
      //       item.asset.contentType.includes("image")
      //     )) ||
      //   []
      // if (imgResult && imgResult.length === 0) {
      //   this.noImageUrl = true
      //   this.$message.error("Please add at least 1 image to publish")
      // }
      this.submit(this.sendParams.id, this.sendParams)
    },
    submit(id, param) {
      trainingClient
        .updateTrainingProduct(id, param)
        .then(() => {
          // this.$store.dispatch("training/getProductList", this.productData.brand.id)
          this.$emit("updateNewTrainingList")
          this.isShow = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    async saveDraft() {
      // If it is in the Save Draft state, state is 0 and isPublish is false
      const param = {
        ...this.sendParams,
        training: {
          ...this.sendParams.training,
          states: 0,
          isPublished: false
        }
      }
      await this.submit(this.sendParams.id, param)
      this.$message.success("Your draft has been saved")
      this.isShow = false
    },
    formatTrainingAssets(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].imgAsset && Object.values(list[i].imgAsset).length !== 0) {
          const obj = {
            asset: {
              ...list[i].imgAsset
            },
            title: list[i].title
          }
          list[i].imgAsset = {}
          list.push(obj)
        }
      }
      return list
    },
    init(data) {
      this.imgAssetsList = []
      this.imgVideoNumber = 0
      this.imgImageNumber = 0
      this.howToDemo = (data.training && data.training.howToDemo) || ""
      this.demoTips = (data.training && data.training.demoTips) || ""
      this.uploadBtn = data.trainingAssets && data.trainingAssets.length !== 0
      this.form.trainingSteps = this.checkValue(data.trainingSteps)
      this.form.trainingBestUsedFors = this.checkValue(data.trainingBestUsedFors)
      this.form.trainingCrossSellers = this.checkValue(data.trainingCrossSellers)
      // if (data.trainingVideos && data.trainingVideos.length !== 0) {
      //   this.videoUrl = data.trainingVideos[0].asset.url
      //   this.imageUrl = data.trainingVideos[0].imgAsset.url
      // }
      this.imgAssetsList = this.formatTrainingVideos(data.trainingAssets)
    },
    formatTrainingVideos(list) {
      if (!list || list.length === 0) {
        return [this.defaultNoVideo, this.defaultNoVideo, this.defaultNoimg, this.defaultNoimg]
      }
      if (list.length === 4) {
        return list
      }
      let trainingAssetList = this.formatTrainingAssets(list)
      const trainingAssetImg = this.formatimageList(trainingAssetList)
      const trainingAssetVideo = this.formatVideoList(trainingAssetList)
      return trainingAssetImg.concat(trainingAssetVideo)
    },
    formatimageList(list) {
      let imageList = []
      const imgResult = list.filter(item => item.asset.contentType.includes("image"))
      switch (imgResult.length) {
        case 0:
          imageList = [this.defaultNoimg, this.defaultNoimg]
          break
        case 1:
          imageList = [imgResult[0], this.defaultNoimg]
          break
        case 2:
          imageList = imgResult
          break
        default:
          imageList =
            imgResult.filter(item => item.asset.url).length > 2
              ? imgResult.filter(item => item.asset.url).slice(0, 2)
              : imgResult.filter(item => item.asset.url)
      }
      return imageList
    },
    formatVideoList(list) {
      let videoList = []
      const videoResult = list.filter(item => item.asset.contentType.includes("video"))
      switch (videoResult.length) {
        case 0:
          videoList = [this.defaultNoVideo, this.defaultNoVideo]
          break
        case 1:
          videoList = [videoResult[0], this.defaultNoVideo]
          break
        case 2:
          videoList = videoResult
          break
        default:
          videoList =
            videoResult.filter(item => item.asset.url).length > 2
              ? videoResult.filter(item => item.asset.url).slice(0, 2)
              : videoResult.filter(item => item.asset.url)
      }
      return videoList
    },
    changeValue() {
      // this.sendInfo = {
      //   trainingAssets: this.trainingVideos
      // }
      this.sendParamsValue()
    },
    resetProductItemInfo() {
      this.imgVideoNumber = 0
      this.imgImageNumber = 0
      this.howToDemo = ""
      this.demoTips = ""
      this.uploadBtn = false
      this.form.trainingSteps = []
      this.form.trainingBestUsedFors = []
      this.form.trainingCrossSellers = []
      // if (data.trainingVideos && data.trainingVideos.length !== 0) {
      //   this.videoUrl = data.trainingVideos[0].asset.url
      //   this.imageUrl = data.trainingVideos[0].imgAsset.url
      // }
      this.imgAssetsList = []
    },
    checkValue(arr) {
      if (arr && arr.length !== 0) {
        return arr.map(item => {
          return {
            key: item.id,
            label: item.name
          }
        })
      }
    },
    formatTrainingAssetSend(list) {
      return list.filter(item => item.asset.url)
    },
    sendParamsValue() {
      const data = {
        ...this.sendInfo,
        ...this.sendFormTraining,
        // trainingVideos: Object.keys(this.trainingVideos).length === 0 ? [] : [this.trainingVideos],
        training: {
          ...this.sendFormTraining.training,
          howToDemo: this.howToDemo,
          demoTips: this.demoTips,
          states: 1,
          isPublished: true,
          isHero: this.product.training.hero || false
        }
      }
      // In the case of modification and not draft, it is necessary to add ID in Training
      const list = this.formatTrainingAssetSend(this.imgAssetsList)
      data.trainingAssets = list
      data.training.howToDemo = this.howToDemo || ""
      data.training.demoTips = this.demoTips || ""
      data.training.id = this.product.training.id
      data.training.states = this.product.training.states || 1
      data.training.isPublished = this.product.training.published || true
      data.training.sort = this.product.training.sort
      data.trainingSteps =
        data.trainingSteps && data.trainingSteps.length !== 0
          ? data.trainingSteps
          : this.product.trainingSteps
      data.trainingBestUsedFors =
        data.trainingBestUsedFors && data.trainingBestUsedFors.length !== 0
          ? data.trainingBestUsedFors
          : this.product.trainingBestUsedFors
      data.trainingCrossSellers =
        data.trainingCrossSellers && data.trainingCrossSellers.length !== 0
          ? data.trainingCrossSellers
          : this.product.trainingCrossSellers
      if (this.$route.query.draft) {
        data.training.states = 1
        data.training.isPublished = true
      }
      this.sendParams = { id: this.product.id, ...data }
      // return
    },
    formatTraining(data) {
      if (!data || !data.training.howToDemo) {
        return true
      }
      // Because cross-seller is optional, so it can skip checking for nulls
      if (!checkArary(data.trainingSteps)) {
        return true
      }
      if (!checkArary(data.trainingBestUsedFors)) {
        return true
      }
      // if (!checkArary(data.trainingVideos)) {
      //   return true
      // }
    },
    openVideoDialog(index) {
      this.imgVideoNumber = index
      this.$refs["uploadVideo"].openUploadVideo()
    },
    openImageDialog(index) {
      this.imgImageNumber = index
      this.$refs["editUploadVideo"].openEditVideo()
    },
    // Upload video
    handleUploadInfo(sendInfo, isShowEdit) {
      const list = this.imgAssetsList.filter(item => item.asset.contentType.includes("video"))
      const otherList = this.imgAssetsList.filter(item => !item.asset.contentType.includes("video"))
      list[this.imgVideoNumber] = {
        asset: {
          url: sendInfo.file.url || "",
          id: sendInfo.file.id || "",
          contentType: sendInfo.file.contentType || ""
        }
      }
      this.imgAssetsList = otherList.concat(list)
      console.log(isShowEdit)
      this.sendParamsValue()
    },
    // Upload image
    handleEditImageInfo(imageInfo, title) {
      const list = this.imgAssetsList.filter(item => item.asset.contentType.includes("image"))
      const otherList = this.imgAssetsList.filter(item => !item.asset.contentType.includes("image"))
      list[this.imgImageNumber] = {
        asset: {
          ...imageInfo
        },
        title
      }
      this.uploadBtn = true
      this.imgAssetsList = otherList.concat(list)
      this.sendParamsValue()
      // this.sendParamsValue()
    },
    handleCancel() {
      this.modalVisible = false
      let video = document.getElementById("video1")
      if (video) {
        video.pause()
      }
    },
    handleImgCancel() {
      this.showImageVisible = false
    },
    clickToVideo(item, index) {
      this.showModal = false
      this.modalVisible = true
      this.videoUrl = item.asset.url
      this.imgVideoNumber = index
      // console.log(this.product.trainingVideos)
      // console.log(this.$refs["videoM"])
      //   this.$refs["videoM"]
    },
    clickToViewImages(item, index) {
      console.log(index)
      this.showImageVisible = true
      this.modalImage = item.asset.url
    },
    huandleChangeValue() {
      const params = {
        trainingSteps: this.form.trainingSteps ? this.formatValue(this.form.trainingSteps) : [],
        trainingBestUsedFors: this.form.trainingBestUsedFors
          ? this.formatValue(this.form.trainingBestUsedFors)
          : [],
        trainingCrossSellers: this.form.trainingCrossSellers
          ? this.formatValue(this.form.trainingCrossSellers)
          : [],
        training: {
          isHero: this.product.training.hero
        }
      }
      this.sendFormTraining = { ...params }
      this.sendParamsValue()
    },
    formatValue(arr) {
      return arr.map(item => {
        return {
          id: item.key,
          name: item.label
        }
      })
    },
    handleChange(value, maxSize) {
      if (value.length > maxSize) {
        let tem = value.slice(-1)[0]
        value.splice(maxSize - 1, value.length - 1, tem)
      }
      Object.assign(this, {
        value,
        data: {}
      })
    },
    closeVideo(item, index) {
      this.imgVideoNumber = index
      const list = this.imgAssetsList.filter(item => item.asset.contentType.includes("video"))
      const otherList = this.imgAssetsList.filter(item => !item.asset.contentType.includes("video"))
      list[this.imgVideoNumber] = this.defaultNoVideo
      this.imgAssetsList = otherList.concat(list)
      this.sendParamsValue()
    },
    closeImage(item, index) {
      this.imgImageNumber = index
      const list = this.imgAssetsList.filter(item => item.asset.contentType.includes("image"))
      const otherList = this.imgAssetsList.filter(item => !item.asset.contentType.includes("image"))
      list[this.imgImageNumber] = this.defaultNoimg
      this.uploadBtn = true
      this.imgAssetsList = otherList.concat(list)
      this.sendParamsValue()
    }
  }
}
</script>
<style lang="scss">
.product-training-edit-add-dialog__main {
  .adminProductTrainingDialog .ant-modal-footer .ant-btn:hover {
    color: #4a8a5d;
    border-color: #4a8a5d;
  }
  .adminProductTrainingDialog .ant-modal-footer .ant-btn-primary {
    background-color: #4a8a5d;
    border-color: #4a8a5d;
  }
  .adminProductTrainingDialog .ant-modal-footer .ant-btn-primary:hover {
    color: white;
  }
  .adminProductTrainingDialog .ant-select-selection:hover {
    border-color: #4a8a5d;
  }
  .adminProductTrainingDialog .ant-input:hover {
    border-color: #4a8a5d;
  }
  // .ant-switch-checked {
  //   background-color: #4a8a5d !important;
  // }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    color: #4a8a5d;
  }
  // .ant-tabs-nav .ant-tabs-tab:hover {
  //   color: #4a8a5d !important;
  // }
  .product-training-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    text-align: center;
    margin-top: 20px;
    .training-save-draft-button {
      width: 160px;
      height: 30px;
      line-height: 30px;
      border-radius: 7px;
      background-color: #8c8c8c;
      color: white;
      cursor: pointer;
    }
    .training-cancel {
      width: 160px;
      height: 30px;
      line-height: 30px;
      border-radius: 7px;
      background-color: #f27c59;
      color: white;
      margin: 0 13px;
      cursor: pointer;
    }
    .training-public-button {
      width: 160px;
      height: 30px;
      line-height: 30px;
      border-radius: 7px;
      background-color: #a0d29b;
      color: white;
      cursor: pointer;
    }
  }
  .training-product-edit {
    &__number-text {
      float: right;
      font-size: 8px;
      color: #262626;
    }
    &__row-margin {
      margin-bottom: 10px;
    }
  }
  .detail-video-info {
    display: inline-block;
    display: flex;
    justify-content: start;
    align-items: center;
    .video-style {
      width: 69px;
      height: 65px;
      margin-top: 16px;
      text-align: center;
      border: 1px solid #262626;
      cursor: pointer;
      position: relative;
      .video-img {
        //width: 100%;
        height: 100%;
      }
      .public-start {
        position: absolute;
        top: 17px;
        right: 14px;
        border-radius: 50%;
      }
      .background-style {
        width: 43px;
        height: 43px;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        top: 34px;
        right: 83px;
      }
    }
    .add-second-video {
      width: 69px;
      height: 65px;
      //border: 1px solid #d9d9d9;
    }
  }
  .upload-video-style {
    width: 140px;
    border: 1px solid #4a8a5d;
    color: #4a8a5d;
    background-color: #e2eeec;
    text-align: center;
  }
  .upload-success {
    width: 140px;
    border: 1px solid #4a8a5d;
    color: #ffffff;
    background-color: #4a8a5d;
    text-align: center;
  }
  .key-benefits-info-style {
    margin-top: 46px;
    height: 202px;
  }
  #benefits {
    list-style: disc;
    padding: 10px 20px;
  }
  //#benefits li {
  //  float: left;
  //}
  .ant-switch-checked {
    background-color: rgba(243, 202, 80, 1);
  }
  .vide-image-content-two {
    width: 100%;
    display: flex;
    justify-content: start;
    //align-items: center;
    .video-content-left {
      width: 49%;
    }
    .image-content-right {
      margin-left: 2%;
    }
    .content-two-style {
      display: flex;
    }
    .video-style-main {
      width: 69px;
      height: 65px;
      border-radius: 7px;
      border: 1px solid #d9d9d9;
      margin-right: 15px;
      cursor: pointer;
    }
    .no-image-style {
      border-color: red;
      color: red;
    }
    .no-video-style {
      text-align: center;
      line-height: 56px;
    }
    .has-video-style {
      position: relative;
    }
    .retailer-icon-style {
      font-size: 36px;
      color: #d9d9d9;
    }
    .public-start {
      position: absolute;
      top: 22px;
      right: 22px;
      border-radius: 50%;
    }
    .close-icon {
      font-size: 10px;
      position: absolute;
      top: -17px;
      right: 0;
      color: #8c8c8c;
      z-index: 999;
    }
  }
}
</style>
