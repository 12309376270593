<template>
  <a-modal
    ref="createBrandNewMessageModal"
    v-model="showCreateMessage"
    :width="modalWidth"
    :footer="null"
    :title="null"
    :mask="!isSmallStatus"
    :closable="false"
    :body-style="bodyStyle"
    :mask-closable="false"
    dialog-class="admin-message-brand-create-new-modal"
    :dialog-style="isSmallStatus ? { bottom: '100px', right: '-364px' } : { top: '10px' }"
    :destroy-on-close="true"
    @cancel="hideDialog"
    @afterClose="hideDialog"
  >
    <div
      :class="{ 'admin-message-brand-create-new-modal__outer': !isSmallStatus }"
      class="message-new-modal-content"
    >
      <div
        :style="!isSmallStatus ? 'width: 952px;padding-bottom: 48px;' : 'width: 561px;'"
        style="margin: 0 auto;"
      >
        <a-row v-if="!isSmallStatus" style="margin-bottom: 20px;">
          <a-col :span="12" class="admin-message-brand-create-new-modal__title">
            Messaging
          </a-col>
          <a-col :span="12" style="text-align: right;">
            <a-icon
              type="shrink"
              style="margin-right:12px;"
              class="admin-message-brand-create-new-modal__large-icon"
              @click="clickToSwitch"
            />
            <a-icon
              type="close"
              class="admin-message-brand-create-                                new-modal__large-icon"
              @click="hideDialog"
            />
          </a-col>
        </a-row>
        <div class="admin-message-brand-create-new-modal__box-content">
          <a-row
            :style="
              isSmallStatus
                ? 'padding-left: 14px; padding-right: 10px; height: 42px; padding-top: 6px; padding-bottom: 6px;'
                : 'padding-left: 30px; padding-left: 20px; height: 40px; padding-top: 6px; padding-bottom: 6px;'
            "
            class="admin-message-brand-create-new-modal__choose-model-layer"
          >
            <a-col
              :span="1"
              :style="isSmallStatus ? 'width: 255px;' : 'width: 702px;'"
              class="admin-message-brand-create-new-modal__subject"
            >
              To:
            </a-col>
            <a-col :span="18">
              <!-- <multi-select
                v-model="toValue"
                :options="dataSource"
                :multiple="true"
                label="id"
                track-by="id"
                :close-on-select="true"
                :custom-label="tagNameById"
                open-direction="bottom"
                @select="onSelectToTarget"
              /> -->
              <a-select
                v-if="!isFirstCreate"
                v-model="toValue"
                show-search
                :class="
                  isToError
                    ? 'admin-message-brand-create-new-modal__toTarget admin-message-brand-to-error'
                    : 'admin-message-brand-create-new-modal__toTarget'
                "
                :filter-option="false"
                style="width: 200px"
                @search="handleSearch"
                @change="onSelectToTarget"
              >
                <a-select-option v-for="item in dataSource" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <div v-else style="margin-top: 4px;">{{ retailer ? retailer.name : "" }}</div>
            </a-col>
            <div v-if="isSmallStatus" style="float: right; display: inline-block; margin-top: 4px;">
              <a-icon
                type="arrows-alt"
                style="margin-right:8px;"
                class="admin-message-brand-create-new-modal__large-icon"
                @click="clickToSwitch"
              />
              <a-icon
                type="close"
                class="admin-message-brand-create-new-modal__large-icon"
                @click="hideDialog"
              />
            </div>
          </a-row>
          <a-row
            :style="
              isSmallStatus
                ? 'padding-left: 14px; padding-right: 10px; height: 42px; padding-top: 6px; padding-bottom: 6px;'
                : 'padding-left: 30px; padding-left: 20px; height: 40px; padding-top: 6px; padding-bottom: 6px;'
            "
            class="admin-message-brand-create-new-modal__choose-model-layer"
          >
            <div
              :style="isSmallStatus ? 'width: 307px;' : 'width: 702px;'"
              class="admin-message-brand-create-new-modal__subject"
            >
              Subject:
              <a-input
                v-if="!this.isToError && selectedModel === '3'"
                v-model="inputSubject"
                style="width: 150px;"
                :style="inputError ? 'border: 1px solid red' : ''"
                size="small"
              />
              <span v-else>{{ subjectText }} </span>
              <span style="color: red">
                {{ inputError }}
              </span>
            </div>
            <a-select
              v-model="selectedModel"
              style="width: 200px;right: 15px"
              dropdown-class-name="admin-message-brand-create-new-modal__select-dropdown"
              :open="isOpenSelect"
              @change="handleChange"
              @dropdownVisibleChange="handleOpenSelectMode"
            >
              <a-select-option
                v-for="(item, index) in isFirstCreate ? firstSelectOption : selectOption"
                :key="`model_type_${index}`"
                :value="item.id"
              >
                {{ item.description }}
              </a-select-option>
            </a-select>
          </a-row>
          <a-row
            class="admin-message-brand-create-new-modal__choose-model-layer"
            :style="
              isSmallStatus
                ? selectedModel === '0'
                  ? 'height: 270px;'
                  : 'height: 83px;'
                : selectedModel === '0'
                ? 'height: 324px;'
                : 'height: 191px;'
            "
          >
          </a-row>
          <a-row v-if="selectedModel === '1' || selectedModel === '4'">
            <BrandIntroProductTemplate
              v-if="selectedModel === '1'"
              :retailer="retailer"
              :is-type-error="isToError"
              :is-small-status="isSmallStatus"
              :message="$t('Retailers.RetailerMessage.introProductMessage')"
              @products="getIntroProducts"
            />
            <BrandIntroTemplate
              v-else-if="selectedModel === '4'"
              :style="isSmallStatus ? 'width: 400px' : 'width: 600px'"
              :from="brand"
              :to-data="retailer"
            />
          </a-row>
          <a-row :style="isSmallStatus && selectedModel !== '1' ? 'min-height: 138px;' : ''">
            <message-input-component
              :generate-content="contentType"
              :from="brand"
              :to-data="retailer"
              :is-small-status="isSmallStatus"
              :is-has-connection="!isToError"
              @send="sendMessage"
              @sendDraft="sendDraft"
              @hideDialog="hideDialog"
            />
          </a-row>
        </div>
      </div>
      <warn-message-dialog ref="brandOrderWarningDialog" />
    </div>
  </a-modal>
</template>

<script>
import MessageInputComponent from "./MessageInputComponent.vue"
import WarnMessageDialog from "./WarnMessageDialog.vue"
import BrandIntroProductTemplate from "@/components/message/components/BrandIntroProductTemplate"
import BrandIntroTemplate from "@/components/message/components/BrandIntroTemplate"
import { checkBrandRetailerConnection } from "@/utils/validate"
export default {
  components: {
    // "multi-select": Multiselect,
    WarnMessageDialog,
    MessageInputComponent,
    BrandIntroProductTemplate,
    BrandIntroTemplate
  },
  data() {
    return {
      messageData: undefined,
      isSmallStatus: true,
      isOpenSelect: false,
      modalWidth: "561px",
      showCreateMessage: false,
      showOrderInfo: false,
      bodyStyle: {
        padding: "0px"
      },
      toValue: undefined,
      retailer: undefined,
      isFirstCreate: true,
      selectedModel: "0",
      selectedMessageType: undefined,
      contentType: -1,
      selectedProducts: [],
      subjectText: "",
      isToError: false,
      tempOption: { id: "0", name: "Choose Template", description: "Choose Template" },
      firstSelectOption: [],
      selectOption: [],
      dataSource: [],
      inputSubject: undefined,
      inputError: ""
    }
  },
  computed: {
    originReceiverList() {
      let data = this.$store.getters["retailer/retailers"]
      if (!data) {
        data = []
      }
      if (!(data.length > 0 && data[0].id === "*")) {
        data.unshift({ id: "*", name: "Landing" })
      }
      return data
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    retailerProfile() {
      return this.$store.getters["retailer/retailer"]
    },
    messageType() {
      return this.$store.getters["reference/messageType"]
    }
  },
  watch: {
    originReceiverList() {
      this.dataSource = this.originReceiverList.slice(0)
    }
  },
  methods: {
    hideDialog() {
      this.showCreateMessage = false
      this.selectedModel = "0"
      this.toValue = undefined
      this.retailer = undefined
      this.subjectText = ""
      this.contentType = -1
      this.inputError = ""
      this.inputSubject = ""
    },
    clickToSwitch() {
      if (this.isSmallStatus) {
        this.modalWidth = "1020px"
        this.isSmallStatus = false
      } else {
        this.modalWidth = "561px"
        this.isSmallStatus = true
      }
    },
    openDialog() {
      this.isFirstCreate = false
      this.showCreateMessage = true
      this.isSmallStatus = true
      this.selectOption = [this.tempOption]
      let data = this.messageType.filter(item => item.description.indexOf("Brand") < 0)
      this.selectOption.push(...data)
    },
    openDialogFirst(toRetailer) {
      this.isFirstCreate = true
      this.showCreateMessage = true
      this.isSmallStatus = false
      this.modalWidth = "1020px"
      this.toValue = toRetailer.id
      this.retailer = toRetailer
      this.isToError = !checkBrandRetailerConnection(this.brand, toRetailer)
      this.firstSelectOption = []
      this.firstSelectOption.push(this.tempOption)
      if (this.isToError) {
        // no connection
        let data = this.messageType.find(item => item.name.indexOf("brand") > 0)
        if (data) {
          this.firstSelectOption.push(data)
        }
      } else {
        let intro = this.messageType.filter(item => item.name.indexOf("intro") >= 0)
        this.firstSelectOption.push(...intro)
      }
    },
    handleSearch(value) {
      if (!value || value.trim().length === 0) {
        this.dataSource = this.originReceiverList.slice(0)
      } else {
        let result = this.originReceiverList.filter(
          item => item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
        )
        this.dataSource = []
        if (result && result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            this.$set(this.dataSource, i, result[i])
          }
        }
      }
    },
    handleOpenSelectMode(value) {
      if (!(this.retailer && this.retailer.name)) {
        this.isOpenSelect = false
        this.$message.error("Please add user To first")
        return
      }
      this.isOpenSelect = value
    },
    handleChange(value) {
      console.log("....start....")
      console.log(value)
      console.log(this.selectedModel)
      // if (!(this.retailer && this.retailer.name)) {
      //   this.isOpenSelect = false
      //   this.$message.error("Please add user To first")
      //   return
      // }
      //this.isOpenSelect = !this.isOpenSelect
      if (this.selectedModel !== "0") {
        if (this.isFirstCreate) {
          this.selectedMessageType = this.firstSelectOption.find(
            item => item.id === this.selectedModel
          )
        } else {
          this.selectedMessageType = this.selectOption.find(item => item.id === this.selectedModel)
        }
      }
      if (this.selectedModel === "4") {
        this.subjectText = `Introducing ${this.brand.name}`
        this.contentType = 8
        if (this.$route.name !== "brandRetailerProfile") {
          this.showNoConnectionWarnDialog()
          // this.selectedModel = "0"
        }
      } else if (this.selectedModel === "2") {
        let msg = this.$t("Retailers.RetailerMessage.composeOrderMessageWarning")
        this.$refs["brandOrderWarningDialog"].openDialog(msg)
        this.selectedModel = "0"
        this.contentType = -1
      } else if (this.selectedModel === "1") {
        this.subjectText = `New Products from ${this.brand.name}`
        this.contentType = 10
        this.showNoConnectionWarnDialog()
      } else if (this.selectedModel === "3") {
        //custom
        this.contentType = 9
        this.showNoConnectionWarnDialog()
        // this.selectedModel = "0"
      }
      console.log(this.isOpenSelect)
      console.log("....end....")
    },
    showNoConnectionWarnDialog() {
      if (this.isToError) {
        let msg = this.$t("Retailers.RetailerMessage.noRelationShipMessageWarining")
        this.$refs["brandOrderWarningDialog"].openDialog(msg)
        this.selectedModel = "0"
        this.contentType = -1
      }
    },
    getIntroProducts(data) {
      this.selectedProducts = data
    },
    tagNameById({ id }) {
      const tag = this.dataSource.find(tag => tag.id === id)
      return tag && tag.name
    },
    onSelectToTarget(data) {
      this.retailer = this.dataSource.find(item => item.id === data)
      if (data !== "*") {
        this.isToError = !checkBrandRetailerConnection(this.brand, this.retailer)
      } else {
        this.isToError = false
      }
    },
    sendDraft(param, fileList) {
      if (this.selectedModel === "0") {
        this.$message.warning("Please choose a message type")
        return
      }
      if (!this.toValue) {
        this.$message.warning("Please choose a retailer")
        return
      }
      if (
        this.selectedModel === "1" &&
        (!this.selectedProducts || this.selectedProducts.length === 0)
      ) {
        this.$message.warning("Please at least choose a product.")
        return
      }
      let productsParam = []
      let filesParam = []
      if (this.selectedProducts && this.selectedProducts.length > 0) {
        for (let i = 0; i < this.selectedProducts.length; i++) {
          productsParam.push({
            privateId: i + 1,
            product: {
              id: this.selectedProducts[i].id
            }
          })
        }
      }
      if (fileList && fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          filesParam.push({
            privateId: i + 1,
            fileAsset: {
              contentType: fileList[i].contentType,
              id: fileList[i].id,
              url: fileList[i].url
            }
          })
        }
      }
      let sendParam = {
        senderClientId: this.brand.id,
        // category: {
        //   id: "Department Store1"
        // },
        messageType: {
          id: this.selectedMessageType.description
        },
        subject: this.subjectText,
        message:
          this.selectedModel === "1"
            ? `${this.$t("Retailers.RetailerMessage.introProductMessage")} ${
                param && param.length > 0 ? "<br/>" + param : ""
              } `
            : param,
        archive: false,
        drafts: true,
        isActive: true,
        messageReceivers: [
          {
            privateId: 1,
            receiverClientId: this.retailer.id,
            isRead: false,
            isActive: true,
            archive: false
          }
        ]
      }
      if (productsParam && productsParam.length > 0) {
        sendParam["messageProducts"] = productsParam
      }
      if (filesParam && filesParam.length > 0) {
        sendParam["messageFiles"] = filesParam
      }
      this.$store
        .dispatch("adminMessage/createMessage", sendParam)
        .then(() => {
          this.$emit("refreshData")
          this.hideDialog()
        })
        .catch(err => {
          console.log(err)
        })
    },
    sendMessage(param, fileList) {
      if (this.selectedModel === "0") {
        this.$message.warning("Please choose a message type")
        return
      }
      if (!this.toValue) {
        this.$message.warning("Please choose a retailer")
        return
      }
      if (!this.isToError && this.selectedModel === "3" && !this.inputSubject) {
        this.inputError = "mandatory field"
        return
      }
      if (
        this.selectedModel === "1" &&
        (!this.selectedProducts || this.selectedProducts.length === 0)
      ) {
        this.$message.warning("Please at least choose a product.")
        return
      }
      let productsParam = []
      let filesParam = []
      if (this.selectedProducts && this.selectedProducts.length > 0) {
        for (let i = 0; i < this.selectedProducts.length; i++) {
          productsParam.push({
            privateId: i + 1,
            product: {
              id: this.selectedProducts[i].id
            }
          })
        }
      }
      if (fileList && fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          filesParam.push({
            privateId: i + 1,
            fileAsset: {
              contentType: fileList[i].contentType,
              id: fileList[i].id,
              url: fileList[i].url,
              ...fileList[i]
            }
          })
        }
      }
      let sendParam = {
        senderClientId: this.brand.id,
        // category: {
        //   id: "Department Store1"
        // },
        messageType: {
          id: this.selectedMessageType.description
        },
        subject: this.subjectText ? this.subjectText : this.inputSubject,
        message:
          this.selectedModel === "1"
            ? `${this.$t("Retailers.RetailerMessage.introProductMessage")} ${
                param && param.length > 0 ? "<br/>" + param : ""
              } `
            : param,
        archive: false,
        drafts: false,
        isActive: true,
        messageReceivers: [
          {
            privateId: 1,
            receiverClientId: this.retailer.id,
            isRead: false,
            isActive: true,
            archive: false
          }
        ]
      }
      if (productsParam && productsParam.length > 0) {
        sendParam["messageProducts"] = productsParam
      }
      if (filesParam && filesParam.length > 0) {
        sendParam["messageFiles"] = filesParam
      }
      this.$store
        .dispatch("adminMessage/createMessage", sendParam)
        .then(() => {
          this.$emit("refreshData")
          this.hideDialog()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
<style lang="scss">
.admin-message-brand-create-new-modal {
  //position: relative;
  //.message-new-modal-content {
  //  position: absolute;
  //  right: 10px;
  //  bottom: 20px;
  //}
  &__outer {
    padding-top: 40px;
    padding-bottom: 47px;
    background-color: #fff;
    height: 100%;
  }
  &__title {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;
    color: #262626;
  }
  &__large-icon {
    color: #595959;
    font-size: 14px;
  }
  &__box-content {
    background: #ffffff;
    border: 1px solid rgba(74, 138, 93, 0.46);
    box-sizing: border-box;
    border-radius: 4px;
  }
  &__choose-model-layer {
    border-bottom: 1px solid rgba(74, 138, 93, 0.46);
    vertical-align: middle;
  }
  &__subject {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    padding-right: 4px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 4px;
  }
  &__intro-brand {
    //width: 600px;
    min-height: 300px;
    background: #f5f5f5;
    border-radius: 16px;
    padding: 15px 20px;
  }
  &__intro-brand-title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  &__intro-brand-subtitle {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
  }
  &__intro-brand-divider {
    border-bottom: 1px solid #d9d9d9;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &__intro-brand-img {
    border-radius: 4px;
    width: 680px;
    height: 151px;
    margin-bottom: 20px;
  }
  &__intro-brand-content {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    width: inherit;
  }
  &__intro-product {
    background: #f5f5f5;
    border-radius: 16px;
    padding: 13px 20px;
  }
  &__intro-product-title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 38px;
    color: #262626;
  }
  &__intro-product-desc {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    margin-bottom: 14px;
  }
  &__intro-product-list-item-outer {
    width: 141px;
    height: 195px;
    position: relative;
    background-color: inherit;
    // display: inline-block;
    float: left;
  }
  &__intro-product-list-item-icon {
    font-size: 15px;
    color: #4a8a5d;
    position: absolute;
    top: 2px;
    right: 12px;
    // margin-top: 2px;
    // float: right;
    // margin-right: 12px;
  }
  &__intro-product-list-item {
    width: 123px;
    height: 185px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 18px;
    display: inline-block;
    margin-top: 8px;
  }
  &__intro-product-list-item-no-product {
    width: 123px;
    height: 185px;
    background: #ffffff;
    border: 1px dashed #999999;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 18px;
    display: inline-block;
    margin-top: 8px;
  }
  &__intro-product-list-item-text {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 12px;
    position: absolute;
    bottom: 7px;
    width: 119px;
    padding: 0px 2px;
    text-align: center;
  }
  &__add-product {
    background: #4a8a5d;
    border-radius: 2px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
  }
  &__sign-txt {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    margin-top: 14px;
  }
}
</style>
<style lang="scss">
// .admin-message-brand-create-new-modal__outer {
//   padding-top: 40px;
//   padding-bottom: 47px;
//   background-color: #fff;
// }
.order_info_dialog .order_info_layer {
  height: 52px;
  padding: 10px 0px;
}
.order_info_content {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: 339px;
  margin-left: 16px;
  float: left;
}
.admin-message-brand-create-new-modal__choose-model-layer
  .admin-message-brand-create-new-modal__toTarget.ant-select {
  line-height: 28px;
  position: absolute;
  top: 0px;
}
.admin-message-brand-create-new-modal__choose-model-layer
  .admin-message-brand-to-error.admin-message-brand-create-new-modal__toTarget.ant-select
  .ant-select-selection-selected-value {
  color: #ff4d4f;
}
.admin-message-brand-create-new-modal__choose-model-layer .ant-select-selection--single {
  height: 30px;
}
.admin-message-brand-create-new-modal__choose-model-layer .ant-select-selection {
  background: rgba(74, 138, 93, 0.11);
  border: 1px dashed rgba(74, 138, 93, 0.46);
  box-sizing: border-box;
  border-radius: 2px;
}
.admin-message-brand-create-new-modal__choose-model-layer .ant-select-selection-selected-value {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: #4a8a5d;
}
.admin-message-brand-create-new-modal__choose-model-layer .ant-select-arrow {
  color: #4a8a5d;
}
.admin-message-brand-create-new-modal__choose-model-layer .ant-select-selection:hover,
.admin-message-brand-create-new-modal__choose-model-layer .ant-select-selection:focus,
.admin-message-brand-create-new-modal__choose-model-layer .ant-select-selection:active,
.admin-message-brand-create-new-modal__choose-model-layer .ant-select-open .ant-select-selection,
.admin-message-brand-create-new-modal__choose-model-layer
  .ant-select-focused
  .ant-select-selection {
  border-color: #4a8a5d;
  box-shadow: none;
}
.admin-message-brand-create-new-modal__choose-model-layer .ant-select {
  line-height: 30px;
  position: absolute;
  top: 6px;
}
.admin-message-brand-create-new-modal__select-dropdown .ant-select-dropdown-menu-item-active,
.admin-message-brand-create-new-modal__select-dropdown
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.admin-message-brand-create-new-modal__select-dropdown
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  color: #262626;
  background-color: rgba(74, 138, 93, 0.15);
}
.admin-message-brand-create-new-modal .ant-btn:hover,
.admin-message-brand-create-new-modal .ant-btn:active,
.admin-message-brand-create-new-modal .ant-btn:focus,
.admin-message-brand-create-new-modal .ant-btn.active {
  color: #fff;
  background-color: #4a8a5d;
  border: none;
}
.admin-message-brand-create-new-modal__choose-model-layer .multiselect {
  min-height: 30px;
  height: 30px;
}
.admin-message-brand-create-new-modal__choose-model-layer .multiselect__select {
  display: none;
}
.admin-message-brand-create-new-modal__choose-model-layer .multiselect__tags {
  min-height: 30px;
  padding: 8px;
}
.admin-message-brand-create-new-modal__choose-model-layer .multiselect__input {
  height: 15px;
  line-height: 15px;
}
.admin-message-brand-create-new-modal__choose-model-layer .multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 0px;
  padding-top: 0px;
  position: absolute;
  top: 4px;
}
</style>
