<template>
  <div class="brand-training-product-table__main">
    <div class="training-page-title">
      <div class="training-logo"></div>
      <div class="title-button">
        <a-button class="btn-style" @click="jumpToCreateTraining">
          Add BeautyFluent Retailer
        </a-button>
      </div>
    </div>
    <div class="training-page-table">
      <table class="training-table-style">
        <thead>
          <tr class="table-tr-header">
            <th style="width:130px;">
              Name
              <a-icon
                style="cursor: pointer"
                :type="isRetailerNameSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('retailerName')"
              ></a-icon>
            </th>
            <th style="width:123px;">
              Products
              <a-icon
                style="cursor: pointer"
                :type="isProductsSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('products')"
              ></a-icon>
            </th>
            <th style="width:115px;">
              Published
              <a-icon
                style="cursor: pointer"
                :type="isPublishedSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('published')"
              ></a-icon>
            </th>
            <th style="width:109px;">
              Public
              <a-icon
                style="cursor: pointer"
                :type="isPublicSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('public')"
              ></a-icon>
            </th>
            <th style="width:109px;">
              Start Date
              <a-icon
                style="cursor: pointer"
                :type="isModifiedSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('StartDate')"
              ></a-icon>
            </th>
            <th style="width:109px;">
              End Date
              <a-icon
                style="cursor: pointer"
                :type="isModifiedSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('endDate')"
              ></a-icon>
            </th>
            <th style="width:142px;">
              Billing Cycle
              <a-icon
                style="cursor: pointer"
                :type="isBillingCycle ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('billingCycle')"
              ></a-icon>
            </th>
            <th style="width:109px;">
              Dedicated Training URL
              <!--              <a-icon-->
              <!--                style="cursor: pointer"-->
              <!--                :type="isModifiedSort ? 'caret-down' : 'caret-up'"-->
              <!--                class="icon-sort-style"-->
              <!--                @click="sortList('billingCycle')"-->
              <!--              ></a-icon>-->
            </th>
            <!--            <th style="width:109px;">-->
            <!--              Modified-->
            <!--              <a-icon-->
            <!--                style="cursor: pointer"-->
            <!--                :type="isModifiedSort ? 'caret-down' : 'caret-up'"-->
            <!--                class="icon-sort-style"-->
            <!--                @click="sortList('modified')"-->
            <!--              ></a-icon>-->
            <!--            </th>-->
            <th style="width:109px;">
              Public
            </th>
            <th style="width:116px;">
              Action
            </th>
          </tr>
        </thead>
        <div v-if="tableDate && tableDate.length === 0" class="no-table-data">
          <a-empty />
        </div>
        <tr v-for="item in tableDate" :key="item.name">
          <td style="width:130px;" class="name-tab-style">{{ item.retailerName }}</td>
          <td style="width:123px;" class="">{{ item.productCount }}</td>
          <td style="width:115px;" class="data-tab-style">{{ item.publishedCount }}</td>
          <td style="width:115px;" class="data-tab-style">{{ item.publicCount }}</td>
          <td style="width:115px;" class="data-tab-style">
            {{ item.startDate ? item.startDate.slice(0, 10) : "--" }}
          </td>
          <td style="width:115px;" class="data-tab-style">
            {{ item.endDate ? item.endDate.slice(0, 10) : "--" }}
          </td>
          <td style="width:115px;" class="data-tab-style">
            {{ item.subscriptionTerms }}
          </td>
          <td style="width:115px;" class="data-tab-style">
            <a
              v-if="item.retailerShortName"
              :href="`https://${getTrainingUrl(item.retailerShortName)}`"
              style="color: #4a8a5d"
              target="_blank"
            >
              {{ getTrainingUrl(item.retailerShortName) }}
            </a>
            <a
              v-else
              :href="`https://${getTrainingUrl(item.retailerName)}`"
              style="color: #4a8a5d"
              target="_blank"
            >
              {{ getTrainingUrl(item.retailerName) }}
            </a>
          </td>
          <td style="width:109px" class="click-pro-action">
            <div class="editable-row-operations">
              <a-switch v-model="item.public" @change="onChangePublic(item)" />
            </div>
          </td>
          <td style="width:116px" class="click-pro-action">
            <div class="editable-row-operations">
              <span @click="handleViewItem(item)">
                View
              </span>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <!--    <div v-if="!showShareBtn" class="no-data">-->
    <!--      {{ this.$t("Training.brandTraining.press") }}-->
    <!--      <span class="no-date-btn">-->
    <!--        {{ this.$t("Training.brandTraining.createNew") }}-->
    <!--      </span>-->
    <!--      {{ this.$t("Training.brandTraining.addProducts") }}-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "BrandTrainingProductTable",
  props: {
    tableDate: {
      type: Array
    },
    showShareBtn: {
      type: Boolean
    }
  },
  data() {
    return {
      // isRetailerList: true
      isRetailerNameSort: false,
      isProductsSort: false,
      isPublishedSort: false,
      isPublicSort: false,
      isModifiedSort: false,
      isBillingCycle: false
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    }
  },
  methods: {
    getTrainingUrl(retailerName) {
      return `${window.location.host}/training/${this.brand.shortName}/${retailerName}`
    },
    jumpToCreateTraining() {
      // if (this.$route.params.brandId) {
      //   this.$router.push({
      //     name: "AdminbrandTrainingCreate"
      //   })
      // } else {
      //   this.$router.push({
      //     name: "BrandTrainingCreate"
      //   })
      // }
      // this.$emit("addNewRetailerStep", step)
      this.$emit("addNewRetailer")
    },
    handleViewItem(item) {
      // this.isRetailerList = false
      this.$emit("viewRetailerItemProduct", item)
    },
    // Modify retailer public
    onChangePublic(item) {
      const param = {
        retailerId: item.retailerId,
        public: item.public
      }
      this.$emit("updateTrainingRetailer", [param])
    },
    async sortList(sortValue) {
      const info = this.formatSortValue(sortValue)
      this.handleName(info["_sort-by"], info.isAscend)
      // const sortInfo = this.formatSortValue(sortValue)
      // let params = {
      //   _index: 1,
      //   _limit: this.currentNum,
      //   ...sortInfo
      // }
      // await this.fetchData(params)
    },
    formatSortValue(value) {
      // this.initSort()
      if (value === "retailerName") {
        this.isRetailerNameSort = !this.isRetailerNameSort
        this.isProductsSort = false
        this.isPublishedSort = false
        this.isPublicSort = false
        this.isModifiedSort = false
        this.isBillingCycle = false
        return {
          "_order-by": this.isRetailerNameSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isRetailerNameSort
        }
      }
      if (value === "products") {
        this.isProductsSort = !this.isProductsSort
        this.isRetailerNameSort = false
        this.isPublishedSort = false
        this.isPublicSort = false
        this.isModifiedSort = false
        this.isBillingCycle = false
        return {
          "_order-by": this.isProductsSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isProductsSort
        }
      }
      if (value === "published") {
        this.isPublishedSort = !this.isPublishedSort
        this.isRetailerNameSort = false
        this.isProductsSort = false
        this.isPublicSort = false
        this.isModifiedSort = false
        this.isBillingCycle = false
        return {
          "_order-by": this.isPublishedSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isPublishedSort
        }
      }
      if (value === "public") {
        this.isPublicSort = !this.isPublicSort
        this.isRetailerNameSort = false
        this.isProductsSort = false
        this.isPublishedSort = false
        this.isModifiedSort = false
        this.isBillingCycle = false
        return {
          "_order-by": this.isPublicSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isPublicSort
        }
      }
      if (value === "modified") {
        this.isModifiedSort = !this.isModifiedSort
        this.isRetailerNameSort = false
        this.isProductsSort = false
        this.isPublishedSort = false
        this.isPublicSort = false
        this.isBillingCycle = false
        return {
          "_order-by": this.isModifiedSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isModifiedSort
        }
      }
      if (value === "billingCycle") {
        this.isBillingCycle = !this.isBillingCycle
        this.isRetailerNameSort = false
        this.isProductsSort = false
        this.isPublishedSort = false
        this.isPublicSort = false
        this.isModifiedSort = false
        return {
          "_order-by": this.isBillingCycle ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isBillingCycle
        }
      }
    },
    handleName(sorter, isAscend) {
      let index
      if (sorter === "retailerName") {
        index = 1
      } else if (sorter === "products") {
        index = 4
      } else if (sorter === "published") {
        index = 2
      } else if (sorter === "public") {
        index = 5
      } else if (sorter === "modified") {
        index = 6
      } else if (sorter === "billingCycle") {
        index = 7
      }
      this.sortData(index, isAscend)
    },
    sortData(index, ascend) {
      if (index == 1) {
        this.tableDate.sort((a, b) => {
          a.name = a.retailerName.replace(/^\s*|\s*$/g, "")
          b.name = b.retailerName.replace(/^\s*|\s*$/g, "")
          if (ascend) {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          } else {
            return b.name.toLowerCase().localeCompare(a.name.toLowerCase())
          }
        })
      } else if (index == 4) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            return a.productCount - b.productCount
          } else {
            return b.productCount - a.productCount
          }
        })
      } else if (index == 2) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            return a.publishedCount - b.publishedCount
          } else {
            return b.publishedCount - a.publishedCount
          }
        })
      } else if (index == 5) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            return a.publicCount - b.publicCount
          } else {
            return b.publicCount - a.publicCount
          }
        })
      } else if (index == 6) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            if (a.updatedAt != null && b.updatedAt != null) {
              return a.updatedAt.localeCompare(b.updatedAt)
            }
            if (a.updatedAt != null && b.updatedAt == null) {
              return a.updatedAt.localeCompare(b.createdAt)
            }
            if (a.updatedAt == null && b.updatedAt != null) {
              return a.createdAt.localeCompare(b.updatedAt)
            }
            if (!a.updatedAt && !b.updatedAt) {
              return a.createdAt.localeCompare(b.createdAt)
            }
          } else {
            if (b.updatedAt != null && b.updatedAt != null) {
              return b.updatedAt.localeCompare(a.updatedAt)
            }
            if (b.updatedAt != null && a.updatedAt == null) {
              return b.updatedAt.localeCompare(a.createdAt)
            }
            if (b.updatedAt == null && a.updatedAt != null) {
              return b.createdAt.localeCompare(a.updatedAt)
            }
            if (!a.updatedAt && !b.updatedAt) {
              return b.createdAt.localeCompare(a.createdAt)
            }
          }
        })
      } else if (index == 7) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            return a.subscriptionTerms
              .toLowerCase()
              .localeCompare(b.subscriptionTerms.toLowerCase())
          } else {
            return b.subscriptionTerms
              .toLowerCase()
              .localeCompare(a.subscriptionTerms.toLowerCase())
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.brand-training-product-table__main {
  .training-page-title {
    width: 100%;
    height: 94px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .training-logo {
    font-size: 48px;
    font-weight: bold;
    color: #000000;
    font-family: "Playfair Display";
  }
  .title-button {
    width: 415px;
    text-align: right;
    //display: flex;
    //justify-content: space-between;
    .btn-style {
      width: 186px;
      height: 30px;
      font-family: "Proxima Nova";
      //line-height: 20px;
      //padding: 5px 16px;
      font-size: 14px;
      border: 2px solid #e19387;
      color: #ffffff;
      background: #e19387;
    }
    .btn-style:nth-child(2) {
      margin: 0 26px;
      width: 140px;
    }
    .btn-style:hover {
      color: white;
      background: #f27c59;
    }
  }
  .no-data {
    font-size: 16px;
    font-family: "Proxima Nova";
    color: #bfbfbf;
    height: 30px;
    width: 100%;
    margin: 30px 0;
    text-align: center;
    span {
      display: inline-block;
      border: 1px solid #bfbfbf;
      width: 106px;
      height: 30px;
      line-height: 30px;
      border-radius: 3px;
    }
  }
  .no-table-data {
    width: 100%;
    height: 270px;
    text-align: center;
    color: #bfbfbf;
    font-size: 16px;
    padding-top: 80px;
    position: relative;
    left: 350px;
  }
  .training-page-table {
    //width: 952px;
    //position: relative;
    .training-table-style {
      overflow: visible;
      width: 100%;
      color: #262626;
      //border: #e8e8e8 solid 1px;
      border-collapse: collapse;
    }
    .training-table-style tr {
      width: 100%;
      position: relative;
    }
    .training-table-style th {
      border-top: #e8e8e8 solid 1px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 16px 8px;
      //background-color: #fafafa;
      font-size: 14px;
      font-weight: normal;
    }
    .training-table-style td {
      height: 56px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 17px 8px;
      background-color: #ffffff;
      color: #595959;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .training-table-style tr,
    .training-table-style td.move:hover {
      //cursor: pointer;
    }
    .training-table-style td.click-pro-action:hover {
      cursor: pointer;
    }
    .name-tab-style {
      position: relative;
      span {
        position: absolute;
        right: 16px;
        top: 15px;
      }
    }
    .data-tab-style {
      //position: relative;
    }
  }
  .ant-pagination-item-active {
    border-color: #4a8a5d;
  }
  .ant-switch-checked {
    background-color: #9fd19a;
  }
  .editable-row-operations {
    color: #4a8a5d;
  }
  .icon-sort-style {
    color: #8c8c8c;
  }
}
</style>
