<template>
  <div>
    <p class="brand-setting-subscription__title">
      {{ $t("Brands.BrandSettings.subscriptionPlatform") }}
    </p>
    <span class="brand-line"></span>
    <p v-if="!canShowSubscription(status)" class="brand-not-approved">
      {{ $t("Brands.BrandSettings.brandNotApprovedMsg") }}
    </p>
    <div v-else>
      <a-row class="brand-line__main-block brand-settings__first-row">
        <a-col :span="6" class="brand-line__left-block">
          <p class="brand-setting-subscription__left-text">
            {{ $t("Brands.BrandSettings.planType") }}
          </p>
        </a-col>
        <a-col :span="10" class="brand-line__right-block">
          <p class="brand-setting-subscription__right-text">{{ planType || "Basic" }}</p>
        </a-col>
        <a-col :span="8" class="subscribeActionCntnr">
          <a
            class="upgrade-button button brand-settings__account-modal-form brand-setting-subscription__btn-txt"
            style="margin-right: 10px; margin-bottom: 2px;"
            @click="clickToBrandTrainingPage"
          >
            Join BeautyFluent
            <!--            {{ $t("Brands.BrandSettings.upgradePlan") }}-->
            <!--            Change Subscription-->
          </a>
          <a
            v-if="brand.subscriptions[0].status.id === 'expired' && isAdmin"
            class="upgrade-button button brand-settings__account-modal-form brand-setting-subscription__btn-txt"
            @click="changePlanType(planTypeText.numberType)"
          >
            {{ planTypeText.text }}
            <!--            {{ $t("Brands.BrandSettings.upgradePlan") }}-->
            <!--            Change Subscription-->
          </a>
          <a
            v-else-if="brand.subscriptions[0].status.id !== 'expired'"
            class="upgrade-button button brand-settings__account-modal-form brand-setting-subscription__btn-txt"
            @click="changePlanType(planTypeText.numberType)"
          >
            {{ planTypeText.text }}
            <!--            {{ $t("Brands.BrandSettings.upgradePlan") }}-->
            <!--            Change Subscription-->
          </a>
          <!--          <a-->
          <!--            v-if="!isAdmin && planType !== 'Basic'"-->
          <!--            class="upgrade-button button brand-settings__account-modal-form"-->
          <!--            @click="showCancelSubscription"-->
          <!--          >-->
          <!--            {{ $t("Brands.BrandSettings.cancelSubscription") }}-->
          <!--          </a>-->
          <!--          <a-->
          <!--            v-if="isAdmin"-->
          <!--            class="upgrade-button button brand-settings__account-modal-form"-->
          <!--            @click="showUpdatePlanModal(planType)"-->
          <!--          >-->
          <!--            {{-->
          <!--              planType && planType !== "Subscriber" ? "Change Subscription" : "Cancel Subscription"-->
          <!--            }}-->
          <!--          </a>-->
        </a-col>
      </a-row>
      <a-row class="brand-line__main-block">
        <a-col :span="6" class="brand-line__left-block">
          <p class="brand-setting-subscription__left-text">
            {{ $t("Brands.BrandSettings.products") }}
          </p>
        </a-col>
        <a-col :span="10" class="brand-line__right-block">
          <p class="brand-setting-subscription__right-text">
            {{ productCount }} {{ $t("Brands.BrandSettings.outOf") }}
            {{ scopes.maxProducts > 10000 ? "Unlimited" : scopes.maxProducts }}
            {{ $t("Brands.BrandSettings.productUploaded") }}
          </p>
        </a-col>
        <a-col :span="8" class="subscribeActionCntnr">
          <a
            v-if="isAdmin"
            class="upgrade-button button brand-settings__account-modal-form brand-setting-subscription__btn-txt"
            @click="showUpdateMaxProduct"
          >
            {{ $t("Brands.BrandSettings.edit") }}
          </a>
        </a-col>
      </a-row>
      <a-row class="brand-line__main-block">
        <a-col :span="6" class="brand-line__left-block">
          <p class="brand-setting-subscription__left-text">Transaction Fee</p>
        </a-col>
        <a-col :span="10" class="brand-line__right-block">
          <p class="brand-setting-subscription__right-text">{{ commissions }}%</p>
          <a
            :class="{ 'override-link': !isOverride }"
            class="brand-settings__account overridesLink "
            @click="typeBtnClick"
          >
            {{ $t("Brands.BrandSettings.viewOverrides") }}
          </a>
        </a-col>
        <a-col :span="8" class="subscribeActionCntnr">
          <a
            v-if="isAdmin"
            class="upgrade-button button brand-settings__account-modal-form brand-setting-subscription__btn-txt"
            @click="showUpdateCommissionModal"
          >
            {{ $t("Brands.BrandSettings.edit") }}
          </a>
        </a-col>
      </a-row>
      <a-row class="brand-line__main-block">
        <a-col :span="6" class="brand-line__left-block">
          <p class="brand-setting-subscription__left-text">Renewal Date</p>
        </a-col>
        <a-col :span="10" class="brand-line__right-block">
          <p class="brand-setting-subscription__right-text">{{ renewalDate }}</p>
        </a-col>
        <!--        <a-col :span="8" class="subscribeActionCntnr">-->
        <!--          <a-->
        <!--            v-if="isAdmin"-->
        <!--            class="upgrade-button button brand-settings__account-modal-form"-->
        <!--            @click="showUpdateCommissionModal"-->
        <!--          >-->
        <!--            {{ $t("Brands.BrandSettings.edit") }}-->
        <!--          </a>-->
        <!--        </a-col>-->
      </a-row>
      <!--      <a-row-->
      <!--        v-if="brandType !== 'basic' && subscription.autoRenewal === false"-->
      <!--        class="brand-line__main-block"-->
      <!--      >-->
      <!--        <a-col :span="6" class="brand-line__left-block">-->
      <!--          <p class="brand-settings__account-information_text">-->
      <!--            {{ $t("Brands.BrandSettings.cancelDate") }}-->
      <!--          </p>-->
      <!--        </a-col>-->
      <!--        <a-col :span="10" class="brand-line__right-block">-->
      <!--          <p class="brand-settings__account-information_text textfont">-->
      <!--            {{ formatDate(subscription.unsubscribeTime) }}-->
      <!--          </p>-->
      <!--        </a-col>-->
      <!--        <a-col :span="8" class="subscribeActionCntnr"></a-col>-->
      <!--      </a-row>-->
      <a-row v-if="isAdmin" class="brand-line__main-block">
        <a-col :span="6" class="brand-line__left-block">
          <p class="brand-setting-subscription__left-text">
            {{ $t("Brands.BrandSettings.subscriptionHistory") }}
          </p>
        </a-col>
        <a-col :span="10" class="brand-line__right-block">
          <p class="brand-setting-subscription__right-text" />
        </a-col>
        <a-col :span="8" class="subscribeActionCntnr">
          <a
            class="upgrade-button button brand-settings__account-modal-form brand-setting-subscription__btn-txt"
            @click="showOrHideList"
          >
            {{
              isShowHistoryList
                ? $t("Brands.BrandSettings.hideList")
                : $t("Brands.BrandSettings.view")
            }}
          </a>
        </a-col>
      </a-row>
      <a-row v-if="isAdmin && isShowHistoryList" class="brand-line__main-block">
        <a-table
          class="history-list"
          :columns="columns"
          :data-source="historyList"
          :pagination="isMoreThanOnePage"
        >
          <span slot="customTitle" class="table-title">{{
            $t("Brands.BrandSettings.startDate")
          }}</span>
          <span slot="customTitle1" class="table-title">{{
            $t("Brands.BrandSettings.activities")
          }}</span>
          <span slot="customTitle2" class="table-title">{{
            $t("Brands.BrandSettings.endDate")
          }}</span>
          <span slot="startDate" slot-scope="text" class="table-content">{{
            formatDate(text)
          }}</span>
          <span slot="action" slot-scope="text, record" class="table-content">{{
            record.subscriptionId && record.endDate
              ? $t("Brands.BrandSettings.upgrade")
              : $t("Brands.BrandSettings.downloadGrade")
          }}</span>
          <span slot="endDate" slot-scope="endTime" class="table-content">{{
            formatDate(endTime)
          }}</span>
        </a-table>
      </a-row>
    </div>
    <div>
      <BrandTableDialog ref="upgradeDialog"></BrandTableDialog>
      <UpdateMaxProduct ref="brandSettingUpdateMaxProductDialog" :brand="brand" />
      <UpdateMaxOrderDialog ref="brandSettingUpdateMaxOrderDialog" :brand="brand" />
      <UpdateCommissionDialog ref="brandSettingUpdateCommissionDialog" :commission="commissions" />
      <UpgradePlanDialog ref="brandSettingUpgradePlanDialog" :brand-type="brandType" />
      <CancelSubscriptionDialog ref="cancelSubscriptionDialog" :data="subscription" />
      <CancelSubscriptionDialogNew ref="cancelSubscriptionDialogNew" />
      <addNewCardDialog
        ref="addNewCardDialog"
        @openAddMoreCardDialog="openAddMoreCardDialog"
      ></addNewCardDialog>
      <cardListDialog
        ref="cardListDialog"
        @openAddPaymentDialog="openAddPaymentDialog"
      ></cardListDialog>
      <StartTrialDialog ref="StartTrialDialog"></StartTrialDialog>
    </div>
  </div>
</template>

<script>
import { Row, Col, Table } from "ant-design-vue"
import BrandTableDialog from "@/components/brands/mainPage/components/BrandTableDialog"
import UpgradePlanDialog from "@/components/shared/UpgradePlanDialog"
import UpdateCommissionDialog from "@/components/brands/settings/UpdateCommissionDialog"
import UpdateMaxOrderDialog from "@/components/brands/settings/UpdateMaxOrderDialog"
import UpdateMaxProduct from "@/components/brands/settings/UpdateMaxProduct"
import CancelSubscriptionDialog from "@/components/brands/settings/CancelSubscriptionDialog"
import CancelSubscriptionDialogNew from "@/components/brands/settings/CancelSubscriptionDialogNew"
import addNewCardDialog from "@/components/brands/settings/addPaymentCardDialog"
import cardListDialog from "@/components/brands/settings/addMoreCardDialog"
import StartTrialDialog from "@/components/brands/settings/StartTrialDialog"

const PlanTypeButton = {
  cancelButton: 1,
  resumeButton: 2,
  upgradeButton: 3,
  changeButton: 4,
  startTrialButton: 5
}

export default {
  components: {
    BrandTableDialog,
    UpgradePlanDialog,
    UpdateCommissionDialog,
    UpdateMaxOrderDialog,
    UpdateMaxProduct,
    CancelSubscriptionDialog,
    CancelSubscriptionDialogNew,
    addNewCardDialog,
    cardListDialog,
    StartTrialDialog,
    "a-row": Row,
    "a-col": Col,
    "a-table": Table
  },
  data() {
    return {
      isMoreThanOnePage: false,
      isShowHistoryList: false,
      columns: [
        {
          dataIndex: "startDate",
          key: "startDate",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "startDate" }
        },
        {
          dataIndex: "actionType",
          key: "actionType",
          slots: { title: "customTitle1" },
          scopedSlots: { customRender: "action" }
        },
        {
          dataIndex: "endDate",
          key: "endDate",
          slots: { title: "customTitle2" },
          scopedSlots: { customRender: "endDate" }
        }
      ]
    }
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    },
    historyList() {
      return this.brand.subscriptionHistory
    },
    productCount() {
      return (this.brand.productCount && this.brand.productCount.count) || 0
    },
    brandType() {
      if (
        this.brand.subscriptions &&
        this.brand.subscriptions.length > 0 &&
        this.brand.subscriptions[0].type &&
        this.brand.subscriptions[0].type.id
      ) {
        return this.brand.subscriptions[0].type.id
      }
      return ""
    },
    subscription() {
      if (this.brand && this.brand.subscriptions && this.brand.subscriptions.length > 0) {
        return this.brand.subscriptions[0]
      }
      return {}
    },
    scopes() {
      return this.brand.scopes || {}
    },
    planType() {
      if (this.brand && this.brand.subscriptions && this.brand.subscriptions.length === 0) {
        if (
          this.brand.brandTrainingSubscriptions &&
          this.brand.brandTrainingSubscriptions.length > 0
        ) {
          return "Subscriber"
        }
        return ""
      }
      if (
        this.brand.subscriptions[0].status &&
        this.brand.subscriptions[0].status.id === "cancelled" &&
        this.brand.subscriptions[0].type.name === "Trial"
      ) {
        return `${this.brand.subscriptions[0].type.name} (${this.brand.subscriptions[0].status.name})`
      }
      if (
        this.brand.subscriptions[0].status &&
        this.brand.subscriptions[0].status.id === "cancelled" &&
        this.brand.subscriptions[0].type.name === "Subscriber"
      ) {
        return `${this.brand.subscriptions[0].type.name} (${this.brand.subscriptions[0].status.name})`
      }
      if (
        this.brand.subscriptions[0].status &&
        this.brand.subscriptions[0].status.id === "expired"
      ) {
        return `${this.brand.subscriptions[0].type.name} (${this.brand.subscriptions[0].status.name})`
      }
      return (
        this.brand &&
        this.brand.subscriptions &&
        this.brand.subscriptions[0] &&
        this.brand.subscriptions[0].type.name
      )
    },
    renewalDate() {
      return (
        this.brand &&
        this.brand.subscriptions &&
        this.brand.subscriptions[0] &&
        this.brand.subscriptions[0].endDate
      )
    },
    commissions() {
      let result = -1
      let brandId =
        this.$route.name == "adminBrandSettingsSubscription"
          ? this.$route.params.brandId
          : this.$store.getters["user/businessId"]
      if (this.brand && this.brand.transactionFeeTerms) {
        let commissionOverrides = this.brand.transactionFeeTerms.filter(
          item => item.brandId === brandId && item.standard && item.retailerId === "*"
        )
        if (commissionOverrides.length) {
          result = commissionOverrides[0].transactionFeeValue
        } else {
          let basicCommision = this.brand.transactionFeeTerms.filter(
            item => item.brandId === "*" && item.standard && item.retailerId === "*"
          )
          if (basicCommision.length) {
            result = basicCommision[0].transactionFeeValue
          }
        }
      }
      if (result >= 0) {
        result = +((result / 100) * 100).toFixed(2)
      } else {
        result = 0
      }
      return result
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    isOverride() {
      let brandId =
        this.$route.name == "adminBrandSettingsSubscription"
          ? this.$route.params.brandId
          : this.$store.getters["user/businessId"]
      if (this.brand && this.brand.commissions) {
        let IsOverrides = this.brand.commissions.filter(
          item => item.brand.id == brandId && item.product.id != "*"
        )
        if (IsOverrides.length) {
          return true
        }
      }
      return false
    },
    status() {
      let brand = this.$store.getters["brand/brand"]
      return brand.status.name
    },
    planTypeText() {
      let text
      let numberType
      if (this.isAdmin) {
        if (
          this.planType &&
          this.brand.subscriptions[0].type.name === "Subscriber" &&
          this.subscription.status.id !== "cancelled" &&
          this.subscription.status.id !== "expired"
        ) {
          // 2022-6： The new demand supports refunding, so the subscriber status can be manually adjusted to expired
          text = "Edit Plan"
          numberType = PlanTypeButton.changeButton
        } else if (
          this.planType &&
          this.brand.subscriptions[0].type.name === "Subscriber" &&
          this.subscription.status.id === "cancelled"
        ) {
          text = "Resume Subscription"
          numberType = PlanTypeButton.resumeButton
        } else if (
          this.planType &&
          this.brand.subscriptions[0].type.name === "Trial" &&
          this.subscription.status.id === "cancelled"
        ) {
          text = "Edit Plan"
          numberType = PlanTypeButton.changeButton
        } else {
          text = "Edit Plan"
          numberType = PlanTypeButton.changeButton
        }
      } else {
        if (this.planType && this.brand.subscriptions[0].type.name === "Basic") {
          text = "Start Trial"
          numberType = PlanTypeButton.startTrialButton
        } else if (
          this.planType &&
          this.brand.subscriptions[0].type.name === "Trial" &&
          this.subscription.status.id === "cancelled"
        ) {
          text = "Upgrade Subscription"
          numberType = PlanTypeButton.upgradeButton
        } else if (
          this.planType &&
          this.brand.subscriptions[0].type.name === "Subscriber" &&
          this.subscription.status.id === "cancelled"
        ) {
          text = "Resume Subscription"
          numberType = PlanTypeButton.resumeButton
        } else if (
          this.planType &&
          this.brand.subscriptions[0].type.name === "Trial" &&
          this.subscription.status.id === "activated"
        ) {
          text = "Cancel Trial"
          numberType = PlanTypeButton.cancelButton
        } else {
          text = "Cancel Subscription"
          numberType = PlanTypeButton.cancelButton
        }
      }
      return { text, numberType }
    }
  },
  methods: {
    canShowSubscription(brandStatus) {
      console.log(brandStatus)
      if (brandStatus == "Pending" || brandStatus == "Rejected") {
        return false
      } else if (brandStatus == "Approved") {
        return true
      }
    },
    openAddPaymentDialog() {
      this.$refs["addNewCardDialog"].openAddCardDialog()
    },
    async fetchBrandInfo() {
      const brandId =
        this.$route.name == "adminBrandSettingsSubscription"
          ? this.$route.params.brandId
          : this.$store.getters["user/businessId"]
      await this.$store.dispatch("brand/getBrand", brandId)
    },
    openAddMoreCardDialog() {
      const hasCardInfo = this.brand.subscriptions && this.brand.subscriptions.length !== 0
      this.$refs["cardListDialog"].openAddCardDialog(hasCardInfo)
    },
    clickToBrandTrainingPage() {
      // let hasRetailer =
      //   this.brand &&
      //   this.brand.brandTrainingSubscriptions &&
      //   this.brand.brandTrainingSubscriptions.length > 0
      this.$router.push({
        path: this.isAdmin
          ? `/admin/brand/${this.$route.params.brandId}/training?tab=retailers&isAddRetailer=true`
          : `/brand/training?tab=retailers&isAddRetailer=true`
      })
    },
    checkHasActivedBF() {
      if (
        this.brand &&
        this.brand.brandTrainingSubscriptions &&
        this.brand.brandTrainingSubscriptions.length > 0
      ) {
        for (let i = 0; i < this.brand.brandTrainingSubscriptions.length; i++) {
          if (this.brand.brandTrainingSubscriptions[i].status.id === "activated") {
            return true
          }
        }
      }
      return false
    },
    async changePlanType(numberType) {
      if (this.checkHasActivedBF()) {
        this.$message.warning(
          "This brand has an actived beautyfluent retailer, can't do any operaion for it here."
        )
      } else {
        if (numberType === PlanTypeButton.startTrialButton) {
          // await this.showBrandUpgradeDialog()
          // this.$refs["StartTrialDialog"].openAddCardDialog()
          this.$refs["cardListDialog"].openAddCardDialog()
        }
        if (numberType === PlanTypeButton.cancelButton) {
          // this.showCancelSubscription()
          this.$refs["cancelSubscriptionDialogNew"].openDialog()
        }
        if (numberType === PlanTypeButton.resumeButton) {
          await this.handleResumeSubscription()
        }
        if (numberType === PlanTypeButton.changeButton) {
          this.$refs["brandSettingUpgradePlanDialog"].openDialog()
          //this.clickToBrandTrainingPage()
        }
        if (numberType === PlanTypeButton.upgradeButton) {
          const hasCardInfo = this.brand.subscriptions && this.brand.subscriptions.length !== 0
          this.$refs["cardListDialog"].openAddCardDialog(hasCardInfo)
        }
      }
    },
    async handleResumeSubscription() {
      const param = {
        mode: "resume"
      }
      await this.$store.dispatch("brand/updateBrandSubscriptionNew", param)
      // await this.$store.dispatch("brand/getBrandSubscriptionHistoryList")
      await this.fetchBrandInfo()
    },
    async showBrandUpgradeDialog() {
      // this.$refs["upgradeDialog"].openDialog()
      const param = {
        mode: "trial"
      }
      await this.$store.dispatch("brand/updateBrandSubscriptionNew", param)
      // await this.$store.dispatch("brand/getBrandSubscriptionHistoryList")
      await this.fetchBrandInfo()
    },
    showCancelSubscription() {
      // if (this.subscription.autoRenewal === true) {
      //   this.$refs["cancelSubscriptionDialogNew"].openDialog()
      // } else {
      //   this.$info({
      //     content: this.$t("Brands.BrandSettings.cancelSubscriptionInfo", {
      //       data: this.formatDate(this.subscription.unsubscribeTime)
      //     })
      //   })
      // }
    },
    showUpdateMaxProduct() {
      this.$refs["brandSettingUpdateMaxProductDialog"].openDialog()
    },
    formatDate(value) {
      if (value) {
        let time = value.replace(/-/g, "/")
        let date = Date.parse(time)
        return new Intl.DateTimeFormat("en-US").format(date)
      }
      return " "
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      let priceStr = numberFormat.format(price)
      if (price < 10) {
        let aa = `${priceStr.slice(0, 1)}&ensp;&ensp;&ensp;&ensp;${priceStr.slice(1)}`
        return aa
      } else if (price < 100) {
        return `${priceStr.slice(0, 1)}&ensp;&ensp;&ensp;${priceStr.slice(1)}`
      } else if (price < 1000) {
        return `${priceStr.slice(0, 1)}&ensp;&ensp;${priceStr.slice(1)}`
      }
      return priceStr
    },
    async showUpdatePlanModal(type) {
      if (type === "Subscriber") {
        const param = {
          mode: "cancel"
        }
        await this.$store.dispatch("brand/updateBrandSubscriptionNew", param)
        // await this.$store.dispatch("brand/getBrandSubscriptionHistoryList")
        await this.fetchBrandInfo()
      } else {
        this.$refs["brandSettingUpgradePlanDialog"].openDialog()
      }
    },
    showUpdateCommissionModal() {
      this.$refs["brandSettingUpdateCommissionDialog"].openDialog()
    },
    showOrHideList() {
      this.isShowHistoryList = !this.isShowHistoryList
    },
    typeBtnClick() {
      if (this.isAdmin) {
        this.$router.push({
          name: "adminBrandCommission"
        })
      } else {
        this.$router.push({
          name: "brandCommission"
        })
      }
    }
  }
}
</script>

<style lang="sass">
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon
  color: #4A8A5D !important
.table-title
  font-weight: 600
  font-size: 14px
  color: #333
  line-height: 18px
  letter-spacing: 0.25px
.table-content
  font-weight: 300
  font-size: 14px
  color: #333
  line-height: 18px
  letter-spacing: 0.25px
.brand-line__main-block
  width: 100%
  display: flex
  padding: 10px 0px 8px 35px
  border-bottom: 1px solid #ddd
  min-height: 50px
.brand-settings__account-information_text
  margin-bottom: 0px !important
  text-align: left

.brand-line__left-block
  padding: 0px !important
  display: flex
  align-items: center

  .brand-settings__account-information_text
    font-size:14px
    font-weight: 600
    color: #333

.brand-line__right-block
  padding: 0px !important
  display: flex
  align-items: center

  .brand-settings__account-information_text
    font-size: 14px
    font-weight: 300
    color: #333


.brand-settings__account-information
  width: 100%
  padding-top: 68px

  .brand-line
    :last-child
      border-bottom: none

.brand-settings__account-information
  width: 100%
  padding-top: 68px

  .brand-line
    :last-child
      border-bottom: none
.upgrade-button
  background-color: #000 !important
  border-color: transparent
  color: white !important
  min-width: 124px
  height: 30px
  font-size: 14px
  font-weight: 600
</style>
