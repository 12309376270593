<template>
  <!-- <router-view class="parent-view-content"></router-view> -->
  <div style="margin-top: 2px;">
    <div class="wrapper" style="margin-bottom: 2px;">
      <div class="desktop-nav">
        <div class="nav-container">
          <div class="column quick-search">
            <RetailerQuickSearch ref="retailerQuickSearch" key="1" :is-admin="true" />
          </div>
          <ul class="menu-items">
            <li>
              <router-link :to="`/admin/brand/${brandId}/main`">
                <a-icon class="retailer-icon-style" type="home" />
                {{ $t("Components.Brands.Header.home") }}
              </router-link>
            </li>
            <li>
              <router-link :to="`/admin/brand/${brandId}/products`">
                <a-icon class="retailer-icon-style" type="audit" />
                {{ $t("Components.Brands.Header.catalog") }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="
                  `/admin/brand/${brandId}/orders?tabId=1&status=pending,confirmed&_sort-by=created_at&_order-by=PREVIOUS&_limit=50`
                "
              >
                <a-icon class="retailer-icon-style" type="line-chart" />
                {{ $t("Components.Brands.Header.orders") }}
              </router-link>
            </li>
            <li>
              <router-link :to="`/admin/brand/${brandId}/inventory`">
                <a-icon class="retailer-icon-style" type="table" />
                {{ $t("Components.Brands.Header.inventory") }}
              </router-link>
            </li>
            <li>
              <router-link :to="`/admin/brand/${brandId}/training?tab=product`">
                <a-icon class="retailer-icon-style" type="bulb" />
                Training
              </router-link>
            </li>
            <li>
              <router-link :to="`/admin/brand/${brandId}/clean`">
                <img id="brandCleanPreCheck" class="retailer-icon-style" />
                {{ $t("Components.Brands.Header.preCheck") }}
              </router-link>
            </li>
            <!-- <li>
              <router-link :to="`/admin/brand/${brandId}/profile`">
                <a-icon class="retailer-icon-style" type="user" />
                {{ $t("Components.Brands.Header.profile") }}
              </router-link>
            </li>
            <li>
              <router-link :to="`/admin/brand/${brandId}/settings`">
                <a-icon class="retailer-icon-style" type="setting" />
                {{ $t("Components.Brands.Header.settings") }}
              </router-link>
            </li> -->
            <!-- <li>
              <a href="#">
                <img
                  v-if="brand.logoAsset.url"
                  class="dropdown-icon-container"
                  :src="brand.logoAsset.url"
                />
                <img
                  v-if="!brand.logoAsset.url"
                  class="dropdown-icon-container"
                  :src="require('@/assets/img/default-logo-image.png')"
                />
              </a>
            </li> -->
            <li class="dropdown">
              <a href="#">
                <img
                  v-if="brand.logoAsset.url"
                  class="dropdown-icon-container"
                  :src="brand.logoAsset.url"
                />
                <img
                  v-if="!brand.logoAsset.url"
                  class="dropdown-icon-container"
                  :src="require('@/assets/img/default-logo-image.png')"
                />
              </a>
              <div class="profile-menu">
                <router-link :to="`/admin/brand/${brandId}/profile`">
                  <a-icon class="menu-icon-style" type="user" />
                  {{ $t("Components.Brands.Header.profile") }}
                </router-link>
                <router-link :to="`/admin/brand/${brandId}/settings/myAccount`">
                  <a-icon class="menu-icon-style" type="setting" />
                  {{ $t("Components.Brands.Header.settings") }}
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
import RetailerQuickSearch from "@/components/brands/retailers/RetailerQuickSearch"
export default {
  components: {
    RetailerQuickSearch
  },
  data() {
    return {
      brandId: undefined
    }
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    }
  },
  async created() {
    this.brandId = this.$route.params.brandId
    await this.$store.dispatch("brand/getBrand", this.brandId)
  }
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@font-face {
  font-family: "ProximaNova-Regular";
  src: url(~@/assets/fonts/ProximaNova-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Display-Regular";
  src: url(~@/assets/fonts/SFProDisplay-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Colors */

:root {
  --Gray-2: #fafafa;
  --Gray-3: #f5f5f5;
  --Gray-4: #e8e8e8;
  --Gray-5: #d9d9d9;
  --Gray-7: #8c8c8c;
  --Gray-8: #595959;
  --Gray-10: #000000;
  --Landing_Brand_Orange: #ff6132;
  --Landing_Green: #4a8a5d;
}

body {
  font-family: "ProximaNova-Regular";
}

.wrapper {
  width: 100%;
  margin: 0 auto;
}

/* Navigation Style */

.quick-search {
  margin-left: 5%;
  margin-right: 1%;
}

.column {
  padding: 3px 0px 3px 0px;
}

.desktop-nav {
  background: #fffaf9;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: "ProximaNova-Regular";
}

.desktop-nav .nav-container {
  position: relative;
  display: -webkit-flex;
  justify-content: space-between;
  max-width: 1197px;
  /*min-width: 954px;*/
  margin: auto;
  /*box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 0;*/
}

.desktop-nav .logo {
  margin-top: 13px;
  margin-bottom: 13px;
  margin-left: 126px;
}

.desktop-nav .menu-items {
  margin-top: 14px;
  margin-bottom: 5px;
  margin-right: 106px;
  display: -webkit-flex;
  list-style: none;
}

.desktop-nav .menu-items li {
  margin: 0 12px;
}

.desktop-nav .menu-items li a {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  text-decoration: none;
  color: var(--Gray-7);
}

.desktop-nav .menu-items li img {
  display: block;
  margin: auto;
}

.inventory {
  padding: 3px 1px;
}

.catalog {
  padding: 1px 3px;
}

.payments {
  padding: 1px 1px;
}

.cpcheck {
  padding-top: 1px;
  padding-bottom: 2px;
}

.desktop-nav .menu-items .dropdown-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #8c8c8c;
  width: 36px;
  height: 36px;
  margin-top: 1px;
  margin-bottom: 0px;
}

/* Begin Dropdown */

.desktop-nav .menu-items li .profile-menu {
  display: none;
  position: absolute;
  margin-top: 48px;
  min-width: 99px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  margin-left: -28px;
  z-index: 5;
}

.desktop-nav .menu-items li .profile-menu a {
  display: block;
  padding: 4px;
  padding-left: 36px;
  font-family: "ProximaNova-Regular" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--Gray-8);
}

.desktop-nav .menu-items li .profile-menu a img {
  display: block;
  position: absolute;
  margin-left: -24px;
  margin-top: 2px;
}

.desktop-nav .menu-items li .profile-menu a:hover {
  background-color: var(--Gray-4);
}

.desktop-nav .menu-items .dropdown:hover .profile-menu {
  display: block;
}
.router-link-active {
  color: #4a8a5d !important;
}
.retailer-icon-style {
  display: block;
  font-size: 24px;
}
.menu-icon-style {
  display: block;
  position: absolute;
  margin-left: -24px;
  margin-top: 2px;
  font-size: 14px;
}
#brandCleanPreCheck {
  content: url("~@/assets/img/clean-pre-check/clean_nav_gray.svg");
}
.router-link-active > #brandCleanPreCheck {
  content: url("~@/assets/img/clean-pre-check/clean_nav_green.svg");
}
</style>
