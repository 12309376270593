var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{ref:"createBrandNewMessageModal",attrs:{"width":_vm.modalWidth,"footer":null,"title":null,"mask":!_vm.isSmallStatus,"closable":false,"body-style":_vm.bodyStyle,"mask-closable":false,"dialog-class":"admin-message-brand-create-new-modal","dialog-style":_vm.isSmallStatus ? { bottom: '100px', right: '-364px' } : { top: '10px' },"destroy-on-close":true},on:{"cancel":_vm.hideDialog,"afterClose":_vm.hideDialog},model:{value:(_vm.showCreateMessage),callback:function ($$v) {_vm.showCreateMessage=$$v},expression:"showCreateMessage"}},[_c('div',{staticClass:"message-new-modal-content",class:{ 'admin-message-brand-create-new-modal__outer': !_vm.isSmallStatus }},[_c('div',{staticStyle:{"margin":"0 auto"},style:(!_vm.isSmallStatus ? 'width: 952px;padding-bottom: 48px;' : 'width: 561px;')},[(!_vm.isSmallStatus)?_c('a-row',{staticStyle:{"margin-bottom":"20px"}},[_c('a-col',{staticClass:"admin-message-brand-create-new-modal__title",attrs:{"span":12}},[_vm._v(" Messaging ")]),_c('a-col',{staticStyle:{"text-align":"right"},attrs:{"span":12}},[_c('a-icon',{staticClass:"admin-message-brand-create-new-modal__large-icon",staticStyle:{"margin-right":"12px"},attrs:{"type":"shrink"},on:{"click":_vm.clickToSwitch}}),_c('a-icon',{staticClass:"admin-message-brand-create- new-modal__large-icon",attrs:{"type":"close"},on:{"click":_vm.hideDialog}})],1)],1):_vm._e(),_c('div',{staticClass:"admin-message-brand-create-new-modal__box-content"},[_c('a-row',{staticClass:"admin-message-brand-create-new-modal__choose-model-layer",style:(_vm.isSmallStatus
              ? 'padding-left: 14px; padding-right: 10px; height: 42px; padding-top: 6px; padding-bottom: 6px;'
              : 'padding-left: 30px; padding-left: 20px; height: 40px; padding-top: 6px; padding-bottom: 6px;')},[_c('a-col',{staticClass:"admin-message-brand-create-new-modal__subject",style:(_vm.isSmallStatus ? 'width: 255px;' : 'width: 702px;'),attrs:{"span":1}},[_vm._v(" To: ")]),_c('a-col',{attrs:{"span":18}},[(!_vm.isFirstCreate)?_c('a-select',{class:_vm.isToError
                  ? 'admin-message-brand-create-new-modal__toTarget admin-message-brand-to-error'
                  : 'admin-message-brand-create-new-modal__toTarget',staticStyle:{"width":"200px"},attrs:{"show-search":"","filter-option":false},on:{"search":_vm.handleSearch,"change":_vm.onSelectToTarget},model:{value:(_vm.toValue),callback:function ($$v) {_vm.toValue=$$v},expression:"toValue"}},_vm._l((_vm.dataSource),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_c('div',{staticStyle:{"margin-top":"4px"}},[_vm._v(_vm._s(_vm.retailer ? _vm.retailer.name : ""))])],1),(_vm.isSmallStatus)?_c('div',{staticStyle:{"float":"right","display":"inline-block","margin-top":"4px"}},[_c('a-icon',{staticClass:"admin-message-brand-create-new-modal__large-icon",staticStyle:{"margin-right":"8px"},attrs:{"type":"arrows-alt"},on:{"click":_vm.clickToSwitch}}),_c('a-icon',{staticClass:"admin-message-brand-create-new-modal__large-icon",attrs:{"type":"close"},on:{"click":_vm.hideDialog}})],1):_vm._e()],1),_c('a-row',{staticClass:"admin-message-brand-create-new-modal__choose-model-layer",style:(_vm.isSmallStatus
              ? 'padding-left: 14px; padding-right: 10px; height: 42px; padding-top: 6px; padding-bottom: 6px;'
              : 'padding-left: 30px; padding-left: 20px; height: 40px; padding-top: 6px; padding-bottom: 6px;')},[_c('div',{staticClass:"admin-message-brand-create-new-modal__subject",style:(_vm.isSmallStatus ? 'width: 307px;' : 'width: 702px;')},[_vm._v(" Subject: "),(!this.isToError && _vm.selectedModel === '3')?_c('a-input',{staticStyle:{"width":"150px"},style:(_vm.inputError ? 'border: 1px solid red' : ''),attrs:{"size":"small"},model:{value:(_vm.inputSubject),callback:function ($$v) {_vm.inputSubject=$$v},expression:"inputSubject"}}):_c('span',[_vm._v(_vm._s(_vm.subjectText)+" ")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.inputError)+" ")])],1),_c('a-select',{staticStyle:{"width":"200px","right":"15px"},attrs:{"dropdown-class-name":"admin-message-brand-create-new-modal__select-dropdown","open":_vm.isOpenSelect},on:{"change":_vm.handleChange,"dropdownVisibleChange":_vm.handleOpenSelectMode},model:{value:(_vm.selectedModel),callback:function ($$v) {_vm.selectedModel=$$v},expression:"selectedModel"}},_vm._l((_vm.isFirstCreate ? _vm.firstSelectOption : _vm.selectOption),function(item,index){return _c('a-select-option',{key:`model_type_${index}`,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.description)+" ")])}),1)],1),_c('a-row',{staticClass:"admin-message-brand-create-new-modal__choose-model-layer",style:(_vm.isSmallStatus
              ? _vm.selectedModel === '0'
                ? 'height: 270px;'
                : 'height: 83px;'
              : _vm.selectedModel === '0'
              ? 'height: 324px;'
              : 'height: 191px;')}),(_vm.selectedModel === '1' || _vm.selectedModel === '4')?_c('a-row',[(_vm.selectedModel === '1')?_c('BrandIntroProductTemplate',{attrs:{"retailer":_vm.retailer,"is-type-error":_vm.isToError,"is-small-status":_vm.isSmallStatus,"message":_vm.$t('Retailers.RetailerMessage.introProductMessage')},on:{"products":_vm.getIntroProducts}}):(_vm.selectedModel === '4')?_c('BrandIntroTemplate',{style:(_vm.isSmallStatus ? 'width: 400px' : 'width: 600px'),attrs:{"from":_vm.brand,"to-data":_vm.retailer}}):_vm._e()],1):_vm._e(),_c('a-row',{style:(_vm.isSmallStatus && _vm.selectedModel !== '1' ? 'min-height: 138px;' : '')},[_c('message-input-component',{attrs:{"generate-content":_vm.contentType,"from":_vm.brand,"to-data":_vm.retailer,"is-small-status":_vm.isSmallStatus,"is-has-connection":!_vm.isToError},on:{"send":_vm.sendMessage,"sendDraft":_vm.sendDraft,"hideDialog":_vm.hideDialog}})],1)],1)],1),_c('warn-message-dialog',{ref:"brandOrderWarningDialog"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }