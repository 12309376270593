<template lang="pug">
.columns.client-item(v-click-outside="hideDropdown")
  .column.is-1.admin-clients__table-cell.admin-clients__table-cell--checkbox
    .admin-clients__checkbox
    //img(src="@/assets/img/icon/checked-select-client.png")
  
  .column.is-1.admin-clients__table-cell
    .admin-clients__logo-container(v-if="client.logoAsset && client.logoAsset.url")
      img(:src="client.logoAsset.url")
    .admin-clients__logo-container(v-else)
      img(src="@/assets/img/default-logo-image.png")
  .column.is-1.admin-clients__table-cell
    div {{ clientType}}
  .column.is-2.admin-clients__brand-info.client-name
    router-link.admin-clients__client-name(
      v-if="isBrand", :to="{ name: 'adminBrandProfile', params: { brandId: client.id }}"
    ) {{ client.name }}
    router-link.admin-clients__client-name(v-else :to="{ name: 'adminRetailerProfile', params: { retailerId: client.id }}") {{ client.name }}
    //.admin-clients__client-website {{ client.website }}
  .column.is-1.admin-clients__table-cell.location
    div {{clientAddress(client)}}
  //- .column.is-1.admin-clients__table-cell
  //-   div {{$t('Components.Admin.ClientItem.value1000')}}
  .column.is-2.admin-clients__table-cell.date-registered {{ client.createdAt.split('T')[0] }}
  .column.is-1.admin-clients__table-cell.status
    span.admin-clients__status-circle
    span.admin-clients__status {{ client.status && client.status.id }}
  .column.is-2.admin-clients__table-cell
    a.admin-clients__review-link(v-if="client.termsUpdatePending", @click="showChangeRequestModal") {{$t('Components.Admin.ClientItem.needsReview')}}
  .column.is-1.admin-clients__table-cell
    .admin-clients__dropdown-circles(@click="isDropdownOpen = true")
      img(src="@/assets/img/icon/ellipses.svg")
    .admin-clients__dropdown(v-show="isDropdownOpen")
      .admin-clients__dropdown-item(@click="updateClientStatus('pending')")
        span.admin-clients__dropdown-button {{$t('Components.Admin.ClientItem.pending')}}
      .admin-clients__dropdown-item(@click="updateClientStatus('rejected')")
        span.admin-clients__dropdown-button {{$t('Components.Admin.ClientItem.reject')}}
      .admin-clients__dropdown-item(@click="approveModal = true; isDropdownOpen = false")
        span.admin-clients__dropdown-button {{$t('Components.Admin.ClientItem.approve')}}
  ApproveModal(
    v-if="client",
    :approveError="approveError",
    :showError="showError"
    :show="approveModal" :initialShortName="client.shortName",
    @hideApproveModal="hideApproveModal" @subscribe="subscribe"
  )
  ChangeRequestModal(
    :show="showChanges"
    :pendingData="retailer.termsPending"
    @hideChangeRequestModal="hideChangeRequestModal"
    @approveChanges="approve"
    @rejectChanges="reject"
  )
  ViewPendingChanges(
    :showpendingchanges="viewBrandChanges"
    @hidePendingChangesModal="viewBrandChanges = false"
    :viewPendingChangeRequest= "brand.termsPending"
    @changeTermsPending="changeTermsPending"
    
    ) 
</template>
<script>
import ApproveModal from "@/components/admin/ApproveModal"
import ChangeRequestModal from "@/components/retailers/profile/ChangeRequestModal"
import ViewPendingChanges from "@/components/brands/settings/ViewPendingChanges"

export default {
  components: {
    ApproveModal,
    ChangeRequestModal,
    ViewPendingChanges
  },

  props: { client: { type: Object, default: () => null } },
  data() {
    return {
      isDropdownOpen: false,
      approveModal: false,
      approveError: null,
      showError: false,
      showChanges: false,
      viewBrandChanges: false
    }
  },
  computed: {
    isBrand() {
      return this.client && this.client.type && this.client.type.id === "brand"
    },
    isRetailer() {
      return this.client && this.client.type && this.client.type.id === "retailer"
    },
    clientType() {
      if (this.isBrand) {
        return "Brand"
      } else if (this.isRetailer) {
        return "Retailer"
      }
      return null
    },

    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    }
  },
  methods: {
    updateClientStatus(statusId, shortName) {
      this.isDropdownOpen = false
      const payload = { status: { id: statusId }, shortName, id: this.client.id }
      this.isBrand ? this.updateBrand(payload) : this.updateRetailer(payload)
    },
    changeTermsPending() {
      this.client.termsUpdatePending = false
    },
    clientAddress(client) {
      for (var index in client.addresses) {
        if (client.addresses[index].hq) {
          return client.addresses[index].country.name
        }
      }
      return ""
    },
    async updateBrand(payload) {
      try {
        await this.$store.dispatch("brand/updateBrandPartially", payload)
        this.client.status.id = payload.status.id
        this.client.shortName = payload.shortName
        this.approveModal = false
        this.showError = false
        this.approveError = ""
      } catch (error) {
        this.handleApproveError(error)
      }
    },
    async updateRetailer(payload) {
      try {
        await this.$store.dispatch("retailer/updateRetailerPartially", payload)
        this.client.status.id = payload.status.id
        this.client.shortName = payload.shortName
        this.approveModal = false
        this.approveError = ""
        this.showError = false
      } catch (error) {
        this.handleApproveError(error)
      }
    },
    handleApproveError(error) {
      if (error.response.status === 400) {
        this.approveError = error.response.data.underlying[0].developerMessage
        this.showError = true
        return
      }
    },
    hideApproveModal() {
      this.approveModal = false
    },
    hideDropdown() {
      this.isDropdownOpen = false
    },
    subscribe(shortName) {
      this.updateClientStatus("approved", shortName)
    },
    async showChangeRequestModal() {
      if (this.client.type.id === "retailer") {
        await this.$store.dispatch("retailer/fetchRetailer", this.client.id)
        this.showChanges = true
      } else if (this.client.type.id === "brand") {
        await this.$store.dispatch("brand/getBrand", this.client.id)
        this.viewBrandChanges = true
      }
    },

    hideChangeRequestModal() {
      this.showChanges = false
    },
    approve() {
      this.retailer.termsPending.status.id = "approved"
      this.retailer.termsPending.status.name = "Approved"
      this.updatePendingTermStatus()
    },
    reject() {
      this.retailer.termsPending.status.id = "rejected"
      this.retailer.termsPending.status.name = "Rejected"
      this.updatePendingTermStatus()
    },
    async updatePendingTermStatus() {
      try {
        await this.$store.dispatch("retailer/updatePendingTerms", this.retailer.termsPending)
        this.client.termsUpdatePending = false
      } catch (error) {
        console.error(error)
      }

      try {
        await this.$store.dispatch("retailer/fetchRetailer", this.retailer.id)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
<style></style>
