<template>
  <div class="brand-banner__main">
    <a-carousel :after-change="onChange" autoplay="">
      <a slot="customPaging">
        <p class="costom-pag-radius"></p>
      </a>
      <div v-for="(item, index) in imgList" :key="index" class="banner-content">
        <img v-if="showBigImg" :src="item.img" alt="banner" />
        <img v-else :src="item.smallImg" alt="banner" />
        <div v-if="index !== 1" class="content-text">
          <p class="banner-title"></p>
          <p class="banner-title banner-one">{{ item.BannerTitle }}</p>
          <p class="banner-content-text">{{ item.bannerContent }}</p>
          <p class="banner-button" @click="jumpToPage(index)">
            {{ item.bannerButton }}
          </p>
        </div>
        <div v-else class="left-content-text">
          <p class="banner-title">{{ item.BannerTitle }}</p>
          <p class="banner-content-text two-text">{{ item.bannerContent }}</p>
          <p class="banner-button" style="float: right;" @click="jumpToPage(index)">
            {{ item.bannerButton }}
          </p>
        </div>
      </div>
    </a-carousel>
    <BrandTableDialog ref="tableDialog"></BrandTableDialog>
  </div>
</template>

<script>
import BrandTableDialog from "./components/BrandTableDialog"
import constants from "@/config.js"
// import { Carousel } from "ant-design-vue"
import GroupBanner from "@/assets/img/homepage/brands/Group-banner.png"
import smallGroupBanner from "@/assets/img/homepage/brands/small-Group.png"
import labhbannerimage from "@/assets/img/homepage/brands/labhbannerimage.png"
import smallLabhbannerimage from "@/assets/img/homepage/brands/small-labhbannerimage.png"
import LandingPad from "@/assets/img/homepage/brands/TopBanner.png"
import smallLandingPad from "@/assets/img/homepage/brands/small-TopBanner.png"
export default {
  components: {
    // "a-carousel": Carousel,
    BrandTableDialog
  },
  data() {
    return {
      showBigImg: false
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    imgList() {
      let barndOrRetailerList
      const list = [
        { img: GroupBanner, smallImg: smallGroupBanner },
        { img: labhbannerimage, smallImg: smallLabhbannerimage },
        { img: LandingPad, smallImg: smallLandingPad }
      ]
      let textList = this.$t("Components.Brands.mainPage.bannerList")
      if (this.isBrand) {
        barndOrRetailerList = this.$t("Components.Brands.mainPage.BrandBannerList")
        barndOrRetailerList = barndOrRetailerList.map(item => {
          item.img = LandingPad
          item.smallImg = smallLandingPad
          return item
        })
        textList = textList.concat(barndOrRetailerList)
        return textList.map((item, index) => {
          return { ...item, ...list[index] }
        })
      } else {
        barndOrRetailerList = this.$t("Components.Brands.mainPage.retailerBannerList")
        return barndOrRetailerList.map(item => {
          item.img = LandingPad
          item.smallImg = smallLandingPad
          return item
        })
      }
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    brandType() {
      return (
        this.brand.subscriptions &&
        this.brand.subscriptions[0].type &&
        this.brand.subscriptions[0].type.id
      )
    }
  },
  created() {
    this.isSmallPage()
  },
  methods: {
    onChange() {},
    jumpToPage(value) {
      if (value === 2) {
        if (this.$route.params.brandId) {
          this.$router.push({
            name: "adminBrandTraining",
            params: {
              brandId: this.$route.params.brandId
            }
          })
        } else {
          this.$router.push({
            path: "/brand/training?main=true"
          })
        }

        // if (this.brandType === "basic") {
        //   this.$refs["tableDialog"].openDialog()
        // }
      } else if (value === 0 && !this.isRetailer) {
        window.location.href = "https://landinginternational.com/blog/"
      } else if (value === 1) {
        if (this.$route.params.brandId) {
          this.$router.push({
            name: "adminBrandCleanCheck",
            params: {
              brandId: this.$route.params.brandId
            }
          })
        } else {
          this.$router.push({
            path: "/brand/clean?cpcmain=true"
          })
        }
      } else if ((value === 3 || value === 0) && this.isRetailer) {
        this.$router.push({
          path: "/retailer/products/all"
        })
      } else if (value === 3 && this.isBrand) {
        this.$router.push({
          path: "/brand/products"
        })
      }

      // if (value === 2 || value === 1) this.$refs["tableDialog"].openDialog()
      // need routerName and page(landing pad)
      // else this.$router.push({})
    },
    isSmallPage() {
      const pageWidth = document.body.clientWidth
      this.showBigImg = pageWidth > 1500
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Playfair Display";
  src: url("../../../assets/fonts/PlayfairDisplay-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../../../assets/fonts/Proxima-Nova-Light.otf") format("truetype");
}
.brand-banner__main {
  width: 100%;
  margin-top: 2px;
  height: 544px;
  .banner-content {
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .content-text {
      text-align: left;
      position: absolute;
      top: 150px;
      left: 68px;
      z-index: 3;
    }
    .banner-title {
      font-weight: 700;
      font-size: 48px;
      font-family: "Playfair Display";
      color: #12433d;
    }
    .banner-content-text {
      font-size: 24px;
      font-family: "Proxima Nova";
      width: 400px;
      line-height: 40px;
      margin-top: 50px;
      margin-bottom: 38px;
      color: #000000;
      font-weight: 600;
    }
    .banner-button-yellow {
      width: 268px;
      height: 43px;
      background-color: #fec206;
      text-align: center;
      border-radius: 22px;
      line-height: 40px;
      font-size: 18px;
      color: #ffffff;
      font-family: "Proxima Nova";
      font-weight: 600;
      cursor: pointer;
    }
    .banner-button {
      width: 268px;
      height: 43px;
      border: 2px solid #ff6132;
      text-align: center;
      border-radius: 22px;
      line-height: 40px;
      font-size: 16px;
      color: #000000;
      font-family: "Proxima Nova";
      cursor: pointer;
    }
    .banner-button:hover {
      background: #ff6132;
      color: white;
    }
    .left-content-text {
      // width: 100%;
      position: absolute;
      top: 97px;
      right: 68px;
      z-index: 3;
      margin-right: 0;
      .banner-content-text {
        text-align: right;
        width: 500px;
      }
      .two-text {
        margin-bottom: 56px;
      }
      .banner-button-yellow {
        float: right;
      }
    }
  }
  .banner-one {
    width: 510px;
    margin-top: -67px;
    //margin-bottom: -1em;
    line-height: 60px;
    //height: 20px;
  }
  .banner-three {
    height: 0;
  }
}
.ant-carousel .slick-dots li.slick-active {
  //width: 16px !important;
}
//.ant-carousel .slick-dots li {
//  margin: 0 7px !important;
//}
.ant-carousel .slick-dots li.slick-active .costom-pag-radius {
  background: #f5f4f4;
  margin-right: 0;
  width: 24px;
  height: 24px;
  margin-top: -19px;
}

.costom-pag-radius {
  height: 21px;
  width: 21px;
  border-radius: 50%;
  background: white;
  margin-top: -16px;
}

@media screen and (min-width: 1500px) {
  .slick-track {
    height: 544px !important;
  }
  .brand-banner__main .banner-content .left-content-text {
    right: 190px;
  }
  .brand-banner__main {
    .banner-content {
      .content-text {
        left: 183px;
      }
    }
  }
}
</style>
