<template>
  <div class="beauty-fluent-step-two__main">
    <div class="step-title">
      <a-avatar
        style="color: #ffffff; background-color: #F15A42;z-index: 4;cursor: pointer"
        @click="clickToStepOne"
        >1</a-avatar
      >
      <a-avatar style="color: #ffffff; background-color: #F15A42;z-index: 4;">2</a-avatar>
      <a-avatar style="color: #1A1818; background-color: rgba(237, 142, 131, .3);z-index: 4;"
        >3</a-avatar
      >
      <div class="step-line"></div>
      <div class="no-step-line"></div>
      <span class="step-icon-style">PLANS</span>
      <span class="step-icon-style billing-style">BILLING</span>
      <span class="step-icon-style payment-style">PAYMENT</span>
    </div>
    <div class="select-billing-frequency-title">Select Billing Frequency</div>
    <div class="billing-content">
      <BFStep2Com :retailer-info="retailerInfo" @selectPlan="selectPlan"></BFStep2Com>
    </div>
  </div>
</template>

<script>
import BFStep2Com from "@/components/training/__components/BFStep2__com"
export default {
  name: "BFStep1",
  components: {
    BFStep2Com
  },
  props: {
    retailerInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  methods: {
    selectPlan(billingInfo) {
      this.$emit("selectPlan", billingInfo)
      // this.$router.push({
      //   name: this.$route.name,
      //   query: {
      //     ...this.$route.query,
      //     step: stepValue
      //   }
      // })
    },
    clickToStepOne() {
      this.$emit("clickToStepOne")
    }
  }
}
</script>

<style scoped lang="scss">
.beauty-fluent-step-two__main {
  text-align: center;
  .step-title {
    width: 316px;
    text-align: center;
    margin: 50px auto 60px;
    display: flex;
    justify-content: space-between;
    position: relative;
    .step-line {
      height: 3px;
      width: 158px;
      background-color: #f15a42;
      position: absolute;
      top: 15px;
      left: 0;
      z-index: 1;
    }
    .no-step-line {
      background-color: rgba(237, 142, 131, 0.3);
      height: 3px;
      width: 158px;
      position: absolute;
      top: 15px;
      right: 31px;
      z-index: 0;
    }
  }
  .select-billing-frequency-title {
    font-size: 40px;
    color: #1a1818;
    font-family: DMSans;
    margin-bottom: 78px;
  }
  .billing-content {
    //margin-top: 48px;
  }
  .step-icon-style {
    position: absolute;
    top: -25px;
    left: -7px;
    color: #1a1818;
    font-weight: 500;
    font-family: DMSans;
  }
  .billing-style {
    left: 129px;
    color: #f27c59;
  }
  .payment-style {
    left: 260px;
  }
}
</style>
