import { render, staticRenderFns } from "./AdminRetialerDirtyIngredient.vue?vue&type=template&id=015dfa98&scoped=true&"
import script from "./AdminRetialerDirtyIngredient.vue?vue&type=script&lang=js&"
export * from "./AdminRetialerDirtyIngredient.vue?vue&type=script&lang=js&"
import style0 from "./AdminRetialerDirtyIngredient.vue?vue&type=style&index=0&id=015dfa98&prod&lang=sass&scoped=true&"
import style1 from "./AdminRetialerDirtyIngredient.vue?vue&type=style&index=1&id=015dfa98&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "015dfa98",
  null
  
)

export default component.exports