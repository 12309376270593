<template>
  <div style="margin: 0 auto">
    <div v-if="isLoading" style="text-align: center">
      <a-spin size="large" />
    </div>
    <div v-if="step === stepValue.stepOne">
      <beauty-fluent-plan-step
        :existed-retailer="existedRetailer"
        :is-sign-up="!isBrandAccount"
        :is-has-general="isHasGeneral"
        @subscribe="subscribe"
        @clickHereAction="clickToPlatformBrandRegister"
      />
    </div>
    <div v-if="step === stepValue.stepTwo" class="step-two-content">
      <BFStep2
        :retailer-info="retailerInfo"
        @selectPlan="selectPlan"
        @clickToStepOne="clickToStepOne"
      ></BFStep2>
    </div>
    <div v-if="step === stepValue.stepThree" style="max-width: 1440px">
      <BFStep3
        :is-brand="isBrandAccount"
        :retailer-info="retailerInfo"
        :billing-info="billingInfo"
        :existed-retailer="existedRetailer"
        @updateBilling="updateBilling"
        @changeBilling="changeBilling"
        @changePromocode="changePromocode"
        @updateRetailer="updateRetailer"
        @success="success"
        @clickToStepOne="clickToStepOne"
        @clickToStepTwo="clickToStepTwo"
      ></BFStep3>
    </div>
    <div v-if="step === stepValue.stepSuccess">
      <!--      <BFStep3 :retailer-info="retailerInfo" :billing-info="billingInfo"></BFStep3>-->
    </div>
    <UpgradePlanDialog ref="brandSettingUpgradePlanDialog" :brand-type="brandType" />
  </div>
</template>
<script>
import BeautyFluentPlanStep from "@/components/training/BeautyFluentPlanStep"
import BFStep2 from "@/views/Training/BFStep2"
import BFStep3 from "@/views/Training/BFStep3"
import UpgradePlanDialog from "@/components/shared/UpgradePlanDialog"
import { mapActions } from "vuex"
import constants from "@/config"

const stepValue = {
  stepOne: 1,
  stepTwo: 2,
  stepThree: 3,
  stepSuccess: 4
}

export default {
  components: {
    BeautyFluentPlanStep,
    BFStep2,
    BFStep3,
    UpgradePlanDialog
  },
  props: {
    isBrand: {
      type: Boolean,
      default: false
    },
    existedRetailer: {
      type: Array,
      default: () => []
    },
    isHasGeneral: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stepValue,
      step: stepValue.stepOne,
      retailerInfo: undefined,
      isGeneral: false,
      billingInfo: undefined,
      couponCode: undefined,
      couponAmount: undefined,
      isLoading: false
    }
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    },
    role() {
      return this.$store.getters["user/role"]
    },
    isBrandAccount() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    brandSubscriptionStatus() {
      return (
        this.brand.subscriptions &&
        this.brand.subscriptions[0].status &&
        this.brand.subscriptions[0].status.id
      )
    },
    brandType() {
      if (
        this.brand &&
        this.brand.subscriptions &&
        this.brand.subscriptions.length > 0 &&
        this.brand.subscriptions[0].type &&
        this.brand.subscriptions[0].type.id
      ) {
        return this.brand.subscriptions[0].type.id
      } else {
        return ""
      }
    },
    preferredRetailers() {
      let data = this.$store.getters["retailer/preferredRetailers"]
      if (data && data.length > 0) {
        if (this.existedPreferredRetailer && this.existedPreferredRetailer.length > 0) {
          for (let i = 0; i < this.existedPreferredRetailer.length; i++) {
            data = data.filter(
              item => item.retailerId !== this.existedPreferredRetailer[i].retailerId
            )
          }
        }
      }
      return data
    },
    generalRetailer() {
      return this.$store.getters["retailer/generalRetailer"]
    }
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch("reference/setReference")
    if (this.isBrand || this.brand.id) {
      let brandId = this.$route.params.brandId
        ? this.$route.params.brandId
        : this.$store.getters["user/businessId"]
      await this.$store.dispatch("brand/getBrand", brandId)
      await this.$store.dispatch("brandPayment/fetchCards", brandId)
    }
    if (this.$route.name === "BrandBeautyFluentPayment") {
      this.isLoading = true
      this.$store.dispatch("retailer/getPreferredRetailers")
      const brandId = this.brand.id ? this.brand.id : this.userId
      const list = await this.getRetailersList(brandId)
      const selectList = list.filter(item => item.retailerId === "*")
      if (selectList && selectList.length === 0) {
        this.retailerInfo = this.generalRetailer
      } else {
        this.retailerInfo = this.preferredRetailers[0]
      }
      this.step = stepValue.stepThree
      const billingList = [
        {
          id: 3,
          titleName: "MONTHLY PLAN",
          savings: "NO",
          hasSav: false,
          price: this.retailerInfo.monthlyPrice,
          actualPrice: this.retailerInfo.monthlyPrice,
          saveNumber: 0,
          unitText: "month",
          bgColor: "#FAC92C",
          number: "12 payments",
          hasSaveUp: false,
          isClick: false,
          hasSave: false,
          hasPopular: false,
          type:
            (this.referenceInfo && this.referenceInfo.filters(item => item.id === 3)[0].name) ||
            "monthly",
          name:
            (this.referenceInfo &&
              this.referenceInfo.filters(item => item.id === 3)[0].description) ||
            "Monthly",
          subscriptionTotalText: "Payment Due Today, \nand every month:",
          savingTxt: "",
          savingNum: 1,
          sumTotal: this.retailerInfo.monthlyPrice,
          subTotalAmount: this.retailerInfo.annuallyPrice,
          savingNumber: 0
        },
        {
          id: 1,
          titleName: "YEARLY PLAN",
          savings: "5%",
          hasSav: true,
          price: this.retailerInfo.annuallyPrice,
          actualPrice: this.retailerInfo.annuallyPrice - this.retailerInfo.annuallyPrice * 0.05,
          number: "One-time payment",
          unitText: "year",
          hasSaveUp: false,
          isClick: true,
          hasSave: true,
          hasPopular: true,
          bgColor: "#F15A42",
          saveNumber: this.retailerInfo.annuallyPrice * 0.05,
          type:
            (this.referenceInfo && this.referenceInfo.filters(item => item.id === 1)[0].name) ||
            "annual",
          name:
            (this.referenceInfo &&
              this.referenceInfo.filters(item => item.id === 3)[0].description) ||
            "Annual",
          subscriptionTotalText: "Payment Due Today:",
          savingTxt: "Annual Plan Savings - 5%",
          savingNum: 0.05,
          sumTotal: this.retailerInfo.annuallyPrice - this.retailerInfo.annuallyPrice * 0.05,
          subTotalAmount: this.retailerInfo.annuallyPrice,
          savingNumber: this.retailerInfo.annuallyPrice * 0.05
        },
        {
          id: 2,
          titleName: "QUARTERLY PLAN",
          savings: "3%",
          hasSav: true,
          price: this.retailerInfo.quarterlyPrice,
          actualPrice: this.retailerInfo.quarterlyPrice - this.retailerInfo.quarterlyPrice * 0.03,
          unitText: "quarter",
          number: "4 payments",
          hasSaveUp: false,
          isClick: false,
          hasSave: true,
          hasPopular: false,
          bgColor: "#1B4793",
          saveNumber: this.retailerInfo.quarterlyPrice * 0.03,
          type:
            (this.referenceInfo && this.referenceInfo.filters(item => item.id === 2)[0].name) ||
            "quarterly",
          name:
            (this.referenceInfo &&
              this.referenceInfo.filters(item => item.id === 3)[0].description) ||
            "Quarter",
          subscriptionTotalText: "Payment Due Today, \nand every quarter:",
          savingTxt: "Quarterly Plan Savings - 3%",
          savingNum: 0.03,
          sumTotal:
            Math.round(
              ((this.retailerInfo.annuallyPrice - this.retailerInfo.annuallyPrice * 0.03) / 4) * 100
            ) / 100,
          subTotalAmount: this.retailerInfo.annuallyPrice,
          savingNumber: this.retailerInfo.annuallyPrice * 0.03
        }
      ]
      // this.billingInfo = this.changeBilling(this.retailerInfo, billingList)
      this.billingInfo = billingList
    }
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      getRetailersList: "training/getBrandTraining"
    }),
    changePromocode(code, amount) {
      this.couponCode = code
      this.couponAmount = amount
    },
    changeBilling(retailerInfo, list) {
      const binningList = list ? list : this.billingInfo
      const total = binningList.filter(item => item.name === "Annual")[0].price
      this.billingInfo = binningList.map(item => {
        if (item.type === "annual") {
          item.price = retailerInfo ? retailerInfo.annuallyPrice : item.price
          item.actualPrice = retailerInfo
            ? retailerInfo.annuallyPrice - retailerInfo.annuallyPrice * 0.05
            : item.actualPrice
          item.saveNumber = retailerInfo ? retailerInfo.annuallyPrice * 0.05 : item.saveNumber

          if (this.couponCode && this.couponAmount) {
            item.subTotalAmount = retailerInfo
              ? Number(retailerInfo.annuallyPrice - this.couponAmount).toFixed(2)
              : Number(total - this.couponAmount).toFixed(2)
            item.savingNumber = (item.subTotalAmount * item.savingNum).toFixed(2)
            item.sumTotal = (item.subTotalAmount - item.savingNumber).toFixed(2)
          } else {
            item.subTotalAmount = retailerInfo ? retailerInfo.annuallyPrice : total
            item.savingNumber = retailerInfo ? retailerInfo.annuallyPrice * 0.05 : total * 0.05
            item.sumTotal = retailerInfo
              ? retailerInfo.annuallyPrice - retailerInfo.annuallyPrice * 0.05
              : total - total * 0.05
          }
        }
        if (item.type === "quarterly") {
          item.price = retailerInfo ? retailerInfo.quarterlyPrice : item.price
          item.actualPrice = retailerInfo
            ? retailerInfo.quarterlyPrice - retailerInfo.quarterlyPrice * 0.03
            : item.actualPrice
          item.saveNumber = retailerInfo ? retailerInfo.quarterlyPrice * 0.03 : item.saveNumber

          if (this.couponCode && this.couponAmount) {
            item.subTotalAmount = retailerInfo
              ? Number(retailerInfo.annuallyPrice - this.couponAmount).toFixed(2)
              : Number(total - this.couponAmount).toFixed(2)
            item.savingNumber = (Number(item.subTotalAmount) * item.savingNum).toFixed(2)
            const number = Number(item.subTotalAmount - item.savingNumber).toFixed(2)
            item.sumTotal = Math.round((number / 4) * 100) / 100
          } else {
            item.subTotalAmount = retailerInfo ? retailerInfo.annuallyPrice : total
            item.savingNumber = retailerInfo ? retailerInfo.quarterlyPrice * 0.03 : total * 0.03
            const number = retailerInfo
              ? retailerInfo.annuallyPrice - retailerInfo.annuallyPrice * 0.03
              : item.subTotalAmount - item.savingNumber
            item.sumTotal = Math.round((number / 4) * 100) / 100
          }
        }
        if (item.type === "monthly") {
          item.price = retailerInfo ? retailerInfo.monthlyPrice : item.price
          item.actualPrice = retailerInfo ? retailerInfo.monthlyPrice : item.actualPrice
          item.saveNumber = 0

          if (this.couponCode && this.couponAmount) {
            item.subTotalAmount = retailerInfo
              ? Number(retailerInfo.annuallyPrice - this.couponAmount).toFixed(2)
              : Number(total - this.couponAmount).toFixed(2)
            item.savingNumber = (item.subTotalAmount * item.savingNum).toFixed(2)
            item.sumTotal = Math.round((item.subTotalAmount / 12) * 100) / 100
          } else {
            item.subTotalAmount = retailerInfo ? retailerInfo.annuallyPrice : total
            item.sumTotal = retailerInfo
              ? retailerInfo.monthlyPrice
              : Math.round((item.subTotalAmount / 12) * 100) / 100
            item.savingNumber = 0
          }
        }
        return item
      })
    },
    subscribe(isGeneral, retailer) {
      this.step = stepValue.stepTwo
      this.retailerInfo = retailer
      this.isGeneral = isGeneral
      document.body.scrollTop = document.documentElement.scrollTop = 0
      console.log(this.retailerInfo)
    },
    selectPlan(billingInfo) {
      this.step = stepValue.stepThree
      // this.billingInfo = billingInfo
      this.changeBilling(undefined, billingInfo)
    },
    clickToStepOne() {
      this.step = stepValue.stepOne
    },
    clickToStepTwo() {
      this.step = stepValue.stepTwo
    },
    updateBilling(list) {
      this.billingInfo = list
    },
    updateRetailer(retailer) {
      this.retailerInfo = retailer
      this.billingInfo = this.billingInfo.map(item => {
        if (item.type === "annual") {
          item.price = retailer.annuallyPrice
          item.actualPrice = retailer.annuallyPrice - retailer.annuallyPrice * 0.05
          item.saveNumber = retailer.annuallyPrice * 0.05
        }
        if (item.type === "quarterly") {
          item.price = retailer.quarterlyPrice
          item.actualPrice = retailer.quarterlyPrice - retailer.quarterlyPrice * 0.03
          item.saveNumber = retailer.quarterlyPrice * 0.03
        }
        if (item.type === "monthly") {
          item.price = retailer.monthlyPrice
          item.actualPrice = retailer.monthlyPrice
          item.saveNumber = 0
        }
        return item
      })
    },
    success() {
      this.$emit("success")
    },
    checkHasActivedBF() {
      if (
        this.brand &&
        this.brand.brandTrainingSubscriptions &&
        this.brand.brandTrainingSubscriptions.length > 0
      ) {
        for (let i = 0; i < this.brand.brandTrainingSubscriptions.length; i++) {
          if (this.brand.brandTrainingSubscriptions[i].status.id === "activated") {
            return true
          }
        }
      }
      return false
    },
    clickToPlatformBrandRegister() {
      if (this.isBrand) {
        if (this.checkHasActivedBF()) {
          this.$message.warning(
            "This brand has an actived beautyfluent retailer, can't do any operaion for it here."
          )
        } else {
          this.$refs["brandSettingUpgradePlanDialog"].openDialog()
        }
      } else {
        if (this.brand && this.brand.id) {
          console.log(this.brandType)
          //logined brand
          if (
            (this.brandType && this.brandType === "basic") ||
            this.brandSubscriptionStatus === "cancelled"
          ) {
            //show payment card
            this.$router.push({
              name: "BrandRegistrationMain",
              params: {
                brandId: this.brand.id
              }
            })
          } else {
            //show brand home
            this.$router.push({
              name: "brandMain",
              params: {
                isShowJoinBF: true
              }
            })
          }
        } else {
          this.$router.push({
            name: "BrandRegistrationMain"
          })
        }
      }
    },
    clickDialogBtn() {
      console.log("....clickDialogBtn....")
    }
  }
}
</script>

<style lang="scss" scoped>
.step-two-content {
  margin-bottom: 400px;
}
</style>
