<template lang="pug">
.products-pagination
  a(@click="previousPage" :class="{ 'is-disabled': isPaginationLoading }")
    img(v-show="page > 1 && !isPaginationLoading" src="@/assets/img/pagination/left-arrow.png")
    img(v-show="page == 1 && isPaginationLoading " src="@/assets/img/pagination/left-arrow-disabled.png")

  a(v-show="page > 2" @click="firstPage") 1
  a(v-show="page - 1 > 2") ...
  a(v-show="page > 1" @click="previousPage") {{ page - 1 }}
  a.selected-page {{ page }}
  a(v-show="page + 1 < maxPage" @click="nextPage") {{ page + 1 }}
  a(v-show="maxPage > page + 2") ...
  a(v-show="maxPage && maxPage !== page" :class="{ disabled: maxPage > page + 1 }" @click="nextPage") {{ maxPage }}
  a(@click="nextPage" :class="{ 'is-disabled': isPaginationLoading }")
    img(v-show="page < maxPage && !isPaginationLoading" src="@/assets/img/pagination/right-arrow.png")
    img(v-show="page == maxPage && isPaginationLoading" src="@/assets/img/pagination/right-arrow-disabled.png")
</template>

<script>
export default {
  props: {
    page: { default: 1, type: Number },
    maxPage: { default: 1, type: [Number, String] },
    isPaginationLoading: { default: false, type: Boolean }
  },
  methods: {
    nextPage() {
      if (this.page == this.maxPage) {
        return
      }
      this.$emit("nextPage")
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 500)
    },
    firstPage() {
      this.$emit("resetPagination")
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 500)
    },
    previousPage() {
      if (this.page === 1) {
        return
      }
      this.$emit("previousPage")
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 500)
    }
  }
}
</script>

<style lang="sass">
.products-pagination
  display: flex
  justify-content: center
  margin-bottom: 15px
  padding-bottom: 70px

  a:not(:last-child)
    margin-right: 25px

  a
    color: #000
    font-size: 11px
    letter-spacing: 0.14px
    line-height: 20px
    user-select: none
    height: 24px
    width: 24px
    line-height: 24px
    text-align: center

    &.selected-page
      border-radius: 50%
      background-color: #292929
      color: white
      text-decoration: underline

    &.disabled
      color: #9e9e9e

  .is-disabled
    pointer-events: none
</style>
