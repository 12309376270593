<template>
  <div>
    <a-row>
      <a-col :span="18">
        <search-component
          :search-recent="searchRecent"
          search-hint="Search by Retailer"
          @doSearch="onSearch"
          @deleteTag="closeTagItem"
        />
      </a-col>
      <a-col :span="6" style="text-align: right;">
        <a-button class="admin-training-landing-red-btn" @click="showCreateNewDialog"
          >Add BeautyFluent Retailer</a-button
        >
      </a-col>
    </a-row>

    <a-table
      :columns="tableColumn"
      :data-source="tableData"
      size="middle"
      style="margin-top: 40px;"
      class="admin-training-table"
      :pagination="false"
      :loading="isLoading"
      :row-class-name="
        (_recode, index) => (index % 2 === 1 ? 'admin-training-table-striped' : null)
      "
      @change="handleName"
    >
      <span slot="nameTitle">
        Name
        <a-icon
          type="caret-up"
          :rotate="sortName.toString().length > 0 && sortName === 'descend' ? 180 : 0"
          :class="
            sortName.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('retailerName')"
        />
      </span>
      <span slot="locationTitle">
        Locations
        <a-icon
          type="caret-up"
          :rotate="sortLocation.toString().length > 0 && sortLocation === 'descend' ? 180 : 0"
          :class="
            sortLocation.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('locationCount')"
        />
      </span>
      <span slot="priceTitle">
        Pricing A/Q/M
        <a-icon
          type="caret-up"
          :rotate="sortPrice.toString().length > 0 && sortPrice === 'descend' ? 180 : 0"
          :class="
            sortPrice.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('pricing')"
        />
      </span>
      <span slot="modifiedTitle">
        Created
        <a-icon
          type="caret-up"
          :rotate="sortModify.toString().length > 0 && sortModify === 'descend' ? 180 : 0"
          :class="
            sortModify.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('modified')"
        />
      </span>
      <span slot="statusTitle">
        Status
        <a-icon
          type="caret-up"
          :rotate="sortStatus.toString().length > 0 && sortStatus === 'descend' ? 180 : 0"
          :class="
            sortStatus.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('status')"
        />
      </span>
      <span slot="brandTitle">
        Brands
        <a-icon
          type="caret-up"
          :rotate="sortBrand.toString().length > 0 && sortBrand === 'descend' ? 180 : 0"
          :class="
            sortBrand.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('brandCount')"
        />
      </span>
      <span slot="productTitle">
        Products
        <a-icon
          type="caret-up"
          :rotate="sortProduct.toString().length > 0 && sortProduct === 'descend' ? 180 : 0"
          :class="
            sortProduct.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('productCount')"
        />
      </span>
      <span slot="userTitle">
        Users
        <a-icon
          type="caret-up"
          :rotate="sortUser.toString().length > 0 && sortUser === 'descend' ? 180 : 0"
          :class="
            sortUser.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('sellerCount')"
        />
      </span>
      <div slot="pricing" slot-scope="text, record">
        <div style="display: inline-block; width: 80%;">
          {{
            record.annuallyPrice
              ? `${priceFormatString(record.annuallyPrice)}/${priceFormatString(
                  record.quarterlyPrice
                )}/${priceFormatString(record.monthlyPrice)}`
              : ""
          }}
        </div>
        <span
          class="admin-training-retailer__click-oper"
          style="margin-left: 12px; color: #4a8a5d;"
          @click="showEditPriceDialog(record)"
          >Edit</span
        >
      </div>
      <!-- <span slot="locations" slot-scope="text, record">
        {{ record.locations ? record.locations.length : 0 }}
      </span> -->
      <span slot="modifyDate" slot-scope="text, record">
        {{ record.updatedAt ? formatDate(record.updatedAt) : formatDate(record.createdAt) }}
      </span>
      <span slot="actionOper" slot-scope="text, record">
        <span
          v-if="record.retailerId !== '*'"
          class="admin-training-retailer__click-oper"
          @click="clickToView(record)"
        >
          View
        </span>
      </span>
    </a-table>
    <AdminTrainingRetailerDialog ref="createDialog" @updateSuccess="updateSuccess" />
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex"
import { adminRetailerTag } from "@/utils/const"
import SearchComponent from "@/components/training/SearchComponent"
import AdminTrainingRetailerDialog from "@/components/admin/AdminTrainingRetailerDialog"
export default {
  components: {
    "search-component": SearchComponent,
    AdminTrainingRetailerDialog
  },
  data() {
    return {
      searchRecent: [],
      searchValue: "",
      isLoading: false,
      tableColumn: [
        {
          //title: "Name",
          dataIndex: "retailerName",
          key: "retailerName",
          //sorter: true,
          slots: { title: "nameTitle" },
          width: "26%"
        },
        {
          //title: "Locations",
          dataIndex: "locationCount",
          key: "locationCount",
          slots: { title: "locationTitle" }
          // sorter: true
          // scopedSlots: { customRender: "locations" }
        },
        {
          //title: "Pricing A/Q/M",
          dataIndex: "pricing",
          key: "pricing",
          //sorter: true,
          slots: { title: "priceTitle" },
          scopedSlots: { customRender: "pricing" },
          width: "21%"
        },
        {
          //title: "Modified",
          dataIndex: "modified",
          key: "modified",
          //sorter: true,
          slots: { title: "modifiedTitle" },
          scopedSlots: { customRender: "modifyDate" }
        },
        {
          //title: "Modified",
          dataIndex: "status",
          key: "status",
          //sorter: true,
          slots: { title: "statusTitle" }
        },
        {
          //title: "Brands",
          dataIndex: "brandCount",
          key: "brandCount",
          slots: { title: "brandTitle" }
          //sorter: true
        },
        {
          //title: "Products",
          dataIndex: "productCount",
          key: "productCount",
          slots: { title: "productTitle" }
          //sorter: true
        },
        {
          //title: "Users",
          dataIndex: "sellerCount",
          key: "sellerCount",
          slots: { title: "userTitle" }
          //sorter: true
        },
        {
          title: "",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "actionOper" }
        }
      ],
      originalData: [],
      tableData: [],
      sortName: "",
      sortLocation: "",
      sortPrice: "",
      sortModify: "",
      sortBrand: "",
      sortProduct: "",
      sortUser: "",
      sortStatus: ""
    }
  },
  computed: {
    ...mapState({
      userId: state => state.user.id
      // tableDate: state => state.training.adminProductList
    }),
    ...mapGetters({
      searchProductList: "training/getTrainingRetailer"
    }),
    // If no data is displayed this button
    showShareBtn() {
      return this.tableDate && this.tableDate.length !== 0
    }
  },
  watch: {
    searchProductList() {
      this.initTableData(this.searchProductList, true)
    }
  },
  async created() {
    this.isLoading = true
    await this.getSearchTrainingPro()
    this.searchRecent = await this.getTagList(adminRetailerTag)
  },
  methods: {
    ...mapActions({
      updateTagList: "training/updateBrandTags",
      getTagList: "training/getSearchBrandTags",
      getSearchTrainingPro: "training/getTrainingRetailer"
    }),
    sortByField(fieldName) {
      let order = ""
      let index = 0
      if (fieldName === "retailerName") {
        if (this.sortName.length === 0) {
          this.sortName = "ascend"
        } else if (this.sortName === "ascend") {
          this.sortName = "descend"
        } else {
          this.sortName = "ascend"
        }
        this.sortLocation = ""
        this.sortPrice = ""
        this.sortModify = ""
        this.sortBrand = ""
        this.sortProduct = ""
        this.sortUser = ""
        order = this.sortName
        index = 0
      } else if (fieldName === "pricing") {
        if (this.sortPrice.length === 0) {
          this.sortPrice = "ascend"
        } else if (this.sortPrice === "ascend") {
          this.sortPrice = "descend"
        } else {
          this.sortPrice = "ascend"
        }
        this.sortLocation = ""
        this.sortName = ""
        this.sortModify = ""
        this.sortBrand = ""
        this.sortProduct = ""
        this.sortUser = ""
        order = this.sortPrice
        index = 2
      } else if (fieldName === "modified") {
        if (this.sortModify.length === 0) {
          this.sortModify = "ascend"
        } else if (this.sortModify === "ascend") {
          this.sortModify = "descend"
        } else {
          this.sortModify = "ascend"
        }
        this.sortLocation = ""
        this.sortPrice = ""
        this.sortName = ""
        this.sortBrand = ""
        this.sortProduct = ""
        this.sortUser = ""
        order = this.sortModify
        index = 3
      } else if (fieldName === "locationCount") {
        if (this.sortLocation.length === 0) {
          this.sortLocation = "ascend"
        } else if (this.sortLocation === "ascend") {
          this.sortLocation = "descend"
        } else {
          this.sortLocation = "ascend"
        }
        this.sortName = ""
        this.sortPrice = ""
        this.sortModify = ""
        this.sortBrand = ""
        this.sortProduct = ""
        this.sortUser = ""
        order = this.sortLocation
        index = 1
      } else if (fieldName === "brandCount") {
        if (this.sortBrand.length === 0) {
          this.sortBrand = "ascend"
        } else if (this.sortBrand === "ascend") {
          this.sortBrand = "descend"
        } else {
          this.sortBrand = "ascend"
        }
        this.sortLocation = ""
        this.sortPrice = ""
        this.sortModify = ""
        this.sortName = ""
        this.sortProduct = ""
        this.sortUser = ""
        order = this.sortBrand
        index = 4
      } else if (fieldName === "productCount") {
        if (this.sortProduct.length === 0) {
          this.sortProduct = "ascend"
        } else if (this.sortProduct === "ascend") {
          this.sortProduct = "descend"
        } else {
          this.sortProduct = "ascend"
        }
        this.sortLocation = ""
        this.sortPrice = ""
        this.sortModify = ""
        this.sortBrand = ""
        this.sortName = ""
        this.sortUser = ""
        order = this.sortProduct
        index = 5
      } else if (fieldName === "sellerCount") {
        if (this.sortUser.length === 0) {
          this.sortUser = "ascend"
        } else if (this.sortUser === "ascend") {
          this.sortUser = "descend"
        } else {
          this.sortUser = "ascend"
        }
        this.sortLocation = ""
        this.sortPrice = ""
        this.sortModify = ""
        this.sortBrand = ""
        this.sortProduct = ""
        this.sortName = ""
        order = this.sortUser
        index = 6
      }
      this.sortData(index, order == "ascend")
    },
    handleName(pagination, filters, sorter) {
      console.log(sorter)
      let index = 0
      if (sorter.field === "retailerName") {
        index = 0
      } else if (sorter.field === "pricing") {
        index = 2
      } else if (sorter.field === "modified") {
        index = 3
      } else if (sorter.field === "locationCount") {
        index = 1
      } else if (sorter.field === "brandCount") {
        index = 4
      } else if (sorter.field === "productCount") {
        index = 5
      } else if (sorter.field === "sellerCount") {
        index = 6
      }
      if (!sorter.order) {
        sorter.order = "ascend"
      }
      this.tableColumn[index].sortOrder = sorter.order
      for (let i = 0; i < 7; i++) {
        if (i !== index) {
          this.tableColumn[i].sortOrder = false
        }
      }
      this.sortData(index, sorter.order == "ascend")
    },
    sortData(index, ascend) {
      if (index == 0) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.retailerName.toLowerCase().localeCompare(b.retailerName.toLowerCase())
          } else {
            return b.retailerName.toLowerCase().localeCompare(a.retailerName.toLowerCase())
          }
        })
      } else if (index == 1) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.locationCount - b.locationCount
          } else {
            return b.locationCount - a.locationCount
          }
        })
      } else if (index == 2) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.annuallyPrice - b.annuallyPrice
          } else {
            return b.annuallyPrice - a.annuallyPrice
          }
        })
      } else if (index == 3) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.updatedAt != null && b.updatedAt != null) {
              return a.updatedAt.localeCompare(b.updatedAt)
            }
            if (a.updatedAt != null && b.updatedAt == null) {
              return a.updatedAt.localeCompare(b.createdAt)
            }
            if (a.updatedAt == null && b.updatedAt != null) {
              return a.createdAt.localeCompare(b.updatedAt)
            }
          } else {
            if (b.updatedAt != null && b.updatedAt != null) {
              return b.updatedAt.localeCompare(a.updatedAt)
            }
            if (b.updatedAt != null && a.updatedAt == null) {
              return b.updatedAt.localeCompare(a.createdAt)
            }
            if (b.updatedAt == null && a.updatedAt != null) {
              return b.createdAt.localeCompare(a.updatedAt)
            }
          }
        })
      } else if (index == 4) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.brandCount - b.brandCount
          } else {
            return b.brandCount - a.brandCount
          }
        })
      } else if (index == 5) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.productCount - b.productCount
          } else {
            return b.productCount - a.productCount
          }
        })
      } else {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.sellerCount - b.sellerCount
          } else {
            return b.sellerCount - a.sellerCount
          }
        })
      }
    },
    priceFormatString(value) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(value)
    },
    async onSearch(value) {
      this.searchValue = value
      this.searchRecent = await this.updateTagList({
        tagName: adminRetailerTag,
        tagItem: this.searchValue
      })
      let result = this.originalData.filter(item => {
        if (
          item.retailerName &&
          item.retailerName.toLowerCase().indexOf(value.toLowerCase()) >= 0
        ) {
          return true
        }
        return false
      })
      this.initTableData(result, false)
    },
    initTableData(data, needInitOrigin) {
      this.isLoading = false
      this.tableData = []
      if (needInitOrigin) {
        this.originalData = []
      }
      if (data && data.length > 0) {
        let len = data.length
        for (let i = 0; i < len; i++) {
          let aa = Object.assign({}, data[i])
          aa.isChecked = false
          aa.isPreferred = aa.gradeType && aa.gradeType === "preferred" ? true : false
          this.tableData.push(aa)
          if (needInitOrigin) {
            this.originalData.push(aa)
          }
        }
      }
    },
    async closeTagItem(item) {
      await this.updateTagList({ tagName: adminRetailerTag, tagItem: item.name, type: "delete" })
    },
    async updateSuccess() {
      let param = { status: "approved", "have-training-price": true }
      if (this.searchValue && this.searchValue.length > 0) {
        param["retailer"] = this.searchValue
      }
      await this.getSearchTrainingPro({ params: param })
    },
    clickToView(data) {
      this.$router.push({
        name: "adminTrainingRetailerView",
        params: {
          retailerId: data.retailerId,
          name: data.retailerName
        }
      })
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value)
        return new Intl.DateTimeFormat("en-US").format(date)
      }
      return " "
    },
    // async handleName(pagination, filters, sorter) {
    //   console.log(pagination)
    //   console.log(filters)
    //   console.log(sorter)
    //   console.log("....handle name.....")
    //   if (sorter.field === "pricing") {
    //     if (sorter.order === "ascend") {
    //       console.log("ascend")
    //       this.tableData.sort(function(a, b) {
    //         return a.annuallyPrice)
    //       })
    //     } else {
    //       this.tableData.sort(function(a, b) {
    //         console.log(a)
    //         console.log(b)
    //         console.log(b.retailerName.toString().localeCompare(a.retailerName.toString()))
    //         return b.retailerName.toString().localeCompare(a.retailerName.toString())
    //       })
    //     }
    //   }
    //   // if (sorter.field === "modified") {
    //   //   params = { sortModified: sorter.order === "ascend" ? true : false }
    //   //   await this.getSearchTrainingPro({
    //   //     params: {
    //   //       retailer: this.searchValue,
    //   //       status: "approved",
    //   //       "have-training-price": true,
    //   //       ...params
    //   //     }
    //   //   })
    //   // }
    // },
    showCreateNewDialog() {
      this.$refs["createDialog"].openForAdd("Add BeautyFluent Retailer")
    },
    showEditPriceDialog(data) {
      this.$refs["createDialog"].openDialog("Edit Pricing", data)
    }
  }
}
</script>
<style lang="scss">
.admin-training-retailer {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  &__click-oper {
    cursor: pointer;
    color: #4a8a5d;
  }
}
</style>
