<template>
  <div class="select-payment-pay">
    <a-row :span="24">
      <a-col :span="16">
        <div class="select-payment-pay__card-content">
          <a-row>
            <a-col :span="14">
              <span class="select-payment-pay__title">
                {{ $t("Components.Brands.Payment.selectPaymentMethod") }}
              </span>
            </a-col>
            <a-col :span="4">
              <div
                :Class="
                  !paymentMethods || paymentMethods.length === 0 || !isShowMore
                    ? 'select-payment-pay__operation-unable'
                    : 'select-payment-pay__operation'
                "
                @click="showPaymentMethods"
              >
                {{ $t("Components.Brands.Payment.change") }}
              </div>
              <div
                v-if="isShowMore && paymentMethods && paymentMethods.length > 0"
                class="select-payment-pay__operation-border"
              />
            </a-col>
            <a-col :span="6">
              <img src="@/assets/img/icon/secure.svg" />
            </a-col>
          </a-row>
          <a-row>
            <PaymentInfo :credit-card="selectedPayment" @cardInfo="receiveSelectedData" />
          </a-row>
        </div>
      </a-col>
      <a-col :span="8">
        <a-row>
          <OrderSummary @changeCode="getPromoCode" @checkSuccess="getTotalAmount" />
        </a-row>
        <a-row class="select-payment-pay__button-row">
          <a-button
            id="doPurchase"
            class="select-payment-pay__button"
            :disabled="!canPurchase"
            :loading="isLoading"
            @click="doPurchase"
          >
            {{
              isOnlyPay
                ? $t("Components.Brands.Payment.payNow")
                : $t("Components.Brands.Payment.purchaseNow")
            }}
          </a-button>
        </a-row>
      </a-col>
    </a-row>
    <div>
      <a-modal v-model="isShowFail" :footer="null" width="490px">
        <template #closeIcon>
          <img src="@/assets/img/icon/close-circle.svg" />
        </template>
        <a-result status="error">
          <template #title>
            <p class="pay-failed-dialog__failed-title">
              {{ $t("Components.Brands.Payment.payFailTitle") }}
            </p>
          </template>
          <template #subTitle>
            <span
              class="pay-failed-dialog__content"
              v-html="$t('Components.Brands.Payment.payFailInfo')"
            />
          </template>
          <template #icon>
            <img src="@/assets/img/icon/error_info_dialog.svg" />
          </template>
          <template #extra>
            <a-button class="pay-failed-dialog__btn" @click="hideFailDialog">
              {{ $t("Components.Brands.Payment.tryAgain") }}
            </a-button>
          </template>
        </a-result>
      </a-modal>
      <a-modal
        v-model="isShowList"
        :footer="null"
        :destroy-on-close="true"
        with="950px"
        :body-style="modalBodyStyle"
      >
        <template #closeIcon>
          <img src="@/assets/img/icon/close-circle.svg" />
        </template>
        <a-row>
          <span class="select-payment-pay__title">
            {{ $t("Components.Brands.Payment.selectPaymentMethod") }}
          </span>
        </a-row>
        <ChooseCardList
          :card-info-list="paymentMethods"
          :current-method="selectedPayment"
          :brand-id="brandId"
          @chooseMethod="setSelectData"
        />
      </a-modal>
    </div>
  </div>
</template>

<script>
import { Row, Col, Button, Modal } from "ant-design-vue"
import PaymentInfo from "@/components/brands/payment/PaymentInfo"
import OrderSummary from "@/components/brands/payment/OrderSummary"
import ChooseCardList from "@/components/brands/payment/ChooseCardList"
import Utils from "@/common/Utils"
export default {
  components: {
    "a-row": Row,
    "a-col": Col,
    "a-button": Button,
    "a-modal": Modal,
    PaymentInfo,
    OrderSummary,
    ChooseCardList
  },
  data() {
    return {
      renewDate: "",
      paidAmount: "",
      payAmount: 2000,
      selectedPayment: undefined,
      canPurchase: this.selectedPayment ? true : false,
      isLoading: false,
      isShowFail: false,
      isShowList: false,
      isShowMore: true,
      isOnlyPay: false,
      modalBodyStyle: {
        padding: "14px"
      },
      promoCode: undefined
    }
  },
  computed: {
    paymentMethods() {
      return this.$store.getters["brandPayment/cardInfoList"]
    },
    defaultCard() {
      return this.$store.getters["brandPayment/defaultCard"]
    },
    brandId() {
      return this.isAdmin ? this.$route.params.brandId : this.$store.getters["user/businessId"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    }
  },
  async created() {
    this.isOnlyPay = this.$route.params ? this.$route.params.isOnlyPay : false
    let brandId = this.$route.params.brandId
      ? this.$route.params.brandId
      : this.$store.getters["user/businessId"]
    await this.$store.dispatch("brandPayment/fetchCards", brandId)
    this.selectedPayment = this.$store.getters["brandPayment/defaultCard"]
    if (this.selectedPayment) {
      this.canPurchase = true
    } else {
      this.canPurchase = false
    }
    this.renewDate = this.getDate()
    this.paidAmount = this.getAmount()
    this.promoCode = undefined
  },
  methods: {
    getPromoCode(code) {
      this.promoCode = code
      if (code === undefined || code.replace(/^\s*|\s*$/g, "").length === 0) {
        this.canPurchase = true
      } else {
        this.canPurchase = false
      }
    },
    getTotalAmount(promoCode) {
      this.promoCode = promoCode
      this.payAmount = 2000
      this.canPurchase = true
    },
    getDate() {
      let today = new Date()
      today.setTime(today.getTime() + 364 * 24 * 60 * 60 * 1000)
      const dateFormat = new Intl.DateTimeFormat("en-US")
      return dateFormat.format(today)
    },
    getAmount() {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(2000)
    },
    receiveSelectedData(data) {
      if (data.payType && data.payType !== "creditCard") {
        this.isShowMore = false
      } else {
        this.isShowMore = true
      }
      this.selectedPayment = data
      if (this.isShowList) {
        this.selectedPayment.payType = "creditCard"
        this.hidePaymentMethods()
      }
      this.initCanPurchase()
    },
    initCanPurchase() {
      if (
        this.selectedPayment &&
        this.selectedPayment.payType === "creditCard" &&
        this.selectedPayment.cardNumber &&
        this.selectedPayment.cardCode &&
        this.selectedPayment.expirationDate
      ) {
        this.canPurchase = true
      } else {
        this.canPurchase = false
      }
    },
    setSelectData(data) {
      this.receiveSelectedData(data)
    },
    showSuccessDialog() {
      if (this.$route.name.toString().startsWith("admin")) {
        this.$router.push({ name: "adminBrandPaySubscriptionSuccess" })
      } else {
        this.$router.push({ name: "brandPaySubscriptionSuccess" })
      }
    },
    showFailDialog() {
      this.isShowFail = true
    },
    hideFailDialog() {
      this.isShowFail = false
    },
    showPaymentMethods() {
      if (!this.paymentMethods || this.paymentMethods.length === 0 || !this.isShowMore) {
        this.isShowList = false
      } else {
        this.isShowList = true
      }
    },
    hidePaymentMethods() {
      this.isShowList = false
    },

    showErrorInfo(message) {
      if (message) {
        this.$message.error(message)
      }
      this.isLoading = false
    },
    isAddNewCard() {
      if (!this.selectedPayment.privateId) {
        return true
      }
      return false
    },
    isModifyOtherCardInfo(originPayment) {
      if (
        this.isModifyNameOrDefault(originPayment) ||
        this.selectedPayment.cardCode !== originPayment.cardCode ||
        this.selectedPayment.expirationDate !== originPayment.expirationDate
      ) {
        return true
      }
      return false
    },
    isModifyNameOrDefault(originPayment) {
      if (
        this.selectedPayment.cardHolderName !== originPayment.cardHolderName ||
        this.selectedPayment.default !== originPayment.default
      ) {
        return true
      }
      return false
    },
    checkCardInfo() {
      if (!this.selectedPayment.cardNumber) {
        this.showErrorInfo(this.$t("Components.Brands.Payment.cardNumberEmpty"))
        return false
      }
      if (!this.selectedPayment.expirationDate) {
        this.showErrorInfo(this.$t("Components.Brands.Payment.expirationDateEmpty"))
        return false
      }
      if (!this.selectedPayment.cardCode) {
        this.showErrorInfo(this.$t("Components.Brands.Payment.cvvEmpty"))
        return false
      }
      if (!Utils.checkCardNumber(this.selectedPayment.cardNumber)) {
        // check number
        this.showErrorInfo(this.$t("Components.Brands.Payment.cardNumberError"))
        return false
      }
      return this.checkCardCodeAndExpirationDate()
    },
    checkCardCodeAndExpirationDate() {
      if (!Utils.checkExpiredDate(this.selectedPayment.expirationDate)) {
        this.showErrorInfo(this.$t("Components.Brands.Payment.expiredDateFormatError"))
        return false
      }
      if (Utils.isDateExpired(this.selectedPayment.expirationDate)) {
        this.showErrorInfo(this.$t("Components.Brands.Payment.expiredDateExpiredError"))
        return false
      }
      return this.checkCardCode()
    },
    checkCardCode() {
      if (!Utils.checkCardCode(this.selectedPayment.cardCode)) {
        this.showErrorInfo(this.$t("Components.Brands.Payment.cardCodeError"))
        return false
      }
      return true
    },
    async doPurchase() {
      // modify the api
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "SubscriptionExpired",
        userId: this.$store.getters["user/id"],
        firstName: this.$store.getters["user/givenName"],
        lastName: this.$store.getters["user/familyName"],
        emailAddress: this.$store.getters["user/email"],
        userType: "Brand"
      })
      this.isLoading = true
      if (this.isAddNewCard()) {
        if (this.checkCardInfo()) {
          this.addCard()
        }
      } else {
        let originPayment = this.paymentMethods.find(
          item => item.privateId === this.selectedPayment.privateId
        )
        if (this.isModifyOtherCardInfo(originPayment)) {
          if (this.selectedPayment.expirationDate === originPayment.expirationDate) {
            if (this.checkCardCode()) {
              this.updateCardCvvOrDefault(this.brandId, originPayment, true)
            }
          } else {
            if (this.checkCardCodeAndExpirationDate()) {
              this.updateExpiration()
              if (this.isModifyNameOrDefault(originPayment)) {
                this.updateCardCvvOrDefault(this.brandId, originPayment, false)
              }
            }
          }
        } else {
          this.payOrder(this.brandId)
        }
      }
    },
    async addCard() {
      await this.$store
        .dispatch("brandPayment/updateCardInfo", {
          brandId: this.brandId,
          cardInfo: this.selectedPayment
        })
        .then(response => {
          if (!response.error) {
            this.payOrder(this.brandId)
          } else {
            // add failed
            this.showErrorInfo(response.developerMessage)
          }
        })
        .catch(() => {
          this.showErrorInfo(this.$t("Components.Brands.Payment.addCardFailed"))
        })
    },
    async updateCardCvvOrDefault(brandId, originPayment, isNeedPay) {
      let isNeedSuccess = false
      if (this.selectedPayment.cardCode !== originPayment.cardCode) {
        isNeedSuccess = true
      }
      let defaultParam = []
      defaultParam.push({
        privateId: this.selectedPayment.privateId,
        cardHolderName: this.selectedPayment.cardHolderName,
        cardCode: this.selectedPayment.cardCode,
        isDefault: this.selectedPayment.default ? 1 : 0
      })
      let isDefaultChange = originPayment.default !== this.selectedPayment.default
      if (isDefaultChange) {
        this.doCancelDefault(brandId)
      }
      await this.$store
        .dispatch("brandPayment/setCardDefault", {
          brandId: brandId,
          param: defaultParam
        })
        .then(response => {
          if (isNeedSuccess && response.error) {
            this.showErrorInfo(response.developerMessage)
          } else {
            if (isNeedPay) {
              this.payOrder(brandId)
            }
          }
        })
        .catch(() => {
          if (isNeedSuccess) {
            this.showErrorInfo(this.$t("Components.Brands.Payment.addCardFailed"))
          } else {
            if (isNeedPay) {
              this.payOrder(brandId)
            }
          }
        })
    },
    async doCancelDefault(brandId) {
      let defaultItem = this.paymentMethods.find(item => item.default)
      if (defaultItem) {
        let params = []
        params.push({
          privateId: defaultItem.privateId,
          isDefault: 0
        })
        await this.$store.dispatch("brandPayment/setCardDefault", {
          brandId: brandId,
          param: params
        })
      }
    },
    async updateExpiration() {
      let params = {
        cardNumber: this.selectedPayment.cardNumber,
        cardCode: this.selectedPayment.cardCode,
        customerProfileId: this.selectedPayment.customerProfileId,
        customerPaymentProfileId: this.selectedPayment.customerPaymentProfileId,
        privateId: this.selectedPayment.privateId,
        expirationDate: this.selectedPayment.expirationDate
      }
      try {
        await this.$store
          .dispatch("brandPayment/updateExpireDate", {
            brandId: this.brandId,
            param: params
          })
          .then(response => {
            if (response.error) {
              this.showErrorInfo(response.developerMessage)
            } else {
              this.payOrder(this.brandId)
            }
          })
      } catch (e) {
        this.showErrorInfo(e.toString())
      }
    },
    async payOrder(brandId) {
      if (this.isOnlyPay) {
        //pay now....
        this.payNow()
      } else {
        // purchase now....
        let params = { amount: this.payAmount, cardNumber: this.selectedPayment.cardNumber }
        if (this.promoCode && this.promoCode.length > 0) {
          params["couponCode"] = this.promoCode
        }
        await this.$store
          .dispatch("brandPayment/payOrder", {
            brandId: brandId,
            param: params
          })
          .then(response => {
            if (response.error) {
              this.showFailDialog()
              this.isLoading = false
            } else {
              this.showSuccessDialog()
              this.showErrorInfo()
            }
          })
          .catch(response => {
            if (!response.message) {
              this.showErrorInfo(this.$t("Components.Brands.Payment.payFailedServerError"))
            } else {
              let data = response.message
              this.showErrorInfo(data)
            }
          })
      }
    },
    payNow() {
      this.isLoading = true
      let params = { amount: this.payAmount, cardNumber: this.selectedPayment.cardNumber }
      if (this.promoCode && this.promoCode.length > 0) {
        params["couponCode"] = this.promoCode
      }
      this.$store
        .dispatch("brandPayment/paymentOfFee", {
          brandId: this.brandId,
          param: params
        })
        .then(response => {
          if (response.error) {
            this.showFailDialog()
            this.isLoading = false
          } else {
            this.showSuccessDialog()
            this.showErrorInfo()
          }
        })
        .catch(response => {
          if (!response.message) {
            this.showErrorInfo(this.$t("Components.Brands.Payment.payFailedServerError"))
          } else {
            let data = response.message
            this.showErrorInfo(data)
          }
        })
    }
  }
}
</script>

<style lang="sass">
.select-payment-pay
    width: 900px
    margin: 0 auto
    align-content: center
    padding: 60px 60px !important
    &__card-content
        width: 487px
        border: 1px solid #D9D9D9
        padding: 24px 24px 28px 30px
        margin-left: 11px
    &__title
        font-family: Proxima Nova
        font-style: normal
        font-weight: bold
        font-size: 18px
        line-height: 24px
        display: flex
        align-items: center
        color: #262626
        margin-bottom: 17px
    &__operation
        font-weight: normal
        font-size: 18px
        line-height: 24px
        display: flex
        align-items: center
        color: #4A8A5D
        margin-bottom: 0px
        width: fit-content
        width: -moz-fit-content
        cursor: pointer
    &__operation-border
        border-bottom: 1px solid #4a8a5d
        margin-bottom: 17px
        margin-top: 0px
        margin-right: 9px
    &__operation-unable
        color: #595959
    &__border
        width: 435px
        border-bottom: 0.5px solid #E8E8E8
        height: 1px
        margin-bottom: 17px !important
        margin-top: 13px !important
    &__info
        font-family: Proxima Nova
        font-style: normal
        font-weight: normal
        font-size: 12px
        line-height: 22px
        color: #595959
    &__button-row
        align-items: center
        margin-top: 26px
        padding: 0px 25%
    &__button
        font-family: Proxima Nova !important
        font-style: normal !important
        font-weight: normal !important
        font-size: 16px !important
        line-height: 24px !important
        border-radius: 2px !important
.pay-failed-dialog
  &__failed-title
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 24px
    line-height: 24px
    color: #262626
    text-align: center
    margin-bottom: 10px !important
  &__content
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    width: 375px
    font-size: 14px
    line-height: 20px
    display: flex
    align-items: center
    text-align: center
    color: #595959
    margin: 0 auto
  &__btn
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal !important
    font-size: 14px
    line-height: 20px
    align-items: center
    text-align: center
    color: #4A8A5D !important
    border: 1px solid #4A8A5D !important
    box-sizing: border-box
    border-radius: 2px !important
    padding: 2px 14px !important

.ant-result
  padding: 48px 16px !important
.ant-result-extra
  margin-top: 13px !important
.ant-col-4
    align-content: end
    img
        margin-right: 0px
#doPurchase
    border-color: #4A8A5D
    color: #4A8A5D
#doPurchase:disabled
    background-color: rgba(0,0,0,0) !important
    color: #BFBFBF !important
    border-color: #BFBFBF !important
    border-radius: 2px !important
</style>
