<template>
  <div class="public-read-training__main">
    <div class="training-create-title">
      <div class="training-page-title">
        {{ userInfo ? userInfo.name : "" }} Training Page
        <!--        <img src="../../assets/img/landing-title.png" />-->
        <!--        <div class="training-logo">{{ this.$t("Training.brandTraining.trainingPage") }}</div>-->
      </div>
    </div>
    <div class="training-details-content">
      <div class="company-details">
        <div class="content-right">
          <div class="right-top-text-button">
            <div
              class="right-text-right"
              :style="{
                width: `${
                  userInfo.introductionAsset && Object.keys(userInfo.introductionAsset).length !== 0
                    ? '570px'
                    : '694px'
                }`
              }"
            >
              <!--              <div class="title-name">-->
              <!--                {{ userInfo.name }}-->
              <!--              </div>-->
              <div class="about-content-main">
                <div class="content-instructions">
                  {{ userInfo ? userInfo.about : "" }}
                </div>
              </div>
            </div>
            <!--            <div-->
            <!--                v-if="-->
            <!--                userInfo.introductionAsset && Object.keys(userInfo.introductionAsset).length !== 0-->
            <!--              "-->
            <!--                class="right-button-left"-->
            <!--            >-->
            <!--              <div v-if="userInfo.introductionAsset" class="video-style" @click="clickToVideo">-->
            <!--                <img class="public-start" src="../../assets/training_public_video.jpg" alt="" />-->
            <!--                <video width="250" height="110" style="height: 110px !important;">-->
            <!--                  <source :src="userInfo.introductionAsset.url" type="video/mp4" />-->
            <!--                  Your browser does not support the HTML5 video tag.-->
            <!--                </video>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div class="other-content">
            <div class="curations">
              <div class="name-curation-value">
                Curations
              </div>
              <div>
                <span v-for="(tag, index) in userInfo.curations" :key="index" class="blue-tag">
                  {{ tag.description }}
                </span>
              </div>
            </div>
            <div class="brand-value">
              <div class="name-curation-value">
                Brand Values
              </div>
              <div v-if="brandValue && brandValue.length !== 0">
                <span v-for="item in brandValue" :key="item.id" class="green-tag">
                  {{ item.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="brand-info-img-video">
          <div class="img-video-list">
            <div v-for="item in brandImgAndVideoInfo" :key="item.asset.id">
              <div v-if="item.isImg" class="item-img-info" @click="showBigImg(item)">
                <img :src="item.asset.url" alt="/" />
              </div>
              <div v-if="item.asset.url && !item.isImg" class="item-img-info">
                <div class="video-style" @click="showBigImg(item)">
                  <img class="public-start" :src="publicVideoIcon" alt="" />
                  <video width="auto" height="35" style="height: 35px !important;">
                    <source :src="item.asset.url" type="video/mp4" />
                    Your browser does not support the HTML5 video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div v-if="bigImg.isImg" class="content-left">
            <img :src="bigImg.url" alt="/" />
          </div>
          <div v-else-if="bigImg.contentType.includes('video')" @click="clickToVideo(bigImg)">
            <div class="content-left" style="cursor: pointer">
              <img
                class="public-start-button"
                :src="publicVideoIcon"
                alt=""
                :style="{ display: `${bigImg.isImg ? 'none' : 'block'}` }"
              />
              <video width="auto" height="200" style="margin: 10px auto">
                <source :src="bigImg.url" type="video/mp4" />
                Your browser does not support the HTML5 video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-list">
      <div v-for="(item, index) in proList" :key="index">
        <guestReadProductList
          :product="item"
          :is-read="true"
          :user-info="userInfo"
        ></guestReadProductList>
      </div>
    </div>
    <a-modal
      :visible="modalVisible"
      :footer="null"
      :body-style="{ padding: 0 }"
      @cancel="handleCancel"
    >
      <a-icon slot="closeIcon" type="close-circle" class="modal-icon-cancel" />
      <div class="video-main">
        <video id="video1" ref="videoM" controls>
          <source :src="introductionAssetUrl" type="video/mp4" />
          Your browser does not support the HTML5 video tag.
        </video>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Image from "@/assets/img/back-arrow.png"
import guestReadProductList from "@/components/training/guestReadProductListNew"
import { mapActions } from "vuex"
import trainingClient from "@/api/training-client"
import publicVideoIcon from "@/assets/training_public_video.png"

export default {
  name: "ViewAllProducts",
  components: {
    guestReadProductList
  },
  data() {
    return {
      publicVideoIcon,
      bigImg: {
        url: this.userInfo && this.userInfo.logoAsset ? this.userInfo.logoAsset.url : "",
        contentType: "image/jpeg",
        isImg: true
      },
      Image,
      proList: [],
      userInfo: {
        introductionAsset: [],
        curations: []
      },
      modalVisible: false,
      introductionAssetUrl: "",
      retailerName: ""
      // videoImg: null
    }
  },
  computed: {
    brandValue() {
      return !this.userInfo.brandValues ? [] : this.userInfo.brandValues
    },
    brandImgAndVideoInfo() {
      const logoImg = [
        {
          asset: {
            ...this.userInfo.logoAsset,
            contentType: "image/jpeg",
            canEdit: false
          }
        }
      ]
      let videoImgList =
        this.userInfo.assets && this.userInfo.assets.length !== 0 ? this.userInfo.assets : []
      if (videoImgList.length !== 0) {
        videoImgList = videoImgList.map(item => {
          item.canEdit = true
          item.uid = item.id
          return item
        })
      }
      const imgList = videoImgList.filter(item => item.asset.contentType.includes("image"))
      const videoList = videoImgList.filter(item => item.asset.contentType.includes("video"))
      const list = logoImg.concat(imgList).concat(videoList)
      return list.map(item => {
        if (item.asset.contentType.includes("video")) {
          item.isImg = false
        } else {
          item.isImg = true
        }
        return item
      })
    }
  },
  async created() {
    if (this.$route.query.id) {
      this.userInfo = await this.getBrandInfo(this.$route.query.id)
      const list = await this.getBrandProductList(this.$route.query.id)
      this.proList = list.map(item => {
        item.msrpUsd = parseInt(item.msrpUsd, 10).toFixed(2)
        return item
      })
    } else {
      const userName = this.$route.params.brandName
      const brand = await trainingClient.getGuestBrandInfo(userName)
      const userId = brand.data.id
      const info = await trainingClient.getGuestTrainingList(userId)
      this.userInfo = brand.data
      this.proList = info.data.map(item => {
        item.msrpUsd = parseInt(item.msrpUsd, 10).toFixed(2)
        return item
      })
      if (this.$route.params.retailerName) {
        const retailerName = this.$route.params.retailerName
        if (retailerName === "General") {
          const userName = this.$route.params.brandName
          const brand = await trainingClient.getGuestBrandInfo(userName)
          const userId = brand.data.id
          const info = await trainingClient.getGuestTrainingList(userId)
          this.proList = info.data.map(item => {
            item.msrpUsd = parseInt(item.msrpUsd, 10).toFixed(2)
            return item
          })
        } else {
          const retailer = await trainingClient.getRetailerInfo(retailerName)
          this.proList = this.proList.filter(value => {
            let trainingInfo = []
            if (value.trainingForRetailers && value.trainingForRetailers.length !== 0) {
              trainingInfo = value.trainingForRetailers.filter(
                item => item.retailerId === retailer.data.id
              )
            }
            return trainingInfo && trainingInfo.length !== 0
          })
        }
      }
    }
    this.bigImg = {
      url: this.userInfo && this.userInfo.logoAsset ? this.userInfo.logoAsset.url : "",
      contentType: "image/jpeg",
      isImg: true
    }
  },
  methods: {
    ...mapActions({
      getBrandProductList: "training/getBrandProduct",
      getBrandInfo: "brand/getBrand"
    }),
    jumpToTraining() {
      this.$router.go(-1)
      // this.$router.push({
      //   name: "BrandTrainingDetails"
      // })
    },
    jumpToPublicPage() {
      this.$router.push({
        name: "PublicReadTraining"
      })
    },
    handleCancel() {
      this.modalVisible = false
      this.introductionAssetUrl = ""
      this.$refs["videoM"].pause()
    },
    clickToVideo(item) {
      this.introductionAssetUrl = item.url
      this.modalVisible = true
      this.$refs["videoM"].play()
    },
    openVideo(item) {
      this.modalVisible = true
      this.introductionAssetUrl = item.url
      this.$refs["videoM"].play()
    },
    showBigImg(item) {
      this.bigImg = {
        ...item.asset,
        isImg: item.isImg
      }
    }
  }
}
</script>

<style scoped lang="scss">
.public-read-training__main {
  min-height: 520px;
  width: 952px;
  margin: 0 auto;

  .training-page-title {
    width: 100%;
    height: 94px;
    line-height: 94px;
    font-size: 20px;
    text-align: center;
    color: #1a1818;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    margin-top: 24px;
    //display: flex;
    //justify-content: space-between;
    //align-items: center;
    //img {
    //  width: 200px;
    //  height: 64px;
    //}
  }

  .training-logo {
    font-size: 48px;
    font-weight: bold;
    color: #000000;
    font-family: "Playfair Display";
  }
  .title-name {
    color: #000000;
    font-size: 24px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    font-weight: bold;
    //line-height: 94px;
    //margin-left: 49px;
    margin-top: -10px;
    margin-bottom: 8px;
  }
  .training-details-content {
    width: 100%;
    padding: 0 18px 0 22px;
    margin-top: 20px;
    //height: 22px;
    .company-details {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .brand-info-img-video {
      width: 360px;
      display: flex;
      justify-content: space-between;
      //align-items: center;
    }
    .img-video-list {
      width: 35px;
      height: 222px;
      .item-img-info {
        width: 35px;
        height: 35px;
        border: 0.5px solid #d9d9d9;
        border-radius: 2px;
        margin-bottom: 10px;
        img {
          width: 100%;
        }
      }
    }
    .content-left {
      width: 300px;
      height: 222px;
      line-height: 215px;
      text-align: center;
      border: 0.5px solid #d9d9d9;
      border-radius: 2px;
      position: relative;
      //border: 1px solid #bfbfbf;
      img {
        //width: 100%;
        height: 100%;
      }
      .public-start-button {
        width: 50px;
        //height: 30px;
        height: auto;
        position: absolute;
        top: 80px;
        right: 120px;
        border-radius: 50%;
      }
    }
    .content-right {
      width: 500px;
      position: relative;
      .content-instructions {
        height: 108px;
        font-size: 12px;
        line-height: 16px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        margin-bottom: 12px;
        color: #262626;
        position: relative;
        word-break: break-word;
        overflow-y: auto;
      }
      .other-content {
        //display: flex;
        //justify-content: flex-start;
      }
      .curations,
      .brand-value {
        width: 100%;
        font-size: 16px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: start;
        span {
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 10px;
          font-size: 12px;
        }
      }
      .name-curation-value {
        font-size: 12px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        margin-bottom: 8px;
        margin-right: 30px;
        color: #262626;
      }
      .blue-tag {
        padding: 5px 8px;
        color: #ffffff;
        border: 1px solid #f27c59;
        background: #f27c59;
        border-radius: 100px;
        font-size: 10px;
      }
      .green-tag {
        padding: 5px 8px;
        color: #ffffff;
        border: 1px solid #91d395;
        background: #91d395;
        border-radius: 100px;
        font-size: 10px;
      }
      .right-top-text-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right-text-right {
          width: 570px;
        }
        .right-button-left {
          width: 171px;
          margin-left: 30px;
          .upload-video-style {
            width: 170px;
            border: 1px solid #4a8a5d;
            color: #4a8a5d;
            background-color: #e2eeec;
            text-align: center;
          }
          .upload-success {
            width: 170px;
            border: 1px solid #4a8a5d;
            color: #ffffff;
            background-color: #4a8a5d;
            text-align: center;
          }
        }
      }
    }
  }
  .video-style {
    width: 35px;
    height: 35px;
    margin-top: 6px;
    text-align: center;
    //border: 1px solid #262626;
    cursor: pointer;
    position: relative;
    .video-img {
      //width: 100%;
      height: 100%;
    }
    .public-start {
      width: 15px !important;
      position: absolute;
      top: 4px;
      right: 10px;
      border-radius: 50%;
    }
    .background-style {
      width: 10px;
      height: 10px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      top: 10px;
      right: 10px;
    }
  }
  .product-list {
    width: 100%;
    margin-top: 25px;
  }
  .right-img-and-video-list {
    position: relative;
    .replace-video-button-style {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
    }
  }
}
</style>
