import axios from "@/api/axios"

export default {
  addPaymentMethod(brandId, paymentCardInfo = {}) {
    return axios.post(
      `/brands/${brandId}/brandCreditCardInformation`,
      JSON.stringify(paymentCardInfo)
    )
  },
  deletePaymentMethod(brandId, params = {}) {
    return axios.post(`/brands/${brandId}/deleteBrandCreditCardInformation`, JSON.stringify(params))
  },
  getCardInfo(brandId) {
    return axios.get(`/brands/${brandId}/brandCreditCardInformation`)
  },
  setCardInfoBeDefault(brandId, params) {
    return axios.put(`/brands/${brandId}/brandCreditCardInformation`, JSON.stringify(params))
  },
  updateExpireDate(brandId, params) {
    return axios.put(
      `/brands/${brandId}/updateBrandCreditCardExpirationDate`,
      JSON.stringify(params)
    )
  },
  payOrder(brandId, params) {
    return axios.post(`/brands/${brandId}/brandCreditCardPayment`, JSON.stringify(params))
  },
  getHistoryList(brandId) {
    return axios.get(`/brands/${brandId}/brandSubscriptionHistory`)
  },
  getPaymentDownload(brandId, params) {
    return axios.post(`/brand/${brandId}/brandSubscriptionHistoryToPdf`, JSON.stringify(params), {
      responseType: "arraybuffer"
    })
  },
  paymentOfFee(brandId, params) {
    return axios.post(`/brands/${brandId}/paymentOfFees`, JSON.stringify(params))
  },
  paymentRefund(brandId, params) {
    return axios.post(
      `/brands/${brandId}/brandCreditCardPaymentRefund?private-id=${params["private-id"]}`,
      params
    )
  }
}
