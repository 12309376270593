import axios from "@/api/axios"

export default {
  /**
   * Get the goods that can create Training
   * @param brandId
   * @returns {Array}
   */
  getTrainingProducts(brandId) {
    return axios.get(`/products?brand=${brandId}&_limit=9999&have-training=true`)
  },
  /**
   * Create multiple Training goods
   * @param brandId
   * @param params Array
   * @returns
   */
  addTrainingProduct(brandId, params) {
    return axios.put(`/products/${brandId}?_skipNulls=true`, JSON.stringify(params))
  },
  /**
   * @param brandId
   * @returns
   */
  updateTrainingProduct(brandId, params) {
    return axios.put(`/products/${brandId}?_skipNulls=true`, params)
  },
  /**
   * admin，To search for training
   * @param brandName
   * @param params
   * @returns { Array }
   */
  searchTrainingProduct(brandName, params) {
    return axios.get(`/brands/admin/training?brand-product-name=${brandName}&_limit=9990`, {
      params
    })
  },
  /**
   * admin，To search for training
   * @param params
   * @returns { Array }
   */
  searchTrainingBrand(params) {
    return axios.get(`/brands/admin/training`, {
      params
    })
  },
  /**
   * Remove a single Training item
   * @param id
   */
  deleteTrainingProduct(id) {
    return axios.delete(`/products/${id}/training/delete`)
  },
  getProductList(id) {
    return axios.get(`/products?brand=${id}&_limit=9999&have-training=false`)
  },
  getProductTrainingList(id) {
    return axios.get(`/products?brand=${id}&_limit=9999&have-training=true`)
  },
  /**
   * get guest training list
   * @param id
   * @returns {Promise<Array>}
   */
  getGuestTrainingList(id) {
    return axios.get(`/guest/products?have-training=true&brand=${id}&_limit=999`)
  },
  /**
   * Gets information about the guest page's brand
   * @param shortName
   * @returns {Promise<Object>}
   */
  getGuestBrandInfo(shortName) {
    return axios.get(`/guest/brands/${shortName}`)
  },
  /**
   * get AWS info(Used to upload videos or images to AWS servers)
   * @param uploadInfo
   * @returns {Promise<AxiosResponse<any>>}
   */
  getAWSKeyInfo(uploadInfo) {
    return axios.post(`/assets/pre-create`, JSON.stringify(uploadInfo))
  },
  getAWSKeyInfoInAdmin(uploadInfo, clientId) {
    return axios.post(`/assets/pre-create`, JSON.stringify(uploadInfo), {
      headers: { "X-Client-ID": clientId }
    })
  },
  /**
   * send info
   * @param uploadInfo
   * @returns {Promise<AxiosResponse<any>>}
   */
  sendUploadInfo(uploadInfo, clientId) {
    return axios.post(`/assets-v2`, JSON.stringify(uploadInfo), {
      headers: { "X-Client-ID": clientId }
    })
  },
  addBrandClientVideo(brandId, params) {
    return axios.post(`/brands/${brandId}?_skipNulls=true`, params)
  },
  updateBrandTrainingSubscription(brandId, params) {
    return axios.put(`/brands/${brandId}/trainingSubscriptions`, params)
  },
  paymentTrainingSubscription(brandId, params) {
    return axios.post(
      `/brands/${brandId}/paymentForTrainingSubscription/${params.type}?retailer-id=${params.retailerId}`,
      JSON.stringify(params)
    )
  },
  getTrainingRetailer() {
    return axios.get("/training-retailer-statistics")
  },
  getBrandTrainingStatics(brandId) {
    return axios.get(`/brand-training-statistics?brand_id=${brandId}`)
  },
  updateProductTrainingRetailer(productId, params) {
    return axios.put(`/products/${productId}/trainingForRetailers`, params)
  },
  uploadRetailerLocationList(retailerId, file) {
    return axios.post(`/retailers/${retailerId}/locations/upload`, file)
  },
  getRetailerInfo(shortName) {
    return axios.get(`/guest/clients/${shortName}`)
  },
  brandPayment(brandId, param) {
    return axios.post(`/brands/${brandId}/pay/bf-subscription`, JSON.stringify(param))
  }
}
