<template>
  <div class="wrapper">
    <div class="desktop-nav">
      <div class="nav-container">
        <div class="logo">
          <router-link to="/brand/main">
            <img src="~@/assets/img/logo/Logo.svg" alt="" />
          </router-link>
        </div>
        <div class="quick-search">
          <RetailerQuickSearch
            ref="retailerQuickSearch"
            key="1"
            :is-retailer="false"
            :is-brand-expired="isBrandExpired"
          />
        </div>
        <ul class="menu-items">
          <li>
            <router-link :to="'/brand/main'">
              <a-icon class="retailer-icon-style" type="home" />
              {{ $t("Components.Brands.Header.home") }}
            </router-link>
          </li>
          <!--          <li>-->
          <!--            <a href="#">-->
          <!--              <img class="inventory" src="~@/assets/img/retailer-brand-profile/inventory.svg" alt=""/>-->
          <!--              {{$t('Components.Brands.Header.inventory')}}-->
          <!--            </a>-->
          <!--          </li>-->
          <li>
            <router-link :to="'/brand/products'">
              <a-icon class="retailer-icon-style" type="audit" />
              {{ $t("Components.Brands.Header.catalog") }}
            </router-link>
          </li>
          <li>
            <router-link
              :to="
                '/brand/orders?status=pending,confirmed&_limit=50&_index=1&_order-by=PREVIOUS&_sort-by=created_at&tabId=1'
              "
            >
              <a-icon class="retailer-icon-style" type="line-chart" />
              {{ $t("Components.Brands.Header.orders") }}
            </router-link>
          </li>
          <li>
            <router-link :to="'/brand/search?id=0&channel-type='">
              <img
                id="retailerHeaderIcon"
                class="retailer-icon-style retailer-header-style"
                alt=""
              />
              Retailer
            </router-link>
          </li>
          <li>
            <router-link :to="'/brand/inventory'">
              <a-icon class="retailer-icon-style" type="table" />
              {{ $t("Components.Brands.Header.inventory") }}
            </router-link>
          </li>
          <li>
            <router-link :to="'/brand/training?main=true&tab=product'">
              <a-icon class="retailer-icon-style" type="bulb" />
              Training
            </router-link>
          </li>
          <li>
            <router-link :to="'/brand/clean?cpcmain=true'">
              <img id="brandCleanPreCheck" class="retailer-icon-style" />
              {{ $t("Components.Brands.Header.preCheck") }}
            </router-link>
          </li>
          <li>
            <router-link to="/brand/messaging?tab=received">
              <a-badge
                v-if="unReadMessage > 0"
                :count="unReadMessage"
                :overflow-count="99"
                style="font-size:24px;"
              >
                <a-icon type="inbox" />
              </a-badge>
              <a-icon v-else class="retailer-icon-style" type="inbox" />
              <span :style="unReadMessage > 0 ? 'display:block;margin-top:-4px;' : ''">Inbox</span>
            </router-link>
          </li>
          <li class="dropdown" style="width: 54px">
            <a href="#">
              <img
                v-if="brand.logoAsset.url"
                class="dropdown-icon-container"
                :src="brand.logoAsset.url"
              />
              <img
                v-if="!brand.logoAsset.url"
                class="dropdown-icon-container"
                :src="require('@/assets/img/default-logo-image.png')"
              />
            </a>
            <div class="profile-menu">
              <router-link to="/brand/profile">
                <a-icon class="menu-icon-style" type="user" />
                {{ $t("Components.Brands.Header.profile") }}
              </router-link>
              <router-link :to="{ name: 'brandMyAccount' }">
                <a-icon class="menu-icon-style" type="setting" />
                {{ $t("Components.Brands.Header.settings") }}
              </router-link>
              <a @click.prevent="signOut()">
                <img src="~@/assets/img/retailer-brand-profile/signout.svg" alt="" />
                <span style="color:#FF6132">
                  {{ $t("Components.Brands.Header.signOut") }}
                </span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "@/services/firebase-service"
import RetailerQuickSearch from "@/components/brands/retailers/RetailerQuickSearch"

export default {
  components: {
    RetailerQuickSearch
  },
  data() {
    return {
      isShowSearch: true,
      topMenu: false,
      isLegacy: false,
      accountLinks: [
        { name: "Profile", path: "/brand/profile" },
        { name: "Catalog", path: "/brand/products" },
        { name: "Orders", path: "/brand/orders" },
        { name: "Settings", path: "/brand/settings" }
      ],
      showPlan: false,
      retailerAtoZ: false,
      retailerSearch: false,
      memberRemoved: false,
      showSearchDropdown: false
    }
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    },
    unReadMessage() {
      return this.$store.getters["adminMessage/unReadMessageCount"]
    },
    isBrandPremium() {
      return (
        this.brand && this.brand.subscriptions && this.brand.subscriptions[0].type.id == "premium"
      )
    },
    isBrandExpired() {
      if (this.brand && this.brand.subscriptions && this.brand.subscriptions.length !== 0) {
        return (
          (this.brand.subscriptions[0].type.id == "basic" ||
            this.brand.subscriptions[0].type.id == "premium" ||
            this.brand.subscriptions[0].type.id == "subscriber") &&
          this.checkTrialIsEnd()
        )
      } else {
        return false
      }
    },
    isEmailVerified() {
      return this.$store.getters["user/isEmailVerified"]
    },
    isBrandApproved() {
      return this.brand.status && this.brand.status.id === "approved" && this.isEmailVerified
    },
    role() {
      return this.$store.getters["user/role"]
    },
    userEmail() {
      return this.$store.getters["user/email"]
    },
    userId() {
      return this.$store.getters["user/id"]
    },
    isWorkspacePages() {
      return [
        "brandProfile",
        "brandCatalog",
        "brandOrders",
        "brandAnalytics",
        "brandSettings",
        "brandOrder",
        "brandProduct",
        "terms",
        "about",
        "privacyPolicy",
        "purchaseOrderTerms",
        "copyrightDisputePolicy"
      ].includes(this.$route.name)
    },
    monthAgo() {
      const today = new Date()
      const monthAgoDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())

      return monthAgoDate.toISOString().split("T")[0]
    }
  },

  watch: {
    async brand() {
      this.isMemberRemoved()
    }
  },
  mounted() {
    this.$watch(
      "$refs.retailerQuickSearch.showDropdown",
      new_value => (this.showSearchDropdown = new_value)
    )
  },
  async created() {
    if (await this.isLegacyUser()) {
      this.isLegacy = true
    }
    this.isMemberRemoved()
    this.$store.dispatch("adminMessage/getUnReadMessage")
  },
  methods: {
    mySearch() {
      this.$router.push({
        name: "Search"
      })
    },
    async sha256(email) {
      const msgBuffer = new TextEncoder("utf-8").encode(email)
      const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer)
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashHex = hashArray.map(b => ("00" + b.toString(16)).slice(-2)).join("")
      return hashHex
    },
    async isLegacyUser() {
      return (
        await firebase
          .database()
          .ref("legacy/users/" + (await this.sha256(this.userEmail)))
          .once("value", snapshot => {
            return snapshot.val()
          })
      ).toJSON()
    },
    async isMemberRemoved() {
      for (var a in this.brand.members) {
        if (
          this.brand.members[a] &&
          this.brand.members[a].user &&
          this.brand.members[a].user.id &&
          this.brand.members[a].user.id == this.userId
        ) {
          if (
            this.brand.members[a] &&
            this.brand.members[a].membershipStatus &&
            this.brand.members[a].membershipStatus.id &&
            this.brand.members[a].membershipStatus.id == "removed"
          ) {
            this.memberRemoved = true
          }
        }
      }
    },
    signOut() {
      this.$store.dispatch("user/signOut")
      this.$router.push("/login")
    },
    hideTopMenu() {
      this.topMenu = false
    },
    toggle() {
      this.topMenu = !this.topMenu
    },
    hide() {
      this.showPlan = false
    },
    checkTrialIsEnd() {
      let time
      const brandInfo = this.brand
      if (brandInfo && brandInfo.subscriptions && brandInfo.subscriptions.length !== 0) {
        const myDate = new Date(new Date().getTime() + 8 * 60 * 60 * 1000)
        const list = myDate
          .toJSON()
          .split("T")
          .slice(0, 10)
        time = list[0]
        const timeValue = this.getDaysBetween(time, brandInfo.subscriptions[0].endDate)
        return timeValue === 0
      } else {
        return false
      }
    },
    getDaysBetween(date1, date2) {
      const startDate = Date.parse(date1)
      const endDate = Date.parse(date2)
      if (startDate > endDate) {
        return 0
      }
      if (startDate === endDate) {
        return 1
      }
      return Math.floor((endDate - startDate) / (1 * 24 * 60 * 60 * 1000))
    }
  }
}
</script>

<style scoped>
/* Font Configuration */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@font-face {
  font-family: "ProximaNova-Regular";
  src: url(~@/assets/fonts/ProximaNova-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Display-Regular";
  src: url(~@/assets/fonts/SFProDisplay-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Colors */

:root {
  --Gray-2: #fafafa;
  --Gray-3: #f5f5f5;
  --Gray-4: #e8e8e8;
  --Gray-5: #d9d9d9;
  --Gray-7: #8c8c8c;
  --Gray-8: #595959;
  --Gray-10: #000000;
  --Landing_Brand_Orange: #ff6132;
  --Landing_Green: #4a8a5d;
}

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "ProximaNova-Regular";
}

.wrapper {
  width: 100%;
  margin: 0 auto;
}

/* Navigation Style */

.quick-search {
  /*margin-left: 5%;*/
  margin-right: 1%;
}

.column {
  padding: 3px 0px 3px 0px;
}

.desktop-nav {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: "ProximaNova-Regular";
}

.desktop-nav .nav-container {
  position: relative;
  display: -webkit-flex;
  justify-content: space-between;
  max-width: 1197px;
  /*min-width: 954px;*/
  margin: auto;
  /*box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 0;*/
}

.desktop-nav .logo {
  margin-top: 13px;
  margin-bottom: 13px;
  margin-left: 126px;
}

.desktop-nav .menu-items {
  margin-top: 20px;
  margin-bottom: 5px;
  margin-right: 106px;
  display: -webkit-flex;
  list-style: none;
}

.desktop-nav .menu-items li {
  margin: 0 12px;
}

.desktop-nav .menu-items li a {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  text-decoration: none;
  color: var(--Gray-7);
}

.desktop-nav .menu-items li img {
  display: block;
  margin: auto;
}

.inventory {
  padding: 3px 1px;
}

.catalog {
  padding: 1px 3px;
}

.payments {
  padding: 1px 1px;
}

.cpcheck {
  padding-top: 1px;
  padding-bottom: 2px;
}

.desktop-nav .menu-items .dropdown .dropdown-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #8c8c8c;
  width: 36px;
  height: 36px;
  margin-top: 1px;
  margin-bottom: 0px;
}

/* Begin Dropdown */

.desktop-nav .menu-items li .profile-menu {
  display: none;
  position: absolute;
  margin-top: 48px;
  min-width: 99px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  margin-left: -28px;
  z-index: 5;
}

.desktop-nav .menu-items li .profile-menu a {
  display: block;
  padding: 4px;
  padding-left: 36px;
  font-family: "ProximaNova-Regular" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--Gray-8);
}

.desktop-nav .menu-items li .profile-menu a img {
  display: block;
  position: absolute;
  margin-left: -24px;
  margin-top: 2px;
}

.desktop-nav .menu-items li .profile-menu a:hover {
  background-color: var(--Gray-4);
}

.desktop-nav .menu-items .dropdown:hover .profile-menu {
  display: block;
}
.router-link-active {
  color: #4a8a5d !important;
}
.retailer-icon-style {
  display: block;
  font-size: 24px;
}
.retailer-header-style {
  /*margin-bottom: 10px;*/
  width: 24px;
  height: 24px;
}
#brandCleanPreCheck {
  content: url("~@/assets/img/clean-pre-check/clean_nav_gray.svg");
}
.router-link-active > #brandCleanPreCheck {
  content: url("~@/assets/img/clean-pre-check/clean_nav_green.svg");
}
#retailerHeaderIcon {
  content: url("~@/assets/img/R_header_icon.svg");
}
.router-link-active > #retailerHeaderIcon {
  content: url("~@/assets/img/R_header_icon_active.svg");
}
.menu-icon-style {
  display: block;
  position: absolute;
  margin-left: -24px;
  margin-top: 2px;
  font-size: 14px;
}
</style>
<style lang="scss">
.nav-container .menu-items li .ant-badge .ant-badge-multiple-words {
  padding: 0 2px;
}
.nav-container .menu-items li .ant-badge .ant-badge-count {
  background: #ff6132;
  height: 18px;
  min-width: 18px;
  padding: 0 2px;
  line-height: 18px;
}
</style>
