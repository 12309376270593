<template>
  <div class="retailer-products">
    <div class="columns">
      <div class="column is-3 filter-style is-paddingless">
        <div class="retailer-categories">
          <div class="retailer-products-page__header">
            {{ $t("Retailers.RetailerProducts.curations") }}
          </div>
          <div class="retailer-products-page__filter">
            <CollectionsFilter
              :category-filter-items="featuredTag"
              @filterSwitch="fetchProductsOrBrands"
            />
          </div>
        </div>
        <div class="retailer-categories__header">
          {{ $t("Retailers.RetailerProducts.shopByCategory") }}
        </div>
        <div
          v-show="activeCategories.length"
          class="retailer-categories__back-to"
          @click="backToCategories()"
        >
          {{ $t("Retailers.RetailerProducts.backAllBeauty") }}
        </div>
        <CategoriesFilter
          v-show="activeCategories.length === 0"
          :category-filter-items="categoryFilterItems"
          @filterSwitch="fetchProductsOrBrands"
        />
        <SubcategoriesProductsFilter
          v-if="isProductsComponent"
          v-show="activeCategories.length && activeSubcategories.length === 0"
          :subcategories="subcategoryFilterItems"
          :active-categories="activeCategories"
          @filterSwitch="fetchProductsOrBrands"
        />
        <MicroCategoriesProductsFilter
          v-if="isProductsComponent"
          v-show="activeSubcategories.length"
          :micro-categories="microCategoryFilterItems"
          :active-subcategories="activeSubcategories"
          @filterSwitch="fetchProductsOrBrands"
        />
        <div class="retailer-products-page__header">
          {{ $t("Retailers.RetailerProducts.filterBy") }}
        </div>
        <div class="retailer-products-page__filter">
          <ProductsFilter
            :title="$t('Retailers.RetailerProducts.brand')"
            :items="brandFilterItems"
            @filterSwitch="fetchProductsOrBrands"
          />
          <ProductsFilter
            :title="
              isProductsComponent
                ? $t('Retailers.RetailerProducts.productValue')
                : $t('Retailers.RetailerProducts.brandValue')
            "
            :items="brandValues"
            :is-need-sub-header="true"
            @filterSwitch="fetchProductsOrBrands"
            @option="option"
          />
          <ProductsFilter
            :title="$t('Retailers.RetailerProducts.countriesOfOrigin')"
            :items="countryFilterItems"
            @filterSwitch="fetchProductsOrBrands"
          />
          <ProductsFilter
            v-if="isProductsComponent"
            :title="$t('Retailers.RetailerProducts.type')"
            :items="typeFilterItems"
            @filterSwitch="fetchProductsOrBrands"
          />
          <PriceRangeFilter
            v-if="activeCategories.length && canShowRange(products)"
            :products="products"
            :total-min="productsMinPrice"
            :total-max="productsMaxPrice"
            @filterSwitch="filterProductsByPrice"
          />
        </div>
      </div>
      <div class="column directory">
        <div class="columns is-marginless">
          <div class="column">
            <div class="control">
              <div class="select">
                <select
                  class="retailer-products__view-name-select"
                  @change="switchAndRefreshResource"
                >
                  <option
                    v-for="view in views"
                    :key="view.name"
                    :value="view.value"
                    :selected="view.value === isProductsComponent"
                  >
                    {{ view.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="column is-narrow">
            <div class="field is-grouped">
              <div class="control">
                <div class="select">
                  <select v-model="query._limit" @change="changeLimit">
                    <option
                      v-for="limit in visibleItems"
                      :key="limit.value"
                      :selected="limit.value == 12"
                      :value="limit.value"
                    >
                      {{ limit.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-marginless">
          <div class="column">
            <div class="field is-grouped is-grouped-multiline filter-tags-results">
              <div
                v-for="(activeFilterItem, index) in activeFilterItems"
                :key="index"
                class="control"
              >
                <div class="tags has-addons">
                  <a class="tag">{{ activeFilterItem.name }}</a>
                  <a
                    class="tag is-delete"
                    @click="disableFilterItemAndFetchProductsOrBrands(activeFilterItem)"
                  ></a>
                </div>
              </div>
              <template v-for="activeHealthFilterItem in activeBrandValueFilterItems">
                <div v-if="filterOption == 'Any'" :key="activeHealthFilterItem.id" class="control">
                  <div class="tags has-addons">
                    <a class="tag">{{ activeHealthFilterItem.name }}</a>
                    <a
                      class="tag is-delete"
                      @click="disableFilterItemAndFetchProductsOrBrands(activeHealthFilterItem)"
                    ></a>
                  </div>
                </div>
              </template>
              <div v-if="filterOption == 'All'" class="control">
                <div class="tags has-addons">
                  <a
                    v-if="
                      showMore == false &&
                        showLess == false &&
                        activeBrandValueFilterItems.length == 1
                    "
                    class="tag"
                  >
                    {{ this.healthIngredientsSingleFilterName }}
                  </a>
                  <a
                    v-if="
                      showMore == false &&
                        showLess == false &&
                        activeBrandValueFilterItems.length == 1
                    "
                    class="tag is-delete"
                    @click="
                      disableHealthFilterItemAndFetchProductsOrBrands(activeHealthFilterItems)
                    "
                  />
                  <a
                    v-if="
                      showMore == true &&
                        showLess == false &&
                        activeBrandValueFilterItems.length > 1
                    "
                    class="tag"
                  >
                    {{ this.healthIngredientsFilterNameShowLess }}
                  </a>
                  <a v-if="showLess == true && activeBrandValueFilterItems.length > 1" class="tag">
                    {{ this.healthIngredientsFilterNameShowMore }}
                  </a>
                  <a
                    v-if="
                      showMore == true &&
                        showLess == false &&
                        activeBrandValueFilterItems.length > 1
                    "
                    class="tag"
                    @click=";(showLess = true), (showMore = false)"
                  >
                    Show more
                  </a>
                  <a
                    v-if="showLess == true && activeBrandValueFilterItems.length > 1"
                    class="tag"
                    @click=";(showMore = true), (showLess = false)"
                  >
                    Show less
                  </a>
                  <a
                    v-if="
                      (showMore == true || showLess == true) &&
                        activeBrandValueFilterItems.length > 1
                    "
                    class="tag is-delete"
                    @click="
                      disableHealthFilterItemAndFetchProductsOrBrands(activeHealthFilterItems)
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="retailer-products__results">
          <p v-if="isProductsComponent && !isResultsEmpty">
            {{ productSearchTotal }} {{ $t("Retailers.RetailerProducts.results") }}
          </p>
          <p v-if="isProductsComponent && isResultsEmpty">
            {{ $t("Retailers.RetailerProducts.zeroResults") }}
          </p>
        </div>
        <div class="retailer-products__results">
          <p v-if="!isProductsComponent && !isResultsEmpty">
            {{ brandSearchTotal }} {{ $t("Retailers.RetailerProducts.results") }}
          </p>
          <p v-if="!isProductsComponent && isResultsEmpty">
            {{ $t("Retailers.RetailerProducts.zeroResults") }}
          </p>
        </div>
        <div v-show="isResultsEmpty && isProductsComponent" class="retailer-products__not-found">
          <p>{{ $t("Retailers.RetailerProducts.noProductsFoundText") }}</p>
          <p>{{ $t("Retailers.RetailerProducts.changeProductFilterText") }}</p>
        </div>
        <div v-show="isResultsEmpty && !isProductsComponent" class="retailer-products__not-found">
          <p>{{ $t("Retailers.RetailerProducts.noBrandsFoundText") }}</p>
          <p>{{ $t("Retailers.RetailerProducts.changeBrandFilterText") }}</p>
        </div>
        <BrandsItems v-if="!isProductsComponent" :brands="brands" />
        <div v-if="isProductsComponent" class="columns is-multiline">
          <div v-for="product in products" :key="product.id" class="column is-3">
            <div class="retailer-product-wrapper">
              <ProductItem :product="product" />
            </div>
          </div>
        </div>
        <Pagination
          v-show="displayEmptyResult && !isResultsEmpty"
          :page="page"
          :max-page="!isProductsComponent ? brandMaxPage : productMaxPage"
          :is-pagination-loading="isPaginationLoading"
          @resetPagination="changeLimit"
          @nextPage="nextPage"
          @previousPage="previousPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import BrandsItems from "@/components/retailers/brands/BrandsItems.vue"
import ProductItem from "@/components/retailers/products/ProductItem"
import CollectionsFilter from "@/components/filters/CollectionsFilter.vue"
import CategoriesFilter from "@/components/filters/CategoriesFilter.vue"
import ProductsFilter from "@/components/filters/ProductsFilter"
import PriceRangeFilter from "@/components/filters/PriceRangeFilter"
import SubcategoriesProductsFilter from "@/components/filters/SubcategoriesProductsFilter"
import MicroCategoriesProductsFilter from "@/components/filters/MicroCategoriesProductsFilter"
import { mapActions } from "vuex"
import Pagination from "@/components/Pagination"

export default {
  components: {
    ProductItem,
    CollectionsFilter,
    CategoriesFilter,
    ProductsFilter,
    PriceRangeFilter,
    SubcategoriesProductsFilter,
    MicroCategoriesProductsFilter,
    BrandsItems,
    Pagination
  },
  props: {
    navigationalTagId: { type: String, default: "" },
    categoryId: { type: String, default: "" },
    resetFilters: { type: Boolean, default: false }
  },
  data() {
    return {
      page: 1,
      products: [],
      brands: [],
      countryFilterItems: [],
      tagFilterItems: [],
      healthIngredientsTag: [],
      brandValues: [],
      featuredTag: [],
      typeFilterItems: [],
      brandFilterItems: [],
      categoryFilterItems: [],
      subcategoryFilterItems: [],
      microCategoryFilterItems: [],
      views: [
        { name: "View Brands", value: false },
        { name: "View Products", value: true }
      ],
      sorts: [{ name: "Sort By" }],
      visibleItems: [
        { name: "View 12", value: 12 },
        { name: "View 24", value: 24 },
        { name: "View 36", value: 36 }
      ],
      query: { _limit: "12" },
      since: this.$route.query.since,
      isProductsComponent: false,
      displayEmptyResult: false,
      isPaginationLoading: false,
      showMore: false,
      showLess: false,
      healthIngredientsSingleFilterName: "",
      healthIngredientsFilterNameShowLess: "",
      healthIngredientsFilterNameShowMore: "",
      activeHealthFilterItems: [],
      filterOption: ""
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    isResultsEmpty() {
      return (
        (this.isProductsComponent && !this.products.length && this.displayEmptyResult) ||
        (!this.isProductsComponent && !this.brands.length && this.displayEmptyResult)
      )
    },
    navigationalTags() {
      return this.$store.getters["reference/navigationalTags"]
    },
    brandMaxPage() {
      return parseInt(this.$store.getters["brand/brandsSearchPageCount"])
    },
    productMaxPage() {
      return parseInt(this.$store.getters["retailer/productsSearchPageCount"])
    },
    productsMinPrice() {
      return this.$store.getters["retailer/productsSearchMinPrice"]
    },
    productsMaxPrice() {
      return this.$store.getters["retailer/productsSearchMaxPrice"]
    },

    brandSearchTotal() {
      return this.$store.getters["brand/brandsSearchTotalCount"]
    },
    productSearchTotal() {
      return this.$store.getters["retailer/productsSearchTotalCount"]
    },

    activeBrands() {
      return this.brandFilterItems.filter(item => item.checked)
    },
    // activeTags() {
    //   return this.tagFilterItems.filter(item => item.checked)
    // },
    activeHealthIngredientsTags() {
      return this.healthIngredientsTag.filter(item => item.checked)
    },
    activeBrandValues() {
      return this.brandValues.filter(item => item.checked)
    },
    activeFeaturedTags() {
      return this.featuredTag.filter(item => item.checked)
    },
    activeTypes() {
      return this.typeFilterItems.filter(item => item.checked)
    },
    activeCountries() {
      return this.countryFilterItems.filter(item => item.checked)
    },
    activeCategories() {
      return this.categoryFilterItems.filter(item => item.checked)
    },
    activeSubcategories() {
      if (!this.activeCategories.length) {
        return []
      }
      return this.subcategoryFilterItems.filter(item => item.checked)
    },
    activeMicroCategories() {
      if (!this.activeSubcategories.length) {
        return []
      }
      return this.microCategoryFilterItems.filter(item => item.checked)
    },
    activeFilterItems() {
      return [
        ...this.countryFilterItems,
        ...this.featuredTag,
        ...this.typeFilterItems,
        ...this.brandFilterItems,
        ...this.categoryFilterItems,
        ...this.activeSubcategories,
        ...this.activeMicroCategories
      ].filter(item => item.checked)
    },
    activeHealthAndIngredientsFilterItems() {
      return [...this.healthIngredientsTag].filter(item => item.checked)
    },
    activeBrandValueFilterItems() {
      return [...this.brandValues].filter(item => item.checked)
    }
  },
  async beforeRouteEnter(to, from, next) {
    next(async vm => {
      await vm.setReference()
      await vm.fetchFilters()

      vm.handleUserSelection(to, vm)
      vm.fetchProductsOrBrands()
    })
  },
  async beforeRouteUpdate(to, from, next) {
    this.handleUserSelection(to, this)
    await this.fetchProductsOrBrands()

    next()
  },
  async created() {
    if (this.isAdmin) {
      this.query["_jwt-c_id"] = this.$route.params.retailerId
      this.query["_jwt-c_type"] = "retailer"
    }
    if (sessionStorage.getItem("viewItem") == "product") {
      this.isProductsComponent = true
    }
    if (sessionStorage.getItem("viewNumber")) {
      this.query._limit = Number(sessionStorage.getItem("viewNumber"))
    }
  },
  methods: {
    handleUserSelection(to, vm) {
      if (to.params.resetFilters) {
        vm.resetActiveFilters()
      }
      if (to.params && to.params.navigationalTagId == "sheet") {
        vm.isProductsComponent = true
        sessionStorage.setItem("viewItem", "product")
      }
      vm.switchNavigationalTags(to.params.navigationalTagId)
      vm.switchCategories(to.params.categoryId)
      vm.since = to.query.since
    },
    resetActiveFilters() {
      this.activeFilterItems.forEach(item => (item.checked = false))
    },
    resetPagination() {
      delete this.query["_order-by"]
      delete this.query["_from"]
    },
    changeLimit() {
      sessionStorage.setItem("viewNumber", this.query._limit)
      this.page = 1
      this.resetPagination()
      this.fetchProductsOrBrands()
    },
    nextPage() {
      const items = this.isProductsComponent ? this.products : this.brands
      const lastItem = items[items.length - 1]

      this.query["_sort-by"] = "created"
      this.query["_order-by"] = "next"
      this.query["_from"] = lastItem.createdAt + "::" + lastItem.id

      this.page++

      this.fetchProductsOrBrands()
    },
    previousPage() {
      if (this.page === 1) {
        return
      }

      const items = this.isProductsComponent ? this.products : this.brands
      const lastItem = items[0]

      this.query["_sort-by"] = "created"
      this.query["_order-by"] = "previous"
      this.query["_from"] = lastItem.createdAt + "::" + lastItem.id

      this.page--

      this.fetchProductsOrBrands()
    },
    switchAndRefreshResource(event) {
      this.isProductsComponent = event.target.value === "true"
      if (this.isProductsComponent) {
        sessionStorage.setItem("viewItem", "product")
      } else {
        sessionStorage.setItem("viewItem", "brand")
        this.typeFilterItems = this.typeFilterItems.map(tag => {
          tag.checked = false
          return tag
        })
      }
      this.displayEmptyResult = false
      this.page = 1

      this.fetchProductsOrBrands()
    },
    ...mapActions("reference", ["setReference"]),
    navigationalTagNameBySlug(slug) {
      const navigationalTag = this.navigationalTags.find(
        navigationalTag => navigationalTag.id === slug
      )
      return navigationalTag && navigationalTag.name
    },
    disableFilterItemAndFetchProductsOrBrands(item) {
      for (var categoryIndex in this.categoryFilterItems) {
        if (item == this.categoryFilterItems[categoryIndex]) {
          this.subcategoryFilterItems.forEach(subcategory => (subcategory.checked = false))
          this.microCategoryFilterItems.forEach(microCategory => (microCategory.checked = false))
        }
      }
      for (var subCategoryIndex in this.subcategoryFilterItems) {
        if (item == this.subcategoryFilterItems[subCategoryIndex]) {
          this.microCategoryFilterItems.forEach(microCategory => (microCategory.checked = false))
        }
      }
      item.checked = false
      this.fetchProductsOrBrands()
    },
    disableHealthFilterItemAndFetchProductsOrBrands(items) {
      for (var item in items) {
        items[item].checked = false
        this.fetchProductsOrBrands()
      }
    },
    async option(option) {
      this.filterOption = option
    },
    canShowRange(products) {
      if ((products.length === 1 && !this.query.msrp) || !this.isProductsComponent) {
        return false
      } else {
        return true
      }
    },
    filterName(activeFilters) {
      if (activeFilters.length && activeFilters.length != 1) {
        this.healthIngredientsFilterNameShowLess =
          activeFilters[0].name + ", " + "+ " + this.getLength(activeFilters.length) + " more"
        this.healthIngredientsFilterNameShowMore = activeFilters.map(item => item.name).join(", ")
        this.showMore = true
      } else if (activeFilters.length == 1) {
        this.healthIngredientsSingleFilterName = activeFilters[0].name
        this.showMore = false
        this.showLess = false
      }
    },
    getLength(filters) {
      return filters - 1
    },
    setQueryKeyByFilterItems(activeFilterItems, key) {
      if (activeFilterItems.length) {
        this.query[key] = activeFilterItems.map(item => item.id).join(",")
        console.log(this.query)
        if (key == "sell-side-value") {
          this.query[key] = activeFilterItems.map(item => item.name).join(",")
          this.activeHealthFilterItems = activeFilterItems
          this.filterName(activeFilterItems)
          if (this.filterOption == "All") {
            this.query[key] = "all(" + this.query[key] + ")"
          }
          if (this.filterOption == "Any") {
            this.query[key] = "any(" + this.query[key] + ")"
          }
        }
      } else {
        delete this.query[key]
      }
    },
    syncQueryWithFilters() {
      if (this.since) {
        this.query.since = this.since
      } else {
        delete this.query.since
      }

      this.setQueryKeyByFilterItems(this.activeBrandValues, "sell-side-value")
      this.setQueryKeyByFilterItems(this.activeFeaturedTags, "featured")
      this.setQueryKeyByFilterItems(this.activeBrands, "brand")
      if (sessionStorage.getItem("viewItem") == "product") {
        delete this.query.id
        this.setQueryKeyByFilterItems(this.activeBrands, "brand")
      } else {
        delete this.query.brand
        this.setQueryKeyByFilterItems(this.activeBrands, "id")
      }

      const countryParam = this.isProductsComponent ? "country" : "hq-country"
      this.setQueryKeyByFilterItems(this.activeCountries, countryParam)

      this.setQueryKeyByFilterItems(this.activeCategories, "category")
      if (!this.activeCategories.length) {
        delete this.query.msrp
      }

      this.setQueryKeyByFilterItems(this.activeSubcategories, "subcategory")
      this.setQueryKeyByFilterItems(this.activeMicroCategories, "micro-category")
      this.setQueryKeyByFilterItems(this.activeTypes, "type")
    },
    filterProductsByPrice(min, max) {
      if (min && max && this.activeCategories.length) {
        this.query.msrp = `${min},${max}`
      } else {
        delete this.query.msrp
      }

      this.fetchProductsOrBrands()
    },
    switchNavigationalTags(activeNavigationalTagId) {
      this.healthIngredientsTag = this.healthIngredientsTag.map(tag => {
        tag.checked =
          (tag.navigational && tag.id === activeNavigationalTagId) ||
          (!tag.navigational && tag.checked)
        return tag
      })
      this.featuredTag = this.featuredTag.map(tag => {
        tag.checked =
          (tag.navigational && tag.id === activeNavigationalTagId) ||
          (!tag.navigational && tag.checked)
        return tag
      })
      this.typeFilterItems = this.typeFilterItems.map(tag => {
        tag.checked = tag.id === activeNavigationalTagId
        return tag
      })
    },
    switchCategories(activeCategoryId) {
      this.categoryFilterItems.forEach(category => {
        category.checked = category.id === activeCategoryId
      })
    },
    async fetchProductsOrBrands() {
      this.syncQueryWithFilters()
      this.isProductsComponent ? await this.fetchProducts() : await this.fetchBrands()
      this.displayEmptyResult = true
      this.resetPagination()
    },
    async fetchProducts() {
      this.isPaginationLoading = true
      try {
        await this.$store.dispatch("retailer/fetchProducts", {
          params: { ...this.query, "brand-status": "approved" }
        })
        this.products = this.$store.getters["retailer/products"]
      } catch (error) {
        this.products = []
      } finally {
        this.isPaginationLoading = false
      }
    },
    async fetchBrands() {
      this.isPaginationLoading = true
      try {
        await this.$store.dispatch("brand/searchBrands", {
          params: { ...this.query, status: "approved" }
        })
        this.brands = this.$store.getters["brand/brandsSearch"]
      } catch (error) {
        this.brands = []
      } finally {
        this.isPaginationLoading = false
      }
    },
    async fetchFilters() {
      this.countryFilterItems = this.$store.getters["reference/countryFilterItems"]
      this.countryFilterItems.forEach(item => (item.checked = false))
      this.typeFilterItems = this.$store.getters["reference/typeFilterItems"]
      this.typeFilterItems.forEach(item => (item.checked = false))
      this.categoryFilterItems = this.$store.getters["reference/categoryFilterItems"]
      this.categoryFilterItems.forEach(item => (item.checked = false))
      this.subcategoryFilterItems = this.$store.getters["reference/subcategoryFilterItems"]
      this.subcategoryFilterItems.forEach(item => (item.checked = false))
      this.microCategoryFilterItems = this.$store.getters["reference/microCategoryFilterItems"]
      this.microCategoryFilterItems.forEach(item => (item.checked = false))
      this.brandValues = this.$store.getters["reference/brandValue"].map(brand => {
        return { checked: false, ...brand }
      })
      this.setTagFilterItems()
      this.tagFilterItems.forEach(item => (item.checked = false))
      this.splitTags(this.tagFilterItems)
      await this.setBrandFilterItems()
    },
    splitTags(tagFilters) {
      this.healthIngredientsTag = []
      this.featuredTag = []
      for (var tags in tagFilters) {
        if (tagFilters[tags].name === "Cruelty-free") {
          this.healthIngredientsTag.push(tagFilters[tags])
        } else if (tagFilters[tags].name === "Paraben-free") {
          this.healthIngredientsTag.push(tagFilters[tags])
        } else if (tagFilters[tags].name === "Phthalate-free") {
          this.healthIngredientsTag.push(tagFilters[tags])
        } else if (tagFilters[tags].name === "Sulfate-free") {
          this.healthIngredientsTag.push(tagFilters[tags])
        } else if (tagFilters[tags].name === "Vegan") {
          this.healthIngredientsTag.push(tagFilters[tags])
        } else if (tagFilters[tags].name === "Organic") {
          this.healthIngredientsTag.push(tagFilters[tags])
        } else {
          if (tagFilters[tags].navigational) this.featuredTag.push(tagFilters[tags])
        }
      }
    },
    async setBrandFilterItems() {
      try {
        await this.$store.dispatch("brand/fetchAllBrands", {
          params: { fields: "name,id", status: "approved" }
        })
        this.brandFilterItems = this.$store.getters["brand/brands"].map(brand => {
          return { checked: false, ...brand }
        })
      } catch {
        this.brandFilterItems = []
      }
    },
    async backToCategories() {
      this.categoryFilterItems.forEach(category => (category.checked = false))
      this.subcategoryFilterItems.forEach(subcategory => (subcategory.checked = false))
      this.microCategoryFilterItems.forEach(microCategory => (microCategory.checked = false))
      delete this.query.msrp
      try {
        await this.fetchProductsOrBrands()
      } catch (error) {
        console.error(error)
      }
    },

    setTagFilterItems() {
      this.tagFilterItems = this.$store.getters["reference/sellSideTags"].map(tag => {
        return { checked: this.navigationalTagId == tag.id, ...tag }
      })
    }
  }
}
</script>

<style lang="sass">
.retailer-products
  padding: 0 20px 0 30px
.retailer-products__not-found
  font-size: 12px
  font-weight: 600
  padding-top: 15px
  padding-left: 10px
.filter-style
  min-height:90vh

.retailer-categories
  padding-top: 10px
  padding-bottom: 10px

.filter-tags-results
  padding-left: 10px
  padding-top: 5px

.retailer-categories__header
  font-size: 14px
  font-weight: 600
  margin-top: 16px

.retailer-categories__back-to
  cursor: pointer
  font-size: 14px
  font-weight: 300
  padding-top: 10px

.retailer-products
  padding-top: 10px
  padding-bottom: 50px


  &__view-name-select
    margin-left: 11px

  &__view-no-background
    background: none !important

  &__results
    display: flex
    justify-content: flex-end
    font-size: 12px
    margin-top: 8px
    padding: 0px

  .retailer-product-wrapper
    max-width: 250px
    margin: 0 auto

  .retailer-products-page__title
    font-size: 14px
    color: #000
    border-bottom: 1px solid EBEFF2
    padding: 15px

  .retailer-products-page__header
    font-size: 14px
    font-weight: 600
    text-transform: uppercase

  .retailer-products-page__filter
    cursor: pointer
    margin: 0px 48px 0px 0px

  .title__navigationalTag
    margin: -4px 0px 22px 0px
    font-size: 28px
    font-weight: 300
    line-height: 32px

  .filter__control
    margin-top: 30px

  select
    border-radius: 0px !important
    border-color: #e8e8e8 !important
    color: #000
    font-size: 12px
    letter-spacing: 0.17px
    line-height: 20px
    height: 39px
    width: 141px
    padding: 10px 0px 9px 14px
</style>
