<template>
  <div ref="adminLocationUser" class="admin-retailer-location-user__main">
    <a-row>
      <a-col :span="20">
        <search-component
          :search-recent="searchRecent"
          search-hint="Search by First or Last Name"
          @doSearch="onSearch"
          @deleteTag="closeTagItem"
        />
      </a-col>
      <a-col :span="4" style="text-align: right;">
        <a-button class="admin-training-landing-red-border-btn" @click="clickToDownload"
          >Download CSV</a-button
        >
      </a-col>
    </a-row>

    <a-table
      :columns="tableColumn"
      :data-source="tableData"
      size="middle"
      class="admin-training-table"
      :row-class-name="
        (_recode, index) => (index % 2 === 1 ? 'admin-training-table-striped' : null)
      "
      style="margin-top: 40px;"
      :loading="isLoading"
      :pagination="false"
      :get-popup-container="() => $refs.adminLocationUser"
    >
      <span slot="firstTitle">
        First
        <a-icon
          type="caret-up"
          :rotate="sortFirst.toString().length > 0 && sortFirst === 'descend' ? 180 : 0"
          :class="
            sortFirst.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('first')"
        />
      </span>
      <span slot="lastTitle">
        Last
        <a-icon
          type="caret-up"
          :rotate="sortLast.toString().length > 0 && sortLast === 'descend' ? 180 : 0"
          :class="
            sortLast.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('last')"
        />
      </span>
      <span slot="emailTitle">
        Email
        <a-icon
          type="caret-up"
          :rotate="sortEmail.toString().length > 0 && sortEmail === 'descend' ? 180 : 0"
          :class="
            sortEmail.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('email')"
        />
      </span>
      <span slot="stateTitle">
        State
        <a-icon
          type="caret-up"
          :rotate="sortCountry.toString().length > 0 && sortCountry === 'descend' ? 180 : 0"
          :class="
            sortCountry.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('country')"
        />
      </span>
      <span slot="countryTitle">
        Country
        <a-icon
          type="caret-up"
          :rotate="sortState.toString().length > 0 && sortState === 'descend' ? 180 : 0"
          :class="
            sortState.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('state')"
        />
      </span>
      <span slot="storeTitle">
        Store #
        <a-icon
          type="caret-up"
          :rotate="sortStoreNumber.toString().length > 0 && sortStoreNumber === 'descend' ? 180 : 0"
          :class="
            sortStoreNumber.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('storeNumber')"
        />
      </span>
      <span slot="cityTitle">
        City
        <a-icon
          type="caret-up"
          :rotate="sortCity.toString().length > 0 && sortCity === 'descend' ? 180 : 0"
          :class="
            sortCity.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('city')"
        />
      </span>
      <span slot="loginTitle">
        Last Login
        <a-icon
          type="caret-up"
          :rotate="sortLogin.toString().length > 0 && sortLogin === 'descend' ? 180 : 0"
          :class="
            sortLogin.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('lastLoginStr')"
        />
      </span>
      <span slot="sessionTitle">
        Sessions
        <a-icon
          type="caret-up"
          :rotate="sortSession.toString().length > 0 && sortSession === 'descend' ? 180 : 0"
          :class="
            sortSession.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('sessionCount')"
        />
      </span>
      <span slot="customCheckTitle">
        <a-checkbox v-model="isCheckAll" @change="handleSelectAllReport" />
      </span>
      <span slot="checkAction" slot-scope="text, record">
        <a-checkbox :checked="text" @change="checkedValue => chooseData(checkedValue, record)" />
      </span>
      <span slot="createdAt" slot-scope="text">
        {{ formatDate(text) }}
      </span>
      <span slot="storeNumber" slot-scope="text, record">
        {{ record.location.storeNumber ? record.location.storeNumber : "" }}
      </span>
      <span slot="stateData" slot-scope="text, record" style="text-transform:uppercase;">
        {{ record.location.state ? record.location.state.id : "" }}
      </span>
      <span slot="cityData" slot-scope="text, record">
        {{ record.location.city ? record.location.city : "" }}
      </span>
      <span slot="lastLoginData" slot-scope="text">
        {{ text ? text : "" }}
      </span>
      <span slot="retailer">
        {{ retailer.name }}
      </span>
      <span slot="active" slot-scope="text">
        <!-- <a-switch
          :checked="text"
          class="admin-training-switch"
          @change="deactionLocation(record)"
        /> -->
        <div :class="text ? 'admin-training-active-green' : 'admin-training-deactive-red'"></div>
      </span>
      <span
        slot="actionOper"
        slot-scope="text, record"
        class="admin-training-retailer__click-oper"
        @click="clickToEdit(record)"
      >
        Edit
      </span>
    </a-table>
    <admin-training-user-edit-dialog
      ref="editAdminTrainingUserProfile"
      @success="getSearchTrainingPro"
    />
  </div>
</template>
<script>
import SearchComponent from "@/components/training/SearchComponent"
import AdminTrainingUserEditDialog from "@/components/admin/AdminTrainingUserEditDialog"
import { downloadCsvData, getNowDateStr, replaceCommaForCsvStr } from "@/utils/validate"
import { adminRetailerUsersTag } from "@/utils/const"
export default {
  components: {
    "search-component": SearchComponent,
    AdminTrainingUserEditDialog
  },
  props: {
    retailer: { type: Object, default: () => undefined }
  },
  data() {
    return {
      isCheckAll: false,
      selectedData: new Set(),
      searchRecent: [],
      isLoading: false,
      searchValue: "",
      tableColumn: [
        {
          key: "isChecked",
          dataIndex: "isChecked",
          slots: { title: "customCheckTitle" },
          scopedSlots: { customRender: "checkAction" },
          width: "30px"
        },
        {
          //title: "First",
          dataIndex: "first",
          key: "first",
          // sorter: true,
          slots: { title: "firstTitle" },
          width: "11%"
        },
        {
          //title: "Last",
          dataIndex: "last",
          key: "last",
          slots: { title: "lastTitle" },
          //sorter: true,
          width: "11%"
        },
        {
          // title: "Email",
          dataIndex: "email",
          key: "email",
          slots: { title: "emailTitle" },
          //sorter: true,
          width: "13%"
        },
        {
          //title: "Store #",
          dataIndex: "storeNumber",
          key: "storeNumber",
          // sorter: true,
          slots: { title: "storeTitle" },
          scopedSlots: { customRender: "storeNumber" },
          width: "8%"
        },
        {
          //title: "State",
          dataIndex: "state",
          key: "state",
          slots: { title: "stateTitle" },
          //sorter: true,
          scopedSlots: { customRender: "stateData" },
          width: "8%"
        },
        {
          //title: "Retailer",
          dataIndex: "country",
          key: "country",
          slots: { title: "countryTitle" },
          width: "10%"
          //   sorter: true
        },
        {
          //title: "City",
          dataIndex: "city",
          key: "city",
          slots: { title: "cityTitle" },
          //sorter: true,
          scopedSlots: { customRender: "cityData" },
          width: "8%"
        },
        // {
        //   title: "Created",
        //   dataIndex: "createdAt",
        //   key: "createdAt",
        //   scopedSlots: { customRender: "createdAt" }
        // },
        {
          //title: "Last Login",
          dataIndex: "lastLoginStr",
          key: "lastLoginStr",
          scopedSlots: { customRender: "lastLoginData" },
          slots: { title: "loginTitle" },
          //sorter: true,
          width: "12%"
        },
        {
          //title: "Sessions",
          dataIndex: "sessionCount",
          key: "sessionCount",
          slots: { title: "sessionTitle" },
          //sorter: true,
          width: "8%"
        },
        {
          title: "Active",
          dataIndex: "active",
          key: "active",
          scopedSlots: { customRender: "active" },
          width: "6%"
          //   sorter: true
        },
        {
          title: "",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "actionOper" },
          width: "6%"
        }
      ],
      tableData: [],
      orginalData: [],
      sortFirst: "",
      sortLast: "",
      sortEmail: "",
      sortStoreNumber: "",
      sortState: "",
      sortCountry: "",
      sortCity: "",
      sortLogin: "",
      sortSession: ""
    }
  },
  async created() {
    this.getSearchTrainingPro()
    this.searchRecent = await this.$store.dispatch(
      "training/getSearchBrandTags",
      adminRetailerUsersTag
    )
  },
  methods: {
    //get seller list
    getSearchTrainingPro() {
      let param = this.getBaseParam()
      this.isLoading = true
      this.$store
        .dispatch("seller/getSellerList", param)
        .then(() => {
          this.isLoading = false
          this.tableData = this.$store.getters["seller/sellerList"]
          this.getUsersInfo()
        })
        .catch(err => {
          console.log(err)
          this.isLoading = false
        })
    },
    getUsersInfo() {},
    getBaseParam() {
      let param = { "retailer-id": this.retailer.id, _limit: 9999 }
      if (this.searchValue && this.searchValue.length > 0) {
        param["name"] = this.searchValue
      }
      return param
    },
    sortByField(field) {
      let index = 0
      let order = ""
      if (field === "first") {
        index = 1
        if (this.sortFirst.length === 0) {
          this.sortFirst = "ascend"
        } else if (this.sortFirst === "ascend") {
          this.sortFirst = "descend"
        } else {
          this.sortFirst = "ascend"
        }
        this.sortLast = ""
        this.sortEmail = ""
        this.sortStoreNumber = ""
        this.sortState = ""
        this.sortCountry = ""
        this.sortCity = ""
        this.sortLogin = ""
        this.sortSession = ""
        order = this.sortFirst
      } else if (field === "email") {
        index = 3
        if (this.sortEmail.length === 0) {
          this.sortEmail = "ascend"
        } else if (this.sortEmail === "ascend") {
          this.sortEmail = "descend"
        } else {
          this.sortEmail = "ascend"
        }
        this.sortLast = ""
        this.sortFirst = ""
        this.sortStoreNumber = ""
        this.sortState = ""
        this.sortCountry = ""
        this.sortCity = ""
        this.sortLogin = ""
        this.sortSession = ""
        order = this.sortEmail
      } else if (field === "storeNumber") {
        index = 4
        if (this.sortStoreNumber.length === 0) {
          this.sortStoreNumber = "ascend"
        } else if (this.sortStoreNumber === "ascend") {
          this.sortStoreNumber = "descend"
        } else {
          this.sortStoreNumber = "ascend"
        }
        this.sortLast = ""
        this.sortEmail = ""
        this.sortFirst = ""
        this.sortState = ""
        this.sortCountry = ""
        this.sortCity = ""
        this.sortLogin = ""
        this.sortSession = ""
        order = this.sortStoreNumber
      } else if (field === "last") {
        index = 2
        if (this.sortLast.length === 0) {
          this.sortLast = "ascend"
        } else if (this.sortLast === "ascend") {
          this.sortLast = "descend"
        } else {
          this.sortLast = "ascend"
        }
        this.sortFirst = ""
        this.sortEmail = ""
        this.sortStoreNumber = ""
        this.sortState = ""
        this.sortCountry = ""
        this.sortCity = ""
        this.sortLogin = ""
        this.sortSession = ""
        order = this.sortLast
      } else if (field === "state") {
        index = 5
        if (this.sortState.length === 0) {
          this.sortState = "ascend"
        } else if (this.sortState === "ascend") {
          this.sortState = "descend"
        } else {
          this.sortState = "ascend"
        }
        this.sortLast = ""
        this.sortEmail = ""
        this.sortStoreNumber = ""
        this.sortFirst = ""
        this.sortCountry = ""
        this.sortCity = ""
        this.sortLogin = ""
        this.sortSession = ""
        order = this.sortState
      } else if (field === "country") {
        index = 6
        if (this.sortCountry.length === 0) {
          this.sortCountry = "ascend"
        } else if (this.sortCountry === "ascend") {
          this.sortCountry = "descend"
        } else {
          this.sortCountry = "ascend"
        }
        this.sortLast = ""
        this.sortEmail = ""
        this.sortStoreNumber = ""
        this.sortCity = ""
        this.sortState = ""
        this.sortFirst = ""
        this.sortLogin = ""
        this.sortSession = ""
        order = this.sortCity
      } else if (field === "city") {
        index = 6
        if (this.sortCity.length === 0) {
          this.sortCity = "ascend"
        } else if (this.sortCity === "ascend") {
          this.sortCity = "descend"
        } else {
          this.sortCity = "ascend"
        }
        this.sortLast = ""
        this.sortEmail = ""
        this.sortStoreNumber = ""
        this.sortState = ""
        this.sortCountry = ""
        this.sortFirst = ""
        this.sortLogin = ""
        this.sortSession = ""
        order = this.sortCity
      } else if (field === "lastLoginStr") {
        index = 7
        if (this.sortLogin.length === 0) {
          this.sortLogin = "ascend"
        } else if (this.sortLogin === "ascend") {
          this.sortLogin = "descend"
        } else {
          this.sortLogin = "ascend"
        }
        this.sortLast = ""
        this.sortEmail = ""
        this.sortStoreNumber = ""
        this.sortState = ""
        this.sortCountry = ""
        this.sortCity = ""
        this.sortFirst = ""
        this.sortSession = ""
        order = this.sortLogin
      } else if (field === "sessionCount") {
        index = 8
        if (this.sortSession.length === 0) {
          this.sortSession = "ascend"
        } else if (this.sortSession === "ascend") {
          this.sortSession = "descend"
        } else {
          this.sortSession = "ascend"
        }
        this.sortLast = ""
        this.sortEmail = ""
        this.sortStoreNumber = ""
        this.sortState = ""
        this.sortCountry = ""
        this.sortCity = ""
        this.sortLogin = ""
        this.sortFirst = ""
        order = this.sortSession
      }
      this.sortData(index - 1, order == "ascend")
    },
    handleName(pagination, filters, sorter) {
      console.log(sorter)
      let index = 0
      if (sorter.field === "first") {
        index = 1
      } else if (sorter.field === "email") {
        index = 3
      } else if (sorter.field === "storeNumber") {
        index = 4
      } else if (sorter.field === "last") {
        index = 2
      } else if (sorter.field === "state") {
        index = 5
      } else if (sorter.field === "city") {
        index = 6
      } else if (sorter.field === "lastLoginStr") {
        index = 7
      } else if (sorter.field === "sessionCount") {
        index = 8
      }
      if (!sorter.order) {
        sorter.order = "ascend"
      }
      this.tableColumn[index].sortOrder = sorter.order
      for (let i = 1; i < 9; i++) {
        if (i !== index) {
          this.tableColumn[i].sortOrder = false
        }
      }
      this.sortData(index - 1, sorter.order == "ascend")
    },
    sortData(index, ascend) {
      if (index == 0) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.first.localeCompare(b.first)
          } else {
            return b.first.localeCompare(a.first)
          }
        })
      } else if (index == 1) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.last.localeCompare(b.last)
          } else {
            return b.last.localeCompare(a.last)
          }
        })
      } else if (index == 2) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.email.localeCompare(b.email)
          } else {
            return b.email.localeCompare(a.email)
          }
        })
      } else if (index == 3) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.location.storeNumber && b.location.storeNumber) {
              return a.location.storeNumber - b.location.storeNumber
            } else if (a.location.storeNumber && !b.location.storeNumber) {
              return a.location.storeNumber - 0
            } else if (!a.location.storeNumber && b.location.storeNumber) {
              return 0 - b.location.storeNumber
            }
          } else {
            if (a.location.storeNumber && b.location.storeNumber) {
              return b.location.storeNumber - a.location.storeNumber
            } else if (a.location.storeNumber && !b.location.storeNumber) {
              return b.location.storeNumber - 0
            } else if (!a.location.storeNumber && b.location.storeNumber) {
              return 0 - a.location.storeNumber
            }
          }
        })
      } else if (index == 4) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (
              a.location.state &&
              a.location.state.id &&
              b.location.state &&
              b.location.state.id
            ) {
              return a.location.state.id.localeCompare(b.location.state.id)
            }
            if (
              a.location.state &&
              a.location.state.id &&
              (!b.location.state || !b.location.state.id)
            ) {
              return a.location.state.id.localeCompare("")
            }
            if (
              (!a.location.state || !a.location.state.id) &&
              b.location.state &&
              b.location.state.id
            ) {
              return "".localeCompare(b.location.state.id)
            }
          } else {
            if (
              a.location.state &&
              a.location.state.id &&
              b.location.state &&
              b.location.state.id
            ) {
              return b.location.state.id.localeCompare(a.location.state.id)
            }
            if (
              b.location.state &&
              b.location.state.id &&
              (!a.location.state || !a.location.state.id)
            ) {
              return b.location.state.id.localeCompare("")
            }
            if (
              (!b.location.state || !b.location.state.id) &&
              a.location.state &&
              a.location.state.id
            ) {
              return "".localeCompare(a.location.state.id)
            }
          }
        })
      } else if (index == 5) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.location.city && b.location.city) {
              return a.location.city.localeCompare(b.location.city)
            }
            if (a.location.city && !b.location.city) {
              return a.location.city.localeCompare("")
            }
            if (a.location.city && b.location.city) {
              return "".localeCompare(b.location.city)
            }
          } else {
            if (a.location.city && b.location.city) {
              return b.location.city.localeCompare(a.location.city)
            }
            if (b.location.city && !a.location.city) {
              return b.location.city.localeCompare("")
            }
            if (!b.location.city && a.location.city) {
              return "".localeCompare(a.location.city)
            }
          }
        })
      } else if (index === 6) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.lastLoginStr && b.lastLoginStr) {
              return a.lastLoginStr.localeCompare(b.lastLoginStr)
            } else if (!a.lastLoginStr && b.lastLoginStr) {
              return "".localeCompare(b.lastLoginStr)
            } else if (a.lastLoginStr && !b.lastLoginStr) {
              return a.lastLoginStr.localeCompare("")
            }
          } else {
            if (a.lastLoginStr && b.lastLoginStr) {
              return b.lastLoginStr.localeCompare(a.lastLoginStr)
            } else if (!b.lastLoginStr && a.lastLoginStr) {
              return "".localeCompare(a.lastLoginStr)
            } else if (b.lastLoginStr && !a.lastLoginStr) {
              return b.lastLoginStr.localeCompare("")
            }
          }
        })
      } else {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.sessionCount - b.sessionCount
          } else {
            return b.sessionCount - a.sessionCount
          }
        })
      }
    },
    async onSearch(value) {
      this.searchValue = value
      this.searchRecent = await this.$store.dispatch("training/updateBrandTags", {
        tagName: adminRetailerUsersTag,
        tagItem: this.searchValue
      })
      this.getSearchTrainingPro()
    },
    handleSelectAllReport() {
      this.selectedData.clear()
      this.tableData.forEach(item => {
        item.isChecked = this.isCheckAll
        if (this.isCheckAll) {
          this.selectedData.add(item)
        }
      })
    },
    async closeTagItem(item) {
      await this.$store.dispatch("training/updateBrandTags", {
        tagName: adminRetailerUsersTag,
        tagItem: item.name,
        type: "delete"
      })
    },
    chooseData(checkedValue, record) {
      record.isChecked = checkedValue.target.checked
      if (checkedValue.target.checked) {
        // add record into the list
        this.selectedData.add(record)
        if (this.selectedData.size === this.tableData.length) {
          this.isCheckAll = true
        }
      } else {
        // remove it form list
        this.selectedData.delete(record)
        if (this.isCheckAll) {
          this.isCheckAll = false
        }
      }
      this.$forceUpdate()
    },
    async clickToDownload() {
      console.log("....download data.....")
      if (this.selectedData && this.selectedData.size > 0) {
        const brandName = this.retailer ? this.retailer.name : "retailer"
        await downloadCsvData(
          this.generateDownloadStr(),
          `${brandName}_user_${getNowDateStr()}.csv`
        )
      } else {
        this.$message.warning(this.$t("Admins.AdminInventory.downloadTooltip"), 5)
      }
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value)
        return new Intl.DateTimeFormat("en-US").format(date)
      }
      return " "
    },
    generateDownloadStr() {
      if (this.selectedData && this.selectedData.size > 0) {
        let csvData = ["First, Last, Email, Store #, State, City, Last Login, Sessions, Active"]
        for (let item of this.selectedData) {
          let str = `${replaceCommaForCsvStr(item.user.givenName)}, ${replaceCommaForCsvStr(
            item.user.familyName
          )}, ${replaceCommaForCsvStr(item.user.email)}, ${item.location.storeNumber}, ${
            item.location.state && item.location.state.id ? item.location.state.id : ""
          }, ${item.location.city ? replaceCommaForCsvStr(item.location.city) : ""}, ${
            item.lastLoginStr ? item.lastLoginStr : ""
          }, ${item.sessionCount}, ${item.active}`
          csvData.push(str)
        }
        return csvData
      }
      return []
    },
    clickToEdit(data) {
      this.$refs["editAdminTrainingUserProfile"].openDialog(data)
    },
    deactionLocation(data) {
      let param = { id: data.id, active: !data.active }
      this.$store
        .dispatch("seller/updateASeller", { sellerId: data.id, params: param })
        .then(() => {
          this.$message.success(data.active ? "Deactive user success" : "Active user success")
          data.active = !data.active
        })
        .catch(err => {
          console.log(err)
          this.$message.error(err.message)
        })
    }
  }
}
</script>

<style lang="scss">
.admin-retailer-location-user__main {
  .ant-checkbox-wrapper::after {
    contain: none;
    display: none;
  }
}
</style>
