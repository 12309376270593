<template>
  <a-modal v-model="isShow" width="403px" dialog-class="adminProductTrainingDialog" :footer="null">
    <a-row class="admin-modal-title"> Edit User Profile </a-row>
    <div class="admin-modal-content">
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item prop="first">
          <a-input v-model="form.first" placeholder="First" />
        </a-form-model-item>
        <a-form-model-item prop="last">
          <a-input v-model="form.last" placeholder="Last" />
        </a-form-model-item>
        <a-form-model-item prop="email">
          <span v-if="isEdit">{{ form.email }}</span>
          <a-input v-else v-model="form.email" placeholder="Email" />
        </a-form-model-item>
        <a-form-model-item prop="retailer">
          <a-select
            v-model="form.retailer"
            placeholder="Retailer"
            style="width: 100%;"
            @change="handleRetailerChange"
          >
            <a-select-option v-for="item in retailers" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="storeNumber">
          <a-select
            v-model="form.storeNumber"
            placeholder="Store #"
            show-search
            style="width: 100%;"
            :filter-option="filterOption"
            @change="handleChange"
          >
            <a-select-option v-for="item in locations" :key="item.locationId">
              {{ `#${item.storeNumber} ${item.storeName ? item.storeName : item.city}` }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <a-row style="margin-bottom: 20px;">
        <a-switch
          :checked="active"
          :loading="isDoActiveAction"
          class="admin-training-switch"
          style="float: right;"
          @change="deactionLocation"
        />
        <span style="margin-right: 8px; float: right;">
          {{ active ? "Deactivate User" : "Active User" }}
        </span>
      </a-row>
      <div style="text-align: center;" class="admin-training-retailer-dialog__row-margin">
        <!-- <a-button style="margin-right: 10px;" @click="deactionLocation">Deactive User</a-button> -->
        <a-button
          :class="
            isSentReset
              ? 'admin-training-landing-light-green-btn'
              : 'admin-training-landing-red-border-btn'
          "
          style="width: 100%;"
          @click="resetUserPwd"
        >
          {{ isSentReset ? "Reset Password Link Sent" : "Send Reset Password Link" }}
        </a-button>
      </div>
      <a-row>
        <a-button class="admin-training-landing-deep-red-btn" @click="handleCancel"
          >Cancel</a-button
        >
        <a-button
          class="admin-training-landing-light-green-btn"
          :loading="isDoSaveAction"
          @click="handleSave"
          >Save</a-button
        >
      </a-row>
    </div>
  </a-modal>
</template>
<script>
export default {
  data() {
    return {
      isShow: false,
      locationData: undefined,
      selectedRetailer: null,
      selectedLocation: null,
      locations: [],
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      rules: {
        first: [
          { required: true, message: "Please input given name", trigger: "blur", whitespace: true }
        ],
        last: [
          { required: true, message: "Please input family name", trigger: "blur", whitespace: true }
        ],
        email: [
          { required: true, message: "Please input email", trigger: "blur", whitespace: true }
        ],
        retailer: [
          { required: true, message: "Please choose retailer", trigger: "change", whitespace: true }
        ],
        locationId: [
          { required: true, message: "Please choose location", trigger: "change", whitespace: true }
        ]
      },
      form: {
        first: "",
        last: "",
        email: "",
        retailer: undefined,
        storeNumber: undefined
      },
      isEdit: false,
      isSentReset: false,
      originLocation: [],
      isDoActiveAction: false,
      isDoSaveAction: false,
      active: false
    }
  },
  computed: {
    retailers() {
      let data = this.$store.getters["retailer/retailers"]
      return data
    }
  },
  methods: {
    openDialog(data) {
      this.isShow = true
      this.locationData = data
      this.locationData.isDeactive = !this.locationData.active
      if (this.locationData) {
        this.isEdit = true
        this.form.first = this.locationData.user.givenName
        this.form.last = this.locationData.user.familyName
        this.form.email = this.locationData.user.email
        this.form.retailer = this.locationData.retailer.id
        this.form.storeNumber = this.locationData.location.locationId
        this.active = this.locationData.active
        let rData = this.$store.getters["retailer/retailerById"](this.locationData.retailer.id)
        console.log(rData)
        if (rData) {
          this.locations = rData.locations.slice(0)
          this.originLocation = rData.locations
          console.log(this.locations)
        }
      } else {
        this.form.first = ""
        this.form.last = ""
        this.form.email = ""
        this.form.retailer = undefined
        this.form.storeNumber = undefined
        this.isEdit = false
        this.active = false
      }
    },
    handleRetailerChange(value) {
      console.log(value)
      let rData = this.$store.getters["retailer/retailerById"](value)
      if (rData) {
        this.locations = rData.locations.slice(0)
        this.originLocation = rData.locations
      }
      console.log(this.locations)
      //this.$forceUpdate()
      this.form.storeNumber = undefined
    },
    handleChange(value) {
      console.log(value)
    },
    filterOption(input) {
      let result = this.originLocation.filter(item => {
        if (
          (item.storeName != null &&
            item.storeName.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
          (item.city != null && item.city.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
          (item.postalCode != null &&
            item.postalCode.toLowerCase().indexOf(input.toLowerCase()) >= 0)
        ) {
          return true
        }
        return false
      })
      this.locations = result
    },
    handleCancel() {
      this.isShow = false
    },
    handleSave() {
      console.log("....handle save....")
      this.$refs.ruleForm.validate(valid => {
        console.log("....validate.....")
        console.log(valid)
        if (valid) {
          this.isDoSaveAction = true
          this.doSaveAction()
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },
    doSaveAction() {
      if (
        this.locationData.user.givenName !== this.form.first ||
        this.locationData.user.familyName !== this.form.last ||
        this.locationData.user.email !== this.form.email
      ) {
        // update user info
        let param = {
          id: this.locationData.user.id,
          givenName: this.form.first,
          familyName: this.form.last,
          email: this.form.email
        }
        this.$store
          .dispatch("clientUser/updateUser", param)
          .then(() => {
            if (
              this.locationData.retailer.id !== this.form.retailer ||
              this.locationData.location.locationId !== this.form.storeNumber
            ) {
              // update seller
              this.updateSeller()
            } else {
              this.doSuccess()
            }
          })
          .catch(err => {
            this.isDoSaveAction = false
            this.$message.error(err.message)
          })
      } else {
        this.updateSeller()
      }
    },
    updateSeller() {
      let param = {
        id: this.locationData.id,
        user: {
          familyName: this.form.last,
          givenName: this.form.first,
          email: this.form.email,
          id: this.locationData.user.id
        },
        retailer: {
          id: this.form.retailer
        },
        location: {
          locationId: this.form.storeNumber
        },
        sellerFavoriteProducts: this.locationData.sellerFavoriteProducts,
        active: this.locationData.active
      }
      this.$store
        .dispatch("seller/updateASeller", { sellerId: this.locationData.id, params: param })
        .then(() => {
          console.log("update user success")
          this.doSuccess()
        })
        .catch(err => {
          console.log(err)
          this.isDoSaveAction = false
          this.$message.error(err.message)
        })
    },
    doSuccess() {
      this.isDoSaveAction = false
      this.$message.success("update user success")
      this.$emit("success")
      this.isShow = false
    },
    deactionLocation() {
      this.isDoActiveAction = true
      let param = { id: this.locationData.id, active: !this.locationData.active }
      this.$store
        .dispatch("seller/updateASeller", { sellerId: this.locationData.id, params: param })
        .then(() => {
          this.$message.success(
            this.locationData.active ? "Deactive user success" : "Active user success"
          )
          this.isDoActiveAction = false
          this.isShow = false
          this.$emit("success")
        })
        .catch(err => {
          console.log(err)
          this.isDoActiveAction = false
          this.$message.error(err.message)
        })
    },
    resetUserPwd() {
      console.log(" send reset password link .....")
      if (this.locationData && this.locationData.user && this.locationData.user.email) {
        this.$store
          .dispatch("user/sendPasswordRecoveryLink", this.locationData.user.email)
          .then(() => {
            this.$message.success("Send reset password link success.")
            this.isSentReset = true
          })
          .catch(() => {
            this.$message.error("Send reset password link failed.")
            this.isSentReset = false
          })
      } else {
        this.$message.info("Not get user email data.")
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-trainig-user-edit {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  &__left-label {
    color: #262626;
    text-align: right;
    padding-right: 10px;
  }
  &__row-margin {
    margin-bottom: 14px;
  }
}
</style>
