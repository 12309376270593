<template>
  <div class="beauty-fluent-step-two-com__main">
    <div class="three-select-billing">
      <div v-for="item in billingList" :key="item.id" class="beauty-fluent-select-main">
        <div
          class="beauty-fluent-select"
          :class="item.isClick ? 'click-beauty-fluent-select' : ''"
          @click="chooseBox(item)"
        >
          <div class="saving-style" :style="`background: ${item.bgColor}`">
            <span>{{ item.savings }} SAVINGS</span>
          </div>
          <div class="title-plan" :class="item.isClick ? 'title-plan-click' : ''">
            {{ item.titleName }}
          </div>
          <div class="price-content">
            <p style="font-family: 'Proxima Nova', 'Roboto', sans-serif">
              Price:
              <span :style="`text-decoration: ${item.hasSav ? 'line-through' : 'none'}`">{{
                priceFormat(item.price.toFixed(2))
              }}</span>
            </p>
            <p>
              <span style="font-size: 40px">{{ priceFormat(item.sumTotal.toFixed(2)) }}</span>
              <span style="font-weight: bold; font-family: 'Proxima Nova', 'Roboto', sans-serif"
                >/ {{ item.unitText }}</span
              >
            </p>
            <p style="font-family: 'Proxima Nova', 'Roboto', sans-serif">{{ item.number }}</p>
            <p v-if="item.hasSave" class="save-up-style">
              SAVE UP TO {{ priceFormat(item.saveNumber.toFixed(2)) }}
            </p>
          </div>
          <div class="choose-plan-button">
            <p style="font-family: 'Niveau Grotesk'" @click="choosePlan(item)">CHOOSE THIS PLAN</p>
          </div>
        </div>
        <div v-if="item.hasPopular" class="top-line-style">MOST POPULAR</div>
      </div>
    </div>
  </div>
</template>

<script>
import { priceFormat } from "@/utils/validate"

export default {
  name: "BFStep1",
  props: {
    retailerInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      priceFormat,
      billingList: [
        {
          id: 3,
          titleName: "MONTHLY PLAN",
          savings: "NO",
          hasSav: false,
          price: this.retailerInfo.monthlyPrice,
          actualPrice: this.retailerInfo.monthlyPrice,
          saveNumber: 0,
          unitText: "month",
          bgColor: "#FAC92C",
          number: "12 payments",
          hasSaveUp: false,
          isClick: false,
          hasSave: false,
          hasPopular: false,
          type:
            (this.referenceInfo && this.referenceInfo.filters(item => item.id === 3)[0].name) ||
            "monthly",
          name:
            (this.referenceInfo &&
              this.referenceInfo.filters(item => item.id === 3)[0].description) ||
            "Monthly",
          subscriptionTotalText: "Payment Due Today, \nand every month:",
          savingTxt: "",
          savingNum: 1,
          sumTotal: this.retailerInfo.monthlyPrice,
          subTotalAmount: this.retailerInfo.annuallyPrice,
          savingNumber: 0
        },
        {
          id: 1,
          titleName: "YEARLY PLAN",
          savings: "5%",
          hasSav: true,
          price: this.retailerInfo.annuallyPrice,
          actualPrice: this.retailerInfo.annuallyPrice - this.retailerInfo.annuallyPrice * 0.05,
          number: "One-time payment",
          unitText: "year",
          hasSaveUp: false,
          isClick: true,
          hasSave: true,
          hasPopular: true,
          bgColor: "#F15A42",
          saveNumber: this.retailerInfo.annuallyPrice * 0.05,
          type:
            (this.referenceInfo && this.referenceInfo.filters(item => item.id === 1)[0].name) ||
            "annual",
          name:
            (this.referenceInfo &&
              this.referenceInfo.filters(item => item.id === 3)[0].description) ||
            "Annual",
          subscriptionTotalText: "Payment Due Today:",
          savingTxt: "Annual Plan Savings - 5%",
          savingNum: 0.05,
          sumTotal: this.retailerInfo.annuallyPrice - this.retailerInfo.annuallyPrice * 0.05,
          subTotalAmount: this.retailerInfo.annuallyPrice,
          savingNumber: this.retailerInfo.annuallyPrice * 0.05
        },
        {
          id: 2,
          titleName: "QUARTERLY PLAN",
          savings: "3%",
          hasSav: true,
          price: this.retailerInfo.quarterlyPrice,
          actualPrice: this.retailerInfo.quarterlyPrice - this.retailerInfo.quarterlyPrice * 0.03,
          unitText: "quarter",
          number: "4 payments",
          hasSaveUp: false,
          isClick: false,
          hasSave: true,
          hasPopular: false,
          bgColor: "#1B4793",
          saveNumber: this.retailerInfo.quarterlyPrice * 0.03,
          type:
            (this.referenceInfo && this.referenceInfo.filters(item => item.id === 2)[0].name) ||
            "quarterly",
          name:
            (this.referenceInfo &&
              this.referenceInfo.filters(item => item.id === 3)[0].description) ||
            "Quarter",
          subscriptionTotalText: "Payment Due Today, \nand every quarter:",
          savingTxt: "Quarterly Plan Savings - 3%",
          savingNum: 0.03,
          sumTotal:
            Math.round(
              ((this.retailerInfo.annuallyPrice - this.retailerInfo.annuallyPrice * 0.03) / 4) * 100
            ) / 100,
          subTotalAmount: this.retailerInfo.annuallyPrice,
          savingNumber: this.retailerInfo.annuallyPrice * 0.03
        }
      ]
    }
  },
  computed: {
    referenceInfo() {
      return this.$store.getters["reference/subscriptionTerms "]
    }
  },
  methods: {
    choosePlan(billingInfo) {
      this.chooseBox(billingInfo)
      let list = this.billingList.filter(item => item.id !== billingInfo.id)
      list = list.concat(billingInfo)
      this.$emit("selectPlan", list)
    },
    chooseBox(boxitem) {
      this.billingList = this.billingList.map(item => {
        if (item.id === boxitem.id) {
          item.isClick = true
        } else {
          item.isClick = false
        }
        return item
      })
    }
  }
}
</script>

<style scoped lang="scss">
.beauty-fluent-step-two-com__main {
  p {
    margin: 0;
  }
  .three-select-billing {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .beauty-fluent-select {
    width: 307px;
    height: 430px;
    border: 1px solid #f15a42;
    color: #1a1818;
    overflow: hidden;
    position: relative;
    .saving-style {
      width: 192px;
      height: 192px;
      border-radius: 50%;
      background: #fac92c;
      text-align: left;
      color: #ffffff;
      position: absolute;
      top: -76px;
      right: -61px;
      z-index: 5;
      span {
        display: inline-block;
        margin-left: 30px;
        margin-top: 120px;
        font-size: 14px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
      }
    }
    .title-plan {
      width: 99px;
      margin: 32px;
      text-align: left;
      font-family: "Niveau Grotesk";
      font-size: 18px;
      font-weight: bold;
    }
    .title-plan-click {
      margin: 32px 32px 56px 32px;
    }
  }
  .click-beauty-fluent-select {
    height: 477px;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    background: rgba(237, 142, 131, 0.2);
  }
  .beauty-fluent-select-main:nth-child(2) {
    margin: 0 32px;
  }
  .beauty-fluent-select-main {
    position: relative;
  }
  .price-content {
    height: 180px;
    margin-left: 32px;
    margin-bottom: 50px;
    padding-top: 34px;
    text-align: left;
    font-family: DMSans;
  }
  .save-up-style {
    display: inline-block;
    //width: 142px;
    //height: 24px;
    text-align: center;
    padding: 3px 10px;
    margin-top: 20px;
    border: 1px solid #f15a42;
    border-radius: 30px;
    color: #f15a42;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 700;
  }
  .choose-plan-button {
    //margin: 50px 0 0 36px;
    //margin-top: 55px;
    margin-left: 36px;
    p {
      width: 227px;
      height: 55px;
      line-height: 55px;
      font-size: 18px;
      background: #f15a42;
      border-radius: 50px;
      color: #ffffff;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .top-line-style {
    width: 307px;
    height: 30px;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    font-weight: bold;
    background: #1b4793;
    position: absolute;
    top: -30px;
    left: 0;
  }
}
</style>
