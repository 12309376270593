<template>
  <div class="beauty-fluent-step-three__main">
    <div class="step-title">
      <a-avatar
        style="color: #ffffff; background-color: #F15A42;z-index: 4;cursor: pointer"
        @click="clickToStepOne"
        >1
      </a-avatar>
      <a-avatar
        style="color: #ffffff; background-color: #F15A42;z-index: 4;cursor: pointer"
        @click="clickToStepTwo"
        >2
      </a-avatar>
      <a-avatar style="color: #ffffff; background-color: #F15A42;z-index: 4;">3</a-avatar>
      <div class="step-line"></div>
      <span class="step-icon-style">PLANS</span>
      <span class="step-icon-style billing-style">BILLING</span>
      <span class="step-icon-style payment-style">PAYMENT</span>
    </div>
    <!--    <div style="display: flex;justify-content: center">-->
    <a-row
      :gutter="20"
      style="display: flex;justify-content: center;flex-wrap: wrap;max-width: 1440px"
    >
      <!--      <div class="step-three-com-style">-->
      <!--      <a-col :span="12" :xs="20" style="max-width: 648px;min-width: 450px">-->
      <a-col :xs="18" :sm="18" :md="14" :lg="14">
        <div style="max-width: 648px;margin-right: 70px">
          <div v-if="!isBrandAccount">
            <BFStep3Account
              v-if="this.$route.name !== 'BrandBeautyFluentPayment' || !isBrand"
              ref="bfStep3AccountComp"
              @accountUpdate="accountUpdate"
            ></BFStep3Account>
          </div>
          <BFStep3BillingCard
            :is-brand="isBrand"
            :billing-info="billingInfo"
            @sendBilling="sendBilling"
          ></BFStep3BillingCard>
          <BFStep3Payment
            ref="bfStep3PaymentComp"
            :is-brand="isBrand"
            :card-lists="cardLists"
            :create-email="createEmail"
            :default-card="defaultCard"
            @paymentSend="paymentSend"
            @chooseCreditCard="chooseCreditCard"
            @getPaymentType="getPaymentType"
            @radioChange="radioChange"
          ></BFStep3Payment>
        </div>
      </a-col>
      <!--      <a-col :span="12" :xs="20" style="max-width: 479px;">-->
      <a-col :xs="18" :sm="18" :md="14" :lg="14" style="max-width: 479px;">
        <div style="max-width: 479px;">
          <BFStep3Subscription
            :retailer-info="retailerInfo"
            :billing-info="billingInfo"
            :sub-total-amount="subTotalAmount"
            :saving-number="savingNumber"
            :sum-total="sumTotal"
            :existed-retailer="existedRetailer"
            @promoCodeSend="promoCodeSend"
            @checkRadio="checkRadio"
            @updateRetailerInfo="updateRetailerInfo"
          ></BFStep3Subscription>
          <a-button class="submit-style" :loading="isLoading" @click="submit"
            >SUBMIT&nbsp;YOUR&nbsp;SUBSCRIPTION
          </a-button>
          <p class="cancel-style" @click="clickToStepOne">CANCEL</p>
        </div>
      </a-col>
      <!--      </div>-->
    </a-row>
    <!--    </div>-->
  </div>
</template>

<script>
import BFStep3Account from "@/components/training/__components/BFStep3Account"
import BFStep3BillingCard from "@/components/training/__components/BFStep3BillingCard"
import BFStep3Payment from "@/components/training/__components/BFStep3Payment"
import BFStep3Subscription from "@/components/training/__components/BFStep3Subscription"
import { mapActions } from "vuex"
import AES from "@/common/AES"
import constants from "@/config"

const radioInfo = {
  creditCard: 1,
  transfer: 2,
  newCard: 3,
  selectCard: 4
}

export default {
  name: "BFStep3",
  components: {
    BFStep3Account,
    BFStep3Payment,
    BFStep3Subscription,
    BFStep3BillingCard
  },
  props: {
    retailerInfo: {
      type: Object,
      default: () => {}
    },
    billingInfo: {
      type: Array,
      default: () => []
    },
    isBrand: {
      type: Boolean,
      default: false
    },
    existedRetailer: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      accountInfo: undefined,
      accountReady: false,
      sendParams: undefined,
      cvvNumber: undefined,
      cardNumber: undefined,
      accountParams: undefined,
      billingParams: undefined,
      paymentParams: undefined,
      retailerId: this.retailerInfo.retailerId,
      billingObj: undefined,
      couponCode: undefined,
      couponAmount: undefined,
      chooseCard: undefined,
      createEmail: undefined,
      isPaymentFinal: false,
      checkRadioValue: false,
      isLoading: false,
      paymentType: this.isBrand ? radioInfo.selectCard : radioInfo.creditCard
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    isBrandAccount() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    brandInfo() {
      return this.$store.getters["brand/brand"]
    },
    cardLists() {
      return this.$store.getters["brandPayment/cardInfoList"]
    },
    defaultCard() {
      return this.$store.getters["brandPayment/defaultCard"]
    },
    chooseBillingInfo() {
      const chooseBilling = this.billingInfo.filter(item => item.isClick)
      return chooseBilling[0]
    },
    subTotalAmount() {
      const total = this.billingInfo.filter(item => item.name === "Annual")[0].price
      if (this.couponCode) {
        return (total - this.couponAmount).toFixed(2)
      }
      return total.toFixed(2)
    },
    savingNumber() {
      const info = this.billingInfo.filter(item => item.isClick)
      return (this.subTotalAmount * info[0].savingNum).toFixed(2)
    },
    sumTotal() {
      const number = (this.subTotalAmount - this.savingNumber).toFixed(2)
      if (this.chooseBillingInfo.name === "Quarter") {
        const num = Math.round((number / 4) * 100) / 100
        return num.toFixed(2)
      } else if (this.chooseBillingInfo.name === "Monthly") {
        const num = Math.round((this.subTotalAmount / 12) * 100) / 100
        return num.toFixed(2)
      } else {
        return number
      }
    }
  },
  methods: {
    ...mapActions({
      updatePaymentTrainingSub: "training/updatePaymentTrainingSubscription",
      updateBrandPaymentSub: "training/brandPaymentForSub"
    }),
    accountUpdate(accountInfo) {
      this.accountInfo = accountInfo
      this.checkAccount()
      if (this.accountReady) {
        this.createEmail = this.accountInfo.newEmail
        this.accountParams = {
          user: {
            email: this.accountInfo.newEmail,
            password: this.accountInfo.newPassword,
            confirmPassword: this.accountInfo.confirmPassword,
            givenName: this.accountInfo.firstName,
            familyName: this.accountInfo.lastName
          },
          brand: {
            name: this.accountInfo.brandName,
            website: this.accountInfo.website
          }
        }
      }
    },
    sendBilling(billing, list) {
      this.$emit("changeBilling", undefined, list)
      // this.changeBilling(undefined, list)
      this.billingObj = {
        originalPrice: billing.price,
        subscriptionTerms: {
          id: billing.type
        }
      }
    },
    // changeBilling(retailerInfo, list) {
    //   const binningList = list ? list : this.billing
    //   const total = this.billing.filter(item => item.name === "Annual")[0].price
    //   this.billing = binningList.map(item => {
    //     if (item.type === "annual") {
    //       item.price = retailerInfo ? retailerInfo.annuallyPrice : item.price
    //       item.actualPrice = retailerInfo
    //         ? retailerInfo.annuallyPrice - retailerInfo.annuallyPrice * 0.05
    //         : item.actualPrice
    //       item.saveNumber = retailerInfo ? retailerInfo.annuallyPrice * 0.05 : item.saveNumber
    //
    //       if (this.couponCode && this.couponAmount) {
    //         item.subTotalAmount = retailerInfo
    //           ? Number(retailerInfo.annuallyPrice - this.couponAmount).toFixed(2)
    //           : Number(total - this.couponAmount).toFixed(2)
    //         item.savingNumber = (item.subTotalAmount * item.savingNum).toFixed(2)
    //         item.sumTotal = (item.subTotalAmount - item.savingNumber).toFixed(2)
    //       } else {
    //         item.sumTotal = retailerInfo
    //           ? retailerInfo.annuallyPrice - retailerInfo.annuallyPrice * 0.05
    //           : item.sumTotal
    //         item.subTotalAmount = retailerInfo ? retailerInfo.annuallyPrice : item.subTotalAmount
    //         item.savingNumber = retailerInfo ? retailerInfo.annuallyPrice * 0.05 : item.savingNumber
    //       }
    //     }
    //     if (item.type === "quarterly") {
    //       item.price = retailerInfo ? retailerInfo.quarterlyPrice : item.price
    //       item.actualPrice = retailerInfo
    //         ? retailerInfo.quarterlyPrice - retailerInfo.quarterlyPrice * 0.03
    //         : item.actualPrice
    //       item.saveNumber = retailerInfo ? retailerInfo.quarterlyPrice * 0.03 : item.saveNumber
    //
    //       if (this.couponCode && this.couponAmount) {
    //         item.subTotalAmount = retailerInfo
    //           ? Number(retailerInfo.annuallyPrice - this.couponAmount).toFixed(2)
    //           : Number(total - this.couponAmount).toFixed(2)
    //         item.savingNumber = (Number(item.subTotalAmount) * item.savingNum).toFixed(2)
    //         const number = Number(item.subTotalAmount - item.savingNumber).toFixed(2)
    //         item.sumTotal = Math.round((number / 4) * 100) / 100
    //       } else {
    //         item.subTotalAmount = retailerInfo ? retailerInfo.annuallyPrice : item.subTotalAmount
    //         item.savingNumber = retailerInfo
    //           ? retailerInfo.quarterlyPrice * 0.03
    //           : item.savingNumber
    //         const number = retailerInfo
    //           ? retailerInfo.annuallyPrice - retailerInfo.annuallyPrice * 0.03
    //           : item.subTotalAmount - item.savingNumber
    //         item.sumTotal = Math.round((number / 4) * 100) / 100
    //       }
    //     }
    //     if (item.type === "monthly") {
    //       item.price = retailerInfo ? retailerInfo.monthlyPrice : item.price
    //       item.actualPrice = retailerInfo ? retailerInfo.monthlyPrice : item.actualPrice
    //       item.saveNumber = 0
    //
    //       if (this.couponCode && this.couponAmount) {
    //         item.subTotalAmount = retailerInfo
    //           ? Number(retailerInfo.annuallyPrice - this.couponAmount).toFixed(2)
    //           : Number(total - this.couponAmount).toFixed(2)
    //         item.savingNumber = (item.subTotalAmount * item.savingNum).toFixed(2)
    //         item.sumTotal = Math.round((item.subTotalAmount / 12) * 100) / 100
    //       } else {
    //         item.subTotalAmount = retailerInfo ? retailerInfo.annuallyPrice : item.subTotalAmount
    //         item.sumTotal = retailerInfo ? retailerInfo.monthlyPrice : item.sumTotal
    //         item.savingNumber = 0
    //       }
    //     }
    //     return item
    //   })
    // },
    paymentSend(paymemtObj, cvvNumber, cardNumber) {
      this.paymentParams = paymemtObj
      this.cvvNumber = cvvNumber
      this.cardNumber = cardNumber
    },
    radioChange(type) {
      this.paymentType = type
    },
    chooseCreditCard(cardInfo) {
      this.chooseCard = cardInfo
    },
    getPaymentType(type, isFinal) {
      this.paymentType = type
      this.isPaymentFinal = isFinal
    },
    promoCodeSend(code, couponAmount) {
      this.couponCode = code
      this.couponAmount = couponAmount
      this.$emit("changePromocode", this.couponCode, this.couponAmount)
      // this.changeBilling()
      this.$emit("changeBilling")
    },
    checkRadio(isClick) {
      this.checkRadioValue = isClick
    },
    clickToStepOne() {
      this.$emit("clickToStepOne")
    },
    clickToStepTwo() {
      this.$emit("clickToStepTwo")
    },
    submit() {
      this.isLoading = true
      if (!this.checkValue()) {
        this.isLoading = false
        return
      }
      const billing = this.billingInfo.filter(item => item.isClick)[0]
      if (this.isBrand) {
        const type =
          this.billingObj && this.billingObj.subscriptionTerms.id
            ? this.billingObj.subscriptionTerms.id
            : billing.type
        if (this.paymentType === radioInfo.selectCard) {
          this.sendBrandPayment(type, billing)
          return
        }
        if (this.paymentType === radioInfo.newCard) {
          this.sendAddNewCard(type)
          return
        }
        if (this.paymentType === radioInfo.transfer) {
          this.sendTransferPayment(type, billing)
          return
        }
        return
      }
      this.billingParams = {
        brandTrainingSubscriptions: [
          {
            originalPrice:
              this.billingObj && this.billingObj.originalPrice
                ? this.billingObj.originalPrice
                : billing.price,
            subscriptionTerms: {
              id:
                this.billingObj && this.billingObj.subscriptionTerms.id
                  ? this.billingObj.subscriptionTerms.id
                  : billing.type
            },
            // ...this.billingObj,
            couponCode: {
              code: this.couponCode
            },
            retailerId: this.retailerId
          }
        ]
      }
      const obj = Object.assign(
        {},
        // this.accountParams,
        this.paymentParams,
        this.billingParams
      )
      this.sendParams = {
        ...this.accountParams,
        brand: {
          ...this.accountParams.brand,
          ...obj
        }
      }
      const param = this.sendParams
      this.$store
        .dispatch("brand/brandRegister", param)
        .then(response => {
          console.log(response)
          this.isLoading = false
          // this.brandInfo = response.data.brand
          // window.dataLayer = window.dataLayer || []
          // window.dataLayer.push({
          //   event: "registrationComplete",
          //   userId: response.data.user.id,
          //   firstName: response.data.user.givenName,
          //   lastName: response.data.user.familyName,
          //   emailAddress: response.data.user.email,
          //   userType: "Brand"
          // })
          this.$router.push({
            name: "SubscribeSuccess"
          })
        })
        .catch(err => {
          this.isLoading = false
          this.$message.error(err.message)
        })
    },
    checkIsAddAnotherRetailer() {
      if (
        this.brandInfo &&
        this.brandInfo.brandTrainingSubscriptions &&
        this.brandInfo.brandTrainingSubscriptions.length > 0 &&
        this.brandInfo.brandTrainingSubscriptions[0].retailerId !== "*"
      ) {
        return true
      }
      return false
    },
    async sendBrandPayment(type, billing) {
      if (!this.chooseCard) {
        this.chooseCard = this.defaultCard
      }
      if (this.chooseCard.cardNumber) {
        if (this.checkIsAddAnotherRetailer()) {
          this.sendBrandSubmitPayment(type, billing)
        } else {
          const param = {
            retailerId: this.retailerId,
            couponCode: this.couponCode,
            termsId: type,
            cardNumber: AES.encrypt(this.chooseCard.cardNumber),
            isWireTransfer: false
          }
          this.doPayment(param)
        }
      } else {
        this.isLoading = false
        this.$message.error("Please add a credit card!")
      }
    },
    doPayment(param) {
      this.updateBrandPaymentSub({ brandId: this.brandInfo.id, param })
        .then(() => {
          this.submitSuccess()
        })
        .catch(err => {
          this.isLoading = false
          this.$message.error(err.message)
        })
    },
    async sendTransferPayment(type, billing) {
      if (this.checkIsAddAnotherRetailer()) {
        this.sendTransfer(type, billing)
      } else {
        let first = ""
        let last = ""
        let email = ""
        if (
          this.paymentParams &&
          this.paymentParams.brandWireTransferContacts &&
          this.paymentParams.brandWireTransferContacts.length > 0
        ) {
          first = this.paymentParams.brandWireTransferContacts[0].firstName
          last = this.paymentParams.brandWireTransferContacts[0].lastName
          email = this.paymentParams.brandWireTransferContacts[0].email
        }
        const param = {
          retailerId: this.retailerId,
          couponCode: this.couponCode,
          termsId: type,
          isWireTransfer: true,
          wireTransferFirstName: first,
          wireTransferLastName: last,
          wireTransferLastEmail: email
        }
        this.doPayment(param)
      }
    },
    async sendBrandSubmitPayment(type, billing) {
      console.log(type)
      console.log(billing)
      if (!this.chooseCard) {
        this.chooseCard = this.defaultCard
      }
      // const number = this.paymentParams.brandCreditCardInformation && this.paymentParams.brandCreditCardInformation[0].cardNumber
      const payment = {
        // type: type,
        // cardNumber: AES.encrypt(this.chooseCard.cardNumber),
        // couponCode: this.couponCode,
        // retailerId: this.retailerId
        retailerId: this.retailerId,
        type: type,
        chargeCreditCard: {
          type: type,
          cardNumber: AES.encrypt(this.chooseCard.cardNumber),
          couponCode: this.couponCode,
          amount:
            this.billingObj && this.billingObj.originalPrice
              ? this.billingObj.originalPrice
              : billing.price
        },
        brand: {
          brandTrainingSubscriptions: [
            {
              wireTransfer: false
            }
          ]
        }
      }
      this.updatePaymentTrainingSub({ brandId: this.brandInfo.id, payment })
        .then(() => {
          this.submitSuccess()
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    sendAddNewCard(type) {
      const cardInfo = this.paymentParams
      this.$store
        .dispatch("brandPayment/updateCardInfo", { brandId: this.brandInfo.id, cardInfo })
        .then(async response => {
          if (response && response.length !== 0) {
            this.chooseCard = {
              cardNumber: cardInfo.cardNumber
            }
            //await this.sendBrandSubmitPayment(type)
            await this.sendBrandPayment(type)
            this.submitSuccess()
          }
        })
        .catch(err => {
          this.isLoading = false
          if (err.message) {
            this.$message.error(err.message)
          } else {
            this.$message.error(this.$t("Components.Brands.Payment.addCardFailed"))
          }
        })
    },
    async sendTransfer(type, billing) {
      const payment = {
        retailerId: this.retailerId,
        type: type,
        chargeCreditCard: {
          // type: type,
          // cardNumber: "",
          couponCode: this.couponCode,
          amount:
            this.billingObj && this.billingObj.originalPrice
              ? this.billingObj.originalPrice
              : billing.price
        },
        brand: {
          ...this.paymentParams,
          brandTrainingSubscriptions: [
            {
              wireTransfer: true
            }
          ]
        }
      }
      this.updatePaymentTrainingSub({ brandId: this.brandInfo.id, payment })
        .then(() => {
          this.submitSuccess()
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    submitSuccess() {
      this.isLoading = false
      this.$emit("success")
      this.$router.push({
        name: "BrandTraining",
        query: {
          main: true,
          tab: "retailers"
        }
      })
    },
    updateRetailerInfo(retailerInfo) {
      this.retailerId = retailerInfo.retailerId
      // this.$emit("updateRetailer", retailerInfo)
      // this.changeBilling(retailerInfo)
      this.$emit("changeBilling", retailerInfo)
    },
    checkAccount() {
      const nodataList = Object.values(this.accountInfo)
      if (nodataList.filter(item => item && item.length > 0).length !== 0) {
        if (this.accountInfo.newPassword === this.accountInfo.confirmPassword) {
          this.accountReady = true
        } else {
          this.accountReady = false
        }
      }
    },
    checkValue() {
      let isAccountFilled = true
      let isPaymentCardFilled = true
      if (!this.isBrand) {
        this.$refs["bfStep3AccountComp"].checkValue(isValid => {
          isAccountFilled = isValid
        })
        if (this.paymentType === 1) {
          this.$refs["bfStep3PaymentComp"].checkValue(isValid => {
            isPaymentCardFilled = isValid
          })
        }
        if (!this.accountReady) {
          this.$message.error("The two passwords are different. Please check and enter them again")
          return false
        }
      }
      if (this.paymentType === radioInfo.creditCard || this.paymentType === radioInfo.newCard) {
        if (this.cvvNumber && this.cardNumber) {
          if (this.cardNumber.slice(0, 1) === "3" && this.cvvNumber.length !== 4) {
            this.$message.error("CVV Length should be 4")
            isAccountFilled = false
          } else if (this.cvvNumber.length !== 3) {
            this.$message.error("CVV Length should be 3")
            isAccountFilled = false
          }
        }
      }
      if (this.paymentType === 3) {
        this.$refs["bfStep3PaymentComp"].checkValue(isValid => {
          isPaymentCardFilled = isValid
        })
      }
      if (!this.checkRadioValue) {
        this.$message.error("Please accept the Terms & Conditions!")
        return false
      }
      return isPaymentCardFilled && isAccountFilled
    }
  }
}
</script>

<style scoped lang="scss">
.beauty-fluent-step-three__main {
  margin: 0 auto;
  padding-top: 30px;

  .step-three-com-style {
    display: flex;
    justify-content: space-between;
  }

  .step-title {
    width: 316px;
    text-align: center;
    margin: 20px auto 60px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .step-line {
      height: 3px;
      width: 316px;
      background-color: #f15a42;
      position: absolute;
      top: 15px;
      left: 0;
      z-index: 1;
    }
  }

  .submit-style {
    width: 480px;
    line-height: 57px;
    height: 57px;
    text-align: center;
    border-radius: 50px;
    background-color: #f15a42;
    color: #ffffff;
    margin-top: 45px;
    font-size: 18px;
    font-family: "Niveau Grotesk";
    //font-weight: 700;
    cursor: pointer;
    margin-bottom: 29px;
  }

  .cancel-style {
    width: 480px;
    text-align: center;
    font-size: 18px;
    font-family: DMSans;
    cursor: pointer;
  }

  .step-icon-style {
    position: absolute;
    top: -25px;
    left: -7px;
    color: #1a1818;
    font-weight: 500;
    font-family: DMSans;
  }

  .billing-style {
    left: 129px;
  }

  .payment-style {
    left: 260px;
    color: #f27c59;
  }
}
</style>
