<template>
  <div class="cancel-subscription-dialog-new__main">
    <a-modal
      v-model="isCardModalActive"
      :footer="null"
      :destroy-on-close="true"
      :body-style="noPadding"
      width="520px"
    >
      <div class="cancel-subscription-new" style="overflow: hidden; padding: 0;">
        <img
          src="@/assets/webflowAssets/Modal/dots_pattern.svg"
          alt="dots_pattern"
          style="margin-top: -20px; "
        />
        <div style="font-size: 25px;text-align: center; margin-top: -20px;">
          <p>Are You Sure You Want to Cancel?</p>
        </div>
        <div style="text-align: center; padding: 0 16px;">
          <p>
            Your brand will no longer be discoverable by retailers and you will lose access to our
            tools {{ trialTimeOrAnniversary }}
          </p>
        </div>
        <div class="cancel-dialog-btn-style">
          <span class="continue-cancel-style" @click="doCancelSubscription"
            >CONTINUE CANCELING</span
          >
          <span class="close-dialog-style" @click="closeModal">No, go back</span>
        </div>
        <a-row style="margin-right: -24px; margin-bottom: -24px;">
          <a-col :span="24" style="text-align: right;">
            <img src="@/assets/webflowAssets/Modal/ellipse.svg" />
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "CancelSubscriptionDialog",
  data() {
    return {
      isCardModalActive: false,
      isBfCancel: false,
      noPadding: {
        padding: "0px"
      }
    }
  },

  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    },
    trialTimeOrAnniversary() {
      if (
        this.brand.subscriptions &&
        this.brand.subscriptions.length > 0 &&
        this.brand.subscriptions[0].type.name == "Trial"
      ) {
        const day = this.checkTrialDay(this.brand)
        return `in ${day} days`
      } else {
        return `on ${
          this.brand.subscriptions &&
          this.brand.subscriptions.length > 0 &&
          this.brand.subscriptions[0].endDate
            ? this.brand.subscriptions[0].endDate
            : ""
        }`
      }
    }
  },
  methods: {
    openDialog() {
      this.isCardModalActive = true
    },
    openDialogBF() {
      this.openDialog()
      this.isBfCancel = true
    },
    closeModal() {
      if (this.isBfCancel) {
        this.$emit("close")
      }
      this.isCardModalActive = false
      this.isBfCancel = false
    },
    checkTrialDay(brandInfo) {
      let time
      const myDate = new Date(new Date().getTime() + 8 * 60 * 60 * 1000)
      const list = myDate
        .toJSON()
        .split("T")
        .slice(0, 10)
      time = list[0]
      return this.getDaysBetween(time, brandInfo.subscriptions[0].endDate)
    },
    getDaysBetween(date1, date2) {
      const startDate = Date.parse(date1)
      const endDate = Date.parse(date2)
      if (startDate > endDate) {
        return 0
      }
      if (startDate === endDate) {
        return 1
      }
      return Math.floor((endDate - startDate) / (1 * 24 * 60 * 60 * 1000))
    },
    async doCancelSubscription() {
      // if (this.data) {
      // let params = []
      // params.push({
      //   "unsubscribeTime": moment().format('yyyy-MM-DD'),
      //   "isAutoRenewal":false,
      //   "type": this.data.type,
      //   "subscriptionId": this.data.subscriptionId,
      //   "paidAmountUsd": this.data.paidAmountUsd,
      //   "startDate": this.data.startDate,
      //   "endDate": this.data.endDate
      // })
      // await this.$store.dispatch("brand/updateSubscriptionPlan", params)
      if (this.isBfCancel) {
        this.$emit("doCancel")
        this.closeModal()
      } else {
        const param = {
          mode: "cancel"
        }
        await this.$store.dispatch("brand/updateBrandSubscriptionNew", param)
        // await this.$store.dispatch("brand/getBrandSubscriptionHistoryList")
        await this.fetchBrandInfo()
        this.isCardModalActive = false
        // }
      }
    },
    async fetchBrandInfo() {
      const brandId =
        this.$route.name == "adminBrandSettingsSubscription"
          ? this.$route.params.brandId
          : this.$store.getters["user/businessId"]
      await this.$store.dispatch("brand/getBrand", brandId)
    }
  }
}
</script>

<style scoped lang="scss">
.cancel-subscription-dialog-new__main {
  .cancel-subscription-new {
    text-align: center;
    padding: 48px 0;
    position: relative;
    overflow: hidden;
    p {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      font-family: "Mr Eaves Mod OT";
    }
  }
}
.continue-cancel-style {
  color: #000000;
  cursor: pointer;
}
.close-dialog-style {
  padding: 17px 35px;
  color: white;
  background: #f15a42;
  font-size: 14px;
  font-weight: bold;
  border-radius: 100px;
  margin-left: 30px;
  cursor: pointer;
}
.cancel-dialog-btn-style {
  width: 100%;
  margin-top: 50px;
  text-align: center;
}
</style>
