<template lang="pug">
.brand-new-product
  .modal-background
  .modal-card.brand-new-product-modal
    header.modal-card-head.brand-new-product-modal__header
      p.modal-card-title(v-if="!product.id") {{$t('Components.Brands.Products.BrandProductModal.addProduct')}}
      p.modal-card-title(v-else) {{$t('Components.Brands.Products.BrandProductModal.editProduct')}}
      img.brand-new-product-modal__header-close-icon(src="@/assets/img/icon/close-icon.png" @click="cancelChanges")
    section.brand-product-modal-card-body.brand-new-product-modal__body
      .columns.is-marginless.brand-new-product-modal__container(:class="{'brand-new-product-modal__container-images': isMenuItem('images'), 'brand-new-product-modal__container-documents': isMenuItem('documents')}")
        .column.brand-new-product-modal__menu
          ul.brand-new-product-modal__menu--sticky
            li
              a(:class="{ 'is-selected': isMenuItem('info') }" @click.prevent="toggleMenuItem('info')") {{$t('Components.Brands.Products.BrandProductModal.basicInfo')}}
            li
              a(:class="{ 'is-selected': isMenuItem('images') }" @click.prevent="toggleMenuItem('images')") {{$t('Components.Brands.Products.BrandProductModal.imagesAndVideos')}}
            li 
              a(:class="{ 'is-selected': isMenuItem('documents')}" @click.prevent="toggleMenuItem('documents')")  {{$t('Components.Brands.Products.BrandProductModal.safetyDataSheet')}}
        .column.brand-new-product-modal__product-base-form
          ProductBaseForm(
            :initialProduct="product"
            v-show="currentMenuItem === 'info'"
            :duplicatedBarcode="duplicatedBarcode"
            :invalidTagsCombination="invalidTagsCombination"
            @showDuplicateBarcode="showDuplicateBarcode"
            ref="productBaseForm"
          )
          ProductAssetsForm(
            :initialImages="product.images"
            :clientId="product.brand.id"
            v-show="currentMenuItem === 'images'"
            @updateImages="updateImages"
            @productSaving="productSaving"
            ref="productAssetsForm"
          )
          ProductDocumentsForm(
            :initialDocuments="product.documents"
            :clientId="product.brand.id"
            v-show="currentMenuItem === 'documents'"
            @productSaving="productSaving"
            @updateDocuments="updateDocuments"
            ref="productDocumentsForm"
          )
    footer.modal-card-foot.brand-new-product-modal__form-buttons
      .columns
        .column.is-8.brand-new-product-modal__form-messages
          .has-text-left(v-show="isSaving && currentMenuItem === 'images'") {{$t('Components.Brands.Products.BrandProductModal.Validations.uploadingImage')}}
          .has-text-left(v-show="isSavingVideo && currentMenuItem === 'images'") {{$t('Components.Brands.Products.BrandProductModal.Validations.uploadingVideo')}}
          .has-text-left(v-show="isSaving && currentMenuItem === 'documents'") {{$t('Components.Brands.Products.BrandProductModal.Validations.uploadingDocuments')}}

          .has-text-left(v-show="isSaved && currentMenuItem === 'images' && !invalidBaseForm") {{$t('Components.Brands.Products.BrandProductModal.Validations.successfullyUploaded')}}
          .has-text-left(v-show="isSaved && currentMenuItem === 'documents' && !invalidBaseForm") {{$t('Components.Brands.Products.BrandProductModal.Validations.successfullyUploaded')}}

          .has-text-left.brand-new-product-modal__error-message(v-show="savingError && currentMenuItem === 'images' && !invalidBaseForm") {{$t('Components.Brands.Products.BrandProductModal.Validations.uploadFailed')}}
          .has-text-left.brand-new-product-modal__error-message(v-show="savingError && currentMenuItem === 'documents' && !invalidBaseForm") {{$t('Components.Brands.Products.BrandProductModal.Validations.uploadFailed')}}

          .has-text-left.brand-new-product-modal__error-message(v-show="invalidBaseForm && currentMenuItem !== 'info'") {{$t('Components.Brands.Products.BrandProductModal.Validations.basicInfoTabisIncomplete')}}
          .has-text-left.brand-new-product-modal__error-message(v-show="invalidBaseForm && currentMenuItem !== 'images' && currentMenuItem !== 'documents'") {{$t('Components.Brands.Products.BrandProductModal.Validations.pleaseFillMissingFields')}}

        .column
          .has-text-right
            a.button.brand-new-product-modal__cancel-button(@click="cancelChanges") {{$t('Components.Brands.Products.BrandProductModal.cancel')}}
            a.button.is-black.brand-new-product-modal__save-button(@click.prevent.stop="createProduct") {{$t('Components.Brands.Products.BrandProductModal.save')}}
</template>

<script>
import ProductBaseForm from "@/components/brands/products/ProductBaseForm"
import ProductAssetsForm from "@/components/brands/products/ProductAssetsForm"
import ProductDocumentsForm from "@/components/brands/products/ProductDocumentsForm"
import { nullSafeProduct } from "@/mixins/null-safe-product.js"

export default {
  components: {
    ProductBaseForm,
    ProductAssetsForm,
    ProductDocumentsForm
  },
  mixins: [nullSafeProduct],
  props: {
    brandId: { type: String, default: "" },
    initialProduct: {
      type: Object,
      default() {
        return {
          brand: {
            id: this.brandId
          },
          category: { id: null },
          subcategory: { id: null },
          microCategory: { id: null },
          status: { id: null },
          type: { id: null },
          name: null,
          brandSku: null,
          msrpUsd: null,
          size: null,
          sizeUnits: { id: null },
          description: null,
          benefits: null,
          newBenefits: [{ name: null }, { name: null }, { name: null }],
          ingredients: null,
          newIngredients: [],
          countryOfOrigin: {
            id: null
          },
          values: [],
          sample: false,
          published: false,
          barcodes: [{ code: null, active: true }],
          documents: [],
          images: [],
          tags: []
        }
      }
    }
  },
  data() {
    return {
      isSaving: false,
      isSaved: false,
      savingError: false,
      isModalOpen: false,
      currentMenuItem: "info",
      product: {
        ...this.initialProduct,
        type: { ...this.initialProduct.type },
        status: { ...this.initialProduct.status },
        sizeUnits: { ...this.initialProduct.sizeUnits },
        countryOfOrigin: { ...this.initialProduct.countryOfOrigin },
        barcodes: [
          ...this.initialProduct.barcodes.map(item => {
            return {
              code: item.code
            }
          })
        ],
        newBenefits: [
          ...this.initialProduct.newBenefits.map(item => {
            return {
              name: item.name
            }
          })
        ]
      },
      invalidAssetsForm: false,
      invalidBaseForm: false,
      duplicatedBarcode: false,
      invalidTagsCombination: false,
      isSavingVideo: false
    }
  },
  computed: {
    saveAction() {
      return this.product.id ? "product/updateProduct" : "product/createProduct"
    }
  },
  created() {
    this.$store.dispatch("reference/setReference")
    this.$store.dispatch("product/getIngredientsTag", {
      ingredientName: "",
      page: 1
    })
    if (this.product && this.product.documents && this.product.documents.length > 0)
      this.$store.dispatch("asset/getAsset", this.product.documents[0].id)
  },
  methods: {
    showDuplicateBarcode() {
      this.duplicatedBarcode = false
    },
    isMenuItem(item) {
      return this.currentMenuItem === item
    },

    toggleMenuItem(item) {
      this.isModalOpen = true
      this.currentMenuItem = item
    },
    updateImages(images) {
      this.product.images = images
    },
    updateDocuments(documents) {
      this.product.documents = documents
    },
    isValid() {
      this.$refs.productBaseForm.$v.$touch()
      this.$refs.productAssetsForm.$v.$touch()
      let isIngredientError = this.$refs.productBaseForm.checkIngredient()
      if (this.$refs.productBaseForm.$v.product.$anyError || !isIngredientError) {
        this.invalidBaseForm = true
      } else this.invalidBaseForm = false
      if (this.$refs.productAssetsForm.$v.images.$anyError) {
        this.invalidAssetsForm = true
      } else this.invalidAssetsForm = false
    },
    safeProduct(product) {
      if (product.barcodes[0].code) {
        return this.nullSafeProduct(product)
      }
      const nullSafeProduct = this.nullSafeProduct(product)
      const productWithoutBarcodes = Object.assign({}, nullSafeProduct, { barcodes: [] })
      return productWithoutBarcodes
    },
    async createProduct() {
      this.isValid()
      if (this.invalidBaseForm) {
        return
      }
      if (this.product.status && this.product.status.id === "available") {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "publishCatalog",
          userId: this.$store.getters["user/id"],
          firstName: this.$store.getters["user/givenName"],
          lastName: this.$store.getters["user/familyName"],
          emailAddress: this.$store.getters["user/email"],
          catalogName: this.product.name,
          userType: "Brand"
        })
      } else {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "productUpload",
          userId: this.$store.getters["user/id"],
          firstName: this.$store.getters["user/givenName"],
          lastName: this.$store.getters["user/familyName"],
          emailAddress: this.$store.getters["user/email"],
          productTitle: this.product.name,
          userType: "Brand"
        })
      }

      try {
        let ingredients = []
        this.product.newIngredients.map(item => {
          ingredients.push({ id: item.id })
        })
        let data = this.safeProduct(this.product)
        let param = Object.assign({}, data, { newIngredients: ingredients })
        await this.$store.dispatch(this.saveAction, param)
        this.emitProductUpdate()
        this.$emit("addingProduct", this.product)
        this.emitModalClose()
      } catch (error) {
        console.error(error)
        if (error.response.status === 400) {
          const errors = error.response.data.underlying.map(error => error.error)
          this.invalidTagsCombination = errors.includes("product_tag/invalid_tag_combination")
          this.duplicatedBarcode = errors.includes("product_barcode/duplicate_barcode")
        }
      }
    },
    cancelChanges() {
      this.product = this.initialProduct
      this.currentMenuItem = "info"
      this.savingError = false
      this.emitModalClose()
    },
    emitModalClose() {
      this.isModalOpen = false
      this.currentMenuItem = "info"
      this.savingError = false
      this.$emit("modalClose")
    },
    emitProductUpdate() {
      this.$emit("productUpdate", this.product)
    },
    resetSavedStatus() {
      this.isSaved = false
    },
    resetErrorStatus() {
      this.savingError = false
    },
    productSaving(status) {
      this.isSaving = false
      this.isSaved = false
      this.savingError = false
      this.isSavingVideo = false

      if (status === "saving") {
        this.isSaving = true
      }
      if (status === "savingVideo") {
        this.isSavingVideo = true
      }
      if (status === "saved") {
        this.isSaved = true
        setTimeout(this.resetSavedStatus, 3000)
      }
      if (status === "error") {
        this.savingError = true
        setTimeout(this.resetErrorStatus, 3000)
      }
    }
  }
}
</script>

<style lang="sass">
.brand-product-modal-card-body
  width: 939px
.brand-new-product-modal
  background-color: #ffffff
  width: 875px

  &__body
    overflow-x: hidden

  &__container
    min-height: 1149px

  &__container-images
    min-height: 600px !important

  &__container-documents
    min-height: 500px !important

  &__form
    padding: 15px 20px 125px 20px

  &__product-base-form
    padding: 42px 40px 20px 40px

  &__form-buttons
    padding-right: 20px
    height: 75px
    border-top: 1px solid #E0E4E9
    display: flow-root

  &__header
    font-size: 18px !important
    font-weight: 500
    letter-spacing: 0.25px
    color: #212B36
    padding-left: 20px
    border-bottom: 1px solid #E0E4E9

  &__header-close-icon
    cursor: pointer

  &__menu
    padding-left: 40px
    padding-top: 30px
    background-color: #F4F4F4
    font-size: 18px
    width: 240px

    li
      margin-top: 20px

    a
      color: #000000 !important

  &__menu--sticky
    position: sticky
    top: 30px

  &__save-button, &__cancel-button
    height: 36px
    width: 69px
    font-size: 12px
    font-weight: bold
    margin-left: 10px

  &__error-message
    color: #BF0711
    font-size: 18px

  &__form-messages
    display: flex
    align-items: center
</style>
