<template>
  <div>
    <a-row>
      <search-component
        :search-recent="searchRecent"
        search-hint="Search by Brand"
        @doSearch="onSearch"
        @deleteTag="closeTagItem"
      />
    </a-row>

    <a-table
      :columns="tableColumn"
      :data-source="tableData"
      :loading="isLoading"
      size="middle"
      class="admin-training-table"
      :row-class-name="
        (_recode, index) => (index % 2 === 1 ? 'admin-training-table-striped' : null)
      "
      style="margin-top: 40px;"
    >
      <span slot="nameTitle">
        Name
        <a-icon
          type="caret-up"
          :rotate="sortName.toString().length > 0 && sortName === 'descend' ? 180 : 0"
          :class="
            sortName.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('name')"
        />
      </span>
      <span slot="productTitle">
        Products
        <a-icon
          type="caret-up"
          :rotate="sortProduct.toString().length > 0 && sortProduct === 'descend' ? 180 : 0"
          :class="
            sortProduct.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('productCount')"
        />
      </span>
      <span slot="publicTitle">
        Public
        <a-icon
          type="caret-up"
          :rotate="sortPublic.toString().length > 0 && sortPublic === 'descend' ? 180 : 0"
          :class="
            sortPublic.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('publicCount')"
        />
      </span>
      <span slot="publishTitle">
        Published
        <a-icon
          type="caret-up"
          :rotate="sortPublish.toString().length > 0 && sortPublish === 'descend' ? 180 : 0"
          :class="
            sortPublish.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('publishedCount')"
        />
      </span>
      <span slot="modifyTitle">
        Modified
        <a-icon
          type="caret-up"
          :rotate="sortModify.toString().length > 0 && sortModify === 'descend' ? 180 : 0"
          :class="
            sortModify.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('modified')"
        />
      </span>
      <span slot="beautyfluentTitle">
        BeautyFluent
        <a-icon
          type="caret-up"
          :rotate="sortBeauty.toString().length > 0 && sortBeauty === 'descend' ? 180 : 0"
          :class="
            sortBeauty.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('beautyfluent')"
        />
      </span>
      <span slot="billingTitle">
        B/F Billing
        <a-icon
          type="caret-up"
          :rotate="sortBilling.toString().length > 0 && sortBilling === 'descend' ? 180 : 0"
          :class="
            sortBilling.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('billing')"
        />
      </span>
      <span slot="endDateTitle">
        End Date
        <a-icon
          type="caret-up"
          :rotate="sortEndDate.toString().length > 0 && sortEndDate === 'descend' ? 180 : 0"
          :class="
            sortEndDate.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('endDate')"
        />
      </span>
      <span slot="productCounts" slot-scope="text">
        {{ text && text.count ? text.count : 0 }}
      </span>
      <span
        slot="beautyfluentValue"
        slot-scope="text, record"
        :style="
          record[retailer.id] && record[retailer.id].beauty === 'Member'
            ? 'color: #000;'
            : 'color: #E33716;'
        "
      >
        {{ getBeautyValue(record) }}
      </span>

      <span slot="billingValue" slot-scope="text, record">
        {{ getBilling(record) }}
      </span>
      <span slot="endDateValue" slot-scope="text, record">
        {{ getEndDate(record) }}
      </span>
      <span slot="published" slot-scope="text, record">
        {{
          record && record.brandProductTrainingCount
            ? record.brandProductTrainingCount.publishedCount
            : 0
        }}
      </span>
      <span slot="publics" slot-scope="text, record">
        {{
          record && record.brandProductTrainingCount
            ? record.brandProductTrainingCount.publicCount
            : 0
        }}
      </span>
      <span slot="modifyDate" slot-scope="text, record">
        {{
          record && record.brandProductTrainingCount
            ? formatDate(record.brandProductTrainingCount.modified)
            : ""
        }}
      </span>
      <span
        slot="trainLink"
        slot-scope="text, record"
        class="admin-training-retailer-brand__click-oper"
        @click="copyLink($event, record)"
      >
        {{
          record && record.brandProductTrainingCount
            ? record.isCopyTrainingLink
              ? "Link Copied"
              : "Copy Link"
            : ""
        }}
      </span>
      <span slot="link" slot-scope="text" class="admin-brand-url" @click="clickTo(text)">
        {{ text }}
      </span>
      <span slot="qrCode" slot-scope="text, record" @click="clickToDownload(record)">
        <span class="admin-training-retailer-brand__click-oper">
          {{ record.isDownload ? "Downloaded" : "Download" }}</span
        >
      </span>
      <span
        slot="actionOper"
        slot-scope="text, record"
        class="admin-training-retailer-brand__click-oper"
        @click="clickToEdit(record)"
      >
        View
      </span>
    </a-table>
    <AdminTrainintRetailerBrandProductDialog ref="modifyRetailerBrandProduct" />
    <QRCode ref="retailerBrandQrcode" :url="qrText"></QRCode>
  </div>
</template>
<script>
import { mapActions } from "vuex"
import AdminTrainintRetailerBrandProductDialog from "@/components/admin/AdminTrainintRetailerBrandProductDialog"
import SearchComponent from "@/components/training/SearchComponent"
import QRCode from "@/components/training/__components/QRCode"
import { adminRetailerBrandsTag } from "@/utils/const"
import Clipboard from "clipboard"
import { downloadQR } from "@/utils/validate"
export default {
  components: {
    AdminTrainintRetailerBrandProductDialog,
    "search-component": SearchComponent,
    QRCode
  },
  props: {
    retailer: { type: Object, default: () => undefined }
  },
  data() {
    return {
      isCheckAll: false,
      searchRecent: ["aa", "bb", "cc"],
      searchValue: "",
      qrText: "",
      isLoading: false,
      tableColumn: [
        {
          key: "name",
          dataIndex: "name",
          //title: "Name",
          scopedSlots: { customRender: "brandName" },
          slots: { title: "nameTitle" }
          //sorter: true
        },
        {
          //title: "Products",
          dataIndex: "productCount",
          key: "productCount",
          scopedSlots: { customRender: "productCounts" },
          slots: { title: "productTitle" }
          //sorter: true
        },
        {
          //title: "Published",
          dataIndex: "publishedCount",
          key: "publishedCount",
          scopedSlots: { customRender: "published" },
          slots: { title: "publishTitle" }
          //sorter: true
        },
        {
          //title: "Public",
          dataIndex: "publicCount",
          key: "publicCount",
          scopedSlots: { customRender: "publics" },
          slots: { title: "publicTitle" }
          //sorter: true
        },
        // {
        //   //title: "Modified",
        //   dataIndex: "modified",
        //   key: "modified",
        //   scopedSlots: { customRender: "modifyDate" },
        //   slots: { title: "modifyTitle" }
        //   //sorter: true
        // },
        {
          //title: "Modified",
          dataIndex: "beautyfluent",
          key: "beautyfluent",
          slots: { title: "beautyfluentTitle" },
          scopedSlots: { customRender: "beautyfluentValue" }
          // sorter: true
        },
        {
          //title: "Modified",
          dataIndex: "billing",
          key: "billing",
          slots: { title: "billingTitle" },
          scopedSlots: { customRender: "billingValue" }
          // sorter: true
        },
        {
          //title: "Modified",
          dataIndex: "endDate",
          key: "endDate",
          slots: { title: "endDateTitle" },
          scopedSlots: { customRender: "endDateValue" }
        },
        {
          title: "Brand URL",
          dataIndex: "website",
          key: "website",
          scopedSlots: { customRender: "link" }
        },

        {
          title: "Training URL",
          dataIndex: "link",
          key: "link",
          scopedSlots: { customRender: "trainLink" }
        },
        {
          title: "QR Code",
          dataIndex: "qrCode",
          key: "qrCode",
          scopedSlots: { customRender: "qrCode" }
        },
        {
          title: "Actions",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "actionOper" }
        }
      ],
      originData: [],
      tableData: [],
      sortName: "",
      sortProduct: "",
      sortPublish: "",
      sortPublic: "",
      sortModify: "",
      sortBeauty: "",
      sortBilling: "",
      sortEndDate: ""
    }
  },
  async created() {
    this.isLoading = true
    this.getSearchTrainingPro({ params: this.getBaseParam() }).then(() => {
      this.isLoading = false
      const list = this.$store.getters["brand/brandsSearch"]
      this.originData = list.map(item => {
        item.trainingLink = `${window.location.host}/training/${item.shortName}/${this.retailer.shortName}`
        return item
      })
      this.tableData = this.originData.slice(0)
    })
    this.searchRecent = await this.getTagList(adminRetailerBrandsTag)
  },
  methods: {
    ...mapActions({
      updateTagList: "training/updateBrandTags",
      getTagList: "training/getSearchBrandTags",
      getSearchTrainingPro: "brand/searchBrands"
    }),
    getBaseParam() {
      return {
        status: "approved",
        "training-subscription-at-retailer": this.retailer.id
      }
    },
    getBeautyValue(record) {
      return record[this.retailer.id] ? record[this.retailer.id].beauty : ""
    },
    getBilling(record) {
      //   if (record.brandTrainingSubscriptions && record.brandTrainingSubscriptions.length > 0) {
      //     for (let i = 0; i < record.brandTrainingSubscriptions.length; i++) {
      //       if (record.brandTrainingSubscriptions[i].retailerId === this.retailer.id) {
      //         if (record.brandTrainingSubscriptions[i].subscriptionTerms) {
      //           return record.brandTrainingSubscriptions[i].subscriptionTerms.name
      //         }
      //         return " "
      //       }
      //     }
      //   }
      //   return " "
      return record[this.retailer.id] ? record[this.retailer.id].termName : " "
    },
    getEndDate(record) {
      //   if (record.brandTrainingSubscriptions && record.brandTrainingSubscriptions.length > 0) {
      //     for (let i = 0; i < record.brandTrainingSubscriptions.length; i++) {
      //       if (record.brandTrainingSubscriptions[i].retailerId === this.retailer.id) {
      //         return formatTime(record.brandTrainingSubscriptions[i].endAt)
      //       }
      //     }
      //   }
      //   return " "
      return record[this.retailer.id] ? record[this.retailer.id].endDate : " "
    },
    async onSearch(value) {
      this.searchValue = value
      this.searchRecent = await this.updateTagList({
        tagName: adminRetailerBrandsTag,
        tagItem: this.searchValue
      })
      //   let param = this.getBaseParam()
      //   param["retailer"] = value
      //   await this.getSearchTrainingPro({ params: param })
      if (value.length == 0) {
        this.tableData = this.originData.slice(0)
      } else {
        let result = this.originData.filter(item => {
          if (
            item.name
              .toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) >= 0
          ) {
            return true
          }
          return false
        })
        this.tableData = result
      }
    },
    async closeTagItem(item) {
      await this.updateTagList({
        tagName: adminRetailerBrandsTag,
        tagItem: item.name,
        type: "delete"
      })
    },
    sortByField(field) {
      console.log(field)
      let index = 0
      let order = ""
      if (field === "name") {
        index = 0
        if (this.sortName.length === 0) {
          this.sortName = "ascend"
        } else if (this.sortName === "ascend") {
          this.sortName = "descend"
        } else {
          this.sortName = "ascend"
        }
        this.sortProduct = ""
        this.sortPublish = ""
        this.sortPublic = ""
        this.sortModify = ""
        this.sortBeauty = ""
        this.sortBilling = ""
        this.sortEndDate = ""
        order = this.sortName
      } else if (field === "productCount") {
        index = 1
        if (this.sortProduct.length === 0) {
          this.sortProduct = "ascend"
        } else if (this.sortProduct === "ascend") {
          this.sortProduct = "descend"
        } else {
          this.sortProduct = "ascend"
        }
        this.sortName = ""
        this.sortPublish = ""
        this.sortPublic = ""
        this.sortModify = ""
        this.sortBeauty = ""
        this.sortBilling = ""
        this.sortEndDate = ""
        order = this.sortProduct
      } else if (field === "publishedCount") {
        index = 2
        if (this.sortPublish.length === 0) {
          this.sortPublish = "ascend"
        } else if (this.sortPublish === "ascend") {
          this.sortPublish = "descend"
        } else {
          this.sortPublish = "ascend"
        }
        this.sortName = ""
        this.sortProduct = ""
        this.sortPublic = ""
        this.sortModify = ""
        this.sortBeauty = ""
        this.sortBilling = ""
        this.sortEndDate = ""
        order = this.sortPublish
      } else if (field === "publicCount") {
        index = 3
        if (this.sortPublic.length === 0) {
          this.sortPublic = "ascend"
        } else if (this.sortPublic === "ascend") {
          this.sortPublic = "descend"
        } else {
          this.sortPublic = "ascend"
        }
        this.sortName = ""
        this.sortPublish = ""
        this.sortProduct = ""
        this.sortModify = ""
        this.sortBeauty = ""
        this.sortBilling = ""
        this.sortEndDate = ""
        order = this.sortPublic
      } else if (field === "modified") {
        index = 4
        if (this.sortModify.length === 0) {
          this.sortModify = "ascend"
        } else if (this.sortModify === "ascend") {
          this.sortModify = "descend"
        } else {
          this.sortModify = "ascend"
        }
        this.sortName = ""
        this.sortPublish = ""
        this.sortPublic = ""
        this.sortProduct = ""
        this.sortBeauty = ""
        this.sortBilling = ""
        this.sortEndDate = ""
        order = this.sortModify
      } else if (field === "beautyfluent") {
        index = 5
        if (this.sortBeauty.length === 0) {
          this.sortBeauty = "ascend"
        } else if (this.sortBeauty === "ascend") {
          this.sortBeauty = "descend"
        } else {
          this.sortBeauty = "ascend"
        }
        this.sortName = ""
        this.sortPublish = ""
        this.sortPublic = ""
        this.sortProduct = ""
        this.sortModify = ""
        this.sortBilling = ""
        this.sortEndDate = ""
        order = this.sortBeauty
      } else if (field === "billing") {
        index = 6
        if (this.sortBilling.length === 0) {
          this.sortBilling = "ascend"
        } else if (this.sortBilling === "ascend") {
          this.sortBilling = "descend"
        } else {
          this.sortBilling = "ascend"
        }
        this.sortName = ""
        this.sortPublish = ""
        this.sortPublic = ""
        this.sortProduct = ""
        this.sortBeauty = ""
        this.sortModify = ""
        this.sortEndDate = ""
        order = this.sortBilling
      } else if (field === "endDate") {
        index = 7
        if (this.sortEndDate.length === 0) {
          this.sortEndDate = "ascend"
        } else if (this.sortEndDate === "ascend") {
          this.sortEndDate = "descend"
        } else {
          this.sortEndDate = "ascend"
        }
        this.sortName = ""
        this.sortPublish = ""
        this.sortPublic = ""
        this.sortProduct = ""
        this.sortBeauty = ""
        this.sortBilling = ""
        this.sortModify = ""
        order = this.sortEndDate
      }
      this.sortData(index, order == "ascend")
    },
    handleName(pagination, filters, sorter) {
      console.log(sorter)
      let index = 0
      if (sorter.field === "name") {
        index = 0
      } else if (sorter.field === "productCount") {
        index = 1
      } else if (sorter.field === "publishedCount") {
        index = 2
      } else if (sorter.field === "publicCount") {
        index = 3
      } else if (sorter.field === "modified") {
        index = 4
      }
      if (!sorter.order) {
        sorter.order = "ascend"
      }
      this.tableColumn[index].sortOrder = sorter.order
      for (let i = 0; i < 5; i++) {
        if (i !== index) {
          this.tableColumn[i].sortOrder = false
        }
      }
      this.sortData(index, sorter.order == "ascend")
    },
    sortData(index, ascend) {
      console.log(`sortData: index: ${index}, ascend: ${ascend}, retailerId: ${this.retailer.id}`)
      let retailerId = this.retailer.id
      if (index == 0) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.name.localeCompare(b.name)
          } else {
            return b.name.localeCompare(a.name)
          }
        })
      } else if (index == 1) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.productCount.count - b.productCount.count
          } else {
            return b.productCount.count - a.productCount.count
          }
        })
      } else if (index == 2) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount) {
              return (
                a.brandProductTrainingCount.publishedCount -
                b.brandProductTrainingCount.publishedCount
              )
            }
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount == null) {
              return a.brandProductTrainingCount.publishedCount - 0
            }
            if (b.brandProductTrainingCount != null && a.brandProductTrainingCount == null) {
              return 0 - b.brandProductTrainingCount.publishedCount
            }
          } else {
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount) {
              return (
                b.brandProductTrainingCount.publishedCount -
                a.brandProductTrainingCount.publishedCount
              )
            }
            if (b.brandProductTrainingCount != null && a.brandProductTrainingCount == null) {
              return b.brandProductTrainingCount.publishedCount - 0
            }
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount == null) {
              return 0 - a.brandProductTrainingCount.publishedCount
            }
          }
        })
      } else if (index == 3) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount) {
              return (
                a.brandProductTrainingCount.publicCount - b.brandProductTrainingCount.publicCount
              )
            }
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount == null) {
              return a.brandProductTrainingCount.publicCount - 0
            }
            if (b.brandProductTrainingCount != null && a.brandProductTrainingCount == null) {
              return 0 - b.brandProductTrainingCount.publicCount
            }
          } else {
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount) {
              return (
                b.brandProductTrainingCount.publicCount - a.brandProductTrainingCount.publicCount
              )
            }
            if (b.brandProductTrainingCount != null && a.brandProductTrainingCount == null) {
              return b.brandProductTrainingCount.publicCount - 0
            }
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount == null) {
              return 0 - a.brandProductTrainingCount.publicCount
            }
          }
        })
      } else if (index == 4) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount) {
              return a.brandProductTrainingCount.modified.localeCompare(
                b.brandProductTrainingCount.modified
              )
            }
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount == null) {
              return a.brandProductTrainingCount.modified.localeCompare("")
            }
            if (b.brandProductTrainingCount != null && a.brandProductTrainingCount == null) {
              return "".localeCompare(b.brandProductTrainingCount.modified)
            }
          } else {
            if (b.brandProductTrainingCount != null && a.brandProductTrainingCount) {
              return b.brandProductTrainingCount.modified.localeCompare(
                a.brandProductTrainingCount.modified
              )
            }
            if (b.brandProductTrainingCount != null && a.brandProductTrainingCount == null) {
              return b.brandProductTrainingCount.modified.localeCompare("")
            }
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount == null) {
              return "".localeCompare(a.brandProductTrainingCount.modified)
            }
          }
        })
      } else if (index == 5) {
        this.tableData.sort((a, b) => {
          console.log(a)
          console.log(b)
          console.log(retailerId)
          if (ascend) {
            if (a[retailerId] && b[retailerId]) {
              return a[retailerId].beauty.localeCompare(b[retailerId].beauty)
            }
            if (a[retailerId] && !b[retailerId]) {
              return a[retailerId].beauty.localeCompare("")
            }
            if (!a[retailerId] && b[retailerId]) {
              return "".localeCompare(b[retailerId].beauty)
            }
          } else {
            if (a[retailerId] && b[retailerId]) {
              return b[retailerId].beauty.localeCompare(a[retailerId].beauty)
            }
            if (b[retailerId] && !a[retailerId]) {
              return b[retailerId].beauty.localeCompare("")
            }
            if (!b[retailerId] && a[retailerId]) {
              return "".localeCompare(a[retailerId].beauty)
            }
          }
        })
      } else if (index === 6) {
        this.tableData.sort((a, b) => {
          console.log(a[retailerId] + " index: 6")
          console.log(b[retailerId] + " index: 6")
          if (ascend) {
            if (a[retailerId] && b[retailerId]) {
              return a[retailerId].termName.localeCompare(b[retailerId].termName)
            }
            if (a[retailerId] && !b[retailerId]) {
              return a[retailerId].termName.localeCompare("")
            }
            if (!a[retailerId] && b[retailerId]) {
              return "".localeCompare(b[retailerId].termName)
            }
          } else {
            if (a[retailerId] && b[retailerId]) {
              return b[retailerId].termName.localeCompare(a[retailerId].termName)
            }
            if (b[retailerId] && !a[retailerId]) {
              return b[retailerId].termName.localeCompare("")
            }
            if (!b[retailerId] && a[retailerId]) {
              return "".localeCompare(a[retailerId].termName)
            }
          }
        })
      } else {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a[retailerId] && b[retailerId]) {
              return a[retailerId].endDate.localeCompare(b[retailerId].endDate)
            }
            if (a[retailerId] && !b[retailerId]) {
              return a[retailerId].endDate.localeCompare("")
            }
            if (!a[retailerId] && b[retailerId]) {
              return "".localeCompare(b[retailerId].endDate)
            }
          } else {
            if (a[retailerId] && b[retailerId]) {
              return b[retailerId].endDate.localeCompare(a[retailerId].endDate)
            }
            if (b[retailerId] && !a[retailerId]) {
              return b[retailerId].endDate.localeCompare("")
            }
            if (!b[retailerId] && a[retailerId]) {
              return "".localeCompare(a[retailerId].endDate)
            }
          }
        })
      }
    },
    clickToEdit(data) {
      console.log(data)
      this.$refs["modifyRetailerBrandProduct"].openDialog(data.id)
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value)
        return new Intl.DateTimeFormat("en-US").format(date)
      }
      return " "
    },
    copyLink(e, record) {
      if (
        record.brandProductTrainingCount &&
        record.brandProductTrainingCount.link &&
        !record.isCopyTrainingLink
      ) {
        var text = record.trainingLink
        const clipboard = new Clipboard(e.target, { text: () => `https://${text}` })
        clipboard.on()
        clipboard.on("success", () => {
          this.$message.success("Copy Success")
          clipboard.off("error")
          clipboard.off("success")
          clipboard.destroy()
        })
        clipboard.on("error", () => {
          this.$message.warning("Browser not support copy")
          clipboard.off("error")
          clipboard.off("success")
          clipboard.destroy()
        })
        clipboard.onClick(e)
        record.isCopyTrainingLink = true
        this.$forceUpdate()
      }
    },
    clickTo(value) {
      window.open(`https://${value}`)
    },
    clickToDownload(data) {
      if (!data.isDownload) {
        this.qrText = `https://${data.trainingLink}`
        console.log(this.qrText)
        // this.$refs["brandQrcode"].openDownloadQR()
        downloadQR(this.qrText, `${data.name}_qrcode.png`)
        data.isDownload = true
        this.$forceUpdate()
      }
    },
    clickToPublicTraining(item) {
      const url = `https://${item.brandProductTrainingCount.link}`
      window.open(url)
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-training-retailer-brand {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  &__click-oper {
    cursor: pointer;
    color: #4a8a5d;
  }
  &__bf-no-paid-text {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: #e33716;
  }
}
</style>
