var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"adminTrainingBrandProduct",staticClass:"admin-training-brand-product__main"},[_c('a-row',[_c('a-col',{attrs:{"span":20}}),_c('a-col',{staticStyle:{"text-align":"right"},attrs:{"span":4}},[_c('a-button',{staticClass:"admin-training-landing-red-btn",on:{"click":_vm.ViewAllProduct}},[_vm._v("View All")])],1)],1),_c('a-table',{staticClass:"admin-training-table",staticStyle:{"margin-top":"40px"},attrs:{"columns":_vm.tableColumn,"data-source":_vm.tableData,"size":"middle","row-class-name":(_recode, index) => (index % 2 === 1 ? 'admin-training-table-striped' : null),"pagination":false,"loading":_vm.isLoading},on:{"change":_vm.handleName},scopedSlots:_vm._u([{key:"hero",fn:function(text, record){return _c('span',{},[(record.training != null && record.training.hero)?_c('span',{staticClass:"admin-training-brand-product__hero-product-position"},[_vm._v("Hero")]):_c('span')])}},{key:"skuOper",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(text)+" ")])}},{key:"draftOper",fn:function(text){return _c('div',{},[(text == 0)?_c('div',{staticClass:"admin-training-brand-product__draft-product"},[_vm._v("Draft")]):_vm._e()])}},{key:"publicOper",fn:function(text, record){return _c('span',{},[_c('a-switch',{staticClass:"admin-training-switch",attrs:{"checked":text == 2,"disabled":text <= 0},on:{"change":isChecked => _vm.onChangePublic(isChecked, record)}})],1)}},{key:"actionOper",fn:function(text, record){return _c('span',{staticClass:"admin-training-brand-product__action",on:{"click":function($event){return _vm.clickToView(record)}}},[_vm._v(" "+_vm._s(record.public > 0 ? "View" : "Edit")+" ")])}}])},[_c('span',{attrs:{"slot":"nameTitle"},slot:"nameTitle"},[_vm._v(" Name "),_c('a-icon',{class:_vm.sortName.toString().length > 0
            ? 'admin-training-sort-active'
            : 'admin-training-sort-gray',attrs:{"type":"caret-up","rotate":_vm.sortName.toString().length > 0 && _vm.sortName === 'descend' ? 180 : 0},on:{"click":function($event){return _vm.sortByField('name')}}})],1),_c('span',{attrs:{"slot":"heroTitle"},slot:"heroTitle"},[_vm._v(" Hero "),_c('a-icon',{class:_vm.sortHero.toString().length > 0
            ? 'admin-training-sort-active'
            : 'admin-training-sort-gray',attrs:{"type":"caret-up","rotate":_vm.sortHero.toString().length > 0 && _vm.sortHero === 'descend' ? 180 : 0},on:{"click":function($event){return _vm.sortByField('hero')}}})],1),_c('span',{attrs:{"slot":"skuTitle"},slot:"skuTitle"},[_vm._v(" SKU "),_c('a-icon',{class:_vm.sortSku.toString().length > 0
            ? 'admin-training-sort-active'
            : 'admin-training-sort-gray',attrs:{"type":"caret-up","rotate":_vm.sortSku.toString().length > 0 && _vm.sortSku === 'descend' ? 180 : 0},on:{"click":function($event){return _vm.sortByField('sku')}}})],1),_c('span',{attrs:{"slot":"upcTitle"},slot:"upcTitle"},[_vm._v(" UPC "),_c('a-icon',{class:_vm.sortUpc.toString().length > 0
            ? 'admin-training-sort-active'
            : 'admin-training-sort-gray',attrs:{"type":"caret-up","rotate":_vm.sortUpc.toString().length > 0 && _vm.sortUpc === 'descend' ? 180 : 0},on:{"click":function($event){return _vm.sortByField('upc')}}})],1),_c('span',{attrs:{"slot":"modifyTitle"},slot:"modifyTitle"},[_vm._v(" Modified "),_c('a-icon',{class:_vm.sortModify.toString().length > 0
            ? 'admin-training-sort-active'
            : 'admin-training-sort-gray',attrs:{"type":"caret-up","rotate":_vm.sortModify.toString().length > 0 && _vm.sortModify === 'descend' ? 180 : 0},on:{"click":function($event){return _vm.sortByField('modified')}}})],1)]),_c('AdminProductTrainingDialog',{ref:"adminProductDialog",on:{"updateNewTrainingList":_vm.updateProductList}}),_c('productViewDialogNew',{ref:"productViewDialogNew"}),_c('a-modal',{attrs:{"visible":_vm.showVisible,"footer":null,"destroy-on-close":true,"body-style":{ padding: 15 },"get-container":() => _vm.$refs.adminTrainingBrandProduct},on:{"cancel":_vm.handleCancel}},[_c('div',{staticClass:"can-not-create-training"},[_c('p',{staticClass:"title-error-text"},[_c('a-icon',{staticStyle:{"color":"#faad14","margin-right":"5px","font-size":"18px"},attrs:{"type":"exclamation-circle"}}),_vm._v("Please modify the details of the product ")],1),_c('p',[_vm._v(_vm._s(_vm.contentText))]),_c('div',{staticClass:"button-style"},[_c('a-button',{staticClass:"btn-style",on:{"click":_vm.jumpToBrandProduct}},[_vm._v(" To modify ")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }