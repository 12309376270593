import productClient from "@/api/product-client"
import retailerClient from "@/api/retailer-client"

export default {
  namespaced: true,

  state: {
    retailers: [],
    retailerList: [], // no price retailer
    retailer: {},
    topRetailers: [],
    cartProducts: [],
    cartProductGroups: [],
    products: [],
    productsSearchHeaders: { "x-page-count": null },
    retailersSearchHeaders: { "x-page-count": null },
    memberStatus: "",
    memberItem: {},
    isSuccess: undefined,
    cleanPreCheckList: {},
    cleanStandards: [],
    cartTotalNumber: 0,
    brandLogistics: [],
    trainingRetailer: [],
    bfPreferredRetailers: [],
    bfGeneralRetailer: {},
    bfRetailers: [] //preferred and general retailers
  },

  getters: {
    productsSearchTotalCount(state) {
      return state.productsSearchHeaders["x-total-count"]
    },
    productsSearchPageCount(state) {
      return state.productsSearchHeaders["x-page-count"]
    },
    retailersSearchTotalCount(state) {
      return state.retailersSearchHeaders["x-total-count"]
    },
    retailersSearchPageCount(state) {
      return state.retailersSearchHeaders["x-page-count"]
    },
    productsSearchMinPrice(state) {
      return state.productsSearchHeaders["x-min-msrp-usd"]
    },
    productsSearchMaxPrice(state) {
      return state.productsSearchHeaders["x-max-msrp-usd"]
    },

    retailerById: state => id => {
      return state.retailers.find(retailer => retailer.id === id)
    },

    retailers(state) {
      return state.retailers
    },
    retailerList(state) {
      return state.retailerList
    },
    products(state) {
      return state.products
    },
    retailer(state) {
      return {
        website: "",
        about: "",
        introduction: "",
        foundingDate: null,
        size: {},
        socialAccounts: [],
        locationCounts: [],
        bannerAsset: {},
        logoAsset: {},
        terms: { paymentTerm: { id: null } },
        otherTypes: [],
        status: { id: null },
        productCategories: [],
        ...state.retailer
      }
    },
    isRetailerPending(state) {
      return (
        state.retailer &&
        state.retailer.status &&
        ["pending", "rejected"].includes(state.retailer.status.id)
      )
    },
    isRetailerRejected(state) {
      return (
        state.retailer && state.retailer.status && ["rejected"].includes(state.retailer.status.id)
      )
    },
    cartProductsCount(state) {
      return state.cartProducts.filter(
        item => item.quantity > 0 && !item.saved && item.orderType.id === "purchase"
      ).length
    },
    cartProducts(state) {
      return state.cartProducts
    },
    cartProductGroups(state) {
      return state.cartProductGroups
    },
    uniqueCartProductIds(state) {
      return [...new Set(state.cartProducts.map(item => item.product.id))]
    },
    orderDetailsForCartProductGroup: (state, getters, rootState, rootGetters) => (
      orderDetails,
      isPurchase
    ) => {
      const productId = orderDetails[0].product.id
      const orderTypes = isPurchase
        ? rootGetters["reference/purchaseTypes"]
        : rootGetters["reference/sampleTypes"]

      return orderTypes.map(orderType => {
        const cartProduct =
          orderDetails.find(cartProduct => cartProduct.orderType.id === orderType.id) || {}

        return {
          orderType: { id: orderType.id },
          quantity: cartProduct.quantity || 0,
          product: { id: productId },
          saved: cartProduct.saved || false
        }
      })
    },

    mainImageIds: state => navigationalTagSlug => {
      return state.productsByNavigationalTags[navigationalTagSlug].map(
        product => product.images && product.images.find(image => image.main).assetId
      )
    },
    isTermsPending(state) {
      return (
        state.retailer &&
        state.retailer.termsPending &&
        state.retailer.termsPending.status.id === "pending"
      )
    },
    topRetailers(state) {
      return state.topRetailers
    },
    memberStatus(state) {
      return state.memberStatus
    },
    preCheckList(state) {
      return state.cleanPreCheckList
    },
    cleanStandardsList(state) {
      return state.cleanStandards
    },
    CartTotalNumber(state) {
      return state.cartTotalNumber
    },
    brandLogistics(state) {
      return state.brandLogistics
    },
    trainingRetailers(state) {
      return state.trainingRetailer
    },
    noPriceRetailerById: state => id => {
      return state.retailerList.find(retailer => retailer.id === id)
    },
    preferredRetailers(state) {
      return state.bfPreferredRetailers
    },
    generalRetailer(state) {
      return state.bfGeneralRetailer
    },
    getPreferredRetailerName: state => id => {
      if (id === "*") {
        return "General"
      }
      let result = state.bfPreferredRetailers.find(retailer => retailer.retailerId === id)
      if (result) {
        return result.retailerName
      }
      return " "
    },
    preferredAndGeneralRetailers(state) {
      return state.bfRetailers
    }
  },

  mutations: {
    SET_RETAILERS(state, retailers) {
      state.retailers = retailers
    },
    SET_NO_PRICE_RETAILERS(state, retailers) {
      state.retailerList = retailers
    },
    SET_RETAILER(state, retailer) {
      state.retailer = retailer
    },
    SET_TOP_RETAILERS(state, retailers) {
      state.topRetailers = retailers
    },
    SET_CART(state, cartProducts) {
      state.cartProducts = cartProducts
    },
    SET_CART_GROUPS(state, cartProductGroups) {
      state.cartProductGroups = cartProductGroups
    },
    CART_TOTAL_NUMBER(state, cartTotalNumber) {
      state.cartTotalNumber = cartTotalNumber
    },
    SET_RETAILERS_SEARCH_HEADERS(state, headers) {
      state.retailersSearchHeaders = headers
    },
    SET_PRODUCTS(state, { products }) {
      state.products = products
    },
    ADD_NEW_PRODUCT_TO_CART(state, newCartProduct) {
      state.cartProducts.push(newCartProduct)
    },
    MOVE_SAVED_PRODUCT_TO_CART(state, productId) {
      state.cartProducts
        .filter(cartProduct => cartProduct.product.id === productId && cartProduct.saved === true)
        .forEach(productType => (productType.saved = false))
    },
    REMOVE_PRODUCT_FROM_CART(state, productIdForRemoving) {
      state.cartProducts = state.cartProducts.filter(
        item => item.product.id !== productIdForRemoving
      )
    },
    CHECKOUT_PRODUCTS(state, brandId) {
      state.cartProducts
        .filter(cartProduct => cartProduct.brand.id !== brandId)
        .forEach(product => (product.saved = true))
    },
    UPDATE_RETAILER(state, updatedRetailer) {
      state.retailer = updatedRetailer
    },
    UPDATE_CATEGORIES(state, categories) {
      state.retailer.productCategories = categories
    },
    UPDATE_TERMS(state, terms) {
      state.retailer.terms = terms
    },
    UPDATE_OTHER_TYPES(state, otherTypes) {
      state.retailer.otherTypes = otherTypes
    },
    UPDATE_FORBIDDEN_INGREDIENTS(state, ingredients) {
      state.retailer.forbiddenIngredients = ingredients
      state.cleanStandards = ingredients
    },
    UPDATE_CLEAN_STANDARD(state, ingredients) {
      state.cleanStandards = ingredients
    },
    UPDATE_LOCATIONS(state, locations) {
      state.retailer.locationCounts = locations
    },
    UPDATE_SOCIAL_ACCOUNTS(state, socialAccounts) {
      state.retailer.socialAccounts = socialAccounts
    },
    ADD_ADDRESS(state, addresses) {
      state.retailer.addresses.push(addresses)
    },
    SET_ADDRESS(state, currentAddress) {
      state.retailer.addresses = currentAddress
    },
    UPDATE_ADDRESS(state, addresses) {
      state.retailer.addresses = addresses
    },
    SET_PRODUCTS_SEARCH_HEADERS(state, headers) {
      state.productsSearchHeaders = headers
    },
    UPDATE_PENDING_TERMS(state, termsPending) {
      state.retailer.termsPending = termsPending
    },
    ADD_MEMBER(state, member) {
      state.retailer.members.push(member)
    },
    UPDATE_MEMBER(state, updatedMember) {
      state.memberStatus = updatedMember.membershipStatus
      for (var index in state.retailer.members) {
        if (state.retailer.members[index].user.id === updatedMember.user.id) {
          state.retailer.members[index].membershipStatus = updatedMember.membershipStatus
        }
      }
    },
    SET_PRE_CHECK_LIST(state, list) {
      list.list.map(item => {
        let obj = Object.assign(item, {
          percent: (item.clearedProductsSize / item.totalProductsSize) * 100
        })
        return obj
      })
      state.cleanPreCheckList = list
    },
    SET_REGISTRATION(state, data) {
      state.isSuccess = data
    },
    SET_BRAND_LOGISTIC(state, data) {
      let hasOne = false
      for (let i = 0; i < state.brandLogistics.length; i++) {
        if (state.brandLogistics[i].brandId === data.brandId) {
          state.brandLogistics[i].isLogistic = data.isLogistic
          hasOne = true
          break
        }
      }
      if (!hasOne) {
        state.brandLogistics.push(data)
      }
    },
    SET_TRAINING_RETAILERS(state, data) {
      state.trainingRetailer = data
    },
    SET_PREFERRED_RETAILERS(state, data) {
      state.bfRetailers = data
      if (data && data.length > 0) {
        state.bfPreferredRetailers = data.filter(item => item.gradeType === "preferred")
      } else {
        state.bfPreferredRetailers = data
      }
    },
    SET_GENERAL_RETAILER(state, data) {
      if (data && data.length > 0) {
        let result = data.filter(item => item.retailerId === "*")
        if (result && result.length > 0) {
          state.bfGeneralRetailer = result[0]
        } else {
          state.bfGeneralRetailer = {}
        }
      } else {
        state.bfGeneralRetailer = {}
      }
    }
  },

  actions: {
    async fetchRetailer({ commit }, retailerId) {
      try {
        const { data } = await retailerClient.fetch(retailerId)
        commit("SET_RETAILER", data)
        commit("UPDATE_CLEAN_STANDARD", data.forbiddenIngredients)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchRetailers({ commit }, { params }) {
      try {
        const response = await retailerClient.fetchRetailers(params)
        commit("SET_RETAILERS", response.data)
        commit("SET_RETAILERS_SEARCH_HEADERS", response.headers)
        return response
      } catch (error) {
        if (error.response && error.response.status === 404) {
          commit("SET_RETAILERS", [])
          commit("SET_RETAILERS_SEARCH_HEADERS", error.response.headers)
        } else {
          return Promise.reject(error)
        }
      }
    },
    async fetchNoPriceRetailers({ commit }, { params }) {
      params["have-training-price"] = false
      try {
        const response = await retailerClient.fetchRetailers(params)
        commit("SET_NO_PRICE_RETAILERS", response.data)
        //   commit("SET_RETAILERS_SEARCH_HEADERS", response.headers)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchAllRetailers({ commit }, { params }) {
      try {
        const { data } = await retailerClient.getAllRetailers(params)
        commit("SET_RETAILERS", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchretailersByIds({ commit }, { retailerIds, params = {} }) {
      try {
        let id = retailerIds.join(",")
        const { data } = await retailerClient.fetchRetailers({
          ...params,
          id
        })
        commit("SET_TOP_RETAILERS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateRetailer({ commit }, retailer) {
      try {
        const { data } = await retailerClient.updateRetailer(
          retailer,
          retailer.id == undefined ? retailer.retailerId : retailer.id
        )
        commit("UPDATE_RETAILER", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateRetailerPartially({ commit }, retailer) {
      try {
        const { data } = await retailerClient.updateRetailerPartially(retailer)
        commit("UPDATE_RETAILER", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    setCartProductGroups({ commit, state, getters, rootGetters }) {
      state.brandLogistics = []
      const products = rootGetters["product/productsForCart"] || []

      const productGroups = getters.uniqueCartProductIds.reduce(
        (groupedCartProducts, productId) => {
          const cartProductsByProductId = state.cartProducts.filter(
            item => item.product.id === productId
          )
          const purchaseOrderDetails = cartProductsByProductId.filter(item =>
            ["purchase", "testers"].includes(item.orderType.id)
          )
          const sampleOrderDetails = cartProductsByProductId.filter(
            item => !["purchase", "testers"].includes(item.orderType.id)
          )

          if (purchaseOrderDetails.length) {
            groupedCartProducts.push({
              id: productId,
              product: products.find(product => product.id === productId),
              orderDetails: getters.orderDetailsForCartProductGroup(purchaseOrderDetails, true),
              isPurchase: true,
              isSaved: purchaseOrderDetails.some(orderDetail => orderDetail.saved)
            })
          }
          if (sampleOrderDetails.length) {
            groupedCartProducts.push({
              id: productId,
              product: products.find(product => product.id === productId),
              orderDetails: getters.orderDetailsForCartProductGroup(sampleOrderDetails, false),
              isPurchase: false,
              isSaved: sampleOrderDetails.some(orderDetail => orderDetail.saved)
            })
          }

          return groupedCartProducts
        },
        []
      )

      const safeProductGroups = productGroups.filter(group => group.product)

      const uniqueBrandsIds = [...new Set(safeProductGroups.map(group => group.product.brand.id))]
      const groups = uniqueBrandsIds.reduce((brandGroup, brandId) => {
        const groupByBrand = safeProductGroups.filter(group => group.product.brand.id === brandId)
        brandGroup.push({
          brandId: brandId,
          products: groupByBrand
        })
        return brandGroup
      }, [])

      commit("SET_CART_GROUPS", groups)
    },
    async fetchProducts({ commit }, { params }) {
      try {
        const response = await productClient.getProductsByParams(params)
        commit("SET_PRODUCTS", { products: response.data })
        commit("SET_PRODUCTS_SEARCH_HEADERS", response.headers)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async fetchCart({ commit }, params) {
      try {
        const { data } = await productClient.getCart(params)
        const cartNumber =
          data.filter(item => item.quantityType.id === "purchase" && !item.saved) || []
        commit("SET_CART", data)
        commit("CART_TOTAL_NUMBER", cartNumber.length)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateCart({ commit, state }, { orderDetails, params }) {
      if (orderDetails && orderDetails.length) {
        commit("SET_CART", orderDetails)
      }
      try {
        const { data } = await productClient.updateCart(state.cartProducts, params)
        if (!data.developerMessage) {
          commit("SET_CART", data)
        }
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async addProductToCart(
      { commit, state, dispatch, rootGetters },
      { brand, productId, orderTypeId, params }
    ) {
      const isPurchaseOrderType = rootGetters["reference/isPurchaseOrderType"](orderTypeId)
      const addedCartProduct = state.cartProducts.find(cartProduct => {
        const isPurchaseCartProduct = rootGetters["reference/isPurchaseOrderType"](
          cartProduct.orderType.id
        )
        return cartProduct.product.id === productId && isPurchaseCartProduct === isPurchaseOrderType
      })
      if (addedCartProduct && addedCartProduct.saved === false) {
        return
      }

      if (addedCartProduct && addedCartProduct.saved === true) {
        commit("MOVE_SAVED_PRODUCT_TO_CART", productId)
      }

      if (!addedCartProduct) {
        const newCartProduct = {
          brand: {
            id: brand.id
          },
          product: {
            id: productId
          },
          orderType: {
            id: orderTypeId
          },
          quantity: 1
        }
        commit("ADD_NEW_PRODUCT_TO_CART", newCartProduct)
      }
      return dispatch("updateCart", { params })
    },

    async removeProductFromCart({ commit, dispatch }, { productId, params }) {
      commit("REMOVE_PRODUCT_FROM_CART", productId)
      await dispatch("updateCart", { params })
    },

    async checkoutOnlyOneBrandProducts({ commit, dispatch }, { brandId }) {
      commit("CHECKOUT_PRODUCTS", brandId)
      // await dispatch("updateCart", { params })
      return dispatch("setCartProductGroups")
    },

    async updateCategories({ commit, state }, categories) {
      try {
        const { data } = await retailerClient.updateCategories(categories, state.retailer.id)
        commit("UPDATE_CATEGORIES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateTerms({ commit, state }, terms) {
      try {
        const { data } = await retailerClient.updateTerms(terms, state.retailer.id)
        commit("UPDATE_TERMS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updatePendingTerms({ commit, state }, pendingTerms) {
      try {
        const { data } = await retailerClient.updatePendingTerms(pendingTerms, state.retailer.id)
        commit("UPDATE_PENDING_TERMS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateLocations({ commit, state }, locations) {
      try {
        const { data } = await retailerClient.updateLocations(locations, state.retailer.id)
        commit("UPDATE_LOCATIONS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateSocialAccounts({ commit, state }, socialAccounts) {
      try {
        const { data } = await retailerClient.updateSocialAccounts(
          socialAccounts,
          state.retailer.id
        )
        commit("UPDATE_SOCIAL_ACCOUNTS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async createAddress({ commit, state }, addresses) {
      try {
        const { data } = await retailerClient.updateAddress(addresses, state.retailer.id)
        commit("ADD_ADDRESS", data)
        commit("SET_ADDRESS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateAddress({ commit, state }, addresses) {
      try {
        const { data } = await retailerClient.updateAddress(addresses, state.retailer.id)
        commit("UPDATE_ADDRESS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async addMember({ commit, state }, member) {
      try {
        const { data } = await retailerClient.addMember(member, state.retailer.id)
        if (!data.developerMessage) {
          commit("ADD_MEMBER", data)
        }
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async adminAddMember({ commit, state }, { member, params }) {
      try {
        const { data } = await retailerClient.adminAddMember(member, state.retailer.id, params)
        if (!data.developerMessage) {
          commit("ADD_MEMBER", data)
        }
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateMemberStatus({ commit, state }, { member, params }) {
      try {
        const { data } = await retailerClient.updateMemberStatus(member, state.retailer.id, params)
        commit("UPDATE_MEMBER", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateMemberStatusAfterInvite({ commit }, { member, retailerid, params }) {
      try {
        const { data } = await retailerClient.updateMemberStatus(member, retailerid, params)
        commit("UPDATE_MEMBER", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateOtherTypes({ commit, state }, otherTypes) {
      try {
        const { data } = await retailerClient.updateOtherTypes(otherTypes, state.retailer.id)
        commit("UPDATE_OTHER_TYPES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async retailerRegistration({ commit }, params) {
      try {
        const { data } = await retailerClient.retailerRegistration(params)
        commit("SET_REGISTRATION", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateForbiddenIngredients({ commit, state }, ingredients) {
      try {
        const { data } = await retailerClient.updateForbiddenIngredients(
          ingredients,
          state.retailer.id
        )
        commit("UPDATE_FORBIDDEN_INGREDIENTS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async removeForbiddenIngredients({ commit, state }, ingredients) {
      try {
        const response = await retailerClient.removeForbiddenIngredients(
          ingredients,
          state.retailer.id
        )
        if (response.status === 200) {
          let data = state.retailer.forbiddenIngredients.slice()
          for (let index = 0; index < ingredients.length; index++) {
            for (let fIndex = 0; fIndex < state.retailer.forbiddenIngredients.length; fIndex++) {
              if (ingredients[index].id === state.retailer.forbiddenIngredients[fIndex].id) {
                data.splice(fIndex, 1)
              }
            }
          }
          commit("UPDATE_FORBIDDEN_INGREDIENTS", data)
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getCleanPreCheckList({ commit }, { retailerId, currentPage }) {
      try {
        const { data } = await retailerClient.getRetailerCleanPreCheck(retailerId, currentPage)
        commit("SET_PRE_CHECK_LIST", data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getForbiddenCategoryList({ commit }, retailerId) {
      try {
        const { data } = await retailerClient.getRetailerForbiddenList(retailerId)
        if (data && data.list && data.list.length) {
          data.list.map(item => {
            item.id = item.privateId
            item.ingredientName = item.ingredientId
            item.retailerName = item.retailerId
          })
          commit("UPDATE_FORBIDDEN_INGREDIENTS", data.list)
        } else {
          commit("UPDATE_FORBIDDEN_INGREDIENTS", [])
        }
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getRetailerAdminTrainingList({ commit }, { params }) {
      try {
        const response = await retailerClient.getRetailerAdminTrainingList(params)
        commit("SET_TRAINING_RETAILERS", response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getPreferredRetailers({ commit }) {
      try {
        const response = await retailerClient.getBeautyfluentRetailer()
        commit("SET_PREFERRED_RETAILERS", response.data)
        commit("SET_GENERAL_RETAILER", response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
