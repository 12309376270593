<template>
  <div class="beauty-fluent-step-three-account__main">
    <div class="billing-title">
      <p>
        <a-avatar
          style="color: #F15A42; background-color: rgba(237, 142, 131, .2);margin-right: 18px"
          >1</a-avatar
        >
        <span style="font-size: 18px;font-weight: bold;color: #1a1818">CREATE YOUR ACCOUNT</span>
      </p>
      <p>
        <span>Already have an account?</span>
        <span
          style="color: #F15A42;font-weight: bold;margin-left: 10px;cursor: pointer"
          @click="toLogin"
          >LOG IN HERE</span
        >
      </p>
    </div>
    <div class="step-three-form">
      <a-form-model
        ref="bfBrandSignAccountForm"
        :model="billingForm"
        name="nest-messages"
        :rules="rules"
      >
        <a-form-model-item prop="brandName">
          <a-input v-model="billingForm.brandName" placeholder="Brand Name" @change="inputValue" />
        </a-form-model-item>
        <a-form-model-item prop="website">
          <a-input v-model="billingForm.website" placeholder="Brand Website" @change="inputValue" />
        </a-form-model-item>
        <div class="form-item-name">
          <a-form-model-item prop="firstName" style="width: 313px">
            <a-input
              v-model="billingForm.firstName"
              placeholder="First Name"
              @change="inputValue"
            />
          </a-form-model-item>
          <a-form-model-item prop="lastName" style="width: 313px">
            <a-input v-model="billingForm.lastName" placeholder="Last Name" @change="inputValue" />
          </a-form-model-item>
        </div>
        <a-form-model-item prop="newEmail">
          <a-input v-model="billingForm.newEmail" placeholder="Email" @change="inputValue" />
        </a-form-model-item>
        <a-form-model-item prop="newPassword" class="password-style">
          <a-input
            v-model="billingForm.newPassword"
            :type="isLookPassword ? 'text' : 'password'"
            placeholder="Password"
            @change="inputValue"
          />
          <span class="eye-style" :class="isLookPassword ? '' : 'has-color'" @click="modifyView">
            <a-icon :type="isLookPassword ? 'eye-invisible' : 'eye'" style="font-size: 22px" />
          </span>
        </a-form-model-item>
        <a-form-model-item prop="confirmPassword" class="password-style">
          <a-input
            v-model="billingForm.confirmPassword"
            :type="isLookConfirmPassword ? 'text' : 'password'"
            placeholder="Confirm Password"
            @change="inputValue"
          />
          <span
            class="eye-style"
            :class="isLookConfirmPassword ? '' : 'has-color'"
            @click="modifyConfirmView"
          >
            <a-icon
              :type="isLookConfirmPassword ? 'eye-invisible' : 'eye'"
              style="font-size: 22px"
            />
          </span>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
export default {
  name: "BFStep3Billing",
  data() {
    return {
      isLookPassword: false,
      isLookConfirmPassword: false,
      billingForm: {
        brandName: undefined,
        website: undefined,
        firstName: undefined,
        lastName: undefined,
        newEmail: undefined,
        newPassword: undefined,
        confirmPassword: undefined
      },
      rules: {
        brandName: [{ required: true, message: "Please input brand name", trigger: "blur" }],
        website: [{ required: true, message: "Please input website", trigger: "blur" }],
        firstName: [{ required: true, message: "Please input first name", trigger: "blur" }],
        lastName: [{ required: true, message: "Please input last name", trigger: "blur" }],
        newEmail: [{ required: true, message: "Please input email", trigger: "blur" }],
        newPassword: [{ required: true, message: "Please input password", trigger: "blur" }],
        confirmPassword: [
          { required: true, message: "Please input confirm password", trigger: "blur" }
        ]
      }
    }
  },
  methods: {
    inputValue() {
      this.$emit("accountUpdate", this.billingForm)
    },
    toLogin() {
      this.$router.push({
        name: "login",
        query: {
          hasAccount: true
        }
      })
    },
    modifyView() {
      this.isLookPassword = !this.isLookPassword
    },
    modifyConfirmView() {
      this.isLookConfirmPassword = !this.isLookConfirmPassword
    },
    checkValue(validFun) {
      this.$refs.bfBrandSignAccountForm.validate(valid => {
        console.log(valid)
        if (validFun) {
          validFun(valid)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.beauty-fluent-step-three-account__main {
  max-width: 648px;
  margin-bottom: 48px;
  p {
    margin-bottom: 0;
  }
  .step-three-form {
    .ant-form-item {
      margin-bottom: 24px;
      .ant-input,
      .ant-input-affix-wrapper,
      .ant-input-password,
      .ant-input-affix-wrapper .ant-input {
        height: 55px;
        border-radius: 100px;
      }
    }
    .form-item-name {
      display: flex;
      justify-content: space-between;
    }
  }
  .billing-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    font-family: "Niveau Grotesk";
  }
  .password-style {
    position: relative;
    .eye-style {
      position: absolute;
      top: 11px;
      right: 19px;
      z-index: 10;
      cursor: pointer;
    }
    .has-color {
      color: #f27c59;
    }
  }
}
</style>
