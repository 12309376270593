<template>
  <div class="admin-retailer-dirty">
    <a-row>
      <a-col :span="14">
        <p class="admin-retailer-dirty__table-name">
          {{ $t("Admins.AdminRetailerDirtyIngredient.dirtyIngredient") }}
        </p>
        <p class="admin-retailer-dirty__table-sub-name">
          {{ $t("Admins.AdminRetailerDirtyIngredient.desc") }}
        </p>
      </a-col>
      <a-col :span="10">
        <button class="button admin-retailer-dirty__add-retailer" @click="addRetailer">
          {{ $t("Admins.AdminRetailerDirtyIngredient.addCategory") }}
        </button>
      </a-col>
    </a-row>
    <a-row id="dirtyCategoryIngredientPanel">
      <a-collapse
        expand-icon-position="right"
        :bordered="false"
        accordion
        @change="changeActiveKey"
      >
        <template #expandIcon="props">
          <a-icon type="down" :rotate="props.isActive ? 180 : 0" />
        </template>
        <a-collapse-panel
          v-for="(item, index) in adminRetailerDirtyIngredient.list"
          :key="index"
          class="admin-retailer-dirty__col-panel"
        >
          <template #header>
            <div class="panel_header">
              <a-row v-if="!editCategoryId || editCategoryId !== item.category.privateId">
                <a-col :span="8">
                  <span>{{ item.category.name }}</span>
                </a-col>
                <a-col :span="8">
                  <button
                    class="button admin-retailer-dirty__btn_border-black"
                    @click.stop="clickEditCategory(item.category)"
                  >
                    {{ $t("Admins.AdminRetailerMargin.edit") }}
                  </button>
                  <button
                    class="button admin-retailer-dirty__btn_border-black"
                    @click.stop="clickRemoveCategory(item)"
                  >
                    {{ $t("Admins.AdminRetailerMargin.remove") }}
                  </button>
                </a-col>
              </a-row>
              <a-row v-else>
                <a-col :span="8">
                  <input
                    v-model="editCategoryName"
                    class="input"
                    type="text"
                    :class="{ 'is-danger': $v.editCategoryName.$error }"
                  />
                  <p v-if="!$v.editCategoryName.required" class="help is-danger">
                    Field is required
                  </p>
                </a-col>
                <a-col :span="8" class="admin-retailer-dirty__btn_layer">
                  <button
                    class="button admin-retailer-dirty__btn_black-white"
                    :class="{ 'disable-save': isSavingCategory }"
                    :disable="isSavingCategory"
                    @click.stop="saveCategory(item.category)"
                  >
                    {{ $t("Admins.AdminRetailerMargin.save") }}
                  </button>
                  <button
                    class="button admin-retailer-margin__btn_border-black"
                    @click.stop="cancelEditCategory"
                  >
                    {{ $t("Admins.AdminRetailerMargin.cancel") }}
                  </button>
                </a-col>
              </a-row>
            </div>
          </template>
          <div>
            <a-row
              v-for="(dirtyIngredient, i) in item.categoryIngredients"
              :key="`dirtyIngredient_${i}`"
              class="admin-retailer-dirty__panel-content-row"
            >
              <a-col :span="8">
                Dirty Ingredient:
              </a-col>
              <a-col :span="8">
                <span
                  v-if="
                    !editDirtyIngredient ||
                      dirtyIngredient.privateId !== editDirtyIngredient.privateId
                  "
                  >{{ dirtyIngredient.ingredientId }}</span
                >
                <a-select
                  v-else
                  v-model="modifyDirtyIngredient"
                  size="large"
                  show-search
                  style="width: 100%"
                  placeholder="Please select ingredient"
                  :not-found-content="isLoadingIngredients ? undefined : null"
                  :filter-option="false"
                  @getPopupContainer="getPopupNode"
                  @search="searchIngredients"
                  @change="selectIngredients"
                >
                  <a-spin v-if="isLoadingIngredients" slot="notFoundContent" size="small" />
                  <div slot="dropdownRender" slot-scope="menu">
                    <v-nodes :vnodes="menu" />
                    <!--                    <a-divider style="margin: 4px 0;" />-->
                    <div
                      v-if="searchIngredientPage.curPage < searchIngredientPage.pages"
                      class="admin-retailer-dirty__load-more"
                      @mousedown="e => e.preventDefault()"
                      @click="loadMoreIngredients"
                    >
                      <a-divider style="margin: 4px 0;" />
                      <span> Load More</span>
                      <a-icon type="redo" />
                    </div>
                  </div>
                  <a-select-option v-for="item in ingredientsTag" :key="item.privateId">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="8" class="admin-retailer-dirty__btn_layer">
                <button
                  v-if="
                    !editDirtyIngredient ||
                      dirtyIngredient.privateId !== editDirtyIngredient.privateId
                  "
                  class="button admin-retailer-dirty__btn_border-black"
                  @click="clickEditDirtyIngredient(dirtyIngredient)"
                >
                  {{ $t("Admins.AdminRetailerMargin.edit") }}
                </button>
                <div v-else>
                  <button
                    class="button admin-retailer-dirty__btn_black-white"
                    :class="{ 'disable-save': isSavingDirtyItem }"
                    :disable="isSavingDirtyItem"
                    @click="saveEditDirtyIngredient"
                  >
                    {{ $t("Admins.AdminRetailerMargin.save") }}
                  </button>
                  <button
                    class="button admin-retailer-margin__btn_border-black"
                    @click="cancelEditDirtyIngredient"
                  >
                    {{ $t("Admins.AdminRetailerMargin.cancel") }}
                  </button>
                </div>
                <button
                  v-if="
                    !editDirtyIngredient ||
                      dirtyIngredient.privateId !== editDirtyIngredient.privateId
                  "
                  class="button admin-retailer-dirty__btn_border-black"
                  @click="clickRemoveDirtyIngredient(dirtyIngredient)"
                >
                  {{ $t("Admins.AdminRetailerMargin.remove") }}
                </button>
              </a-col>
            </a-row>
            <a-row
              v-if="isAddNewDirtyIngredient && newCategoryId === item.category.privateId"
              class="admin-retailer-dirty__panel-content-row"
            >
              <a-col :span="8">
                Add New Dirty Ingredient:
              </a-col>
              <a-col :span="8">
                <a-select
                  size="large"
                  show-search
                  style="width: 100%"
                  placeholder="Please select ingredient"
                  :filter-option="false"
                  :not-found-content="isLoadingIngredients ? undefined : null"
                  @getPopupContainer="getPopupNode"
                  @search="searchIngredients"
                  @change="selectNewIngredient"
                >
                  <a-spin v-if="isLoadingIngredients" slot="notFoundContent" size="small" />
                  <div slot="dropdownRender" slot-scope="menu">
                    <v-nodes :vnodes="menu" />
                    <div
                      v-if="searchIngredientPage.curPage < searchIngredientPage.pages"
                      class="admin-retailer-dirty__load-more"
                      @mousedown="e => e.preventDefault()"
                      @click="loadMoreIngredients"
                    >
                      <a-divider style="margin: 4px 0;" />
                      <span> Load More </span>
                      <a-icon type="redo" />
                    </div>
                  </div>
                  <a-select-option v-for="item in ingredientsTag" :key="item.privateId">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="8">
                <div class="admin-retailer-dirty__btn_layer">
                  <button
                    class="button admin-retailer-dirty__btn_black-white"
                    :class="{ 'disable-save': isSavingDirtyItem }"
                    :disable="isSavingDirtyItem"
                    @click="saveNewDirtyIngredient"
                  >
                    {{ $t("Admins.AdminRetailerMargin.save") }}
                  </button>
                  <button
                    class="button admin-retailer-margin__btn_border-black"
                    @click="cancelNewDirtyIngredient"
                  >
                    {{ $t("Admins.AdminRetailerMargin.cancel") }}
                  </button>
                </div>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="20"></a-col>
              <a-col :span="4" style="text-align: right;">
                <a-tooltip placement="topRight">
                  <template slot="title">
                    <span>
                      Add Ingredients
                    </span>
                  </template>
                  <button
                    type="button"
                    class="admin-retailer-dirty__add-float-btn"
                    @click="addNewDirtyIngredient(item)"
                  >
                    <span class="admin-retailer-dirty__add-float-content">
                      <a-icon
                        type="plus"
                        style="width: 20px;height: 20px;font-size: 18px;margin-left: 2px;"
                      />
                    </span>
                  </button>
                </a-tooltip>
              </a-col>
            </a-row>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </a-row>
    <a-row v-if="isAddNew" id="newRetailer">
      <a-col
        :span="12"
        class="admin-retailer-dirty__no-padding-bottom admin-retailer-dirty__no-padding-top"
      >
        <input
          v-model="$v.newCategoryName.$model"
          class="input"
          type="text"
          :class="{ 'is-danger': $v.newCategoryName.$error }"
        />
        <p v-if="!$v.newCategoryName.required" class="help is-danger">
          Field is required
        </p>
      </a-col>
      <a-col :span="12" class="admin-retailer-dirty__btn_layer">
        <button
          class="button admin-retailer-dirty__btn_black-white admin-retailer-dirty__margin-top"
          :class="{ 'disable-save': isSavingNew }"
          :disable="isSavingNew"
          @click="saveNewRetailer"
        >
          {{ $t("Admins.AdminRetailerMargin.save") }}
        </button>
        <button
          class="button admin-retailer-dirty__btn_border-black admin-retailer-dirty__margin-top"
          @click="cancelAddRetailer"
        >
          {{ $t("Admins.AdminRetailerMargin.cancel") }}
        </button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators"
export default {
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },

  validations: {
    newCategoryName: { required },
    newDirtyIngredient: { required },
    modifyDirtyIngredient: { required },
    editCategoryName: { required }
  },

  data() {
    return {
      isAddNew: false,
      isSavingNew: false,
      isSavingCategory: false,
      isSavingDirtyItem: false,
      newCategoryName: "",
      editCategoryId: undefined,
      editCategoryName: "",
      editDirtyIngredient: undefined,
      modifyDirtyIngredient: undefined,
      searchIngredientValue: "",
      isLoadingIngredients: false,
      newDirtyIngredient: undefined,
      newCategoryId: undefined,
      isAddNewDirtyIngredient: false,
      ingredientsMaxTextlen: 50,
      totalCount: 1,
      totalPage: 1,
      activeProducts: [],
      ingredientsTag: []
    }
  },
  computed: {
    adminRetailerDirtyIngredient() {
      return this.$store.getters["cleanPreCheck/allCategoryIngredients"]
    },
    searchIngredientPage() {
      let data = this.$store.getters["product/ingredientsTag"]
      return { total: data.total, pages: data.pages, curPage: data.index }
    }
  },
  async created() {
    this.getCategoryDirtyIngredient(1)
    this.searchIngredients("")
  },
  updated() {
    this.scrollToNew()
  },
  methods: {
    async getCategoryDirtyIngredient(page) {
      await this.$store.dispatch("cleanPreCheck/fetchAllCategoryIngredientsByName", {
        "page-index": page
      })
    },
    changeActiveKey() {
      this.searchIngredientValue = ""
      this.modifyDirtyIngredient = undefined
      this.searchIngredients("")
    },
    getPopupNode(triggerNode) {
      return triggerNode.parent
    },
    searchIngredients(value) {
      this.searchIngredientValue = value
      this.isLoadingIngredients = true
      this.ingredientsTag = []
      this.$store
        .dispatch("product/getIngredientsTag", {
          ingredientName: value,
          page: 1
        })
        .then(() => {
          this.ingredientsTag = this.$store.getters["product/ingredientsTag"].list
          this.$forceUpdate()
        })
        .finally(() => {
          this.isLoadingIngredients = false
        })
    },
    selectIngredients(value) {
      this.modifyDirtyIngredient = value
    },
    selectNewIngredient(value) {
      this.newDirtyIngredient = value
    },
    addRetailer() {
      this.isAddNew = true
    },
    scrollToNew() {
      if (this.isAddNew) {
        let eb = document.getElementById("newRetailer")
        if (eb) {
          eb.scrollIntoView()
        }
      }
    },
    cancelAddRetailer() {
      this.isAddNew = false
      this.isSavingNew = false
      this.newCategoryName = ""
    },
    async saveNewRetailer() {
      this.$v.$touch()
      if (this.$v.newCategoryName.$anyError) {
        return
      }
      let param = {
        name: this.newCategoryName
      }
      try {
        await this.$store.dispatch("cleanPreCheck/updateDirtyIngreidentCategory", param)
        this.cancelAddRetailer()
        this.getCategoryDirtyIngredient(1)
      } catch (error) {
        console.log(`error${error}`)
      }
    },
    clickEditCategory(item) {
      this.editCategoryId = item.privateId
      this.editCategoryName = item.name
    },
    clickRemoveCategory(item) {
      if (!item.categoryIngredients || item.categoryIngredients.length === 0) {
        let _this = this
        this.$confirm({
          title: "Do you want to delete this category?",
          onOk() {
            _this.doDeleteCategory(item)
          },
          onCancel() {
            console.log("Cancel")
          }
        })
      } else {
        this.$message.warning(
          `There still have ingredients under the category ${item.category.name}`
        )
        return
      }
    },
    doDeleteCategory(item) {
      this.$store
        .dispatch("cleanPreCheck/deleteCategory", item.category.privateId)
        .then(() => {
          this.$message.success("Delete category Success")
          this.getCategoryDirtyIngredient(1)
        })
        .catch(error => {
          console.log(error)
          this.$message.error(error.message)
        })
    },
    saveCategory(item) {
      if (item.privateId === this.editCategoryId) {
        this.isSavingCategory = true
        let param = {
          privateId: item.privateId,
          name: this.editCategoryName
        }
        this.$store
          .dispatch("cleanPreCheck/updateDirtyIngreidentCategory", param)
          .then(() => {
            this.$message.info("Update category name success")
            this.cancelEditCategory()
            this.getCategoryDirtyIngredient(1)
          })
          .catch(() => {
            this.$message.error("Update category name failed")
          })
          .finally(() => {
            this.isSavingCategory = false
          })
      }
    },
    cancelEditCategory() {
      this.editCategoryName = ""
      this.editCategoryId = undefined
      this.isSavingCategory = false
    },
    clickEditDirtyIngredient(item) {
      this.editDirtyIngredient = item
      let data = this.ingredientsTag.filter(ingre => ingre.privateId === item.ingredientPrivateId)
      if (!data || data.length === 0) {
        this.ingredientsTag.push({
          privateId: item.ingredientPrivateId,
          name: item.ingredientId
        })
      }

      this.modifyDirtyIngredient = item.ingredientPrivateId
    },
    clickRemoveDirtyIngredient(item) {
      let _this = this
      this.$confirm({
        title: "Do you want to delete this ingredient?",
        onOk() {
          _this.doDeleteIngredientFromCategory(item)
        },
        onCancel() {
          console.log("Cancel")
        }
      })
    },
    doDeleteIngredientFromCategory(item) {
      this.$store
        .dispatch("cleanPreCheck/deleteDirtyIngredient", item.privateId)
        .then(() => {
          let data = this.$store.getters["cleanPreCheck/updatedDirtyIngredient"]
          if (!data) {
            this.$message.success("Delete ingredient Success")
            this.getCategoryDirtyIngredient(1)
          } else {
            this.$message.info(data.toString())
            this.getCategoryDirtyIngredient(1)
          }
        })
        .catch(error => {
          console.log(error)
          this.$message.error(error.message)
        })
    },
    saveEditDirtyIngredient() {
      if (this.editDirtyIngredient && this.modifyDirtyIngredient) {
        this.isSavingDirtyItem = true
        let param = {
          privateId: this.editDirtyIngredient.privateId,
          dirtyCategoryPrivateId: this.editDirtyIngredient.dirtyCategoryPrivateId,
          ingredientPrivateId: this.modifyDirtyIngredient
        }
        this.$store
          .dispatch("cleanPreCheck/updateDirtyIngreident", param)
          .then(() => {
            this.$message.info("Update dirty ingredient success")
            this.cancelEditDirtyIngredient()
            this.getCategoryDirtyIngredient(1)
          })
          .catch(() => {
            this.$message.error("Update dirty ingredient failed")
          })
          .finally(() => {
            this.isSavingDirtyItem = false
          })
      }
    },
    cancelEditDirtyIngredient() {
      this.editDirtyIngredient = undefined
      this.modifyDirtyIngredient = undefined
      this.isSavingDirtyItem = false
    },
    saveNewDirtyIngredient() {
      if (this.newDirtyIngredient) {
        let param = {
          dirtyCategoryPrivateId: this.newCategoryId,
          ingredientPrivateId: this.newDirtyIngredient
        }
        this.isSavingDirtyItem = true
        this.$store
          .dispatch("cleanPreCheck/updateDirtyIngreident", param)
          .then(() => {
            this.$message.info("Update dirty ingredient success")
            this.cancelNewDirtyIngredient()
            this.getCategoryDirtyIngredient(1)
          })
          .catch(() => {
            this.$message.error("Update dirty ingredient failed")
          })
          .finally(() => {
            this.isSavingDirtyItem = false
          })
      }
    },
    cancelNewDirtyIngredient() {
      this.newDirtyIngredient = {}
      this.isAddNewDirtyIngredient = false
      this.newCategoryId = undefined
      this.isSavingDirtyItem = false
    },
    addNewDirtyIngredient(item) {
      this.isAddNewDirtyIngredient = true
      this.newCategoryId = item.category.privateId
      this.newDirtyIngredient = {}
    },
    loadMoreIngredients() {
      this.isLoadingIngredients = true
      this.$store
        .dispatch("product/getIngredientsTag", {
          ingredientName: this.searchIngredientValue,
          page: this.searchIngredientPage.curPage + 1
        })
        .then(() => {
          this.ingredientsTag = this.$store.getters["product/ingredientsTag"].list
        })
        .finally(() => {
          this.isLoadingIngredients = false
        })
    },
    loadMoreCategory() {
      this.getCategoryDirtyIngredient(this.adminRetailerDirtyIngredient.index + 1)
    }
  }
}
</script>

<style lang="sass" scoped>
.v-expansion-panel--active>.v-expansion-panel-header
  min-height: 50px !important
.v-expansion-panel-content__wrap
  padding: 0 0px 10px !important
.row
  margin-left: 0px !important
  margin-right: 0px !important
.admin-retailer-dirty
  padding: 16px 10%
  margin-bottom: 100px
  &__margin-top
    margin: 4px 0px !important

  &__table-name
    color: #000000
    font-size: 20px
    font-weight: 700
    letter-spacing: 0.25px
    padding-left: 15px

  &__table-sub-name
    color: #9E9E9E !important
    font-size: 12px
    font-weight: 500
    padding-top: 10px
    padding-left: 15px

  &__add-retailer
    width: 130px
    height: 30px
    float: right
    text-align: center
    background: #716B68
    font-size: 14px
    font-weight: bold
    color: #eeeeee

  &__expansion-head
    font-weight: bold
    font-size: 12px

  &__expansion-head>div
    margin-left: auto
  &__text-percent
    text-align: center
    font-size: 14px !important
    width: 100%
  &__text-center
    text-align: left
    font-size: 14px !important
  &__text-right
    text-align: right
    font-size: 14px !important
    margin-bottom: 0px !important
    width: 100%
    padding-right: 4px

  &__col-cell
    display: flex
    text-align: left
    color: #333333 !important
    padding: 4px !important
    align-items: center
  &__col-col-cell
    display: flex
    align-items: center
  &__compobox-cell
    font-size: 14px !important

  &__filter-column-name
    color: #292929
    font-size: 16px
    font-weight: 300
    margin-bottom: 15px

  &__border
    border-bottom: 1px solid #E0E0E0

  &__no-margin-bottom
    margin: 0px !important


  &__compbox-left-block
    float: left
    display: block
    padding: 0.4em 1em
    vertical-align: middle
    margin: 10px !important


  &__btn-right
    float: right
    display: flex
    justify-content: end
    justify-content: flex-end
    font-size: 12px
    padding: 10px
    margin: 10px

  &__no-padding-bottom
    padding-bottom: 0px !important

  &__no-padding-top
    padding-top: 0px !important

  &__no-padding-top-bottom
    padding: 0px 0px !important

  &__margin-right
    margin-right: 10px

  &__btn_border-grad
    margin-right: 10px
    width: 70px
    height: 30px
    border: 1px solid #D9D9D9
    box-sizing: border-box
    border-radius: 2px
    font-size: 12px
    font-weight: bold
    color: #595959
  &__btn_layer
    text-align: right
    padding-right: 30px
  &__btn_border-black
    margin-right: 10px !important
    width: 70px
    height: 30px
    border: 1px solid #D9D9D9
    box-sizing: border-box
    border-radius: 2px
    font-size: 12px
    font-weight: bold
    color: #000000
    align-items: center

  &__btn_black-white
    color: #ffffff
    margin-right: 10px !important
    width: 70px
    height: 30px
    background: #000000
    align-items: center
    font-size: 12px
    font-weight: bold
  &__btn_black-white:hover
    color: #ffffff !important

  &__btn_black-white:focus
    background: #000000
    color: #ffffff
  &__padding
    padding: 0px 0px !important

  &__margin-left
    margin-left: 16px !important
  &__margin-left-eight
    margin-left: 8px !important
  &__ctn-width
    width: 40%
    text-align: right
  &__new-retailer-select
    width: 60% !important
  &__panel-top-line
    border-top: 1px solid #d9d9d9
  &__panel-content-row
    border-top: 1px solid #D9D9D9
    padding-top: 6px
    padding-bottom: 6px
  &__padding-left
    padding-left: 42px !important
  &__add-float-btn
    font-size: .75rem
    color: #ffffff
    height: 25px
    width: 25px
    bottom: 0px
    right: 16px
    background: #272727
    border-radius: 50%
    z-index: 4
    box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%)
  &__add-float-content
    align-items: center
    position: relative
    justify-content: inherit
    display: flex
    color: inherit
  &__load-more
    padding: 4px 8px
    cursor: pointer
    text-align: center

#dirtyCategoryIngredientPanel .ant-collapse-borderless
  background: #FFFFFF
#dirtyCategoryIngredientPanel .ant-collapse-borderless .ant-collapse-item
  border-top: 1px solid #D9D9D9 !important
  border-bottom: none !important
#dirtyCategoryIngredientPanel .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header
  padding: 4px 16px !important
  display: block
.admin-retailer-dirty__col-panel:before
  z-index: -1 !important
</style>
<style lang="sass">
#dirtyCategoryIngredientPanel .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header
  display: block
</style>
