<template>
  <div ref="adminTrainingBrandProduct" class="admin-training-brand-product__main">
    <!-- <div class="admin-training-brand-product__title">
      {{ brandName }}
    </div> -->
    <a-row>
      <a-col :span="20"></a-col>
      <a-col :span="4" style="text-align: right;">
        <a-button class="admin-training-landing-red-btn" @click="ViewAllProduct">View All</a-button>
      </a-col>
    </a-row>
    <a-table
      :columns="tableColumn"
      :data-source="tableData"
      size="middle"
      style="margin-top: 40px;"
      class="admin-training-table"
      :row-class-name="
        (_recode, index) => (index % 2 === 1 ? 'admin-training-table-striped' : null)
      "
      :pagination="false"
      :loading="isLoading"
      @change="handleName"
    >
      <span slot="nameTitle">
        Name
        <a-icon
          type="caret-up"
          :rotate="sortName.toString().length > 0 && sortName === 'descend' ? 180 : 0"
          :class="
            sortName.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('name')"
        />
      </span>
      <span slot="heroTitle">
        Hero
        <a-icon
          type="caret-up"
          :rotate="sortHero.toString().length > 0 && sortHero === 'descend' ? 180 : 0"
          :class="
            sortHero.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('hero')"
        />
      </span>
      <span slot="skuTitle">
        SKU
        <a-icon
          type="caret-up"
          :rotate="sortSku.toString().length > 0 && sortSku === 'descend' ? 180 : 0"
          :class="
            sortSku.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('sku')"
        />
      </span>
      <span slot="upcTitle">
        UPC
        <a-icon
          type="caret-up"
          :rotate="sortUpc.toString().length > 0 && sortUpc === 'descend' ? 180 : 0"
          :class="
            sortUpc.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('upc')"
        />
      </span>
      <span slot="modifyTitle">
        Modified
        <a-icon
          type="caret-up"
          :rotate="sortModify.toString().length > 0 && sortModify === 'descend' ? 180 : 0"
          :class="
            sortModify.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('modified')"
        />
      </span>
      <span slot="hero" slot-scope="text, record">
        <span
          v-if="record.training != null && record.training.hero"
          class="admin-training-brand-product__hero-product-position"
          >Hero</span
        >
        <span v-else></span>
      </span>
      <span slot="skuOper" slot-scope="text">
        {{ text }}
        <!-- <span
          v-if="record.training.hero"
          class="admin-training-brand-product__hero-product-position"
          >H</span
        > -->
      </span>
      <div slot="draftOper" slot-scope="text">
        <div v-if="text == 0" class="admin-training-brand-product__draft-product">Draft</div>
      </div>
      <span slot="publicOper" slot-scope="text, record">
        <a-switch
          :checked="text == 2"
          class="admin-training-switch"
          :disabled="text <= 0"
          @change="isChecked => onChangePublic(isChecked, record)"
        />
      </span>
      <span
        slot="actionOper"
        slot-scope="text, record"
        class="admin-training-brand-product__action"
        @click="clickToView(record)"
      >
        {{ record.public > 0 ? "View" : "Edit" }}
      </span>
    </a-table>
    <AdminProductTrainingDialog
      ref="adminProductDialog"
      @updateNewTrainingList="updateProductList"
    />
    <productViewDialogNew ref="productViewDialogNew"></productViewDialogNew>
    <a-modal
      :visible="showVisible"
      :footer="null"
      :destroy-on-close="true"
      :body-style="{ padding: 15 }"
      :get-container="() => $refs.adminTrainingBrandProduct"
      @cancel="handleCancel"
    >
      <div class="can-not-create-training">
        <p class="title-error-text">
          <a-icon
            type="exclamation-circle"
            style="color: #faad14; margin-right: 5px;font-size: 18px"
          />Please modify the details of the product
        </p>
        <p>{{ contentText }}</p>
        <div class="button-style">
          <a-button class="btn-style" @click="jumpToBrandProduct">
            <!--            {{ this.$t("Training.brandTraining.createButton") }}-->
            To modify
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import trainingClient from "@/api/training-client"
import AdminProductTrainingDialog from "@/components/admin/AdminProductTrainingDialog"
import productViewDialogNew from "@/components/training/__components/productViewDialogNew"

export default {
  components: {
    AdminProductTrainingDialog,
    productViewDialogNew
  },
  data() {
    return {
      showVisible: false,
      contentText: "",
      contentLinkId: "",
      tableColumn: [
        {
          //title: "Name",
          dataIndex: "name",
          key: "name",
          slots: { title: "nameTitle" }
          // sorter: true
        },
        {
          //title: "Hero",
          dataIndex: "hero",
          key: "hero",
          // sorter: true,
          slots: { title: "heroTitle" },
          scopedSlots: { customRender: "hero" }
        },
        {
          //title: "SKU",
          dataIndex: "sku",
          key: "sku",
          // sorter: true,
          slots: { title: "skuTitle" },
          scopedSlots: { customRender: "skuOper" }
        },
        {
          //title: "UPC",
          dataIndex: "upc",
          key: "upc",
          slots: { title: "upcTitle" }
          // sorter: true
        },
        {
          //title: "Modified",
          dataIndex: "modified",
          key: "modified",
          slots: { title: "modifyTitle" }
          // sorter: true
        },
        {
          title: "",
          dataIndex: "public",
          key: "draft",
          scopedSlots: { customRender: "draftOper" }
        },
        {
          title: "Public",
          dataIndex: "public",
          key: "public",
          scopedSlots: { customRender: "publicOper" }
        },
        {
          title: "Actions",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "actionOper" }
        }
      ],
      brandId: undefined,
      brandName: undefined,
      tableData: undefined,
      tablePageData: undefined,
      pageSize: 9999,
      currentPage: 1,
      isSort: false,
      isLoading: false,
      sortName: "",
      sortHero: "",
      sortSku: "",
      sortUpc: "",
      sortModify: "",
      brand: {}
    }
  },
  computed: {
    ...mapGetters({
      getTrainingProducts: "product/getProductPageData"
    }),
    role() {
      return this.$store.getters["user/role"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    }
  },
  watch: {
    getTrainingProducts() {
      this.setTableData()
    }
  },
  async created() {
    this.brandId = this.$route.params.brandId
    // this.brandName = this.$route.params.name
    // this.shortName = this.$route.params.shortName
    // if (!this.brandName) {
    this.$store.dispatch("brand/getBrand", this.brandId).then(() => {
      this.brand = this.$store.getters["brand/brand"]
      this.brandName = this.brand.name
      this.shortName = this.brand.shortName
    })
    // }
    this.isLoading = true
    await this.getProductList(this.getInitParam())
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      getProductList: "product/getProductsByPage"
    }),
    async sortByField(fieldName) {
      let order = ""
      if (fieldName === "name") {
        if (this.sortName.length === 0) {
          this.sortName = "ascend"
        } else if (this.sortName === "ascend") {
          this.sortName = "descend"
        } else {
          this.sortName = "ascend"
        }
        this.sortHero = ""
        this.sortSku = ""
        this.sortUpc = ""
        this.sortModify = ""
        order = this.sortName
      } else if (fieldName === "sku") {
        if (this.sortSku.length === 0) {
          this.sortSku = "ascend"
        } else if (this.sortSku === "ascend") {
          this.sortSku = "descend"
        } else {
          this.sortSku = "ascend"
        }
        this.sortHero = ""
        this.sortName = ""
        this.sortUpc = ""
        this.sortModify = ""
        order = this.sortSku
      } else if (fieldName === "upc") {
        if (this.sortUpc.length === 0) {
          this.sortUpc = "ascend"
        } else if (this.sortUpc === "ascend") {
          this.sortUpc = "descend"
        } else {
          this.sortUpc = "ascend"
        }
        this.sortHero = ""
        this.sortSku = ""
        this.sortName = ""
        this.sortModify = ""
        order = this.sortUpc
      } else if (fieldName === "hero") {
        if (this.sortHero.length === 0) {
          this.sortHero = "ascend"
        } else if (this.sortHero === "ascend") {
          this.sortHero = "descend"
        } else {
          this.sortHero = "ascend"
        }
        this.sortName = ""
        this.sortSku = ""
        this.sortUpc = ""
        this.sortModify = ""
        order = this.sortHero
      } else if (fieldName === "modified") {
        if (this.sortModify.length === 0) {
          this.sortModify = "ascend"
        } else if (this.sortModify === "ascend") {
          this.sortModify = "descend"
        } else {
          this.sortModify = "ascend"
        }
        this.sortHero = ""
        this.sortSku = ""
        this.sortUpc = ""
        this.sortName = ""
        order = this.sortModify
      }
      if (fieldName == "hero") {
        this.sortByHero(order === "ascend")
      } else {
        let params = this.getBaseParam(1)
        params.params = Object.assign({}, params.params, {
          "_sort-by": fieldName,
          "_order-by": order === "ascend" ? "PREVIOUS" : "NEXT"
        })
        this.isLoading = true
        await this.getProductList(params)
      }
    },
    async handleName(pagination, filters, sorter) {
      this.isSort = true
      let index = 0
      if (sorter.field === "name") {
        index = 0
      } else if (sorter.field === "sku") {
        index = 2
      } else if (sorter.field === "upc") {
        index = 3
      } else if (sorter.field === "hero") {
        index = 1
      } else if (sorter.field === "modified") {
        index = 4
      }
      if (!sorter.order) {
        sorter.order = "ascend"
      }
      this.tableColumn[index].sortOrder = sorter.order
      for (let i = 0; i < 5; i++) {
        if (i !== index) {
          this.tableColumn[i].sortOrder = false
        }
      }
      if (sorter.field == "hero") {
        this.sortByHero(sorter.order === "ascend")
      } else {
        let params = this.getBaseParam(1)
        params.params = Object.assign({}, params.params, {
          "_sort-by": sorter.field,
          "_order-by": sorter.order === "ascend" ? "PREVIOUS" : "NEXT"
        })
        this.isLoading = true
        await this.getProductList(params)
      }
    },
    setTableData() {
      this.isLoading = false
      this.tableData =
        this.getTrainingProducts == null ||
        (this.getTrainingProducts != null && !this.getTrainingProducts.products)
          ? []
          : this.getTrainingProducts.products.slice(0)
      this.tablePageData = this.getTrainingProducts == null ? {} : this.getTrainingProducts
    },
    getProductListByPage(page) {
      this.getProductList(this.getBaseParam(page))
    },
    getInitParam() {
      let param = this.getBaseParam(1)
      param.params = Object.assign({}, param.params, {
        "_sort-by": "name",
        "_order-by": "PREVIOUS"
      })
      return param
    },
    getBaseParam(page) {
      const param = {
        _index: page,
        _limit: this.pageSize,
        brand: this.brandId,
        "is-download-csv": false
      }
      return { params: param }
    },
    sortByHero(asc) {
      this.tableData.sort((a, b) => {
        if (asc) {
          if (a.heroValue > b.heroValue) {
            return -1
          } else if (a.heroValue < b.heroValue) {
            return 1
          } else {
            return 0
          }
        } else {
          if (a.heroValue > b.heroValue) {
            return 1
          } else if (a.heroValue < b.heroValue) {
            return -1
          } else {
            return 0
          }
        }
      })
      const publicList = []
      const privateList = []
      const draftList = []
      const noTrainingList = []
      this.tableData.forEach(item => {
        if (item.public == 2) {
          publicList.push(item)
        } else if (item.public == 1) {
          privateList.push(item)
        } else if (item.public == 0) {
          draftList.push(item)
        } else {
          noTrainingList.push(item)
        }
      })
      this.tableData = []
      this.tableData.push(...publicList)
      this.tableData.push(...privateList)
      this.tableData.push(...draftList)
      this.tableData.push(...noTrainingList)
    },
    async updateProductList() {
      await this.getProductList(this.getInitParam())
      this.setTableData()
    },
    async onChangePublic(isChecked, value) {
      let statesValue = value.public
      if (!isChecked) {
        if (value.public === 2) {
          statesValue = 1
        }
      } else {
        if (value.public === 1) {
          statesValue = 2
        }
      }
      const param = {
        training: {
          demoTips: value.training.demoTips,
          isHero: value.training.hero,
          howToDemo: value.training.howToDemo,
          id: value.training.id,
          isPublished: true,
          states: statesValue,
          sort: value.training.sort
        },
        trainingBestUsedFors: value.trainingBestUsedFors,
        trainingVideos: value.trainingVideos,
        trainingCrossSellers: value.trainingCrossSellers,
        trainingSteps: value.trainingSteps
      }
      trainingClient.updateTrainingProduct(value.id, param).then(() => (value.public = statesValue))
      //this.updateProductList()
    },
    async ViewAllProduct() {
      // this.$router.push({
      //   name: "TrainingProductViewAll",
      //   query: {
      //     id: this.brandId,
      //     name: this.brandName
      //   }
      // })
      //   console.log(`filter start: ${new Date().getTime()}`)
      //   let list = this.tableData.filter(item => item.public >= 0)
      //   console.log(`filter end: ${new Date().getTime()}`)
      await this.updateProductList()
      let list = this.$store.getters["product/brandTrainingProducts"]
      list = list.filter(
        item => item.training && item.training.states === 2 && item.training.published
      )
      //   console.log(`get traing ${aa}, ${new Date().getTime()}`)
      this.$refs["productViewDialogNew"].openProductDialog(list, this.brandName, this.brand)
    },
    handleCancel() {
      this.showVisible = false
    },
    jumpToBrandProduct() {
      const brandId = this.$route.params.brandId
      this.$router.push({
        path: `/admin/brand/${brandId}/products/${this.contentLinkId}`
      })
    },
    showModalNoInfo(text, id) {
      this.showVisible = true
      this.contentText = text
      this.contentLinkId = id
    },
    checkProductInfo(productItem) {
      if (productItem.training && Object.keys(productItem.training).length !== 0) {
        return true
      }
      let errorText
      if (!productItem.size || Object.keys(productItem.sizeUnits).length === 0) {
        errorText = "product Size or sizeUnits is required Field"
        this.showModalNoInfo(errorText, productItem.id)
        return false
      } else if (!productItem.newBenefits || productItem.newBenefits.length === 0) {
        errorText = "Benefits is required Field"
        this.showModalNoInfo(errorText, productItem.id)
        return false
      } else if (!productItem.msrpUsd || !productItem.name) {
        errorText = "MsrpUsd or name is required Field"
        this.showModalNoInfo(errorText, productItem.id)
        return false
      } else if (!productItem.images || productItem.images.length === 0) {
        errorText = "Product image is required Field"
        this.showModalNoInfo(errorText, productItem.id)
        return false
        // } else if (!productItem.newIngredients || productItem.newIngredients.length === 0) {
        //   errorText = "Ingredients is required Field"
        //   this.showModalNoInfo(errorText, productItem.id)
        //   return false
      } else if (
        Object.keys(productItem.subcategory).length === 0 ||
        Object.keys(productItem.countryOfOrigin).length === 0
      ) {
        errorText = "subcategory or countryOfOrigin is required Field"
        this.showModalNoInfo(errorText, productItem.id)
        return false
      } else if (!productItem.description) {
        errorText = "description is required Field"
        this.showModalNoInfo(errorText, productItem.id)
        return false
      } else {
        return true
      }
    },
    clickToView(record) {
      if (!this.checkProductInfo(record)) {
        return
      }
      // if (record.public >= 0) {
      //   this.$refs["adminProductDialog"].openDialog(record)
      // } else {
      //   console.log("show add training dialog")
      // }
      let param
      let isNewTraining = false
      if (record.public < 0) {
        isNewTraining = true
        param = {
          ...record,
          training: {
            hero: false
          }
        }
      } else {
        param = {
          ...record
        }
      }
      this.$refs["adminProductDialog"].openDialog(param, isNewTraining)
    }
  }
}
</script>
<style lang="scss">
.admin-training-brand-product {
  font-style: normal;
  font-weight: normal;
  &__hero-product-position {
    padding: 3px 8px;
    color: #ffffff;
    font-size: 12px;
    background-color: #f3ca50;
    border-radius: 20px;
  }
  &__draft-product {
    margin-right: 20px;
    padding: 3px 8px;
    color: #ffffff;
    font-size: 12px;
    background-color: #8c8c8c;
    border-radius: 20px;
    float: right;
  }
  &__title {
    text-align: center;
    color: black;
    font-size: 16px;
  }
  &__action {
    cursor: pointer;
    color: #4a8a5d;
  }
}
.admin-training-brand-product__main {
  .can-not-create-training {
    padding: 15px;
    text-align: left;
    font-size: 15px;
    .title-error-text {
      font-weight: bold;
    }
    .button-style {
      text-align: right;
    }
    .btn-style {
      background: #9fd19a;
      border: 1px solid #9fd19a;
      color: #ffffff;
    }
    .btn-style:hover {
      background: #4a8a5d;
      border: 1px solid #4a8a5d;
      color: #ffffff;
    }
  }
}
</style>
