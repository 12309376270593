import { render, staticRenderFns } from "./retailerUsersTab.vue?vue&type=template&id=5615f326&scoped=true&"
import script from "./retailerUsersTab.vue?vue&type=script&lang=js&"
export * from "./retailerUsersTab.vue?vue&type=script&lang=js&"
import style0 from "./retailerUsersTab.vue?vue&type=style&index=0&id=5615f326&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5615f326",
  null
  
)

export default component.exports