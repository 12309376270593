import Vue from "vue"
import VueCarousel from "vue-carousel"
import Vuelidate from "vuelidate"
import App from "@/App.vue"
import router from "@/routes/router"
import store from "@/store/"
import Notifications from "vue-notification"
import firebase from "@/services/firebase-service"
import { VueMaskDirective } from "v-mask"
import PagePlaceholder from "@/components/PagePlaceholder"
import Vuesax from "vuesax"
import vuetify from "@/plugins/vuetify"
import Buefy from "buefy"
import VuejsClipper from "vuejs-clipper"
import "vuesax/dist/vuesax.css"
import "material-icons/iconfont/material-icons.css"
import "@mdi/font/css/materialdesignicons.css"
import i18n from "./i18n"
import Antd from "ant-design-vue"
import "ant-design-vue/dist/antd.css"
import "@/icons" // global svg
import "flag-icon-css/css/flag-icon.css"
import htmlToPdf from "@/utils/htmlToPdf"
import httpRequestor from "@/api/axios"

Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.use(Vuesax)
Vue.use(VueCarousel)
Vue.use(VuejsClipper)
Vue.use(Antd)
Vue.use(htmlToPdf)
Vue.use(Buefy)
Vue.component("PagePlaceholder", PagePlaceholder)
Vue.config.productionTip = false

// TODO: move to isolated file
Vue.directive("click-outside", {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener("click", el.clickOutsideEvent)
  },
  unbind: function(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent)
  }
})

Vue.directive("mask", VueMaskDirective)

// const i18n = new VueI18n({
//   locale: "en", // set locale
//   messages // set locale messages
// })
const vapkey =
  "BHwBTpjd1urJf7duCLvbrDonnP12eEqGlnsUV0GRtoJPy5KDT1nZ8WnS929xR0DPynZUuBcDvg_-8GOkTEMDUBQ"
Vue.prototype.$firebaseMessaging = firebase.messaging()
requestPermission()
let vue = null
firebase.auth().onAuthStateChanged(async user => {
  // console.log("user===========", user)
  await store.dispatch("user/setUser", user)

  if (!vue) {
    vue = new Vue({
      i18n,
      router,
      store,
      vuetify,
      icons: {
        iconfont: "mdi" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
      },
      theme: {
        dark: true
      },
      render: h => h(App)
    }).$mount("#app")
  }
  if (user) {
    getFirebaseMessageToken()
  }
})
router.afterEach(() => {
  window.scrollTo(0, 0)
})
// onMessage(getMessaging(), payload => {
//   showNotification(payload)
// })
// onBackgroundMessage(messaging, payload => {
//   showNotification(payload)
// })
// function showNotification(payload) {
//   console.log("[firebase-messaging-sw.js] Received background message ", payload)
//   // Customize notification here
//   const notificationTitle = "Background Message Title"
//   const notificationOptions = {
//     body: "Background Message body.",
//     icon: "/firebase-logo.png"
//   }
// self.registration.showNotification(notificationTitle,
//   notificationOptions)
//   console.log(notificationTitle)
//   console.log(notificationOptions)
// }
function getFirebaseMessageToken() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/firebase-service-sw.js").then(registration => {
      console.log("Registration successful, scope is:", registration.scope)
      firebase
        .messaging()
        .getToken({ vapidKey: vapkey, serviceWorkerRegistration: registration })
        .then(token => {
          console.log("..get token...")
          console.log(token)
          if (token) {
            console.log("......send to server....")
            console.log(token)
            store.commit("adminMessage/SET_FIRBASE_TOKEN", token)
            firebase.messaging().onMessage(message => {
              console.log(".... on message....")
              console.log(message)
            })
            // firebase.messaging().onBackgroundMessage(message => {
            //   console.log('....on Background message')
            //   console.log(message)
            // })
            // firebase.messaging().setBackgroundMessageHandler(message => {
            //   console.log('....setBackground handler....')
            //   console.log(message)
            // })
          } else {
            console.log("....not get token....")
          }
        })
        .catch(err => {
          console.log(err)
        })
    })
  }
}

function requestPermission() {
  // [START messaging_request_permission]
  console.log("....request permission.....")
  Notification.requestPermission().then(permission => {
    if (permission === "granted") {
      console.log("Notification permission granted.")
      // TODO(developer): Retrieve a registration token for use with FCM.
      // ...
    } else {
      console.log("Unable to get permission to notify.")
    }
  })
  // [END messaging_request_permission]
}
/***********The global variable**********/
window.httpRequestor = httpRequestor

export const eventBus = new Vue()
