<template>
  <div>
    <div v-for="item in cardInfoList" :key="item.privateId" class="new-card-list__card-border">
      <card-number :card-info="item" class="new-card-list__row-margin" />
      <a-row>
        <a-col :span="12">
          <p class="new-card-list__other-title">{{ $t("Components.Brands.Payment.nameOnCard") }}</p>
          <p class="new-card-list__other-text">{{ item.cardHolderName }}</p>
        </a-col>
        <a-col :span="12" class="new-card-list__content-right">
          <p class="new-card-list__other-title">
            {{ $t("Components.Brands.Payment.expiredDateDesc") }}
          </p>
          <p class="new-card-list__other-text">{{ getExpiredDate(item) }}</p>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="13">
          <a-checkbox
            id="unDefaultBtn"
            :checked="item.default"
            @click="setPaymentMethodAsDefault(item)"
          >
            {{ $t("Components.Brands.Payment.defaultPaymentMethod") }}
          </a-checkbox>
        </a-col>
        <a-col :span="11">
          <a-button type="primary" class="new-card-list__btn" @click="showEditDialog(item)">
            {{ $t("Components.Brands.Payment.editAction") }}
          </a-button>
          <a-button class="new-card-list__btn" @click="showDeleteDialog(item)">
            {{ $t("Components.Brands.Payment.deleteAction") }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-modal v-model="isShowDeleteDialog" :footer="null" :destroy-on-close="true">
        <template #closeIcon>
          <img src="@/assets/img/icon/close-circle.svg" />
        </template>
        <a-row class="new-card-list__row-margin">
          <a-col>
            <span class="new-card-list__dialog-title">
              {{ $t("Components.Brands.Payment.deletePayment") }}
            </span>
          </a-col>
        </a-row>
        <card-number :card-info="selectedItem" class="new-card-list__row-margin" />
        <a-row class="new-card-list__row-margin">
          <a-col>
            <span class="new-card-list__dialog-desc">{{
              $t("Components.Brands.Payment.deletePaymentInfo")
            }}</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-button type="primary" class="new-card-list__btn" @click="doDelete">
              {{ $t("Components.Brands.Payment.removeConfirm") }}
            </a-button>
            <a-button class="new-card-list__btn" @click="hideDeleteDialog">
              {{ $t("Components.Brands.Payment.cancelAction") }}
            </a-button>
          </a-col>
        </a-row>
      </a-modal>
      <a-modal v-model="isShowEditDialog" :footer="null" :destroy-on-close="true" :closable="false">
        <edit-card :selected-item="selectedItem" :brand-id="brandId" @onFinish="hideEditDialog" />
      </a-modal>
    </div>
  </div>
</template>

<script>
import { Row, Col, Button, Modal, Checkbox } from "ant-design-vue"
import CardNumber from "./CardNumber"
import EditCard from "./EditCard"
import Utils from "@/common/Utils"
export default {
  components: {
    "a-row": Row,
    "a-col": Col,
    "a-button": Button,
    "a-modal": Modal,
    "card-number": CardNumber,
    "a-checkbox": Checkbox,
    "edit-card": EditCard
  },
  props: {
    cardInfoList: { type: Array, default: () => [] },
    brandId: { type: String, default: () => undefined }
  },
  data() {
    return {
      dateFormat: "MM/YY",
      isShowDeleteDialog: false,
      isExpired: false,
      isShowUpdateMethod: false,
      isShowEditDialog: false,
      selectedItem: undefined,
      paymentMethodCounts: this.cardInfoList ? this.cardInfoList.length : 0
    }
  },
  watch: {
    cardInfoList: {
      handler(newValue) {
        this.cardInfoList = newValue
        if (this.cardInfoList) {
          this.paymentMethodCounts = this.cardInfoList.length
          if (this.paymentMethodCounts === 1 && !this.cardInfoList[0].default) {
            this.setPaymentMethodAsDefault(this.cardInfoList[0], false)
          }
        }
        this.$forceUpdate()
      }
    }
  },
  methods: {
    getExpiredDate(item) {
      let info = this.$t("Components.Brands.Payment.expired")
      return Utils.getExpiredDate(item, info)
    },
    setPaymentMethodAsDefault(item, showInfo = true) {
      if (item.default) {
        let info = this.$t("Components.Brands.Payment.canNotCancelDefault")
        this.$message.warn(info)
        return
      }
      let defaultItem = this.cardInfoList.find(item => item.default)
      this.doDefault(defaultItem, false)
      this.doDefault(item, showInfo)
    },
    async doDefault(item, showInfo) {
      let params = []
      params.push({
        privateId: item.privateId,
        isDefault: item.default ? 0 : 1
      })
      await this.$store
        .dispatch("brandPayment/setCardDefault", {
          brandId: this.brandId,
          param: params
        })
        .then(() => {
          item.default = true
          if (showInfo) {
            let info = this.$t("Components.Brands.Payment.setDefaultSuccess")
            this.$message.success(info)
          }
        })
    },
    /**
     * will show the delete dialog
     * if there is only one method, can't delete, just tell user can't delete it.
     * If more, then can delete selected method when click dialog 'Yes' Button
     * @param profileId
     * @param id
     * @param index
     */
    showDeleteDialog(item) {
      if (item.default) {
        this.isShowDeleteDialog = false
        this.selectedItem = undefined
        let info = this.$t("Components.Brands.Payment.canNotDeleteDefault")
        this.$message.error(info)
      } else {
        this.selectedItem = item
        this.isShowDeleteDialog = true
      }
    },
    hideDeleteDialog() {
      this.isShowDeleteDialog = false
      this.selectedItem = undefined
    },
    showEditDialog(item) {
      this.isShowEditDialog = true
      this.selectedItem = item
    },
    hideEditDialog() {
      this.selectedItem = undefined
      this.isShowEditDialog = false
    },
    async doDelete() {
      if (this.selectedItem) {
        let params = []
        params.push({
          privateId: this.selectedItem.privateId,
          customerProfileId: this.selectedItem.customerProfileId
        })
        try {
          await this.$store
            .dispatch("brandPayment/deleteCardInfo", {
              brandId: this.brandId,
              param: params
            })
            .then(response => {
              if (response.error) {
                this.$message.error(response.developerMessage)
              } else {
                let info = this.$t("Components.Brands.Payment.deleteSuccess")
                this.$message.success(info)
              }
            })
        } catch (e) {
          let index = e.toString().indexOf("400")
          if (index >= 0) {
            let info = this.$t("Components.Brands.Payment.deleteError")
            this.$message.error(info)
          } else {
            this.$message.error(e.toString())
          }
        }
        this.hideDeleteDialog()
      }
    }
  }
}
</script>

<style lang="sass">
.new-card-list
  &__card-border
    border: 1px solid #ddd
    margin-bottom: 10px
    padding: 16px 10px 16px 16px
    webkit-box-shadow: 0 2px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)
    box-shadow: 0 2px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)
  &__row-margin
    margin-bottom: 10px
  &__other-title
    font-family: Proxima Nova
    font-style: normal
    font-weight: bold
    font-size: 12px
    line-height: 20px
    display: flex
    margin-right: 10px
    margin-bottom: 4px !important
    color: #000
  &__other-text
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 20px
    display: flex
    margin-right: 10px
    margin-bottom: 4px !important
    color: #000
  &__content-right
    direction: rtl
  &__btn
    min-width: 80px
    margin-left: 10px
    float: right
  &__set-default
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 20px
    color: #4a8a5d
  &__dialog-title
    font-family: Proxima Nova
    font-style: normal
    font-weight: bold
    font-size: 14px
    line-height: 20px
    margin-bottom: 10px
  &__dialog-desc
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 20px
    margin-bottom: 10px
.new-card-list__btn.ant-btn
  border-radius: 2px !important
.ant-btn:hover
  border-color: inherit
  color: inherit
#unDefaultBtn
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 20px
  color: #4a8a5d
  padding: 1px 8px
  background: #F6FFED
  border: 1px solid #4A8A5D
  box-sizing: border-box
  border-radius: 2px
  text-align: center
</style>
