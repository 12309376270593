<template>
  <div class="guest-red-product-list-new__main">
    <div class="line"></div>
    <div class="guest-product-list-new__content">
      <div class="img-video-and-product-info-top">
        <div class="show-img-video-component">
          <imgVideoCarousel :img-list="imgList"></imgVideoCarousel>
        </div>
        <div class="show-product-info">
          <div v-for="item in productInfoTop" :key="item.name">
            <p class="product-info-text">
              <span class="product-text-name">{{ item.name }}</span
              >{{ item.value }}
            </p>
          </div>
          <p v-if="product.training.hero" class="hero-tips-style">Hero</p>
        </div>
      </div>
      <div class="other-info-center-content">
        <div class="form-content">
          <div>
            <span class="form-item-name">
              {{ this.$t("Training.brandTraining.trainingFormTips.stepLabel") }}:
            </span>
            <p class="form-item-content">
              <span v-for="item in product.trainingSteps" :key="item.id">{{ item.name }}</span>
            </p>
          </div>
          <div>
            <span class="form-item-name">
              {{ this.$t("Training.brandTraining.trainingFormTips.useForLabel") }}:
            </span>
            <p class="form-item-content">
              <span v-for="item in product.trainingBestUsedFors" :key="item.id">
                {{ item.name }}
              </span>
            </p>
          </div>
          <div class="cross-seller-box">
            <span class="form-item-name">
              {{ this.$t("Training.brandTraining.trainingFormTips.crossSellersLabel") }}:
            </span>
            <div class="form-item-content">
              <span
                v-for="item in product.trainingCrossSellers"
                :key="item.id"
                class="span-cross-seller-text"
              >
                {{ item.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="center-key-benefits">
          <div class="key-title">
            {{ this.$t("Training.brandTraining.prodEditCardTips.keyBenefits") }}:
          </div>
          <ul>
            <li v-for="item in product.newBenefits" :key="item.id" class="line-clamp">
              <i></i>
              <p>{{ item.name }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="demo-info-tips-bottom">
        <div class="textarea-bottom">
          <div class="center-demo-textarea">
            <p class="demo-tip-style">How To Demo:</p>
            <p class="tip-content-style">{{ product.training.howToDemo }}</p>
          </div>
          <div class="demo-tip-content">
            <p class="demo-tip-style">
              {{ this.$t("Training.brandTraining.trainingFormTips.demoTipsLabel") }}
            </p>
            <p class="tip-content-style">{{ product.training.demoTips }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgVideoCarousel from "@/components/training/__components/imgVideoCarousel"

export default {
  name: "GuestReadProductListNew",
  components: {
    imgVideoCarousel
  },
  props: {
    product: {
      type: Object
    },
    isRead: {
      type: Boolean
    },
    userInfo: {
      type: Object
    }
  },
  data() {
    return {
      // imgList: []
    }
  },
  computed: {
    imgList() {
      return this.formatTrainingVideos(this.product.trainingAssets)
    },
    productInfoTop() {
      return [
        {
          name: "Brand:",
          value: this.userInfo.name
        },
        {
          name: "Name:",
          value: this.product.name
        },
        {
          name: "SKU:",
          value: this.product.sku
        },
        {
          name: "UPC:",
          value: this.product.barcodes[0].code
        },
        {
          name: "MSRP:",
          value: `$${this.product.msrpUsd}`
        },
        {
          name: "Category:",
          value: this.product.subcategory.name
        }
      ]
    }
  },
  methods: {
    formatTrainingAssets(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].imgAsset && Object.values(list[i].imgAsset).length !== 0) {
          const obj = {
            asset: {
              ...list[i].imgAsset
            },
            title: list[i].title
          }
          list[i].imgAsset = {}
          list.push(obj)
        }
      }
      return list
    },
    formatTrainingVideos(list) {
      if (!list || list.length === 0) {
        if (this.product.images && this.product.images.length === 1) {
          return this.product.images
        }
        return this.product.images.slice(0, 2)
      }
      if (list.length === 4) {
        const img = list.filter(item => item.asset.contentType.includes("image"))
        const video = list.filter(item => item.asset.contentType.includes("video"))
        return img.concat(video)
      }
      let trainingAssetList = this.formatTrainingAssets(list)
      const trainingAssetImg = trainingAssetList.filter(item =>
        item.asset.contentType.includes("image")
      )
      const trainingAssetVideo = trainingAssetList.filter(item =>
        item.asset.contentType.includes("video")
      )
      return trainingAssetImg.concat(trainingAssetVideo)
    }
  }
}
</script>

<style scoped lang="scss">
.guest-red-product-list-new__main {
  .line {
    width: 100%;
    height: 2px;
    margin-bottom: 25px;
    background: #d9d9d9;
  }
  .guest-product-list-new__content {
    margin: 0 auto;
  }
  .img-video-and-product-info-top {
    min-height: 300px;
    display: flex;
    justify-content: start;
    .show-img-video-component {
      width: 450px;
      margin-right: 50px;
    }
    .show-product-info {
      p {
        color: #000000;
        font-size: 12px;
        line-height: 22px;
      }
      .product-text-name {
        display: inline-block;
        width: 60px;
      }
    }
    .hero-tips-style {
      background: #f3ca50;
      color: #ffffff !important;
      border-radius: 100px;
      width: 39px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      margin-top: 10px;
    }
  }
  .other-info-center-content {
    margin: 20px 0;
    display: flex;
    justify-content: start;
    .form-content {
      width: 450px;
      margin-right: 50px;
      color: #262626;
      font-family: "Proxima Nova", "Roboto", sans-serif;
    }
    .form-item-name {
      display: inline-block;
      width: 101px;
      text-align: left;
      margin-right: 24px;
    }
    .form-item-content {
      display: inline-block;
      width: 450px;
      min-height: 24px;
      background-color: #fafafa;
      border: 1px solid #d9d9d9;
      padding: 4px 5px;
      margin-bottom: 12px;
      margin-right: 20px;
      span,
      .span-cross-seller-text {
        margin-right: 4px;
        padding: 1px 8px;
        border: 1px solid #e2eeec;
        border-radius: 2px;
        background-color: white;
      }
      .span-cross-seller-text {
        margin-bottom: 2px;
        display: block;
      }
    }
    .center-key-benefits {
      //height: 221px;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      .key-title {
        font-size: 14px;
        margin-bottom: 8px;
        color: #262626;
        i {
          font-size: 12px;
          color: #bfbfbf;
        }
      }
      ul li {
        display: flex;
        justify-content: space-between;
        width: 100%;
        //height: 80px;
        //line-height: 16px;
        //list-style-type: disc;
        word-break: break-word;
        margin-bottom: 40px;
        -webkit-line-clamp: 5;
        i {
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #000000;
          margin-right: 4px;
          margin-top: 5px;
        }
        p {
          //  display: inline-block;
          width: 381px;
          font-size: 14px;
          color: #262626;
          font-family: "Proxima Nova", "Roboto", sans-serif;
          margin-bottom: 0;
          //  color: #000000;
          //  font-family: "Proxima Nova", "Roboto", sans-serif;
        }
      }
    }
  }
  .demo-info-tips-bottom {
    .textarea-bottom {
      //width: 392px;
      display: flex;
      justify-content: start;
    }
    .center-demo-textarea {
      width: 450px;
      margin-right: 50px;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      position: relative;
    }
    .tip-content-style {
      width: 450px;
      min-height: 96px;
      font-size: 14px;
      color: #262626;
      border: 1px solid #d9d9d9;
      padding: 8px 9px;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      margin-bottom: 16px;
      //word-break: break-word;
      //-webkit-line-clamp: 4;
    }
    .demo-tip-style {
      font-size: 16px;
      margin-bottom: 8px;
      color: #000000;
    }
    .demo-tip-content {
      width: 450px;
      position: relative;
    }
  }
}
</style>
