import axios from "@/api/axios"

export default {
  createOrder(order, params, brandId) {
    if (brandId) {
      return axios.post(`/cart?brand=${brandId}`, JSON.stringify(order), { params })
    }
    return axios.post("/cart", JSON.stringify(order), { params })
  },
  updateOrder(order, params) {
    return axios.put(`/orders/${order.id}`, JSON.stringify(order), { params })
  },
  updateOrderStatus(order, params) {
    let id = order.id
    if (params.batch) delete order.id
    return axios.put(`/orders/${id}?_skipNulls=true`, order, { params })
  },

  // TODO: split to two clients
  getOrders(params) {
    return axios.get("/orders", { params })
  },
  getOrdersByParams(params) {
    return axios.get("/orders", { params })
  },
  getOrderListWithPage(params) {
    return axios.get("order/list", { params })
  },
  getOrder(id) {
    return axios.get(`/orders/${id}`)
  },
  pushOrderComment(id, comment, params) {
    return axios.post(`/orders/${id}/comments`, JSON.stringify(comment), { params })
  },
  getOrderByBatch(id, params) {
    return axios.get(`/orders/${id}`, { params })
  },
  orderDownload(params) {
    return axios.get(`/orders/download`, { params, responseType: "blob" })
  },
  uploadOrder(params) {
    return axios.post(`/orders/upload`, params)
  },
  updateShippingDrder(params) {
    return axios.post(`/orders/upload-shipping`, params)
  },
  brandTermsPending(id) {
    return axios.post(`/brands/${id}/terms-pending`)
  },
  updateLogisticTerms(id, action, params) {
    return axios.post(`/brands/${id}/logistic-terms/${action}`, params)
  },
  updateTransaction(id, action, params) {
    return axios.post(`/brands/${id}/transaction-fee-terms/${action}`, params)
  },
  dowloadPacklistPdf(id) {
    return axios.get(`/orders/${id}/packListToPdf`, { responseType: "arraybuffer" })
  },
  dowloadInvoicePdf(id) {
    return axios.get(`/orders/${id}/invoiceToPdf`, { responseType: "arraybuffer" })
  },
  dowloadPayAdvicePdf(id) {
    return axios.get(`/orders/${id}/paymentAdviceToPdf`, { responseType: "arraybuffer" })
  },
  dowloadOrderToPdf(id) {
    return axios.get(`/orders/${id}/orderToPdf`, { responseType: "arraybuffer" })
  },
  updateAirShipment(id, params) {
    return axios.put(`/orders/${id}/air-shipments`, params)
  },
  updateShipment(id, params) {
    return axios.put(`/orders/${id}/shipments`, params)
  },
  downloadEstimatePaymentPdf(id) {
    return axios.get(`/orders/${id}/viewEstimatedPaymentAdviceToPdf`, {
      responseType: "arraybuffer"
    })
  },
  updateLandingTermStatus(id) {
    return axios.put(`/brands/${id}/logistic-terms/active`)
  }
}
