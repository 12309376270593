<template>
  <div class="terms">
    <!--    <h1 class="terms__main-header">-->
    <!--      Terms & Conditions-->
    <!--    </h1>-->
    <div class="terms__content">
      <p class="terms-content-title">
        PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES (DEFINED BELOW) ARE SUBJECT TO THE
        FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE
        SERVICES IN ANY MANNER.
      </p>
      <p class="terms-of-use__main">
        Terms of Use
      </p>
      <!--      !!!!!!2022-10-26 Update  !!!!!!!!!!-->
      <!--      <p class="terms-privacy-data">LAST UPDATED: MARCH 28, 2022</p>-->
      <p class="terms-privacy-data">LAST UPDATED: OCTOBER 24, 2022</p>
      <p style="margin-bottom: 20px">
        Welcome to Landing International, Inc. Please read on to learn the rules and restrictions
        that govern your use of our website(s), products, services and applications (the
        “Services”). If you have any questions, comments, or concerns regarding these terms or the
        Services, please contact us at
        <a href="mailto:terms@landingintl.com" style="text-decoration: underline;color: #1a1818"
          >terms@landingintl.com</a
        >.
      </p>
      <p>
        These Terms of Use (the “Terms”) are a binding contract between you and Landing
        International, Inc. (“Landing”, “we” and “us”). You must agree to and accept all of the
        Terms, or you don’t have the right to use the Services. Your using the Services in any way
        means that you agree to all of these Terms, and these Terms will remain in effect while you
        use the Services. These Terms include the provisions in this document, as well as those in
        the Privacy Policy, and, with respect to any purchase you make using the Services, the
        Purchase Order Terms and Conditions.
      </p>
      <p>
        The Services are offered and available to users who are 18 years of age or older and reside
        in [the United States or other territories]. By using the Services, you represent to us that
        you are of legal age to form a binding contract with Landing and meet all of the foregoing
        eligibility requirements. If you do not meet all of these requirements, you must not access
        or use the Services.
      </p>
      <p style="font-size: 25px">ARBITRATION NOTICE AND CLASS ACTION WAIVER</p>
      <p style="font-size: 25px">
        PLEASE NOTE THAT THESE TERMS CONTAIN AN ARBITRATION CLAUSE. EXCEPT FOR CERTAIN TYPES OF
        DISPUTES MENTIONED IN THE ARBITRATION CLAUSE, YOU AND LANDING AGREE THAT DISPUTES RELATING
        TO THESE TERMS OR YOUR USE OF THE SERVICES WILL BE RESOLVED BY MANDATORY BINDING
        ARBITRATION, AND YOU WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE
        ARBITRATION.
      </p>
      <p>
        Updates to Terms & Conditions
      </p>
      <p>
        We are constantly trying to improve our Services, so these Terms may need to change along
        with the Services. We reserve the right to change the Terms at any time, but if we do, we
        will bring it to your attention by placing a notice on the www.landinginternational.com
        website, by sending you an email, and/or by some other means.
      </p>
      <p>
        If you don’t agree with the new Terms, you are free to reject them; unfortunately, that
        means you will no longer be able to use the Services. If you use the Services in any way
        after a change to the Terms is effective, that means you agree to all of the changes.
      </p>
      <p>
        Except for changes by us as described here, no other amendment or modification of these
        Terms will be effective unless in writing and signed by both you and us.
      </p>
      <p>Privacy</p>
      <p>
        Landing takes the privacy of its users very seriously. Please review Landing’s current
        Privacy Policy to learn more.
      </p>
      <p>
        The Children’s Online Privacy Protection Act (“COPPA”) requires that online service
        providers obtain parental consent before they knowingly collect personally identifiable
        information online from children who are under 13. We do not knowingly collect or solicit
        personally identifiable information from children under 13; if you are a child under 13,
        please do not attempt to register for the Services or send any personal information about
        yourself to us. If we learn we have collected personal information from a child under 13, we
        will delete that information as quickly as possible. If you believe that a child under 13
        may have provided us personal information, please contact us at privacy@landingintl.com.
      </p>
      <p>Our Services</p>
      <p>
        You may be required to sign up for an account, and select a password and username (“Landing
        User ID”). You promise to provide us with accurate, complete, and updated registration
        information about yourself. You may not select as your Landing User ID a name that you don’t
        have the right to use, or another person’s name with the intent to impersonate that person.
        You may not transfer your account to anyone else without our prior written permission. You
        may not share your account information, including your log-in and password, with anyone else
        without our prior written permission.
      </p>
      <p>
        If you’re agreeing to these Terms on behalf of an organization or entity, you represent and
        warrant that you are authorized to agree to these Terms on that organization or entity’s
        behalf and bind them to these Terms (in which case, the references to “you” and “your” in
        these Terms, except for in this sentence, refer to that organization or entity).
      </p>
      <p>
        If your use of the Services is prohibited by applicable laws, then you aren’t authorized to
        use the Services. We can’t and won’t be responsible for your using the Services in a way
        that breaks the law.
      </p>
      <p>
        You will not share your account or password with anyone, and you must protect the security
        of your account and your password. You’re responsible for any activity associated with your
        account.
      </p>
      <p>Your use of the Services is subject to the following additional restrictions:</p>
      <p>
        You represent, warrant, and agree that you will not contribute any Content or User
        Submission (each of those terms is defined below) or otherwise use the Services or interact
        with the Services in a manner that:
      </p>
      <div style="padding: 0 15px;display: flex;">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">1.</span>
        <p>
          Infringes or violates the intellectual property rights or any other rights of anyone else
          (including Landing);
        </p>
      </div>
      <div style="padding: 0 15px;display: flex;">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">2.</span>
        <p>Violates any law or regulation, including any applicable export control laws;</p>
      </div>
      <div style="padding: 0 15px;display: flex;">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">3.</span>
        <p>
          Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or
          otherwise objectionable;
        </p>
      </div>
      <div style="padding: 0 15px;display: flex;">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">4.</span>
        <p>
          Jeopardizes the security of your Landing account or anyone else’s (such as allowing
          someone else to log in to the Services as you);
        </p>
      </div>
      <div style="padding: 0 15px;display: flex;">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">5.</span>
        <p>
          Attempts, in any manner, to obtain the password, account, or other security information
          from any other user;
        </p>
      </div>
      <div style="padding: 0 15px;display: flex;">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">6.</span>
        <p>
          Violates the security of any computer network, or cracks any passwords or security
          encryption codes;
        </p>
      </div>
      <div style="padding: 0 15px;display: flex;">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">7.</span>
        <p>
          Runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any
          processes that run or are activated while you are not logged into the Services, or that
          otherwise interfere with the proper working of the Services (including by placing an
          unreasonable load on the Services’ infrastructure);
        </p>
      </div>
      <div style="padding: 0 15px;display: flex;">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">8.</span>
        <p>
          “Crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the
          Services or Content (through use of manual or automated means);
        </p>
      </div>
      <div style="padding: 0 15px;display: flex;">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">9.</span>
        <p>Copies or stores any significant portion of the Content;</p>
      </div>
      <div style="padding: 0 15px;display: flex;margin-bottom: 50px">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">10.</span>
        <p>
          Decompiles, reverse engineers, or otherwise attempts to obtain the source code or
          underlying ideas or information of or relating to the Services.
        </p>
      </div>
      <p>Confidentiality</p>
      <p>
        <span style="font-weight: bold"></span>In addition, you understand that Landing has
        disclosed or may disclose information relating to Landing’s technology or business
        (including pricing accessible only to authorized users) (hereinafter referred to as
        “Proprietary Information”). You agree: (i) not to divulge to any third person any such
        Proprietary Information, (i) to give access to such Proprietary information solely to those
        of your employees with a need to have access thereto for purposes of these Terms, and (iii)
        to take the same security precautions to protect against disclosure or unauthorized use of
        such Proprietary Information that you take with your own proprietary information, but in no
        event less than reasonable precautions to protect such Proprietary Information. The
        foregoing will not apply with respect to any information that you can document (a) is or
        becomes generally available to the public other than through your own wrongful act, or (b)
        was in your possession or known by you prior to receipt from us, or (c) was rightfully
        disclosed to you without restriction by a third party, or (d) was independently developed
        without use of any Proprietary Information. Nothing in this Agreement will prevent you from
        disclosing the Proprietary Information pursuant to any judicial or governmental order,
        provided that you give us reasonable written prior notice of such disclosure to contest such
        order.
      </p>
      <p>
        A violation of any of the foregoing is grounds for termination of your right to use or
        access the Services.
      </p>
      <p>Your Rights</p>
      <p>
        The materials displayed or performed or available on or through the Services, including, but
        not limited to, text, graphics, data, articles, photos, images, illustrations, User
        Submissions, and so forth (all of the foregoing, the “Content”) are protected by trademark,
        copyright and/or other intellectual property laws. You promise to abide by all copyright
        notices, trademark rules, information, and restrictions contained in any Content you access
        through the Services, and you won’t use, copy, reproduce, modify, translate, publish,
        broadcast, transmit, distribute, perform, upload, display, license, sell or otherwise
        exploit for any purpose any Content not owned by you, (i) without the prior consent of the
        owner of that Content or (ii) in a way that violates someone else’s (including Landing’s)
        rights.
      </p>
      <p>
        You understand that Landing owns the Services. You won’t modify, publish, transmit,
        participate in the transfer or sale of, reproduce (except as expressly provided in this
        Section), create derivative works based on, or otherwise exploit any of the Services.
      </p>
      <p>
        The Services may allow you to copy or download certain Content; please remember that just
        because this functionality exists, doesn’t mean that all the restrictions above don’t apply
        – they do!
      </p>
      <p>License Grants</p>
      <p>
        Anything you post, upload, share, store, or otherwise provide through the Services is your
        “User Submission.” Some User Submissions may be viewable by other users. In order to display
        your User Submissions on the Services, and to allow other users to enjoy them (where
        applicable), you grant us certain rights in those User Submissions. Please note that all of
        the following licenses are subject to our Privacy Policy to the extent they relate to User
        Submissions that are also your personally-identifiable information.
      </p>
      <p>
        For all User Submissions, you hereby grant Landing a license to translate, modify (for
        technical purposes, for example making sure your content is viewable on a mobile phone as
        well as a computer) and reproduce and otherwise act with respect to such User Submissions,
        in each case to enable us to operate the Services, as described in more detail below. This
        is a license only – your ownership in User Submissions is not affected.
      </p>
      <p>
        If you store a User Submission in your own personal Landing account or input a temporary
        User Submission, in each case in a manner that is not viewable by any other user except you
        (a “Personal User Submission”), you grant Landing the license above, as well as a license to
        display, perform, and distribute your Personal User Submission for the sole purpose of
        making that Personal User Submission accessible to you and providing the Services necessary
        to do so.
      </p>
      <p>
        If you share a User Submission only in a manner that only certain specified users can view
        (for example, a private message to one or more other users) (a “Limited Audience User
        Submission”), then you grant Landing the licenses above, as well as a license to display,
        perform, and distribute your Limited Audience User Submission for the sole purpose of making
        that Limited Audience User Submission accessible to such other specified users, and
        providing the Services necessary to do so. Also, you grant such other specified users a
        license to access that Limited Audience User Submission, and to use and exercise all rights
        in it, as permitted by the functionality of the Services.
      </p>
      <p>
        If you share a User Submission publicly on the Services and/or in a manner that more than
        just you or certain specified users can view, or if you provide us (in a direct email or
        otherwise) with any feedback, suggestions, improvements, enhancements, and/or feature
        requests relating to the Services (each of the foregoing, a “Public User Submission”), then
        you grant Landing the licenses above, as well as a license to display, perform, and
        distribute your Public User Submission for the purpose of making that Public User Submission
        accessible to all Landing users and providing the Services necessary to do so, as well as
        all other rights necessary to use and exercise all rights in that Public User Submission in
        connection with the Services and/or otherwise in connection with Landing’s business for any
        purpose. Also, you grant all other users of the Services a license to access that Public
        User Submission, and to use and exercise all rights in it, as permitted by the functionality
        of the Services.
      </p>
      <p>
        You agree that the licenses you grant are royalty-free, perpetual, sublicensable,
        irrevocable, and worldwide, provided that when you delete your Landing account, we will stop
        displaying your User Submissions (other than Public User Submissions, which may remain fully
        available) to other users (if applicable), but you understand and agree that it may not be
        possible to completely delete that content from Landing’s records, and that your User
        Submissions may remain viewable elsewhere to the extent that they were copied or stored by
        other users.
      </p>
      <p>
        Finally, you understand and agree that Landing, in performing the required technical steps
        to provide the Services to our users (including you), may need to make changes to your User
        Submissions to conform and adapt those User Submissions to the technical requirements of
        connection networks, devices, services, or media, and the foregoing licenses include the
        rights to do so.
      </p>
      <p>
        Copyright Infringement
      </p>
      <p>
        You may have heard of the Digital Millennium Copyright Act (the “DMCA”), as it relates to
        online service providers, like Landing, being asked to remove material that allegedly
        violates someone’s copyright. We respect others’ intellectual property rights, and we
        reserve the right to delete or disable Content alleged to be infringing the copyrights of
        another, and to terminate the accounts of repeat alleged infringers; to report potentially
        infringing content,
        <a
          href="https://www.landinginternational.com/?__hstc=166849825.144e66775323e3e54a6533aa46972e7c.1648613189937.1654827107631.1664287892105.12&__hssc=166849825.35.1664287892105&__hsfp=2330585021"
          style="text-decoration: underline;color: #1a1818"
          >privacy@landingintl.com</a
        >. To learn more about the DMCA,
        <a
          href="https://www.copyright.gov/title17/92appb.html"
          style="text-decoration: underline; color: #1a1818"
          >click here</a
        >.
      </p>
      <p>
        Trademark Infringement
      </p>
      <p>
        We also respect others’ intellectual property rights when it comes to trademark rights. We
        therefore reserve the right to delete or disable Content alleged to be infringing, and to
        terminate the accounts of repeat alleged infringers. To report suspected trademark
        infringement, please email
        <a
          href="https://www.landinginternational.com/?__hstc=166849825.144e66775323e3e54a6533aa46972e7c.1648613189937.1654827107631.1664287892105.12&__hssc=166849825.35.1664287892105&__hsfp=2330585021"
          style="text-decoration: underline;color: #1a1818"
          >privacy@landingintl.com</a
        >.
      </p>
      <p>Use of Services</p>
      <p>
        Any information or content publicly posted or privately transmitted through the Services is
        the sole responsibility of the person who posted that content, and you access all such
        information and content at your own risk. Landing is not liable for any errors or omissions
        in that information or content or for any damages or loss you might suffer in connection
        with it. We cannot control and have no duty to take any action regarding how you may
        interpret and use the Content or what actions you may take as a result of having been
        exposed to the Content, and you hereby release us from all liability for you having acquired
        or not acquired Content through the Services. We can’t guarantee the identity of any users
        with whom you interact in using the Services and are not responsible for which users gain
        access to the Services.
      </p>
      <p>
        You are responsible for all Content you contribute, in any manner, to the Services, and you
        represent and warrant you have all rights necessary to do so, in the manner in which you
        contribute it, and that the Content that you contribute is accurate, true, and correct. You
        will keep all your registration information accurate and current. You are responsible for
        all your activity in connection with the Services.
      </p>
      <p>
        The Services may contain links or connections to third party websites or services that are
        not owned or controlled by Landing. When you access third party websites or use third party
        services, you accept that there are risks in doing so, and that Landing is not responsible
        for such risks. We encourage you to be aware when you leave the Services and to read the
        terms and conditions and privacy policy of each third party website or service that you
        visit or utilize.
      </p>
      <p>
        Landing has no control over, and assumes no responsibility for, the content, accuracy,
        privacy policies, or practices of or opinions expressed in any third party websites or by
        any third party that you interact with through the Services. In addition, Landing will not
        and cannot monitor, verify, censor or edit the content of any third party marketplace or
        service. By using the Services, you release and hold us harmless from any and all liability
        arising from your use of any third party website or service.
      </p>
      <p>
        Your interactions with organizations and/or individuals found on or through the Services,
        including payment and delivery of goods and any other terms, conditions, warranties or
        representations associated with such dealings, are solely between you and such organizations
        and/or individuals. You should make whatever investigation you feel necessary or appropriate
        before proceeding with any online or offline transaction with any of these third parties.
        You agree that Landing shall not be responsible or liable for any loss or damage of any sort
        incurred as the result of any such dealings.
      </p>
      <p>
        If there is a dispute between participants on this Marketplace, as that is defined in the
        Purchase Terms, or between users and any third party, you agree that Landing is under no
        obligation to become involved. In the event that you have a dispute with one or more other
        users, you release Landing, its officers, employees, agents, and successors from claims,
        demands, and damages of every kind or nature, known or unknown, suspected or unsuspected,
        disclosed or undisclosed, arising out of or in any way related to such disputes and/or our
        Services. If you are a California resident, you shall and hereby do waive California Civil
        Code Section 1542, which says: "A general release does not extend to claims which the
        creditor does not know or suspect to exist in his or her favor at the time of executing the
        release, which, if known by him or her must have materially affected his or her settlement
        with the debtor."
      </p>
      <p>Changes to Services</p>
      <p>
        We’re always trying to improve the Services, so they may change over time. We may suspend or
        discontinue any part of the Services, or we may introduce new features or impose limits on
        certain features or restrict access to parts or all of the Services. We’ll try to give you
        notice when we make a material change to the Services that would adversely affect you, but
        this isn’t always practical. Similarly, we reserve the right to remove any Content from the
        Services at any time, for any reason (including, but not limited to, if someone alleges you
        contributed that Content in violation of these Terms), in our sole discretion, and without
        notice.
      </p>
      <p>
        Pricing Terms, Subscriptions, and Purchase Terms
      </p>
      <p>
        We reserve the right to charge for certain or all Services. Charges for Services are as
        described in your settings pages. use of the e of Services requires payment of all
        applicable fees for such Services.
      </p>
      <p>
        With respect to any purchase you make using the Services, the Purchase Order Terms and
        Conditions apply.
      </p>
      <!--      <p>-->
      <!--        You may have access to a free trial period of the Landing Services, which will automatically-->
      <!--        convert into a paid annual subscription term after a specified period of time (the-->
      <!--        “Subscription”). In such instances, you can disable the automatic conversion by following-->
      <!--        the cancellation instructions set forth on the Settings page of your account prior to the-->
      <!--        date of conversion.-->
      <!--      </p>-->
      <p>Landing offers two subscription options to use our Services.</p>
      <div style="padding: 0 15px;display: flex;">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">1.Platform Subscription</span>
      </div>
      <p style="padding-left: 80px;padding-top: 20px">
        The “Platform Subscription” includes all Services on our site except for the abilityto
        publish training materials to the Landing Mobile App, BeautyFluent (the “App”).
      </p>
      <div style="padding: 0 15px;display: flex;">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">2.App Subscription</span>
      </div>
      <p style="padding-left: 80px;padding-top: 20px">
        The “App Subscription” (together with the Platform Subscription, a” Subscription”)gives you
        access to all of the Landing Services plus the ability to publish trainingmaterials to the
        App.
      </p>
      <p>
        Your Subscription is paid for on a monthly, quarterly, or annual basis (the “Initial
        Subscription Fee”) with automatic renewals (“Renewal Subscription Fee”) as described more
        fully below. You acknowledge and agree that Landing is authorized to charge your Payment
        Method, (as defined below) used for (i) the Initial Subscription Fee, and (ii) Renewal
        Subscription Fees in months, quarters, or years, as applicable. Discounts may apply for
        payment on a quarterly or annual basis. You may also have access to a free trial period of
        the Platform Subscription, which will automatically convert into a paid annual subscription
        term after a specified period of time. You must cancel your Subscription before it renews in
        order to avoid billing of the Renewal Subscription Fee to the Payment Method. Refunds cannot
        be claimed for any partial subscription period. Automatic renewal can be disabled on your
        settings page prior to the date of conversion.
      </p>
      <p>
        These Terms and Conditions and the App Terms and Conditions will apply to any App
        Subscription. For more information about the App Terms and Conditions, please click
        <span style="cursor: pointer" @click="linkToHere">[HERE]</span>.
      </p>
      <p>
        Where applicable, you shall pay all fees described by the Services in connection with such
        for-fee products/services purchased by you in the manner specified on or selected through
        the Services (or as otherwise agreed upon in writing by you and us). Unless otherwise
        specified, the following terms apply to all purchases.
      </p>
      <div style="padding: 0 15px;display: flex;margin-bottom: 50px">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">1.</span>
        <p>
          We may use a third-party payment processor (the “Payment Processor”) to bill you through a
          payment account, such as your bank account or debit or credit card, linked to your account
          on the Services (your “Billing Account”). The processing of payments will be subject to
          the terms, conditions and privacy policies of the Payment Processor in addition to these
          Terms. We are not responsible for error by the Payment Processor. By choosing to use
          for-fee Services or purchase products, you agree to pay us, through the Payment Processor,
          all charges at the prices then in effect for any use of such Services or purchase of such
          products in accordance with the applicable payment terms and you authorize us, through the
          Payment Processor, to charge your chosen payment provider (your “Payment Method”). You
          agree to make payment using that selected Payment Method. We reserve the right to correct
          any errors or mistakes that it makes even if it has already requested or received payment.
        </p>
      </div>
      <div style="padding: 0 15px;display: flex;margin-bottom: 20px">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">2.</span>
        <p>
          Payment Method. The terms of your payment will be based on your Payment Method and may be
          determined by agreements between you and the financial institution, credit card issuer or
          other provider of your chosen Payment Method. If we, through the Payment Processor, do not
          receive payment from you, you agree to pay all amounts due upon demand.
        </p>
      </div>
      <div style="padding: 0 15px;display: flex;margin-bottom: 20px">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">3.</span>
        <p>
          Recurring Billing. After the initial free trial period, Landing will charge you for the
          Services on an annual basis, as described above. By using the Services, you agree to a
          recurring payment plan, you acknowledge that such Services have an initial and recurring
          payment feature and you accept responsibility for all recurring charges prior to
          cancellation.
          <span style="font-size: 25px"
            >WE MAY SUBMIT PERIODIC CHARGES (E.G., ANNUALLY) WITHOUT FURTHER AUTHORIZATION FROM YOU,
            UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS CONFIRMED BY US) THAT YOU HAVE
            TERMINATED THIS AUTHORIZATION OR WISH TO CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL
            NOT AFFECT CHARGES SUBMITTED BEFORE WE REASONABLY COULD ACT. TO TERMINATE YOUR
            AUTHORIZATION OR CHANGE YOUR PAYMENT METHOD, GO TO ACCOUNT PAGE.</span
          >
        </p>
      </div>
      <div style="padding: 0 15px;display: flex;margin-bottom: 20px">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">4.</span>
        <p>
          Current Information Required.
          <span style="font-size: 25px"
            >YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT.
            YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE
            AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD
            EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR
            PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A
            POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER
            NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE AT ACCOUNT PAGE. IF YOU FAIL
            TO PROVIDE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU
            FOR ANY USE OF FOR-FEE SERVICES OR PURCHASES OF PRODUCTS UNDER YOUR ACCOUNT.</span
          >
        </p>
      </div>
      <div style="padding: 0 15px;display: flex;margin-bottom: 20px">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">5.</span>
        <p>
          Change in Amount Authorized. If the amount to be charged to your Billing Account varies
          from the amount you preauthorized (other than due to the imposition or change in the
          amount of state sales taxes), you have the right to receive, and we shall provide, notice
          of the amount to be charged and the date of the charge before the scheduled date of the
          transaction. Any agreement you have with your payment provider will govern your use of
          your Payment Method. You agree that we may accumulate charges incurred and submit them as
          one or more aggregate charges during or at the end of each billing cycle.
        </p>
      </div>
      <div style="padding: 0 15px;display: flex;margin-bottom: 20px">
        <span style="font-size: 25px;font-family: 'Mr Eaves Mod OT'">6.</span>
        <p>
          Reaffirmation of Authorization. Your non-termination or continued use of the Services
          reaffirms that we are authorized to charge your Payment Method for that service. We may
          submit those charges for payment and you will be responsible for such charges. This does
          not waive our right to seek payment directly from you. Your charges may be payable in
          advance, in arrears, per usage, or as otherwise described when you initially signed up for
          the Services.
        </p>
      </div>
      <p>Termination</p>
      <p>
        You’re free to terminate the Services at any time, by contacting us at
        <a
          href="https://www.landinginternational.com/?__hstc=166849825.144e66775323e3e54a6533aa46972e7c.1648613189937.1654827107631.1664287892105.12&__hssc=166849825.3.1664287892105&__hsfp=2330585021"
          style="text-decoration: underline;color: #1a1818"
          >support@landingintl.com</a
        >; please refer to our Privacy Policy, as well as the licenses above, to understand how we
        treat information you provide to us after you have stopped using our Services.
      </p>
      <p>
        Landing is also free to terminate (or suspend access to) your use of the Services or your
        account, for any reason in our absolute discretion, including your breach of these Terms.
        Landing has the sole right to decide whether you are in violation of any of the restrictions
        set forth in these
      </p>
      <p>
        Account termination may result in destruction of any Content associated with your account,
        so keep that in mind before you decide to terminate your account.
      </p>
      <p>
        Provisions that, by their nature, should survive termination of these Terms shall survive
        termination. By way of example, all of the following will survive termination: any
        obligation you have to pay us or indemnify us, any limitations on our liability, any terms
        regarding ownership or intellectual property rights, and terms regarding disputes between
        us.
      </p>
      <p>Miscellaneous</p>
      <p>
        <span style="font-weight: bold">Warranty Disclaimer.</span> Neither Landing nor its
        licensors or suppliers makes any representations or warranties concerning any information,
        including Content, contained in or accessed through the Services, and we will not be
        responsible or liable for the accuracy, copyright compliance, legality, or decency of
        material, including Content, contained in or accessed through the Services. We (and our
        licensors and suppliers) make no representations or warranties regarding suggestions or
        recommendations of services or products offered or purchased through the Services, nor of
        any Content provided by users. Products and services purchased or offered (whether or not
        following such recommendations and suggestions) through the Services are provided “AS IS”
        and without any warranty of any kind from Landing or others (unless provided expressly and
        unambiguously in writing for a specific product).
        <span style="font-size: 25px"
          >THE SERVICES AND CONTENT ARE PROVIDED BY LANDING (AND ITS LICENSORS AND SUPPLIERS) ON AN
          “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
          WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR
          ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO
          THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</span
        >
      </p>
      <p>
        <span style="font-weight: bold">Limitation of Liability.</span>
        <span style="font-size: 25px"
          >TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO
          LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR
          OTHERWISE) SHALL LANDING (OR ITS LICENSORS OR SUPPLIERS) BE LIABLE TO YOU OR TO ANY OTHER
          PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND,
          INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS,
          OR COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE
          GREATER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO LANDING IN CONNECTION WITH THE
          SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM. SOME STATES DO
          NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND
          EXCLUSIONS MAY NOT APPLY TO YOU.</span
        >
      </p>
      <p>
        <span style="font-weight: bold">Indemnity.</span> To the fullest extent allowed by
        applicable law, you agree to indemnify and hold Landing, its affiliates, officers, agents,
        employees, and partners harmless from and against any and all claims, liabilities, damages
        (actual and consequential), losses and expenses (including attorneys’ fees) arising from or
        in any way related to any third party claims relating to (a) your use of the Services
        (including any actions taken by a third party using your account), (b) any Content you
        provide, and (c) your violation of these Terms. In the event of such a claim, suit, or
        action (“Claim”), we will attempt to provide notice of the Claim to the contact information
        we have for your account (provided that failure to deliver such notice shall not eliminate
        or reduce your indemnification obligations hereunder).
      </p>
      <p>
        <span style="font-weight: bold">Assignment.</span> You may not assign, delegate or transfer
        these Terms or your rights or obligations hereunder, or your Services account, in any way
        (by operation of law or otherwise) without Landing’s prior written consent. We may transfer,
        assign, or delegate these Terms and our rights and obligations without consent.
      </p>
      <p>
        <span style="font-weight: bold">Choice of Law; Arbitration.</span> These Terms are governed
        by and will be construed under the laws of the State of California, without regard to the
        conflicts of laws provisions thereof. Any dispute arising from or relating to the subject
        matter of these Terms shall be finally settled in Los Angeles County, California, in
        English, in accordance with the Streamlined Arbitration Rules and Procedures of Judicial
        Arbitration and Mediation Services, Inc. (“JAMS”) then in effect, by one commercial
        arbitrator with substantial experience in resolving intellectual property and commercial
        contract disputes, who shall be selected from the appropriate list of JAMS arbitrators in
        accordance with such Rules. Judgment upon the award rendered by such arbitrator may be
        entered in any court of competent jurisdiction. Notwithstanding the foregoing obligation to
        arbitrate disputes, each party shall have the right to pursue injunctive or other equitable
        relief at any time, from any court of competent jurisdiction. For all purposes of these
        Terms, the parties consent to exclusive jurisdiction and venue in the state or federal
        courts located in, respectively, Los Angeles, California, or the Central District of
        California. Except where not permitted by law, you and Landing waive any constitutional and
        statutory rights to go to court and have a trial in front of a judge or a jury. Rather, you
        and Landing elect to have claims and disputes resolved by arbitration, as described above.
        In any litigation between you and Landing over whether to vacate or enforce an arbitration
        award, you and Landing waive all rights to a jury trial, and elect instead to have the
        dispute be resolved by a judge.
      </p>
      <p>
        <span style="font-weight: bold">Class Action Waiver.</span> WHERE PERMITTED UNDER THE
        APPLICABLE LAW, YOU AND LANDING AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN
        YOUR OR OUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
        CLASS OR CONSOLIDATED ACTION. If, however, this waiver of class or consolidated actions is
        deemed invalid or unenforceable, neither you nor Landing are entitled to arbitration;
        instead all claims and disputes will be resolved in a court in the Los Angeles, California,
        or the Central District of California, as described above.
      </p>
      <p>
        <span style="font-weight: bold">Miscellaneous.</span> You will be responsible for paying,
        withholding, filing, and reporting all taxes, duties, and other governmental assessments
        associated with your activity in connection with the Services, provided that Landing may, in
        its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit.
        The failure of either you or us to exercise, in any way, any right herein shall not be
        deemed a waiver of any further rights hereunder. If any provision of these Terms is found to
        be unenforceable or invalid, that provision will be limited or eliminated, to the minimum
        extent necessary, so that these Terms shall otherwise remain in full force and effect and
        enforceable. You and Landing agree that these Terms are the complete and exclusive statement
        of the mutual understanding between you and Landing, and that it supersedes and cancels all
        previous written and oral agreements, communications and other understandings relating to
        the subject matter of these Terms. You hereby acknowledge and agree that you are not an
        employee, agent, partner, or joint venture of Landing, and you do not have any authority of
        any kind to bind Landing in any respect whatsoever. You and Landing agree there are no third
        party beneficiaries intended under these Terms.
      </p>
      <!--      <p-->
      <!--        style="font-size: 60px;font-family: niveau-grotesk, sans-serif;font-weight: 600;padding: 0 110px;text-align: center"-->
      <!--      >-->
      <!--        Purchase Order Terms & Conditions-->
      <!--      </p>-->
      <p style="margin-top: 150px">Purchase Order Terms & Conditions</p>
      <p>
        Your use of Landing’s Services is at all times subject to the Terms of Use, which
        incorporates these Purchase Order Terms and Conditions. Any terms we use in these Purchase
        Order Terms and Conditions without defining them have the definitions given to them in the
        Terms of Use.
      </p>
      <p>Marketplace</p>
      <p>
        One of the Services is an online marketplace (the “Marketplace”) for appropriately qualified
        sellers (“Brands”) to offer to sell goods (“Products”) to prospective retailers
        (“Retailers”). In the event of a sale of any Products, unless otherwise specified, the sale
        is made directly between the Brand and Retailer, and is governed by this Purchase Order
        Terms and Conditions and reflected in the Purchase Order of the Retailer. If there are
        conflicts between these Purchase Order Terms and Conditions and the Purchase Order of the
        Retailer, the Retailer’s Purchase Order governs.
      </p>
      <p>The Role of Landing.</p>
      <p>
        The role of Landing is expressly limited to making the Marketplace available and maintaining
        the Marketplace for Brands, Retailers, or other Users. Landing is an intermediary and not an
        agent or fiduciary for any Brand, Retailer, or other User for any purpose. Landing is not
        responsible for the actual sale of any Products and, unless otherwise indicated on the
        Website, does not vet or control the information provided by Brand, Retailer, or other
        Users, nor their statements, acts, or omissions. Landing is independent from the Seller,
        Buyer and User, and no partnership, joint venture, employee-employer or
        franchiser-franchisee relationship is intended or created by the operation of this
        Marketplace by Landing.
      </p>
      <p>
        Landing may, but is not obligated to, provide intermediary services between Retailer and
        Brand in connection with customer service or dispute resolution matters. In the event
        Landing elects in its sole and absolute discretion to provide intermediary services, then
        the decision of Landing is final and binding on all parties and cannot be appealed,
        challenged or reversed.
      </p>
      <p>
        Landing may charge a Transaction Fee to Brands for any orders processed through the
        Marketplace. If assessed, Transaction Fees are displayed in the Settings Menu for Brands,
        and use of the Services are subject to Transaction Fees. Transaction Fees may change from
        time to time, and any increases will be disclosed to you before they take effect.
      </p>
      <p>Conditions of Sale</p>
      <p>
        Relationship of the Parties.<br />
        Brand(s) are responsible for accurately describing and pricing the Products it is offering
        for sale and for delivering the Products to Retailer(s) in accordance with the arrangements
        made between Brand and Retailer.
      </p>
      <p>
        Retailer(s) are solely responsible for determining the value of the Products being
        purchased, to pay the purchase price to the Brand and to arrange for shipping of the
        Products purchased.
      </p>
      <p>
        The role of Landing is expressly limited as set forth herein. Landing relies on the Brand(s)
        and Retailer(s) for the information each provides on the Marketplace and is not responsible
        in any way for: (i) the description of the Products and their ingredients that are provided
        by the Brand(s), (ii)the pricing of Products on the Marketplace provided by Brand(s), or
        (iii) the terms and conditions provided by Retailer(s). Landing is not responsible for the
        transfer of legal ownership of Products.
      </p>
      <p>
        Products Available on the Marketplace.
      </p>
      <p>
        All Products displayed on the Marketplace are offered for sale subject to availability. The
        Marketplace is designed to provide the Retailer(s) access to Products as the Brand(s)
        present them. Accordingly, Landing does not verify any information provided by the Brand(s)
        (or its representative selling an Products) and Landing makes no representations or
        warranties with respect to the Brand, the Retailer, the Products or the information related
        to the Products.
      </p>
      <p>The Sale Process.</p>
      <p>
        When a Retailer identifies product(s) for purchase, the Retailer makes an offer to purchase
        the product(s) including all margin, payment, damage and shipping terms (“Offer”) directly
        through the Marketplace’s checkout page. By making an Offer, the Retailer irrevocably agrees
        to pay for the Products on the terms specified.
      </p>
      <p>
        The Brand, at its sole discretion, may: (i) accept the Offer; (ii) decline the Offer; or
        (iii) make a counter-offer to the Retailer (“Counter-Offer”). By making a Counter-Offer, the
        Brand agrees to sell the Products to the Retailer at the Counter-Offer price and conditions.
      </p>
      <p>
        Shipment and payment will be handled per the terms of an accepted Offer or Counter-Offer as
        the case may be. Any shipment discrepancy must be reported to the Brand within thirty (30)
        days of receipt of the Product(s) by the Retailer.
      </p>
      <p>
        Landing shall collect and remit payment to Brand(s) pursuant to the Offer or Counter-Offer,
        as the case may be as part of its Services.
      </p>
      <p>
        Due to the nature of the internet, occasional glitches, service interruptions or mistakes
        may cause unintended inaccuracies to appear on the Marketplace. Landing has the right to
        correct any inaccuracies or mistakes, and to void any purchase of an Products that displays
        an inaccurate price or description once brought to our attention.
      </p>
      <p>
        Retailer will pay all charges, including without limitation transportation charges,
        insurance premiums, and shall be responsible for all taxes (except Brand’s U.S. income
        taxes), duties, costs of compliance with export and import controls and regulations, and
        other governmental assessments.
      </p>
      <p>Warranties</p>
      <p>
        Brand warrants to Retailer and to Landing that the Products, when shipped to Retailer by
        Brand, will conform in all material respects to the applicable specifications sheets shipped
        with the Products. Such warranty does not apply to units that have been damaged, mishandled,
        mistreated; used or maintained or stored other than in conformity with such specifications
        and Brand's instructions; or modified, altered or repaired in any manner by any party other
        than Brand.
        <span style="font-size: 25px"
          >RETAILER’S SOLE AND EXCLUSIVE REMEDY FOR ANY BREACH OF THE FOREGOING WARRANTY SHALL BE
          THE REPLACEMENT OF OR (AT BRAND'S OPTION OR IF REPLACEMENT IS IMPRACTICAL) REFUND FOR
          RETURNED NON-CONFORMING UNITS OF PRODUCT FOR WHICH FULL DOCUMENTATION AND PROOF OF
          NON-CONFORMITY IS PROVIDED TO BRAND WITHIN NINETY DAYS AFTER THE ORIGINAL NON-CONFORMING
          UNITS ARE SHIPPED BY BRAND. EXCEPT FOR THE FOREGOING WARRANTIES, BRAND DOES NOT MAKE (AND
          HAS NOT AUTHORIZED ANYONE TO MAKE) ANY EXPRESS OR IMPLIED WARRANTY, INCLUDING, WITHOUT
          LIMITATION, ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR
          NONINFRINGEMENT.</span
        >
      </p>
      <p>
        Remedies for Non-payment.
      </p>
      <p>
        If for any reason the Retailer cancels payment made by credit card or any other means
        following receipt of the Order Confirmation, or the Retailer otherwise fails to make any
        payment with respect to the Total Purchase Price (“Default”), then the Retailer shall remain
        liable to the Brand for the Total Purchase Price in full, as well as any additional costs
        related to the sale of the Products, including but not limited to applicable taxes, storage
        and handling fees and any incurred costs or fees associated with collection of any amount
        due to Landing and/or the Brand including but not limited to legal fees and costs related to
        currency fluctuations.
      </p>
      <p>
        Without limitation to any other provision of these Terms, in addition to any other remedies
        at law or equity, Landing reserves the right, at its election, to retain any and all
        payments paid by Retailer prior to Default with respect to the Products as liquidated
        damages, and to cancel the sale of the Products without any further obligation to Retailer.
      </p>
      <p style="margin-top: 150px">
        Logistics by Landing Terms & Conditions
      </p>
      <p>
        Your use of Landing’s Services is at all times subject to the Terms of Use, which
        incorporates these Logistics by Landing Terms and Conditions. Any terms we use in these
        Logistics by Landing Terms and Conditions without defining them have the definitions given
        to them in the Terms of Use (the “Terms”). Any term capitalized, but not defined herein,
        shall have the meaning set forth in the Terms.
      </p>
      <p>
        In connection with a sale between a Brand and Retailer pursuant to the Services provided by
        Landing, the Brand desires to store consigned inventory of certain Products (the “Consigned
        Product”) at Landing’s third-party logistics premises (hereinafter referred to as the
        “Premises”). Further, the Brand desires Landing to provide fulfillment and shipping services
        related to Offers; provided that, Landing has the ability to change the third-party
        logistics partner at its sole discretion. Brand is willing to provide such Consigned
        Products to Landing at the Premises from time to time upon the following terms and
        conditions:
      </p>
      <p style="padding-left: 30px">
        From time to time, in order to fulfill Offers, Landing and Brand shall mutually determine
        and confirm in writing the amount of Consigned Product to be stored at the Premises. Brand
        shall safely deliver the Consigned Product according to the guidelines provided by Landing
        at Brand’s expense, except as mutually determined by the parties.
      </p>
      <p style="padding-left: 30px">
        Landing will manage fulfillment and shipment of all Offers or Offers from specific Retailers
        as expressly approved by you on website.
      </p>
      <p style="padding-left: 30px">
        Except as withdrawn to fulfill Offers, Landing shall not remove the Consigned Product from
        the Premises. Brand may remove any Consigned Products from the Premises from time to time
        upon reasonable advance notice to Landing.
      </p>
      <p style="padding-left: 30px">
        Charges for these Services will be as accepted by you on our website from time to time and
        deduced prior to any remittances issued to you pursuant to Orders.
      </p>
      <p style="padding-left: 30px">
        Unless caused by employees of Brand, Landing shall be responsible for all loss, damage or
        shortages to the Consigned Product that is stored at the Premises; provided that the we will
        be allowed an annual allowance for inventory shrinkage of 1.5% of the inventory stored at
        the Premises.
      </p>
      <p style="padding-left: 30px">
        Upon the termination of the Logistics by Landing Services by the Brand, Landing shall
        determine whether we will either promptly return any of the goods remaining in the Consigned
        Product at our sole cost and expense or shall promptly pay for the balance of goods in the
        Consigned Product at the Brand’s actual cost for such goods in effect at the time of
        termination.
      </p>
      <p style="padding-left: 30px">
        It is acknowledged that Consigned Product is the property of Brand, until such time as the
        Landing removes the item to fulfill Offers in the ordinary course of its business.
      </p>
      <!--      !!!!!!2022-9-27 Give up  !!!!!!!!!!-->
      <!--      <p>-->
      <!--        PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES (DEFINED BELOW) ARE SUBJECT TO THE-->
      <!--        FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE-->
      <!--        SERVICES IN ANY MANNER.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="display: block;text-align: center;font-size: 20px">Terms of Use</span>-->
      <!--        <br />-->
      <!--        Welcome to Landing International, Inc. Please read on to learn the rules and restrictions-->
      <!--        that govern your use of our website(s), products, services and applications (the-->
      <!--        “Services”). If you have any questions, comments, or concerns regarding these terms or the-->
      <!--        Services, please contact us at-->
      <!--        <a target="_blank" class="terms__link_color" href="mailto:terms@landingintl.com"-->
      <!--          >terms@landingintl.com</a-->
      <!--        >.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        These Terms of Use (the “Terms”) are a binding contract between you and Landing-->
      <!--        International, Inc. (“Landing”, “we” and “us”). You must agree to and accept all of the-->
      <!--        Terms, or you don’t have the right to use the Services. Your using the Services in any way-->
      <!--        means that you agree to all of these Terms, and these Terms will remain in effect while you-->
      <!--        use the Services. These Terms include the provisions in this document, as well as those in-->
      <!--        the Privacy Policy, and, with respect to any purchase you make using the Services, the-->
      <!--        Purchase Order Terms and Conditions.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">Updates to Terms & Conditions</span>-->
      <!--        <br />-->
      <!--        We are constantly trying to improve our Services, so these Terms may need to change along-->
      <!--        with the Services. We reserve the right to change the Terms at any time, but if we do, we-->
      <!--        will bring it to your attention by placing a notice on the www.landinginternational.com-->
      <!--        website, by sending you an email, and/or by some other means.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        If you don’t agree with the new Terms, you are free to reject them; unfortunately, that-->
      <!--        means you will no longer be able to use the Services. If you use the Services in any way-->
      <!--        after a change to the Terms is effective, that means you agree to all of the changes.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Except for changes by us as described here, no other amendment or modification of these-->
      <!--        Terms will be effective unless in writing and signed by both you and us.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">Privacy</span>-->
      <!--        <br />-->
      <!--        Landing takes the privacy of its users very seriously. Please review Landing’s current-->
      <!--        Privacy Policy to learn more.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        The Children’s Online Privacy Protection Act (“COPPA”) requires that online service-->
      <!--        providers obtain parental consent before they knowingly collect personally identifiable-->
      <!--        information online from children who are under 13. We do not knowingly collect or solicit-->
      <!--        personally identifiable information from children under 13; if you are a child under 13,-->
      <!--        please do not attempt to register for the Services or send any personal information about-->
      <!--        yourself to us. If we learn we have collected personal information from a child under 13, we-->
      <!--        will delete that information as quickly as possible. If you believe that a child under 13-->
      <!--        may have provided us personal information, please contact us at-->
      <!--        <a target="_blank" class="terms__link_color" href="mailto:privacy@landingintl.com"-->
      <!--          >privacy@landingintl.com</a-->
      <!--        >.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">Our Services</span>-->
      <!--        <br />-->
      <!--        You may be required to sign up for an account, and select a password and username (“Landing-->
      <!--        User ID”). You promise to provide us with accurate, complete, and updated registration-->
      <!--        information about yourself. You may not select as your Landing User ID a name that you don’t-->
      <!--        have the right to use, or another person’s name with the intent to impersonate that person.-->
      <!--        You may not transfer your account to anyone else without our prior written permission.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        You represent and warrant that you are of legal age to form a binding contract (or if not,-->
      <!--        you’ve received your parent’s or guardian’s permission to use the Services and gotten your-->
      <!--        parent or guardian to agree to these Terms on your behalf). If you’re agreeing to these-->
      <!--        Terms on behalf of an organization or entity, you represent and warrant that you are-->
      <!--        authorized to agree to these Terms on that organization or entity’s behalf and bind them to-->
      <!--        these Terms (in which case, the references to “you” and “your” in these Terms, except for in-->
      <!--        this sentence, refer to that organization or entity).-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        You will only use the Services for your own internal, personal use, and not on behalf of or-->
      <!--        for the benefit of any third party, and only in a manner that complies with all laws that-->
      <!--        apply to you. If your use of the Services is prohibited by applicable laws, then you aren’t-->
      <!--        authorized to use the Services. We can’t and won’t be responsible for your using the-->
      <!--        Services in a way that breaks the law.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        You will not share your account or password with anyone, and you must protect the security-->
      <!--        of your account and your password. You’re responsible for any activity associated with your-->
      <!--        account.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Your use of the Services is subject to the following additional restrictions:-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        You represent, warrant, and agree that you will not contribute any Content or User-->
      <!--        Submission (each of those terms is defined below) or otherwise use the Services or interact-->
      <!--        with the Services in a manner that:-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Infringes or violates the intellectual property rights or any other rights of anyone else-->
      <!--        (including Landing);-->
      <!--        <br />-->
      <!--        Violates any law or regulation, including any applicable export control laws;-->
      <!--        <br />-->
      <!--        Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise-->
      <!--        objectionable;-->
      <!--        <br />-->
      <!--        Jeopardizes the security of your Landing account or anyone else’s (such as allowing someone-->
      <!--        else to log in to the Services as you);-->
      <!--        <br />-->
      <!--        Attempts, in any manner, to obtain the password, account, or other security information from-->
      <!--        any other user;-->
      <!--        <br />-->
      <!--        Violates the security of any computer network, or cracks any passwords or security-->
      <!--        encryption codes;-->
      <!--        <br />-->
      <!--        Runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any-->
      <!--        processes that run or are activated while you are not logged into the Services, or that-->
      <!--        otherwise interfere with the proper working of the Services (including by placing an-->
      <!--        unreasonable load on the Services’ infrastructure);-->
      <!--        <br />-->
      <!--        “Crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services-->
      <!--        or Content (through use of manual or automated means);-->
      <!--        <br />-->
      <!--        Copies or stores any significant portion of the Content;-->
      <!--        <br />-->
      <!--        Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying-->
      <!--        ideas or information of or relating to the Services.-->
      <!--        <br />-->
      <!--        Confidentiality: In addition, you understand that Landing has disclosed or may disclose-->
      <!--        information relating to Landing’s technology or business (including pricing accessible only-->
      <!--        to authorized users) (hereinafter referred to as “Proprietary Information”). You agree: (i)-->
      <!--        not to divulge to any third person any such Proprietary Information, (i) to give access to-->
      <!--        such Proprietary information solely to those employees with a need to have access thereto-->
      <!--        for purposes of these Terms, and (iii) to take the same security precautions to protect-->
      <!--        against disclosure or unauthorized use of such Proprietary Information that you take with-->
      <!--        your own proprietary information, but in no event less than reasonable precautions to-->
      <!--        protect such Proprietary Information. The foregoing will not apply with respect to any-->
      <!--        information that you can document (a) is or becomes generally available to the public other-->
      <!--        than through your own wrongful act, or (b) was in your possession or known by you prior to-->
      <!--        receipt from us, or (c) was rightfully disclosed to you without restriction by a third-->
      <!--        party, or (d) was independently developed without use of any Proprietary Information.-->
      <!--        Nothing in this Agreement will prevent you from disclosing the Proprietary Information-->
      <!--        pursuant to any judicial or governmental order, provided that you give us reasonable prior-->
      <!--        notice of such disclosure to contest such order. A violation of any of the foregoing is-->
      <!--        grounds for termination of your right to use or access the Services.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">Your Rights</span>-->
      <!--        <br />-->
      <!--        The materials displayed or performed or available on or through the Services, including, but-->
      <!--        not limited to, text, graphics, data, articles, photos, images, illustrations, User-->
      <!--        Submissions, and so forth (all of the foregoing, the “Content”) are protected by copyright-->
      <!--        and/or other intellectual property laws. You promise to abide by all copyright notices,-->
      <!--        trademark rules, information, and restrictions contained in any Content you access through-->
      <!--        the Services, and you won’t use, copy, reproduce, modify, translate, publish, broadcast,-->
      <!--        transmit, distribute, perform, upload, display, license, sell or otherwise exploit for any-->
      <!--        purpose any Content not owned by you, (i) without the prior consent of the owner of that-->
      <!--        Content or (ii) in a way that violates someone else’s (including Landing’s) rights.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        You understand that Landing owns the Services. You won’t modify, publish, transmit,-->
      <!--        participate in the transfer or sale of, reproduce (except as expressly provided in this-->
      <!--        Section), create derivative works based on, or otherwise exploit any of the Services.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        The Services may allow you to copy or download certain Content; please remember that just-->
      <!--        because this functionality exists, doesn’t mean that all the restrictions above don’t apply-->
      <!--        – they do!-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">License Grants</span>-->
      <!--        <br />-->
      <!--        Anything you post, upload, share, store, or otherwise provide through the Services is your-->
      <!--        “User Submission.” Some User Submissions may be viewable by other users. In order to display-->
      <!--        your User Submissions on the Services, and to allow other users to enjoy them (where-->
      <!--        applicable), you grant us certain rights in those User Submissions. Please note that all of-->
      <!--        the following licenses are subject to our Privacy Policy to the extent they relate to User-->
      <!--        Submissions that are also your personally-identifiable information.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        For all User Submissions, you hereby grant Landing a license to translate, modify (for-->
      <!--        technical purposes, for example making sure your content is viewable on a mobile phone as-->
      <!--        well as a computer) and reproduce and otherwise act with respect to such User Submissions,-->
      <!--        in each case to enable us to operate the Services, as described in more detail below. This-->
      <!--        is a license only – your ownership in User Submissions is not affected.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        If you store a User Submission in your own personal Landing account or input a temporary-->
      <!--        User Submission, in each case in a manner that is not viewable by any other user except you-->
      <!--        (a “Personal User Submission”), you grant Landing the license above, as well as a license to-->
      <!--        display, perform, and distribute your Personal User Submission for the sole purpose of-->
      <!--        making that Personal User Submission accessible to you and providing the Services necessary-->
      <!--        to do so.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        If you share a User Submission only in a manner that only certain specified users can view-->
      <!--        (for example, a private message to one or more other users) (a “Limited Audience User-->
      <!--        Submission”), then you grant Landing the licenses above, as well as a license to display,-->
      <!--        perform, and distribute your Limited Audience User Submission for the sole purpose of making-->
      <!--        that Limited Audience User Submission accessible to such other specified users, and-->
      <!--        providing the Services necessary to do so. Also, you grant such other specified users a-->
      <!--        license to access that Limited Audience User Submission, and to use and exercise all rights-->
      <!--        in it, as permitted by the functionality of the Services.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        If you share a User Submission publicly on the Services and/or in a manner that more than-->
      <!--        just you or certain specified users can view, or if you provide us (in a direct email or-->
      <!--        otherwise) with any feedback, suggestions, improvements, enhancements, and/or feature-->
      <!--        requests relating to the Services (each of the foregoing, a “Public User Submission”), then-->
      <!--        you grant Landing the licenses above, as well as a license to display, perform, and-->
      <!--        distribute your Public User Submission for the purpose of making that Public User Submission-->
      <!--        accessible to all Landing users and providing the Services necessary to do so, as well as-->
      <!--        all other rights necessary to use and exercise all rights in that Public User Submission in-->
      <!--        connection with the Services and/or otherwise in connection with Landing’s business for any-->
      <!--        purpose. Also, you grant all other users of the Services a license to access that Public-->
      <!--        User Submission, and to use and exercise all rights in it, as permitted by the functionality-->
      <!--        of the Services.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        You agree that the licenses you grant are royalty-free, perpetual, sublicensable,-->
      <!--        irrevocable, and worldwide, provided that when you delete your Landing account, we will stop-->
      <!--        displaying your User Submissions (other than Public User Submissions, which may remain fully-->
      <!--        available) to other users (if applicable), but you understand and agree that it may not be-->
      <!--        possible to completely delete that content from Landing’s records, and that your User-->
      <!--        Submissions may remain viewable elsewhere to the extent that they were copied or stored by-->
      <!--        other users.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Finally, you understand and agree that Landing, in performing the required technical steps-->
      <!--        to provide the Services to our users (including you), may need to make changes to your User-->
      <!--        Submissions to conform and adapt those User Submissions to the technical requirements of-->
      <!--        connection networks, devices, services, or media, and the foregoing licenses include the-->
      <!--        rights to do so.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">Copyright Infringement</span>-->
      <!--        <br />-->
      <!--        You may have heard of the Digital Millennium Copyright Act (the “DMCA”), as it relates to-->
      <!--        online service providers, like Landing, being asked to remove material that allegedly-->
      <!--        violates someone’s copyright. We respect others’ intellectual property rights, and we-->
      <!--        reserve the right to delete or disable Content alleged to be infringing, and to terminate-->
      <!--        the accounts of repeat alleged infringers; to review our complete Copyright Dispute Policy-->
      <!--        and learn how to report potentially infringing content, click here. To learn more about the-->
      <!--        DMCA,-->
      <!--        <a-->
      <!--          target="_blank"-->
      <!--          class="terms__link_color"-->
      <!--          href="https://www.copyright.gov/title17/92appb.html"-->
      <!--          >click here</a-->
      <!--        >.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">Use of Services</span>-->
      <!--        <br />-->
      <!--        Any information or content publicly posted or privately transmitted through the Services is-->
      <!--        the sole responsibility of the person from whom such content originated, and you access all-->
      <!--        such information and content at your own risk, and we aren’t liable for any errors or-->
      <!--        omissions in that information or content or for any damages or loss you might suffer in-->
      <!--        connection with it. We cannot control and have no duty to take any action regarding how you-->
      <!--        may interpret and use the Content or what actions you may take as a result of having been-->
      <!--        exposed to the Content, and you hereby release us from all liability for you having acquired-->
      <!--        or not acquired Content through the Services. We can’t guarantee the identity of any users-->
      <!--        with whom you interact in using the Services and are not responsible for which users gain-->
      <!--        access to the Services.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        You are responsible for all Content you contribute, in any manner, to the Services, and you-->
      <!--        represent and warrant you have all rights necessary to do so, in the manner in which you-->
      <!--        contribute it. You will keep all your registration information accurate and current. You are-->
      <!--        responsible for all your activity in connection with the Services.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        The Services may contain links or connections to third party websites or services that are-->
      <!--        not owned or controlled by Landing. When you access third party websites or use third party-->
      <!--        services, you accept that there are risks in doing so, and that Landing is not responsible-->
      <!--        for such risks. We encourage you to be aware when you leave the Services and to read the-->
      <!--        terms and conditions and privacy policy of each third party website or service that you-->
      <!--        visit or utilize.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Landing has no control over, and assumes no responsibility for, the content, accuracy,-->
      <!--        privacy policies, or practices of or opinions expressed in any third party websites or by-->
      <!--        any third party that you interact with through the Services. In addition, Landing will not-->
      <!--        and cannot monitor, verify, censor or edit the content of any third party Marketplace or-->
      <!--        service. By using the Services, you release and hold us harmless from any and all liability-->
      <!--        arising from your use of any third party website or service.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Your interactions with organizations and/or individuals found on or through the Services,-->
      <!--        including payment and delivery of goods and any other terms, conditions, warranties or-->
      <!--        representations associated with such dealings, are solely between you and such organizations-->
      <!--        and/or individuals. You should make whatever investigation you feel necessary or appropriate-->
      <!--        before proceeding with any online or offline transaction with any of these third parties.-->
      <!--        You agree that Landing shall not be responsible or liable for any loss or damage of any sort-->
      <!--        incurred as the result of any such dealings.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        If there is a dispute between participants on this Marketplace, or between users and any-->
      <!--        third party, you agree that Landing is under no obligation to become involved. In the event-->
      <!--        that you have a dispute with one or more other users, you release Landing, its officers,-->
      <!--        employees, agents, and successors from claims, demands, and damages of every kind or nature,-->
      <!--        known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in-->
      <!--        any way related to such disputes and/or our Services. If you are a California resident, you-->
      <!--        shall and hereby do waive California Civil Code Section 1542, which says: "A general release-->
      <!--        does not extend to claims which the creditor does not know or suspect to exist in his or her-->
      <!--        favor at the time of executing the release, which, if known by him or her must have-->
      <!--        materially affected his or her settlement with the debtor."-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">Changes to Services</span>-->
      <!--        <br />-->
      <!--        We’re always trying to improve the Services, so they may change over time. We may suspend or-->
      <!--        discontinue any part of the Services, or we may introduce new features or impose limits on-->
      <!--        certain features or restrict access to parts or all of the Services. We’ll try to give you-->
      <!--        notice when we make a material change to the Services that would adversely affect you, but-->
      <!--        this isn’t always practical. Similarly, we reserve the right to remove any Content from the-->
      <!--        Services at any time, for any reason (including, but not limited to, if someone alleges you-->
      <!--        contributed that Content in violation of these Terms), in our sole discretion, and without-->
      <!--        notice.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">Pricing Terms</span>-->
      <!--        <br />-->
      <!--        We reserve the right to charge for certain or all Services. Charges for Services are as-->
      <!--        described in your settings pages. USe of Services, requires payment of all applicable fees-->
      <!--        for such Services.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        With respect to any purchase you make using the Services,-->
      <!--        <a href="#name" class="terms__link_color">the Purchase Order Terms and Conditions </a>-->
      <!--        apply.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Where applicable, you shall pay all fees described by the Services in connection with such-->
      <!--        for-fee products/services purchased by you in the manner specified on or selected through-->
      <!--        the Services (or as otherwise agreed upon in writing by you and us). Unless otherwise-->
      <!--        specified, the following terms apply to all purchases.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        We may use a third-party payment processor (the “Payment Processor”) to bill you through a-->
      <!--        payment account, such as your bank account or debit or credit card, linked to your account-->
      <!--        on the Services (your “Billing Account”). The processing of payments will be subject to the-->
      <!--        terms, conditions and privacy policies of the Payment Processor in addition to these Terms.-->
      <!--        We are not responsible for error by the Payment Processor. By choosing to use for-fee-->
      <!--        Services or purchase products, you agree to pay us, through the Payment Processor, all-->
      <!--        charges at the prices then in effect for any use of such Services or purchase of such-->
      <!--        products in accordance with the applicable payment terms and you authorize us, through the-->
      <!--        Payment Processor, to charge your chosen payment provider (your “Payment Method”). You agree-->
      <!--        to make payment using that selected Payment Method. We reserve the right to correct any-->
      <!--        errors or mistakes that it makes even if it has already requested or received payment.-->
      <!--        <br />-->
      <!--        Payment Method. The terms of your payment will be based on your Payment Method and may be-->
      <!--        determined by agreements between you and the financial institution, credit card issuer or-->
      <!--        other provider of your chosen Payment Method. If we, through the Payment Processor, do not-->
      <!--        receive payment from you, you agree to pay all amounts due upon demand.-->
      <!--        <br />-->
      <!--        Recurring Billing. Some of the for-fee Services and product purchases may consist of an-->
      <!--        initial period, for which there is a one-time charge, followed by recurring period charges-->
      <!--        as agreed to by you. By choosing a recurring payment plan, you acknowledge that such-->
      <!--        Services have an initial and recurring payment feature and you accept responsibility for all-->
      <!--        recurring charges prior to cancellation. WE MAY SUBMIT PERIODIC CHARGES (E.G., MONTHLY)-->
      <!--        WITHOUT FURTHER AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS-->
      <!--        CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR WISH TO CHANGE YOUR PAYMENT-->
      <!--        METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED BEFORE WE REASONABLY COULD ACT. TO-->
      <!--        TERMINATE YOUR AUTHORIZATION OR CHANGE YOUR PAYMENT METHOD, GO TO ACCOUNT PAGE.-->
      <!--        <br />-->
      <!--        Current Information Required. YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION-->
      <!--        FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING-->
      <!--        ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD-->
      <!--        NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT-->
      <!--        PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME-->
      <!--        AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR-->
      <!--        USER NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE AT ACCOUNT PAGE. IF YOU FAIL-->
      <!--        TO PROVIDE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR-->
      <!--        ANY USE OF FOR-FEE SERVICES OR PURCHASES OF PRODUCTS UNDER YOUR ACCOUNT.-->
      <!--        <br />-->
      <!--        Change in Amount Authorized. If the amount to be charged to your Billing Account varies from-->
      <!--        the amount you preauthorized (other than due to the imposition or change in the amount of-->
      <!--        state sales taxes), you have the right to receive, and we shall provide, notice of the-->
      <!--        amount to be charged and the date of the charge before the scheduled date of the-->
      <!--        transaction. Any agreement you have with your payment provider will govern your use of your-->
      <!--        Payment Method. You agree that we may accumulate charges incurred and submit them as one or-->
      <!--        more aggregate charges during or at the end of each billing cycle.-->
      <!--        <br />-->
      <!--        Reaffirmation of Authorization. Your non-termination or continued use of a for-fee service-->
      <!--        reaffirms that we are authorized to charge your Payment Method for that service. We may-->
      <!--        submit those charges for payment and you will be responsible for such charges. This does not-->
      <!--        waive our right to seek payment directly from you. Your charges may be payable in advance,-->
      <!--        in arrears, per usage, or as otherwise described when you initially selected to use the-->
      <!--        for-fee service.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Termination-->
      <!--        <br />-->
      <!--        You’re free to terminate the Services at any time, by contacting us at-->
      <!--        <a target="_blank" class="terms__link_color" href="mailto:support@landingintl.com"-->
      <!--          >support@landingintl.com</a-->
      <!--        >; please refer to our Privacy Policy, as well as the licenses above, to understand how we-->
      <!--        treat information you provide to us after you have stopped using our Services.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Landing is also free to terminate (or suspend access to) your use of the Services or your-->
      <!--        account, for any reason in our discretion, including your breach of these Terms. Landing has-->
      <!--        the sole right to decide whether you are in violation of any of the restrictions set forth-->
      <!--        in these-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Account termination may result in destruction of any Content associated with your account,-->
      <!--        so keep that in mind before you decide to terminate your account.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Provisions that, by their nature, should survive termination of these Terms shall survive-->
      <!--        termination. By way of example, all of the following will survive termination: any-->
      <!--        obligation you have to pay us or indemnify us, any limitations on our liability, any terms-->
      <!--        regarding ownership or intellectual property rights, and terms regarding disputes between-->
      <!--        us.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Miscellaneous-->
      <!--        <br />-->
      <!--        Warranty Disclaimer. Neither Landing nor its licensors or suppliers makes any-->
      <!--        representations or warranties concerning any content contained in or accessed through the-->
      <!--        Services, and we will not be responsible or liable for the accuracy, copyright compliance,-->
      <!--        legality, or decency of material contained in or accessed through the Services. We (and our-->
      <!--        licensors and suppliers) make no representations or warranties regarding suggestions or-->
      <!--        recommendations of services or products offered or purchased through the Services. Products-->
      <!--        and services purchased or offered (whether or not following such recommendations and-->
      <!--        suggestions) through the Services are provided “AS IS” and without any warranty of any kind-->
      <!--        from Landing or others (unless provided expressly and unambiguously in writing for a-->
      <!--        specific product). THE SERVICES AND CONTENT ARE PROVIDED BY LANDING (AND ITS LICENSORS AND-->
      <!--        SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,-->
      <!--        INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A-->
      <!--        PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR-->
      <!--        ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO-->
      <!--        THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Limitation of Liability. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO-->
      <!--        CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT,-->
      <!--        STRICT LIABILITY, OR OTHERWISE) SHALL LANDING (OR ITS LICENSORS OR SUPPLIERS) BE LIABLE TO-->
      <!--        YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL-->
      <!--        DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE,-->
      <!--        ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE-->
      <!--        AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO LANDING-->
      <!--        IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE-->
      <!--        CLAIM, OR (III) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE-->
      <!--        EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT-->
      <!--        APPLY TO YOU.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Indemnity. To the fullest extent allowed by applicable law, you agree to indemnify and hold-->
      <!--        Landing, its affiliates, officers, agents, employees, and partners harmless from and against-->
      <!--        any and all claims, liabilities, damages (actual and consequential), losses and expenses-->
      <!--        (including attorneys’ fees) arising from or in any way related to any third party claims-->
      <!--        relating to (a) your use of the Services (including any actions taken by a third party using-->
      <!--        your account), and (b) your violation of these Terms. In the event of such a claim, suit, or-->
      <!--        action (“Claim”), we will attempt to provide notice of the Claim to the contact information-->
      <!--        we have for your account (provided that failure to deliver such notice shall not eliminate-->
      <!--        or reduce your indemnification obligations hereunder).-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Assignment. You may not assign, delegate or transfer these Terms or your rights or-->
      <!--        obligations hereunder, or your Services account, in any way (by operation of law or-->
      <!--        otherwise) without Landing’s prior written consent. We may transfer, assign, or delegate-->
      <!--        these Terms and our rights and obligations without consent.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Choice of Law; Arbitration. These Terms are governed by and will be construed under the laws-->
      <!--        of the State of California, without regard to the conflicts of laws provisions thereof. Any-->
      <!--        dispute arising from or relating to the subject matter of these Terms shall be finally-->
      <!--        settled in Los Angeles County, California, in English, in accordance with the Streamlined-->
      <!--        Arbitration Rules and Procedures of Judicial Arbitration and Mediation Services, Inc.-->
      <!--        (“JAMS”) then in effect, by one commercial arbitrator with substantial experience in-->
      <!--        resolving intellectual property and commercial contract disputes, who shall be selected from-->
      <!--        the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the-->
      <!--        award rendered by such arbitrator may be entered in any court of competent jurisdiction.-->
      <!--        Notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the-->
      <!--        right to pursue injunctive or other equitable relief at any time, from any court of-->
      <!--        competent jurisdiction. For all purposes of these Terms, the parties consent to exclusive-->
      <!--        jurisdiction and venue in the state or federal courts located in, respectively, Los Angeles,-->
      <!--        California, or the Southern District of California.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <a name="name" />Miscellaneous. You will be responsible for paying, withholding, filing, and-->
      <!--        reporting all taxes, duties, and other governmental assessments associated with your-->
      <!--        activity in connection with the Services, provided that Landing may, in its sole discretion,-->
      <!--        do any of the foregoing on your behalf or for itself as it sees fit. The failure of either-->
      <!--        you or us to exercise, in any way, any right herein shall not be deemed a waiver of any-->
      <!--        further rights hereunder. If any provision of these Terms is found to be unenforceable or-->
      <!--        invalid, that provision will be limited or eliminated, to the minimum extent necessary, so-->
      <!--        that these Terms shall otherwise remain in full force and effect and enforceable. You and-->
      <!--        Landing agree that these Terms are the complete and exclusive statement of the mutual-->
      <!--        understanding between you and Landing, and that it supersedes and cancels all previous-->
      <!--        written and oral agreements, communications and other understandings relating to the subject-->
      <!--        matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent,-->
      <!--        partner, or joint venture of Landing, and you do not have any authority of any kind to bind-->
      <!--        Landing in any respect whatsoever. Except as expressly set forth in the section above-->
      <!--        regarding the Apple Application, you and Landing agree there are no third party-->
      <!--        beneficiaries intended under these Terms.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="display: block;text-align: center;font-size: 20px"-->
      <!--          >Purchase Order Terms & Conditions</span-->
      <!--        >-->
      <!--        <br />-->
      <!--        Your use of Landing’s Services is at all times subject to the Terms of Use, which-->
      <!--        incorporates these Purchase Order Terms and Conditions. Any terms we use in these Purchase-->
      <!--        Order Terms and Conditions without defining them have the definitions given to them in the-->
      <!--        Terms of Use.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">Marketplace</span><br />-->
      <!--        One of the Services is an online marketplace (the “Marketplace”) for appropriately qualified-->
      <!--        sellers (“Brands”) to offer to sell goods (“Products”) to prospective retailers-->
      <!--        (“Retailers”). In the event of a sale of any Products, unless otherwise specified, the sale-->
      <!--        is made directly between the Brand and Retailer, and is governed by this Purchase Order-->
      <!--        Terms and Conditions.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">The Role of Landing.</span><br />-->
      <!--        The role of Landing is expressly limited to making the Marketplace available and maintaining-->
      <!--        the Marketplace for Brands, Retailers or Users. Landing is an intermediary and not an agent-->
      <!--        or fiduciary for any Brand, Retailer or User for any purpose. Landing is not responsible for-->
      <!--        the actual sale of any Products and, unless otherwise indicated on the website, does not-->
      <!--        control the information provided by Brand, Retailer, or Users, nor their acts or omissions.-->
      <!--        Landing is independent from the Seller, Buyer and User, and no partnership, joint venture,-->
      <!--        employee-employer or franchiser-franchisee relationship is intended or created by the-->
      <!--        operation of this Marketplace by Landing.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Landing may, but is not obligated to, provide intermediary services between Retailer and-->
      <!--        Brand in connection with customer service or dispute resolution matters. In the event-->
      <!--        Landing elects in its sole discretion to provide intermediary services, then the decision of-->
      <!--        Landing is final and binding on all parties and cannot be appealed, challenged or reversed.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Landing charges a Transaction Fee to Brands for any orders processed through the-->
      <!--        Marketplace. Transaction Fees are displayed in the Settings Menu for Brands, and use of the-->
      <!--        Services are subject to Transaction Fees.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">Conditions of Sale</span>-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Relationship of the Parties.<br />-->
      <!--        Brand(s) are responsible for accurately describing and pricing the Products it is offering-->
      <!--        for sale and for delivering the Products to Retailer(s) in accordance with the arrangements-->
      <!--        made between Brand and Retailer.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Retailer(s) are solely responsible for determining the value of the Products being-->
      <!--        purchased, to pay the purchase price to the Seller and to arrange for shipping of the-->
      <!--        Products purchased.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        The role of Landing is expressly limited as set forth herein. Landing relies on the Brand(s)-->
      <!--        and Retailer(s) for the information each provides on the Marketplace and is not responsible-->
      <!--        in any way for the description or pricing of Products on the Marketplace provided by-->
      <!--        Brand(s) or terms and conditions provided by Retailer(s). Landing is not responsible for the-->
      <!--        transfer of legal ownership of Products.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">Products Available on the Marketplace.</span>-->
      <!--        <br />-->
      <!--        All Products displayed on the Marketplace are offered for sale subject to availability. The-->
      <!--        Marketplace is designed to provide the Retailer(s) access to Products as the Brand(s)-->
      <!--        present them. Accordingly, Landing does not verify any information provided by the Brand(s)-->
      <!--        (or its representative selling an Products) and Landing makes no representations or-->
      <!--        warranties with respect to the Retailer, the Products or the information related to the-->
      <!--        Products.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">The Sale Process.</span>-->
      <!--        <br />-->
      <!--        When a Retailer identifies product(s) for purchase, the Retailer makes an offer to purchase-->
      <!--        the product(s) including all margin, payment, damage and shipping terms (“Offer”) directly-->
      <!--        through the Marketplace’s checkout page. By making an Offer, the Retailer irrevocably agrees-->
      <!--        to pay for the Products on the Retailer’s terms.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        The Brand, at its sole discretion, may: (i) accept the Offer; (ii) decline the Offer; or-->
      <!--        (iii) make a counter-offer to the Retailer (“Counter-Offer”). By making a Counter-Offer, the-->
      <!--        Seller agrees to sell the Products to the Buyer at the Counter-Offer price and conditions.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Shipment and payment will be handled per the terms of an accepted Offer or Counter-Offer as-->
      <!--        the case may be. Any shipment discrepancy must be reported to Brand within thirty (30) days-->
      <!--        of receipt of the Product(s).-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Landing shall collect and remit payment to Brand(s) pursuant to the Offer or Counter-Offer,-->
      <!--        as the case may be as part of its Services.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Due to the nature of the internet, occasional glitches, service interruptions or mistakes-->
      <!--        may cause unintended inaccuracies to appear on the Marketplace. Landing has the right to-->
      <!--        correct any inaccuracies or mistakes, and to void any purchase of an Products that displays-->
      <!--        an inaccurate price or description once brought to our attention.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Retailer will pay all charges, including without limitation transportation charges,-->
      <!--        insurance premiums, and shall be responsible for all taxes (except Brand’s U.S. income-->
      <!--        taxes), duties, costs of compliance with export and import controls and regulations, and-->
      <!--        other governmental assessments.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">Warranties</span>-->
      <!--        <br />-->
      <!--        Brand warrants only to Retailer that the Products, when shipped to Buyer by Supplier, will-->
      <!--        conform in all material respects to the applicable specifications sheets shipped with the-->
      <!--        Products. Such warranty does not apply to units that have been damaged, mishandled,-->
      <!--        mistreated; used or maintained or stored other than in conformity with such specifications-->
      <!--        and Brand's instructions; or modified, altered or repaired in any manner by any party other-->
      <!--        than Brand. RETAILER’S SOLE AND EXCLUSIVE REMEDY FOR ANY BREACH OF THE FOREGOING WARRANTY-->
      <!--        SHALL BE THE REPLACEMENT OF OR (AT BRAND'S OPTION OR IF REPLACEMENT IS IMPRACTICAL) REFUND-->
      <!--        FOR RETURNED NON-CONFORMING UNITS OF PRODUCT FOR WHICH FULL DOCUMENTATION AND PROOF OF-->
      <!--        NON-CONFORMITY IS PROVIDED TO BRAND WITHIN NINETY DAYS AFTER THE ORIGINAL NON-CONFORMING-->
      <!--        UNITS ARE SHIPPED BY BRAND. EXCEPT FOR THE FOREGOING WARRANTIES, BRAND DOES NOT MAKE (AND-->
      <!--        HAS NOT AUTHORIZED ANYONE TO MAKE) ANY EXPRESS OR IMPLIED WARRANTY, INCLUDING, WITHOUT-->
      <!--        LIMITATION, ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR-->
      <!--        NONINFRINGEMENT.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <span style="text-decoration: underline">Remedies for Non-payment.</span>-->
      <!--        <br />-->
      <!--        If for any reason the Buyer cancels payment made by credit card or any other means following-->
      <!--        receipt of the Order Confirmation, or the Buyer otherwise fails to make any payment with-->
      <!--        respect to the Total Purchase Price (“Default”), then the Buyer shall remain liable to the-->
      <!--        Seller for the Total Purchase Price in full, as well as any additional costs related to the-->
      <!--        sale of the Products, including but not limited to applicable taxes, storage and handling-->
      <!--        fees and any incurred costs or fees associated with collection of any amount due to Landing-->
      <!--        and/or the Seller including but not limited to legal fees and costs related to currency-->
      <!--        fluctuations.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        Without limitation to any other provision of these Terms, in addition to any other remedies-->
      <!--        at law or equity, Landing reserves the right, at its election, to retain any and all-->
      <!--        payments paid by Buyer prior to Default with respect to the Products as liquidated damages,-->
      <!--        and to cancel the sale of the Products without any further obligation to Buyer.-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        <br />-->
      <!--        &lt;!&ndash;        <span style="display: block;text-align: center;font-size: 20px">Logistics by Landing Terms & Conditions</span>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <br/>&ndash;&gt;-->
      <!--        &lt;!&ndash;        Your use of Landing’s Services is at all times subject to the Terms of Use, which incorporates these Logistics by Landing Terms and Conditions. Any terms we use in these Logistics by Landing Terms and Conditions without defining them have the definitions given to them in the Terms of Use (the “Terms”). Any term capitalized, but not defined herein, shall have the meaning set forth in the Terms.&ndash;&gt;-->
      <!--        &lt;!&ndash;        <br/>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <br/>&ndash;&gt;-->
      <!--        &lt;!&ndash;        In connection with a sale between a Brand and Retailer pursuant to the Services provided by the Company, the Brand desires to store consigned inventory of certain Products (the “Consigned Product”) at the Company’s third-party logistics premises (hereinafter referred to as the “Premises”). Further, the Brand desires the Company to provide fulfillment and shipping services related to Offers; provided that, the Company has the ability to change the third-party logistics partner at its sole discretion. Brand is willing to provide such Consigned Products to the Company at the Premises from time to time upon the following terms and conditions:&ndash;&gt;-->
      <!--        &lt;!&ndash;        <br/>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <br/>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <span style="display: block;padding-left: 40px">&ndash;&gt;-->
      <!--        &lt;!&ndash;          From time to time, in order to fulfill Offers, Company and Brand shall mutually determine and confirm in writing the amount of Consigned Product to be stored at the Premises. Brand shall safely deliver the Consigned Product according to the guidelines provided by Landing at Brand’s expense, except as mutually determined by the parties.</span>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <br/>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <span style="display: block;padding-left: 40px">Landing will manage fulfillment and shipment of all Offers or Offers from specific Retailers as expressly approved by you on website. </span>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <br/>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <span style="display: block;padding-left: 40px">Except as withdrawn to fulfill Offers, Company shall not remove the Consigned Product from the Premises. Brand may remove any Consigned Products from the Premises from time to time upon reasonable advance notice to the Company.</span>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <br/>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <span style="display: block;padding-left: 40px">Charges for these Services will be as accepted by you on our website from time to time and deduced prior to any remittances issued to you pursuant to Orders.</span>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <br/>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <span style="display: block;padding-left: 40px">Unless caused by employees of Brand, Landing shall be responsible for all loss, damage or shortages to the Consigned Product that is stored at the Premises; provided that the we will be allowed an annual allowance for inventory shrinkage of 1.5% of the inventory stored at the Premises. </span>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <br/>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <span style="display: block;padding-left: 40px">Upon the termination of the Logistics by Landing Services by the Brand, Landing shall determine whether we will either promptly return any of the goods remaining in the Consigned Product at our sole cost and expense or shall promptly pay for the balance of goods in the Consigned Product at the Brand’s actual cost for such goods in effect at the time of termination. </span>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <br/>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <span style="display: block;padding-left: 40px">It is acknowledged that Consigned Product is the property of Brand, until such time as the Landing removes the item to fulfill Offers in the ordinary course of its business.</span>&ndash;&gt;-->
      <!--      </p>-->
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    linkToHere() {
      const linkUrl = "https://www.beautyfluentpro.com/app-terms-and-conditions"
      window.open(linkUrl)
    }
  }
}
</script>
<style scoped lang="scss">
p {
  margin-bottom: 25px;
  //font-family: niveau-grotesk, sans-serif;
  font-family: "Mr Eaves Mod OT", sans-serif;
  font-size: 25px;
  font-weight: 300;
  line-height: 1.4;
  color: #000;
}
.terms {
  max-width: 783px;
  width: 85%;
  margin: 0 auto;
}
.terms__main-header {
  color: #12433d;
  font-size: 45px;
  font-weight: bold;
  font-family: "Playfair Display Bold";
  letter-spacing: 0.44px;
  margin-bottom: 40px;
  /*text-align: left;*/
  line-height: 1.2;
  text-align: center;
}
.terms__content {
  margin-bottom: 20px;
  font-family: "ProximaNova-Regular", "ProximaNova", "Roboto", sans-serif;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}
.terms-content-title {
  font-size: 25px;
  font-family: "Mr Eaves Mod OT", sans-serif;
  font-weight: 100 !important;
  margin-bottom: 60px;
  margin-top: 79px;
}
.terms-of-use__main {
  font-size: 65px;
  text-align: center;
  margin-bottom: 40px;
  color: #1a1818;
  /*font-family: niveau-grotesk, sans-serif;*/
}
.terms-privacy-data {
  margin-bottom: 59px;
  color: #91d395;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.terms__link_color {
  color: #008489;
}
</style>
