<template>
  <div ref="catalogProductDialog" class="brand-catalog-upload-product-dialog">
    <a-modal
      v-model="showUpload"
      width="600px"
      :footer="null"
      :get-container="() => $refs.catalogProductDialog"
      @cancel="handleCancelUpload"
    >
      <div class="upload-modal__body">
        <a-upload-dragger
          name="file"
          :multiple="false"
          :file-list="fileList"
          :show-upload-list="false"
          :before-upload="beforeUpload"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="cloud-upload" style="color: #4A8a5d" />
          </p>
          <p class="ant-upload-text">
            {{ $t("Admins.AdminOrders.dragUpload") }}
          </p>
          <p class="ant-upload-text">
            {{ $t("Admins.AdminOrders.or") }}
          </p>
          <a-button class="upload-modal__btn">
            {{ $t("Admins.AdminOrders.chooseFile") }}
          </a-button>
        </a-upload-dragger>
        <div v-if="fileList.length > 0" class="upload-modal__body">
          <a-row v-for="(item, index) in fileList" :key="index">
            <a-col :span="1">
              <a-icon type="paper-clip" />
            </a-col>
            <a-col :span="19">
              {{ item.name }}
            </a-col>
            <a-col :span="4">
              <a-icon type="delete" @click="handleRemove(item)" />
            </a-col>
          </a-row>
        </div>
        <div class="retailer-location-upload-modal__action">
          <a-button
            class="upload-modal__btn-upload"
            :disabled="fileList.length === 0"
            :loading="uploading"
            @click="handleUpload"
          >
            {{ $t("Admins.AdminOrders.upload") }}
          </a-button>
        </div>
      </div>
    </a-modal>
    <a-modal
      v-model="showReport"
      width="600px"
      :get-container="() => $refs.catalogProductDialog"
      :footer="null"
      @cancel="handleCancelReport"
    >
      <div class="upload-modal__body">
        <p class="ant-upload-text">
          <a-icon type="exclamation-circle" class="upload-modal__error-icon" />
          {{ $t("Admins.AdminOrders.errorTitle") }}
        </p>
        <p class="ant-upload-text" v-html="errors"></p>
      </div>
      <div style="display: flex">
        <a-button class="upload-modal__btn" :loading="isLoading" @click="downloadReports">{{
          $t("Admins.AdminOrders.orderDownload")
        }}</a-button>
      </div>
    </a-modal>
    <a-modal v-model="uploadSuccess" width="400px" :footer="null" @cancel="handleCancelSuccess">
      <a-result status="success" :title="$t('Admins.AdminOrders.uploadSuccess')" />
    </a-modal>
    <a-modal v-model="uploadFail" width="400px" :footer="null" @cancel="handleCancelFail">
      <a-result status="warning" :title="$t('Admins.AdminOrders.uploadFail')">
        <template #extra>
          <a-button type="primary" class="upload-modal__body" @click="showErrorReport">{{
            $t("Admins.AdminOrders.viewReports")
          }}</a-button>
        </template>
      </a-result>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment"
import { exportOrderExcel } from "@/utils/validate"

export default {
  name: "UploadProductDialog",
  data() {
    return {
      uploading: false,
      showUpload: false,
      fileList: [],
      showReport: false,
      errorReports: "",
      errors: "",
      isLoading: false,
      uploadSuccess: false,
      uploadFail: false
    }
  },
  methods: {
    openDialog() {
      this.showUpload = true
    },
    handleUpload() {
      if (this.fileList && this.fileList.length > 0) {
        let isFormatRight = true
        for (let i = 0; i < this.fileList.length; i++) {
          let name = this.fileList[i].name
          isFormatRight = this.isCsv(name)
          if (!isFormatRight) {
            let info = this.$t("Admins.AdminOrders.uploadFailInfo")
            this.$message.error(info)
            return
          }
        }
        // this.doUpload(this.fileList[0])
        this.$emit("doUpload", this.fileList[0])
      }
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload(file) {
      this.fileList = []
      this.fileList.push(file)
      return false
    },
    handleCancelUpload() {
      this.showUpload = false
      this.fileList = []
      this.$emit("fetchData")
    },
    isCsv(name) {
      if (name) {
        let index = name.lastIndexOf(".")
        let ext = name.substr(index + 1)
        if (ext.toLowerCase() === "csv") {
          return true
        }
      }
      return false
    },
    handleCancelReport() {
      this.showReport = false
      this.errorReports = ""
      this.errors = ""
    },
    handleCancelSuccess() {
      this.uploadSuccess = false
    },
    handleSuccess() {
      this.uploadSuccess = true
    },
    showErrorReport(errorReports, errors) {
      this.errorReports = errorReports
      this.errors = errors
      this.handleCancelFail()
      this.showReport = true
    },
    handleCancelFail() {
      this.uploadFail = false
    },
    downloadReports() {
      this.isLoading = true
      let data = new Blob([this.errorReports])
      let fileName = `upload_products_error_reports_${moment().format("YYYY-MM-DD HH23:mm:ss")}`
      exportOrderExcel(data, fileName).finally(() => {
        this.isLoading = false
        this.handleCancelReport()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.brand-catalog-upload-product-dialog {
  text-align: left;
  .retailer-location-upload-modal__action {
    margin: 10px auto;
    display: flex;
    justify-content: center;
  }
  .upload-modal__btn-upload {
    background-color: #4a8a5d;
    color: white;
  }
  .upload-modal__error-icon {
    color: red;
    margin-right: 10px;
  }
  .ant-upload-text {
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .upload-modal__btn {
    font-family: "Proxima Nova";
    font-weight: normal;
    font-size: 14px;
    margin: 0 auto;
    padding: 4px 20px;
    background-color: #4a8a5d;
    color: white !important;
  }
}
</style>
