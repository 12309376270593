<template>
  <div class="retailer-training-brand-tab">
    <div class="retailer-training-search">
      <div class="search-style search-input">
        <p>Search</p>
        <div>
          <a-input-search
            v-model="searchBrand"
            placeholder=" Search by Brand"
            :loading="isLoadingSearch"
            @search="searchBrandInfo"
            @change="changeValue"
          />
        </div>
      </div>
      <div class="search-style search-resent">
        <!--        <p>Recent</p>-->
        <!--        <div>-->
        <!--          <a-tag v-for="(tag, index) in tagList" :key="index" closable @close="closeTagItem(tag)">-->
        <!--            {{ tag.name }}-->
        <!--          </a-tag>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="retailer-brand-training-table">
      <table class="training-table-style">
        <thead>
          <tr class="table-tr-header">
            <th style="width:243px;">
              Name
              <a-icon
                style="cursor: pointer"
                :type="isNameSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('sortBrand')"
              ></a-icon>
            </th>
            <th style="width:133px;">
              Products
              <a-icon
                style="cursor: pointer"
                :type="isProductSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('sortProducts')"
              ></a-icon>
            </th>
            <th style="width:133px;">
              Shared
              <a-icon
                style="cursor: pointer"
                :type="isShareSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('sortShared')"
              ></a-icon>
            </th>
            <th style="width:133px;">
              Modified
              <a-icon
                style="cursor: pointer"
                :type="isModifiedSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('sortModified')"
              ></a-icon>
            </th>
            <th style="width:109px;">Brand URL</th>
            <th style="width:109px;">Training URL</th>
            <th style="width:159px;">Share Training URL</th>
            <th style="width:136px;">Actions</th>
          </tr>
        </thead>
        <div v-if="tableDate && tableDate.length === 0" class="no-table-data">
          <a-empty />
        </div>
        <tbody v-else>
          <tr v-for="item in tableDate" :key="item.name">
            <td style="width:273px;" class="name-tab-style">
              {{ item.name }}
            </td>
            <td style="width:123px;" class="">
              {{ item.productCount.count || "--" }}
            </td>
            <td style="width:115px;" class="data-tab-style">
              {{ item.brandProductTrainingCount.publicCount || "--" }}
            </td>
            <td style="width:115px;" class="data-tab-style">
              {{ item.brandProductTrainingCount.modified.slice(0, 10) || "--" }}
            </td>
            <td
              style="width:115px;"
              class="data-tab-style link-click-style"
              @click="clickToBrandUrl(item)"
            >
              {{ item.website || "--" }}
            </td>
            <td
              style="width:115px;"
              class="data-tab-style link-click-style"
              @click="clickToPublicTraining(item)"
            >
              <!--              {{ item.brandProductTrainingCount.link || "&#45;&#45;" }}-->
              {{ item.trainingLink || "--" }}
            </td>
            <td style="width:159px" class="click-pro-action">
              <div class="editable-row-operations-share">
                <div
                  id="copyBtn"
                  class="row-operations-share-btn"
                  @click="handleShareLinkItem(item.trainingLink, item.id)"
                >
                  {{ item.isCopy ? "Link Copied" : "Copy Link" }}
                </div>
              </div>
            </td>
            <td style="width:136px" class="click-pro-action">
              <div class="editable-row-operations">
                <span @click="handleProductList(item)">
                  View
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <QRCode ref="qrcode" :url="qrText"></QRCode>
  </div>
</template>

<script>
import Clipboard from "clipboard"
import { mapActions } from "vuex"
import { retailerBrandTag } from "@/utils/const"
import QRCode from "../__components/QRCode"

export default {
  name: "RetailerTrainingBrand",
  components: {
    QRCode
  },
  data() {
    return {
      isLoadingSearch: false,
      showQrcode: false,
      qrText: "",
      searchBrand: "",
      tagList: [],
      tableDate: [],
      pageInfo: {},
      isNameSort: false,
      isProductSort: false,
      isShareSort: false,
      isModifiedSort: false,
      clipboard: null,
      currentNum: 50
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    }
  },
  async created() {
    await this.fetchData()
    this.tagList = await this.getTagList(retailerBrandTag)
  },
  mounted() {
    this.initCopyBtn()
  },
  destroyed() {
    if (this.clipboard && this.shareVisible) {
      this.clipboard.destroyed()
    }
  },
  methods: {
    ...mapActions({
      getTagList: "training/getSearchBrandTags",
      getRetailerBrandList: "brand/searchBrands",
      updateTagList: "training/updateBrandTags",
      searchBrandInfo: "training/getSearchInfo"
    }),
    async fetchData(info) {
      const routerRetailerId = this.retailer.id ? this.retailer.id : this.$route.params.retailerId
      // const retailerShortName = this.retailer.shortName ? this.retailer.shortName : this.$route.params.retailerId
      let params = {
        status: "approved",
        "training-subscription-at-retailer": routerRetailerId,
        "product-training-count": true,
        "training-public": true
      }
      if (info) {
        params = {
          ...params,
          ...info
        }
      }
      const { data, headers } = await this.getRetailerBrandList({ params })
      this.tableDate = data.map(item => {
        item.isCopy = false
        item.trainingLink = `${window.location.host}/training/${item.shortName}/${this.retailer.shortName}`
        return item
      })
      this.pageInfo = headers
    },
    async closeTagItem(item) {
      await this.updateTagList({ tagName: retailerBrandTag, tagItem: item.name, type: "delete" })
    },
    async searchBrandInfo() {
      this.isLoadingSearch = true
      if (!this.searchBrand) {
        this.isLoadingSearch = false
        return
      }
      // await this.searchBrandInfo(this.searchBrand)
      const param = {
        name: this.searchBrand
      }
      await this.fetchData(param)
      this.tagList = await this.updateTagList({
        tagName: retailerBrandTag,
        tagItem: this.searchBrand
      })
      this.isLoadingSearch = false
    },
    async changeValue() {
      if (!this.searchBrand) {
        await this.fetchData()
      }
    },
    handleShareLinkItem(link, id) {
      // this.qrText = item.brandProductTrainingCount.link
      // this.$refs["qrcode"].openDownloadQR()
      const url = `https://${link}`
      this.initCopyBtn(url)
      this.copyLinkText()
      this.tableDate = this.tableDate.map(item => {
        if (item.id === id) {
          item.isCopy = true
        }
        return item
      })
    },
    clickToPublicTraining(item) {
      const url = `https://${item.trainingLink}`
      window.open(url)
    },
    clickToBrandUrl(item) {
      const url = `https://${item.website}`
      window.open(url, "_blank")
    },
    handleProductList(item) {
      if (this.$route.params.retailerId) {
        this.$router.push({
          name: "AdminRetailerTrainingProduct",
          query: {
            id: item.id,
            name: item.name,
            tab: "list"
          }
        })
      } else {
        this.$router.push({
          name: "retailerTrainingProduct",
          query: {
            id: item.id,
            name: item.name,
            tab: "list"
          }
        })
      }
    },
    async sortList(sortValue) {
      const info = this.formatSortValue(sortValue)
      this.handleName(info["_sort-by"], info.isAscend)
      // const sortInfo = this.formatSortValue(sortValue)
      // let params = {
      //   _index: 1,
      //   _limit: this.currentNum,
      //   ...sortInfo
      // }
      // await this.fetchData(params)
    },
    formatSortValue(value) {
      // this.initSort()
      if (value === "sortBrand") {
        this.isNameSort = !this.isNameSort
        this.isProductSort = false
        this.isShareSort = false
        this.isModifiedSort = false
        return {
          "_order-by": this.isNameSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isNameSort
        }
      }
      if (value === "sortProducts") {
        this.isProductSort = !this.isProductSort
        this.isNameSort = false
        this.isShareSort = false
        this.isModifiedSort = false
        return {
          "_order-by": this.isProductSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isProductSort
        }
      }
      if (value === "sortShared") {
        this.isShareSort = !this.isShareSort
        this.isNameSort = false
        this.isProductSort = false
        this.isModifiedSort = false
        return {
          "_order-by": this.isShareSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isShareSort
        }
      }
      if (value === "sortModified") {
        this.isModifiedSort = !this.isModifiedSort
        this.isNameSort = false
        this.isProductSort = false
        this.isShareSort = false
        return {
          "_order-by": this.isModifiedSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isModifiedSort
        }
      }
    },
    handleName(sorter, isAscend) {
      let index = 0
      if (sorter === "sortBrand") {
        index = 0
      } else if (sorter === "sortProducts") {
        index = 1
      } else if (sorter === "sortShared") {
        index = 2
      } else if (sorter === "sortModified") {
        index = 3
      }
      this.sortData(index, isAscend)
    },
    sortData(index, ascend) {
      if (index === 0) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            return a.name.localeCompare(b.name)
          } else {
            return b.name.localeCompare(a.name)
          }
        })
      } else if (index === 1) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            return a.productCount.count - b.productCount.count
          } else {
            return b.productCount.count - a.productCount.count
          }
        })
      } else if (index === 2) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount) {
              return (
                a.brandProductTrainingCount.publishedCount -
                b.brandProductTrainingCount.publishedCount
              )
            }
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount == null) {
              return a.brandProductTrainingCount.publishedCount - 0
            }
            if (b.brandProductTrainingCount != null && a.brandProductTrainingCount == null) {
              return 0 - b.brandProductTrainingCount.publishedCount
            }
          } else {
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount) {
              return (
                b.brandProductTrainingCount.publishedCount -
                a.brandProductTrainingCount.publishedCount
              )
            }
            if (b.brandProductTrainingCount != null && a.brandProductTrainingCount == null) {
              return b.brandProductTrainingCount.publishedCount - 0
            }
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount == null) {
              return 0 - a.brandProductTrainingCount.publishedCount
            }
          }
        })
      } else if (index === 3) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount) {
              return a.brandProductTrainingCount.modified.localeCompare(
                b.brandProductTrainingCount.modified
              )
            }
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount == null) {
              return a.brandProductTrainingCount.modified.localeCompare("")
            }
            if (b.brandProductTrainingCount != null && a.brandProductTrainingCount == null) {
              return "".localeCompare(b.brandProductTrainingCount.modified)
            }
          } else {
            if (b.brandProductTrainingCount != null && a.brandProductTrainingCount) {
              return b.brandProductTrainingCount.modified.localeCompare(
                a.brandProductTrainingCount.modified
              )
            }
            if (b.brandProductTrainingCount != null && a.brandProductTrainingCount == null) {
              return b.brandProductTrainingCount.modified.localeCompare("")
            }
            if (a.brandProductTrainingCount != null && b.brandProductTrainingCount == null) {
              return "".localeCompare(a.brandProductTrainingCount.modified)
            }
          }
        })
      }
    },
    initCopyBtn(link) {
      this.clipboard = new Clipboard("#copyBtn", { text: () => link })
    },
    copyLinkText() {
      this.clipboard.once("success", () => {
        this.$message.success("Copy success")
      })
      this.clipboard.once("error", err => {
        console.error(err)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.retailer-training-brand-tab {
  p {
    margin-bottom: 0;
  }
  .retailer-training-search {
    display: flex;
    .search-style {
      display: flex;
      flex-direction: column;
    }
    .search-input {
      width: 350px;
      margin-right: 15px;
      p {
        margin-bottom: 10px;
      }
    }
    .search-resent {
      width: 400px;
      p {
        margin-bottom: 10px;
      }
    }
  }
  .retailer-brand-training-table {
    width: 1200px;
    margin-top: 30px;
    //position: relative;
    .training-table-style {
      overflow: visible;
      width: 100%;
      color: #262626;
      //border: #e8e8e8 solid 1px;
      border-collapse: collapse;
    }
    .training-table-style tr {
      width: 100%;
      position: relative;
    }
    .training-table-style th {
      border-top: #e8e8e8 solid 1px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 16px;
      //background-color: #fafafa;
      font-size: 14px;
      font-weight: normal;
    }
    .training-table-style td {
      height: 56px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 17px 16px;
      background-color: #ffffff;
      color: #595959;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .training-table-style tr,
    .training-table-style td.move:hover {
      //cursor: pointer;
    }
    .training-table-style td.click-pro-action:hover {
      cursor: pointer;
    }
    .name-tab-style {
      position: relative;
      span {
        position: absolute;
        right: 16px;
        top: 15px;
      }
    }
    .data-tab-style {
      //position: relative;
    }
    .link-click-style:hover {
      color: #4a8a5d;
      text-decoration: underline;
      cursor: pointer;
    }
    .editable-row-operations {
      span:hover {
        color: #4a8a5d;
        text-decoration: underline;
      }
    }
    .editable-row-operations-share {
      width: 130px;
      text-align: left;
      padding: 3px 4px;
      //background-color: #4a8a5d;
      color: #4a8a5d;
      border-radius: 5px;
    }
  }
  .no-table-data {
    width: 100%;
    height: 270px;
    text-align: center;
    color: #bfbfbf;
    font-size: 16px;
    padding-top: 80px;
    position: relative;
    left: 350px;
  }
  .ant-tag {
    margin-bottom: 3px;
  }
  .icon-sort-style {
    color: #8c8c8c;
  }
}
</style>
