<template>
  <!--  this is brand middle-->
  <div class="brand-profile-middle-main">
    <div class="brand-profile-middle-title">{{ titleText }}</div>
    <div class="brand-profile-box__main">
      <Row class="brand-profile-middle-box" :span="24">
        <a-col
          v-for="(item, index) in textInfo"
          :key="index"
          class="middle-box-card"
          :xm="24"
          :lg="24"
        >
          <div class="box-card-img">
            <img :src="item.img" alt="" />
          </div>
          <div class="box-card-content">
            <p class="card-title">{{ item.cardTitle }}</p>
            <p class="card-content">
              <span>{{ item.cardContent }}</span
              ><br />
            </p>
            <p v-if="typeNumber === 2" class="card-link-text" @click="JumpToPage(item)">
              {{ item.linkText }}
            </p>
            <p v-else class="card-link-text-button" @click="JumpToPage(item)">
              {{ item.linkText }}
            </p>
          </div>
        </a-col>
      </Row>
    </div>
  </div>
</template>
<script>
import { Row, Col } from "ant-design-vue"
export default {
  components: {
    Row,
    "a-col": Col
  },
  props: {
    typeNumber: { type: Number, default: 2 },
    textInfo: { type: Array, default: () => [] }
  },
  data() {
    return {}
  },
  computed: {
    titleText() {
      return this.typeNumber === 1
        ? this.$t("Components.Brands.mainPage.MiddleBoxCardListTitle")
        : this.$t("Components.Brands.mainPage.MiddleBoxLearnTitle")
    }
  },
  methods: {
    JumpToPage(item) {
      if (item.linkTo.includes("https")) {
        window.open(item.linkTo)
      } else {
        this.$router.push({
          path: item.linkTo
        })
      }

      // if (value === 0) {
      //   return this.$router.push({
      //     name: "brandplatform"
      //   })
      // }
      // if (value === 1) {
      //   return this.$router.push({
      //     name: "brandPitch"
      //   })
      // }
      // if (value === 2) {
      //   return this.$router.push({
      //     name: "BrandBuyerBox"
      //   })
      // }
    }
  }
}
</script>
<style lang="sass">
@font-face
  font-family: 'Proxima Nova'
  src: url("../../../assets/fonts/Proxima-Nova-Light.otf") format('truetype')
.brand-profile-middle-main
  width: 1082px
  margin: 110px auto
.brand-profile-middle-title
  height: 130px
  line-height: 130px
  font-size: 48px
  text-align: center
  font-weight: 700
  font-family: "Playfair Display", "ProximaNova-Regular", "Roboto", sans-serif
  color: #12433D
.brand-profile-box__main
  //padding-left: 14%
  display: flex
  justify-content: center
  align-items: center
  //margin: 0 auto
  .brand-profile-middle-box
    .middle-box-card:nth-child(2)
      margin: 10px 25px 0
    .middle-box-card
      width: 343px
      //padding: 0 16px
      //cursor: pointer
      margin-top: 10px
      .box-card-img
        width: 100%
        height: 205px
        img
          width: 100%
          height: 100%
      .box-card-content
        width: 100%
        border: 1px solid #CECECE
        padding: 10px 17px
        color: #000000
        font-family: 'Proxima Nova'
        .card-title
          font-size: 20px
          font-weight: 600
          line-height: 35px
          word-break: break-all
        .card-content
          min-height: 81px
          font-size: 18px
          padding: 0 54px 0 0
          //word-break: break-all
          word-wrap: break-word
          overflow: hidden
        .card-link-text
          font-weight: bold
          color: #12433d
          cursor: pointer
        .card-link-text-button
          width: 203px
          height: 44px
          border-radius: 21px
          background: #FF6132
          color: white
          font-size: 18px
          font-family: "Proxima Nova", "Roboto", sans-serif
          font-weight: 600
          text-align: center
          line-height: 44px
          cursor: pointer
          margin-left: 100px
@media screen and (min-width: 1500px)
  .brand-profile-middle-main
    width: 100%
  .slick-track
    height: 544px !important
  .brand-profile-box__main
    width: 1620px
    margin: 0 auto
    .brand-profile-middle-box
      .middle-box-card:nth-child(2)
        margin: 10px 51px 0
      .middle-box-card
        .box-card-content
          padding: 34px 18px 34px 22px
          .card-content
            padding-right: 26px
  .brand-profile-box__main
    .brand-profile-middle-box
      .middle-box-card
        .box-card-img
          height: 299px
  .brand-profile-box__main
    .brand-profile-middle-box
      .middle-box-card
        width: 500px
  .card-link-text-button
    margin-left: 240px !important
</style>
