<template>
  <div ref="dialogTootip" class="brand-training-page__main">
    <!--    <div class="training-logo">{{ this.$t("Training.brandTraining.title") }}</div>-->
    <div class="brand-training-breadcrumb">
      <a-breadcrumb>
        <a-breadcrumb-item>Training</a-breadcrumb-item>
        <a-breadcrumb-item>{{ twoTextRouter }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="training-page-menu">
      <brandMenu :current="menuTab" :is-brand="true" @clickToNewTab="clickToNewTab"></brandMenu>
    </div>
    <div v-if="routerTabTaxt === 'product'" class="training-page-content">
      <BrandProduct
        :table-date="tableDate"
        :show-share-btn="showShareBtn"
        :get-training-products="getTrainingProducts"
        :download-text="downloadText"
        :share-text="shareText"
        :clipboard="clipboard"
        @updateNewTrainingList="updateNewTrainingList"
        @jumpToCreateTraining="jumpToCreateTraining"
        @handleProductList="handleProductList"
        @showModalNoInfo="showModalNoInfo"
        @shareToLink="shareToLink"
        @downloadQRCode="downloadQRCode"
      ></BrandProduct>
    </div>
    <div v-else-if="routerTabTaxt === 'retailers'" class="training-page-content">
      <BrandTrainingRetailersTable
        v-if="!isShowPlanStep && tableDate && tableDate.length > 0"
        :table-date="tableDate"
        :show-share-btn="showShareBtn"
        @addNewRetailerStep="showSubcribePlanStep"
        @viewRetailerItemProduct="viewRetailerItemProduct"
        @updateTrainingRetailer="updateTrainingRetailer"
        @addNewRetailer="addNewRetailer"
      ></BrandTrainingRetailersTable>
      <BeautyFluentPlanStep
        v-else
        :is-brand="true"
        :is-has-general="hasGeneralRetailer"
        :existed-retailer="tableDate"
        :is-sign-up="false"
        @subscribe="success"
        @success="success"
        @clickHereAction="clickToBrandUpgrade"
      />
    </div>
    <ShareLink ref="shareLink"></ShareLink>
    <BrandTableDialog ref="brandTableDialog"></BrandTableDialog>
    <a-modal
      v-model="sureVisible"
      :centered="true"
      :footer="null"
      :width="464"
      :get-container="() => $refs.dialogTootip"
      :title="null"
    >
      <span slot="closeIcon">
        <a-icon type="close-circle" style="font-size: 21px" />
      </span>
      <div class="dialog-info-before-submit">
        <p class="dialog-info-title">BUT FIRST!</p>
        <p class="dialog-content-style">
          We recently updated our training tool to automatically import key benefits from your
          product catalog. Make sure your product pages are updated with key benefits before you
          begin to ensure your training sheets can be published.
        </p>
        <p class="dialog-close-btn" @click="SubmitDialog">Update Your Product Catalog</p>
      </div>
    </a-modal>
    <upLoadVideoDialog ref="uploadVideo" @sendSuccess="sendSuccess"></upLoadVideoDialog>
    <AddRetailerStepOne
      ref="AddRetailerStepOne"
      @updateStepStatus="updateStepStatus"
    ></AddRetailerStepOne>
    <AddRetailerStepTwo
      ref="AddRetailerStepTwo"
      @updateStepStatus="updateStepStatus"
    ></AddRetailerStepTwo>
    <AddRetailerStepThree
      ref="AddRetailerStepThree"
      @backToDialog="backToDialog"
      @updatePaymentTrainingSubscription="updatePaymentTrainingSubscription"
    ></AddRetailerStepThree>
    <addRetailerPurchase ref="addRetailerPurchase" @doPurchase="doPurchase"></addRetailerPurchase>
    <retailerItemProduct
      ref="retailerItemProduct"
      @updateRetailerInfo="viewRetailerItemProduct"
      @updateRetailerList="updateRetailerList"
    ></retailerItemProduct>
    <AdminTrainintRetailerBrandProductDialog
      ref="modifyRetailerBrandProduct"
      @updateNewTrainingList="updateNewTrainingList"
    ></AdminTrainintRetailerBrandProductDialog>
    <ProductViewDialogNew ref="ProductViewDialogNew"></ProductViewDialogNew>
    <membershipExpiringDialog
      ref="membershipExpiringDialog"
      @addCardList="openAddMoreCardDialog"
    ></membershipExpiringDialog>
    <addNewCardDialog
      ref="addNewCardDialog"
      @openAddMoreCardDialog="openAddMoreCardDialog"
    ></addNewCardDialog>
    <cardListDialog
      ref="cardListDialog"
      @openAddPaymentDialog="openAddPaymentDialog"
    ></cardListDialog>
    <a-modal
      :visible="showVisible"
      :footer="null"
      :destroy-on-close="true"
      :body-style="{ padding: 15 }"
      :get-container="() => $refs.dialogTootip"
      @cancel="handleCancel"
    >
      <div class="can-not-create-training">
        <p class="title-error-text">
          <a-icon
            type="exclamation-circle"
            style="color: #faad14; margin-right: 5px;font-size: 18px"
          />Please modify the details of the product
        </p>
        <p>{{ contentText }}</p>
        <div class="button-style">
          <a-button class="btn-style" @click="jumpToBrandProduct">
            <!--            {{ this.$t("Training.brandTraining.createButton") }}-->
            To modify
          </a-button>
        </div>
      </div>
    </a-modal>
    <a-modal
      :visible="showAddRetasilerVisible"
      :footer="null"
      :destroy-on-close="true"
      :body-style="{ padding: 15 }"
      :get-container="() => $refs.dialogTootip"
      @cancel="handleAddRetailerCancel"
    >
      <div class="can-not-create-training">
        <p class="title-error-text">
          <a-icon
            type="exclamation-circle"
            style="color: #faad14; margin-right: 5px;font-size: 18px"
          />warning
        </p>
        <p style="padding-left: 20px">You have not added your bank card, please add.</p>
        <div class="button-style">
          <a-button class="btn-style" @click="handleAddRetailerCancel">
            <!--            {{ this.$t("Training.brandTraining.createButton") }}-->
            OK
          </a-button>
        </div>
      </div>
    </a-modal>
    <UpgradePlanDialog ref="brandSettingUpgradePlanDialog" :brand-type="brandType" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"
import trainingClient from "@/api/training-client"
import { setSesStorage, getSesStorage } from "@/utils/storageUtil"
import ShareLink from "@/components/training/shareLinkDialog"
import BrandTableDialog from "@/components/brands/mainPage/components/BrandTableDialog"
import upLoadVideoDialog from "@/components/training/upLoadVideoDialog"
import brandMenu from "@/components/training/__components/brandRetailerMenu"
import BrandTrainingRetailersTable from "@/components/training/brandTrainingRetailerTable"
import AddRetailerStepOne from "@/components/training/__components/brandTrainingRetailerAddStep1Dialog"
import AddRetailerStepTwo from "@/components/training/__components/brandTrainingRetailerAddStep2Dialog"
import AddRetailerStepThree from "@/components/training/__components/brandTrainingRetailerAddStep3Dialog"
import addRetailerPurchase from "@/components/training/__components/addNewRetailerPurchase"
import retailerItemProduct from "@/components/training/__components/retailerItemProduct"
import AdminTrainintRetailerBrandProductDialog from "@/components/admin/AdminProductTrainingDialog"
import ProductViewDialogNew from "@/components/training/__components/productViewDialogNew"
import membershipExpiringDialog from "@/components/webflowComponent/MembershipExpiringDialog"
import addNewCardDialog from "@/components/brands/settings/addPaymentCardDialog"
import cardListDialog from "@/components/brands/settings/addMoreCardDialog"
import constants from "@/config"
import Clipboard from "clipboard"
import BeautyFluentPlanStep from "@/views/BrandBFRegistrationStepOne"
import UpgradePlanDialog from "@/components/shared/UpgradePlanDialog"
import BrandProduct from "@/components/training/brandTraining/brandProduct"
import { downloadQR } from "@/utils/validate"

export default {
  name: "BrandCreateTraining",
  components: {
    ShareLink,
    BrandTableDialog,
    brandMenu,
    BrandTrainingRetailersTable,
    AddRetailerStepOne,
    AddRetailerStepTwo,
    AddRetailerStepThree,
    addRetailerPurchase,
    retailerItemProduct,
    ProductViewDialogNew,
    AdminTrainintRetailerBrandProductDialog,
    upLoadVideoDialog,
    membershipExpiringDialog,
    addNewCardDialog,
    BrandProduct,
    cardListDialog,
    BeautyFluentPlanStep,
    UpgradePlanDialog
  },
  props: {
    retailerId: { type: String, default: "" }
  },
  data() {
    return {
      shareVisibile: false,
      showVisible: false,
      showAddRetasilerVisible: false,
      contentText: "",
      contentLinkId: "",
      drag: false,
      tableDate: [],
      isDetele: false,
      publicTab: false,
      list: [],
      defaultPage: 1,
      pageCurrent: 17,
      sureVisible: false,
      userId: undefined,
      menuTab: "",
      typeMoney: "",
      addRetailerSelectArr: [],
      downloadText: "Download QR code",
      shareText: "Share Link",
      clipboard: null,
      isNameSort: false,
      isHeroSort: false,
      isSkuSort: false,
      isUpcSort: false,
      isModifiedSort: false,
      currentNum: 50,
      canNotDrag: 0,
      isShowPlanStep: false,
      hasGeneralRetailer: false
    }
  },
  computed: {
    ...mapState({
      userName: state => state.brand.brand.shortName
    }),
    // ...mapState({
    //   userId: state => state.user.businessId
    // }),
    ...mapGetters({
      getTrainingProducts: "training/getTrainingProducts"
    }),
    // If no data is displayed this button
    showShareBtn() {
      return this.getTrainingProducts && this.getTrainingProducts.length !== 0
    },
    deleteText() {
      return this.$t("ButtonText.delete")
    },
    editText() {
      return this.$t("ButtonText.edit")
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    role() {
      return this.$store.getters["user/role"]
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    brandType() {
      return (
        this.brand &&
        this.brand.subscriptions &&
        this.brand.subscriptions[0].type &&
        this.brand.subscriptions[0].type.id
      )
    },
    twoTextRouter() {
      if (this.$route.query.tab === "product") {
        return "Product"
      } else {
        return "BeautyFluent App"
      }
    },
    copyText() {
      // return `${window.location.origin}/TrainingProductViewAll?id=${this.userId}&name=${this.userName}`
      return `${window.location.origin}/training/${this.userName}`
    },
    routerTabTaxt() {
      return this.$route.query.tab
    }
  },
  watch: {
    async $route() {
      this.menuTab = this.$route.query.tab
      this.initCopyBtn()
      await this.updateNewTrainingList()
    }
  },
  created() {
    this.userId = this.$route.params.brandId
      ? this.$route.params.brandId
      : this.$store.getters["user/businessId"]
    this.menuTab = this.$route.query.tab || "product"
    this.isShowPlanStep = this.$route.query.isAddRetailer || false
  },
  // async watch() {
  //   console.log(22222)
  //   this.menuTab = this.$route.query.tab
  //   this.initCopyBtn()
  //   await this.$store.dispatch("brandPayment/fetchCards", this.brand.id)
  //   await this.$store.dispatch("reference/setReference")
  // },
  async mounted() {
    this.userId = this.$route.params.brandId
      ? this.$route.params.brandId
      : this.$store.getters["user/businessId"]
    this.menuTab = this.$route.query.tab
    this.isShowPlanStep = this.$route.query.isAddRetailer || false
    if (this.brandType === "basic" && this.isBrand) {
      this.$refs["membershipExpiringDialog"].openDialog()
      return
    } else if (this.$route.query.main && this.$route.query.tab === "product") {
      this.sureVisible = true
    }
    await this.updateNewTrainingList()
    if (this.brandType === "basic") {
      return
    }
    this.initCopyBtn()
    await this.$store.dispatch("brandPayment/fetchCards", this.brand.id)
    await this.$store.dispatch("reference/setReference")
  },
  destroyed() {
    if (this.clipboard && this.shareVisible) {
      this.clipboard.destroyed()
    }
  },
  methods: {
    ...mapActions({
      getProductList: "training/getProductList",
      getProductListSort: "training/getProductListSort",
      getProducts: "training/getProducts",
      deleteProduct: "training/deleteProduct",
      // getRetailersList: "training/getRetailersTableList",
      getRetailersList: "training/getBrandTraining",
      updateRetailer: "training/updateTrainingRetailer",
      updatePaymentTrainingSub: "training/updatePaymentTrainingSubscription"
    }),
    /**
     * Share Link
     */
    shareToLink() {
      // this.$refs["shareLink"].openDialog(true)
      this.clipboard.once("success", () => {
        this.shareText = "Link Copied"
        this.$message.success("Copy success")
      })
      this.clipboard.once("error", err => {
        console.error(err)
      })
    },
    downloadQRCode() {
      // this.$refs["qrcodeView"].openDownloadQRdialo()
      // this.$router.push({
      //   name: "TrainingProductViewAll",
      //   query: {
      //     id: this.brand.id,
      //     name: this.brand.name
      //   }
      // })
      this.downloadText = "QR Dowloaded"
      const linkQr = `${window.location.origin}/training/${this.brand.shortName}`
      downloadQR(linkQr, `${this.brand.name}_qrcode.png`)
    },
    handleCancel() {
      this.showVisible = false
    },
    handleAddRetailerCancel() {
      this.showAddRetasilerVisible = false
    },
    jumpToBrandProduct() {
      this.$router.push({
        name: "brandProduct",
        params: {
          id: this.contentLinkId
        }
      })
    },
    openAddMoreCardDialog() {
      const hasCardInfo = this.brand.subscriptions && this.brand.subscriptions.length !== 0
      this.$refs["cardListDialog"].openAddCardDialog(hasCardInfo)
    },
    openAddPaymentDialog() {
      this.$refs["addNewCardDialog"].openAddCardDialog()
    },
    async updateNewTrainingList() {
      if (this.$route.query.tab === "product") {
        await this.getProductList(this.userId)
        let noTrainingList = await this.getProducts(this.userId)
        noTrainingList = noTrainingList.map(item => {
          item.activeText = "Edit"
          return item
        })
        // this.tableDate = this.getTrainingProducts
        const haveTrainingList = this.formatProductTableList(this.getTrainingProducts)
        this.tableDate = haveTrainingList.concat(noTrainingList)
      } else {
        const brandId = this.brand.id ? this.brand.id : this.userId
        this.tableDate = await this.getRetailersList(brandId)
        this.checkBrandHasGeneralRetailerOrNot()
      }
    },
    clickToEdit(value) {
      if (!value.training.isPublished && value.training.states === 0) {
        // setSesStorage("SAVE_DRAFT_ARR_LIST", [value])
        setSesStorage("EDIT_ONE_TRAINING_PRODUCT", [value])
        if (this.$route.params.brandId) {
          this.$router.push({
            name: "adminBrandTrainingDetails",
            query: {
              isSaveDraft: false,
              id: value.id,
              draft: true
            }
          })
        } else {
          this.$router.push({
            name: "BrandTrainingDetails",
            query: {
              isSaveDraft: false,
              id: value.id,
              draft: true
            }
          })
        }
      } else {
        setSesStorage("EDIT_ONE_TRAINING_PRODUCT", [value])
        if (this.$route.params.brandId) {
          this.$router.push({
            name: "adminBrandTrainingDetails",
            query: {
              isSaveDraft: false,
              id: value.id
            }
          })
        } else {
          this.$router.push({
            name: "BrandTrainingDetails",
            query: {
              isSaveDraft: false,
              id: value.id
            }
          })
        }
      }
    },
    async clickToDelete(value) {
      this.tableDate = this.tableDate.map(item => {
        if (item.id === value.id) {
          item.isDetele = !item.isDetele
        }
        return item
      })
    },
    async sureDelete(value) {
      if (value.isSaveDraft) {
        let list = JSON.parse(getSesStorage("SAVE_DRAFT_ARR_LIST"))
        list = list.filter(item => item.id !== value.id)
        setSesStorage("SAVE_DRAFT_ARR_LIST", list)
      } else {
        await trainingClient.deleteTrainingProduct(value.id)
        await this.clickToDelete(value)
      }
      await this.getProductList(this.userId)
      this.tableDate = this.getTrainingProducts
    },
    editProduct(value) {
      setSesStorage("EDIT_ONE_TRAINING_PRODUCT", [value])
      if (this.$route.params.brandId) {
        this.$router.push({
          name: "adminBrandTrainingDetails",
          params: {
            brandId: this.userId
          },
          query: {
            isSaveDraft: false,
            id: value.id,
            draft: true
          }
        })
      } else {
        this.$router.push({
          name: "BrandTrainingDetails",
          query: {
            isSaveDraft: false,
            id: value.id,
            draft: true
          }
        })
      }
    },
    async onChange(value, isDrag) {
      const param = {
        training: {
          demoTips: value.training.demoTips,
          isHero: value.training.hero,
          howToDemo: value.training.howToDemo,
          id: value.training.id,
          isPublished: true,
          states: value.public ? 2 : 1,
          sort: value.training.sort
        },
        trainingBestUsedFors: value.trainingBestUsedFors,
        trainingVideos: value.trainingVideos,
        trainingCrossSellers: value.trainingCrossSellers,
        trainingSteps: value.trainingSteps
      }
      if (isDrag) {
        param.training.states = value.training.states
        param.training.isPublished = value.training.published
      }
      await trainingClient.updateTrainingProduct(value.id, param)

      await this.updateNewTrainingList()

      // this.tableDate = this.getTrainingProducts
      // this.tableDate = this.getTrainingProducts.slice(
      //   (this.defaultPage - 1) * 17,
      //   this.defaultPage * 17
      // )
    },
    async clickToNewTab(value) {
      this.menuTab = value
      this.isShowPlanStep = false
      if (value === "product") {
        await this.getProductList(this.userId)
        let noTrainingList = await this.getProducts(this.userId)
        noTrainingList = noTrainingList.map(item => {
          item.activeText = "Edit"
          return item
        })
        // this.tableDate = this.getTrainingProducts
        const haveTrainingList = this.formatProductTableList(this.getTrainingProducts)
        this.tableDate = haveTrainingList.concat(noTrainingList)
      } else {
        this.tableDate = await this.getRetailersList(this.brand.id)
        this.checkBrandHasGeneralRetailerOrNot()
      }
      this.downloadText = "Download QR code"
      this.shareText = "Share Link"
      if (this.$route.params.brandId) {
        this.$router.push({
          name: "adminBrandTraining",
          params: {
            brandId: this.userId
          },
          query: {
            tab: value
          }
        })
      } else {
        this.$router.push({
          name: "BrandTraining",
          query: {
            main: true,
            tab: value
          }
        })
      }
    },
    addNewRetailer() {
      // this.$router.push({
      //   name: this.$route.name,
      //   query: {
      //     ...this.$route.query,
      //     addRetailer: true
      //   }
      // })
      this.isShowPlanStep = true
    },
    async success() {
      this.isShowPlanStep = false
      const brandId = this.brand.id ? this.brand.id : this.userId
      this.tableDate = await this.getRetailersList(brandId)
      this.checkBrandHasGeneralRetailerOrNot()
    },
    initCopyBtn() {
      this.clipboard = new Clipboard("#copyBtn", { text: () => this.copyText })
    },
    jumpToCreateTraining() {
      const haveTrainingList = this.formatProductTableList(this.getTrainingProducts)
      this.$refs["ProductViewDialogNew"].openProductDialog(
        haveTrainingList,
        this.brand.name,
        this.brand
      )
    },
    openUploadVideo() {
      this.$refs["uploadVideo"].openUploadVideo(this.brand.id)
    },
    clickPagination(value) {
      this.defaultPage = value
      this.tableDate = this.tableDate.slice((this.defaultPage - 1) * 17, this.defaultPage * 17)
    },
    SubmitDialog() {
      if (this.$route.params.brandId) {
        this.$router.push({
          name: "adminBrandCatalog"
        })
      } else {
        this.$router.push({
          name: "brandCatalog"
        })
      }
    },
    async sendSuccess() {
      await this.$store.dispatch("brand/getBrand", this.brand.id)
    },
    showSubcribePlanStep() {
      this.isShowPlanStep = true
      // check whether has general retailer
    },
    checkBrandHasGeneralRetailerOrNot() {
      if (this.tableDate && this.tableDate.length > 0) {
        let gResult = this.tableDate.filter(item => item.retailerId === "*")
        let pResult = this.tableDate.filter(item => item.retailerId !== "*")
        if ((gResult && gResult.length > 0) || (pResult && pResult.length > 0)) {
          this.hasGeneralRetailer = true
        } else {
          this.hasGeneralRetailer = false
        }
      } else {
        this.hasGeneralRetailer = false
      }
      console.log(this.hasGeneralRetailer)
    },
    addNewRetailerStep() {
      this.isShowPlanStep = true
      // if (this.isAdmin) {
      //   if (stepValue === 1) {
      //     this.$refs["AddRetailerStepOne"].openDialog()
      //   }
      //   return
      // }
      // if (
      //   this.brand.brandCreditCardInformation &&
      //   this.brand.brandCreditCardInformation.length !== 0
      // ) {
      //   if (stepValue === 1) {
      //     this.$refs["AddRetailerStepOne"].openDialog()
      //   }
      // } else {
      //   this.showAddRetasilerVisible = true
      //   // this.$warning({
      //   //   title: "warning",
      //   //   content: "You have not added your bank card, please add."
      //   // })
      // }
    },
    updateStepStatus(stepValue, stepData) {
      if (stepValue === 1) {
        this.$refs["AddRetailerStepOne"].openDialog()
      } else if (stepValue === 2 && stepData) {
        this.addRetailerSelectArr = stepData
        this.$refs["AddRetailerStepOne"].closeDialog()
        this.$refs["AddRetailerStepTwo"].openDialog()
      } else if (stepValue === 3 && stepData) {
        this.typeMoney = stepData
        this.$refs["AddRetailerStepTwo"].closeDialog()
        this.$refs["AddRetailerStepThree"].openDialog(this.addRetailerSelectArr, this.typeMoney)
      }
    },
    backToDialog() {
      this.$refs["AddRetailerStepThree"].closeDialog()
      this.$refs["AddRetailerStepTwo"].openDialog()
    },
    async updateTrainingRetailer(retailerInfo) {
      const brandId = this.brand.id
      const params = retailerInfo
      await this.updateRetailer({ brandId, params })
      this.tableDate = await this.getRetailersList(this.brand.id)
      this.checkBrandHasGeneralRetailerOrNot()
    },
    async viewRetailerItemProduct(retailerItem) {
      await this.getProductList(this.userId)
      const list = this.getTrainingProducts
      this.$refs["retailerItemProduct"].openDialogPro(retailerItem, list)
    },
    async updateRetailerList() {
      this.tableDate = await this.getRetailersList(this.brand.id)
      this.checkBrandHasGeneralRetailerOrNot()
    },
    async updatePaymentTrainingSubscription(arr) {
      if (this.isAdmin) {
        const paymentInfo = {
          type: arr[0].payment,
          amount: arr[0].priceNumber,
          // cardNumber: cardInfo[0].cardNumber,
          retailerId: arr[0].id
        }
        await this.updatePaymentTrainingSub({ brandId: this.brand.id, payment: paymentInfo })
        this.tableDate = await this.getRetailersList(this.brand.id)
        this.checkBrandHasGeneralRetailerOrNot()
        this.$refs["AddRetailerStepThree"].closeDialog()
        return
      }
      const cardInfo = this.brand.brandCreditCardInformation.filter(item => item.default)
      const payment = {
        type: arr[0].payment,
        amount: arr[0].priceNumber,
        cardNumber: cardInfo[0].cardNumber,
        retailerId: arr[0].id
      }
      await this.$store.dispatch("brandPayment/fetchCards", this.brand.id)
      this.$refs["AddRetailerStepThree"].closeDialog()
      this.$refs["addRetailerPurchase"].openCardDialog(
        payment,
        this.addRetailerSelectArr,
        this.typeMoney
      )
    },
    async doPurchase(payment) {
      await this.updatePaymentTrainingSub({ brandId: this.brand.id, payment })
      this.tableDate = await this.getRetailersList(this.brand.id)
      this.checkBrandHasGeneralRetailerOrNot()
    },
    showModalNoInfo(text, id) {
      this.showVisible = true
      this.contentText = text
      this.contentLinkId = id
    },
    handleProductList(product, isNewTraining) {
      this.$refs["modifyRetailerBrandProduct"].openDialog(product, isNewTraining)
    },
    formatProductTableList(list) {
      this.canNotDrag = list.length
      // let publicList = list.filter(item => item.public && item.training && item.training.published)
      // publicList = publicList.map(value => {
      //   value.publicColor = "#9FD19A"
      //   value.activeText = "View"
      //   return value
      // })
      // let publicFalseList = list.filter(
      //   item => !item.public && item.training && item.training.published
      // )
      // publicFalseList = publicFalseList.map(item => {
      //   item.publicColor = "#F27C59"
      //   item.activeText = "View"
      //   return item
      // })
      // let draftList = list.filter(item => !item.public && item.training && !item.training.published)
      // draftList = draftList.map(item => {
      //   item.publicColor = "#8C8C8C"
      //   item.activeText = "View"
      //   return item
      // })
      // return publicList.concat(publicFalseList).concat(draftList)
      return list.map(item => {
        // public is true
        if (item.public && item.training && item.training.published) {
          item.publicColor = "#9FD19A"
          item.activeText = "View"
        }
        // public is false
        if (!item.public && item.training && item.training.published) {
          item.publicColor = "#F27C59"
          item.activeText = "View"
        }
        // draft
        if (!item.public && item.training && !item.training.published) {
          item.publicColor = "#8C8C8C"
          item.activeText = "View"
        }
        return item
      })
    },
    clickToBrandUpgrade() {
      console.log("...click to show upgrade dialog...")
      this.$refs["brandSettingUpgradePlanDialog"].openDialog()
    },
    subscribe(isGeneral, retailerData) {
      console.log(isGeneral)
      console.log(retailerData)
    }
  }
}
</script>

<style scoped lang="scss">
.brand-training-page__main {
  width: 1200px;
  min-height: 660px;
  margin: 0 auto;
  .brand-training-breadcrumb {
    margin-top: 50px;
    font-size: 20px;
  }
  .training-page-menu {
    margin-top: 40px;
  }
  .training-page-title {
    width: 100%;
    height: 94px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .training-logo {
    font-size: 48px;
    font-weight: bold;
    color: #000000;
    font-family: "Playfair Display";
  }
  .title-button {
    width: 415px;
    text-align: right;
    position: relative;
    //display: flex;
    //justify-content: space-between;
    .btn-style {
      width: 106px;
      height: 30px;
      font-family: "Proxima Nova";
      //line-height: 20px;
      //padding: 5px 16px;
      font-size: 14px;
      border: 2px solid #e1644c;
      border-radius: 7px;
      color: #e1644c;
      background: #ffffff;
    }
    .btn-style:nth-child(2) {
      margin: 0 26px;
      width: 140px;
    }
    .btn-style:nth-child(3) {
      background-color: #e19387;
      border: 2px solid #e19387;
      color: #ffffff;
    }
    .btn-style:hover {
      color: white;
      background: #e1644c;
    }
    .active-share-button {
      background: #9fd19a;
      border-color: #9fd19a;
      color: #ffffff;
    }
  }
  .qr-code-style {
    width: 150px;
    height: 150px;
    margin: 20px auto;
    position: absolute;
    left: 0;
    right: 0;
  }
  .no-data {
    font-size: 16px;
    font-family: "Proxima Nova";
    color: #bfbfbf;
    height: 30px;
    width: 100%;
    margin: 30px 0;
    text-align: center;
    span {
      display: inline-block;
      border: 1px solid #bfbfbf;
      width: 106px;
      height: 30px;
      line-height: 30px;
      border-radius: 3px;
    }
  }
  .no-table-data {
    width: 100%;
    height: 270px;
    text-align: center;
    color: #bfbfbf;
    font-size: 16px;
    padding-top: 80px;
    position: relative;
    left: 350px;
  }
  .editable-row-operations {
    color: #4a8a5d;
  }
  .training-page-table {
    width: 1200px;
    //position: relative;
    .training-table-style {
      overflow: visible;
      width: 100%;
      color: #262626;
      //border: #e8e8e8 solid 1px;
      border-collapse: collapse;
    }
    .training-table-style tr {
      width: 100%;
      position: relative;
    }
    .training-table-style tr:nth-child(2n) {
      background-color: rgba(211, 228, 206, 0.06);
    }
    .training-table-style th {
      border-top: #e8e8e8 solid 1px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 16px;
      //background-color: #fafafa;
      font-size: 14px;
      font-weight: normal;
      color: #262626;
    }
    .training-table-style td {
      height: 56px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 17px 16px;
      background-color: #ffffff;
      color: #595959;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .training-table-style tr,
    .training-table-style td.move:hover {
      cursor: move;
    }
    .training-table-style td.click-pro-action:hover {
      cursor: pointer;
    }
    .name-tab-style {
      position: relative;
      span {
        position: absolute;
        right: 16px;
        top: 15px;
      }
    }
    .data-tab-style {
      //position: relative;
    }
  }
  .tab-box-tr {
    position: absolute;
  }
  .hero-product-position {
    padding: 3px 8px;
    color: #ff6132;
    font-size: 12px;
    background-color: #fef2e2;
    border: 1px solid #ff6132;
    position: absolute;
    left: -1055px;
    top: 15px;
    z-index: 5;
  }
  .draft-info {
    font-size: 12px;
    padding: 3px 8px;
    border: 1px solid #262626;
    background-color: #e8e8e8;
    border-radius: 2px;
    cursor: pointer;
  }
  .delete-btn-style {
    height: 56px;
    p {
      background-color: #d2eeec;
      color: #12433d;
      height: 28px;
      line-height: 28px;
      text-align: center;
      margin-bottom: 0;
    }
    p:hover {
      color: white;
      background-color: #12433d;
    }
  }
  .td-no-padding {
    padding: 0 !important;
  }
  .training-pagination-footer {
    margin-top: 30px;
    text-align: right;
  }
  .ant-btn-primary {
    background-color: #12433d;
  }
  .ant-modal-confirm .ant-modal-confirm-btns {
    text-align: center;
    float: none;
  }
  .dialog-close-btn {
    width: 200px;
    height: 33px;
    line-height: 33px;
    border-radius: 2px;
    font-size: 14px;
    margin-top: 27px;
    background-color: #12433d;
    color: white;
    text-align: center;
    margin-left: 110px;
    cursor: pointer;
  }
  .dialog-close-btn:hover {
    background-color: #4a8a5d;
    color: #ffffff;
  }
  .product-hero-style {
    display: inline-block;
    width: 39px;
    height: 22px;
    line-height: 22px;
    background: #f3ca50;
    color: #ffffff;
    border-radius: 100px;
    text-align: center;
  }
  .can-not-create-training {
    padding: 15px;
    text-align: left;
    font-size: 15px;
    .title-error-text {
      font-weight: bold;
    }
    .button-style {
      text-align: right;
    }
    .btn-style {
      background: #9fd19a;
      border: 1px solid #9fd19a;
      color: #ffffff;
    }
    .btn-style:hover {
      background: #4a8a5d;
      border: 1px solid #4a8a5d;
      color: #ffffff;
    }
  }
  .icon-sort-style {
    color: #8c8c8c;
  }
}
.ant-breadcrumb {
  font-size: 20px;
}
.ant-pagination-item-active {
  border-color: #4a8a5d;
}
.ant-switch-checked {
  background-color: #9fd19a;
}
.ant-modal-confirm-title {
  text-align: center;
}
.info-dialog-main {
  text-align: center;
  background-color: yellow;
}
.dialog-info-before-submit {
  //width: 236px;在·
  text-align: center;
  color: #12433d;
  font-size: 13px;
  //margin-left: 77px;
  margin-top: 23px;
  font-family: "Proxima Nova", "Roboto", sans-serif;
}
.dialog-info-title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 23px;
}
.training-table-style tr:hover {
  .drag-three-circular {
    position: absolute;
    .three-circular {
      position: relative;
      width: 28px;
      height: 28px;
      color: #595959;
      //margin: 50px;
      left: -971px;
      top: 15px;
      z-index: 5;
    }
    .three-circular:after {
      content: "•••";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
      font-size: 12px;
      letter-spacing: 2px;
      margin-top: 2px;
    }
  }
}
</style>
