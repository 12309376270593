<template lang="pug">
.modal.approve-modal(:class="{ 'is-active': show }")
  .modal-background(@click="hide")
  .modal-card.approve-modal__content
    header.approve-modal__header {{$t('Components.Admin.ApproveModal.enterShortName')}}
      img.approve-modal__close-button(src="@/assets/img/icon/close-icon.svg" @click="hide")
    section.modal-card-body
      .approve-modal__body
        .approve-modal__input-label {{$t('Components.Admin.ApproveModal.shortName')}}
        input.input(v-model="shortName" v-bind:placeholder="$t('Components.Admin.ApproveModal.lan')"
        @input="showError=false")
        p.help.is-danger(v-if="approveError && showError") {{$t('Components.Admin.ApproveModal.Validations.accountWithShortNameExists')}}
        p.help.is-danger(v-if="!$v.shortName.required&&showValidations") {{$t('Components.Admin.ApproveModal.Validations.required')}}
        p.help.is-danger(v-if="!$v.shortName.alpha&&showValidations") {{$t('Components.Admin.ApproveModal.Validations.enterAlphabets')}}
        p.help.is-danger(v-if="!$v.shortName.maxLength&&showValidations") {{$t('Components.Admin.ApproveModal.Validations.shortNameLimitExceed')}}
        p.help.is-danger(v-if="!$v.shortName.minLength&&showValidations") {{$t('Components.Admin.ApproveModal.Validations.shortNameLimitExceed')}}
    .approve-modal__buttons.modal-card-foot
      a.button.is-black.approve-modal__save-button(@click="approve") {{$t('Components.Admin.ApproveModal.createShortName')}}
</template>

<script>
import { required, alpha, maxLength, minLength } from "vuelidate/lib/validators"
export default {
  props: {
    show: { type: Boolean, default: false },
    initialShortName: { type: String, default: "" },
    approveError: { type: String, default: "" },
    showError: { type: Boolean, default: false }
  },
  validations: {
    shortName: { required, alpha, maxLength: maxLength(4), minLength: minLength(3) }
  },
  data() {
    return {
      shortName: this.initialShortName,
      showValidations: false
    }
  },
  methods: {
    hide() {
      this.$emit("hideApproveModal")
    },

    approve() {
      if (this.$v.$invalid) {
        this.showValidations = true
        return
      } else this.showValidations = false
      this.$emit("subscribe", this.shortName)
    }
  }
}
</script>

<style lang="sass">
.approve-modal
  .modal-card-body
    display: flex
    justify-content: center
    align-items: center

  &__header
    color: #212B36
    font-size: 20px
    border-bottom: 1px solid #E1E4E9
    padding-top: 15px
    padding-left: 20px
    padding-bottom: 15px

  &__content
    position: relative
    background-color: #FFFFFF
    width: 510px
    height: 474px

  &__body
    width: 325px

  &__input-label
    padding-bottom: 5px
    font-size: 14px
    color: #000

  &__buttons
    padding: 10px 20px

  &__close-button
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    height: 20px
    width: 20px

  &__save-button
    font-size: 14px
    width: 325px
    margin: 0 auto

  .is-red
    color: #ff3860
    border-color: #ff3860
</style>
