<template lang="pug">
.brand
  .retailer-brand-profile(v-if="brand")
    .retailer-brand-profile__banner
      img.banner-img(:src="brand.bannerAsset.url" v-if="brand.bannerAsset.url")
      img.banner-img(v-else src="@/assets/img/default-banner-image.png")
    .retailer-brand-profile__header
      .columns.is-gapless
        .column.is-narrow
          .retailer-brand-profile__logo
            LogoOrPlaceholder(:url="brand.logoAsset.url")
        .column.is-narrow
          p.retailer-brand-profile__name {{ brand.name }}
          p.retailer-brand-profile__country(v-if="brand && brand.addresses")
            p(v-for="hq in adderssInfo")
              span(v-if="hq.city") {{ [hq.city, countryName(hq.country.id)].join(", ") }}
        .column
          .field.is-grouped.is-grouped-right.retailer-brand-profile__icons
            .control(v-for="socialAccount in brand.socialAccounts" :key="socialAccount.platform.id")
              a.is-inline-block(target="_blank" @click="goToSocialMedia(socialAccount)")
                img(v-show="socialAccount.address" target="_blank" :src="require(`@/assets/img/icon/${socialAccount.platform.id}-icon.svg`)" width='23' alt='')

      .retailer-brand-profile__description
        p {{ brand.introduction }}
        .retailer-brand-profile__website
          a(target="_blank" @click="goToWebsite") {{$t('Retailers.RetailerBrandProfile.visitWebsite')}}
          //- button.button.is-black.retailer-brand-profile__message-button Message

    .retailer-brand-profile__tabs
      span.tabs
        ul
          li(:class="{ 'is-active': isActiveTab('profile') }")
            a.retailer-brand-profile__tab(@click.prevent="toggleTab('profile')") {{$t('Retailers.RetailerBrandProfile.profile')}}
          li(:class="{ 'is-active': isActiveTab('catalog') }")
            a.retailer-brand-profile__tab(@click.prevent="toggleTab('catalog')") {{$t('Retailers.RetailerBrandProfile.catalog')}}

    .columns.is-multiline(v-show="currentTab === 'catalog'")
      .column.is-3.is-paddingless(v-show="!isEmptyCatalog && displayEmptyResult")
        .retailer-brand-profile__categories
          .retailer-brand-profile__categories--header {{$t('Retailers.RetailerBrandProfile.shopByCategory')}}
          .retailer-brand-profile__categories--back-to(v-show="activeCategories.length" @click="backToCategories()") < {{$t('Retailers.RetailerBrandProfile.backAllBeauty')}}
          CategoriesFilter(
            v-show="activeCategories.length === 0"
            :categoryFilterItems="categoryFilterItems"
            @filterSwitch="fetchDataWhenClickFilter"
          )
          SubcategoriesProductsFilter(
            v-show="activeCategories.length && activeSubcategories.length === 0"
            :subcategories="subcategoryFilterItems"
            :activeCategories="activeCategories"
            @filterSwitch="fetchDataWhenClickFilter"
          )
          MicroCategoriesProductsFilter(
            v-show="activeSubcategories.length"
            :microCategories="microCategoryFilterItems"
            :activeSubcategories="activeSubcategories"
            @filterSwitch="fetchDataWhenClickFilter"
          )
        .retailer-brand-profile__filter-header {{$t('Retailers.RetailerBrandProfile.filterBy')}}
        .retailer-brand-profile__filter

          //ProductsFilter(:title="$t('Retailers.RetailerBrandProfile.tags')" :items="tagFilterItems.filter(tag => !tag.navigational)" @filterSwitch="fetchProducts")
          ProductsFilter(:title="$t('Retailers.RetailerProducts.productValue')" :items="healthIngredientsTag.filter(tag => !tag.navigational)" @filterSwitch="fetchDataWhenClickFilter" @option="option")
          ProductsFilter(:title="$t('Retailers.RetailerProducts.featured')" :items="featuredTag.filter(tag => tag.navigational)" @filterSwitch="fetchDataWhenClickFilter")
          ProductsFilter(:title="$t('Retailers.RetailerBrandProfile.type')" :items="typeFilterItems" @filterSwitch="fetchDataWhenClickFilter")
          PriceRangeFilter(
            v-if="activeCategories.length && canShowRange(products)"
            :products="products"
            @filterSwitch="filterProductsByPrice"
            :totalMin="productsMinPrice"
            :totalMax="productsMaxPrice"
          )
      .column.retailer-brand-profile__products
        .brand-catalog__no-products(v-show="isEmptyCatalog") {{$t('Retailers.RetailerBrandProfile.noProductsCatalog')}}

        .retailer-products__not-found(v-show="isResultsEmpty && !isEmptyCatalog")
          p {{$t('Retailers.RetailerBrandProfile.productNotFoundMessage1')}}
          p {{$t('Retailers.RetailerBrandProfile.productNotFoundMessage2')}}

        .columns.is-multiline
          .column.is-3(v-for="product in products" :key="product.id")
            .retailer-brand-profile__product
              ProductItem(:product="product")
        Pagination(
          v-show="displayEmptyResult && !isEmptyCatalog"
          :page="page"
          @nextPage="nextPage"
          @previousPage="previousPage"
          @resetPagination="changeLimit"
          :maxPage="productPageData.pages"
          :isPaginationLoading="isPaginationLoading"
        )

    .columns.is-multiline(v-show="currentTab === 'profile'")
      .column.is-12.retailer-brand-profile__about
        .columns.profile-style
          .column.retailer-brand-profile__details.retailer-brand-profile__overview
            .retailer-brand-profile__details--title {{$t('Retailers.RetailerBrandProfile.overview')}}
            p.retailer-brand-profile__details--title_text {{ brand.about }}
            .retailer-brand-profile__details--container
              .retailer-brand-profile__details--subtitle {{$t('Retailers.RetailerBrandProfile.website')}}
              .retailer-brand-profile__details--subtitle_link {{ brand.website }}
            .retailer-brand-profile__details--container
              .retailer-brand-profile__details--subtitle {{$t('Retailers.RetailerBrandProfile.companySize')}}
              div(v-if="brand.size.id") {{ clientSizeName(this.brand.size.id) }}
              div(v-else) N/A
            .retailer-brand-profile__details--container(v-if="brand && brand.addresses")
              div(v-for="headquarters in adderssInfo")
                .retailer-brand-profile__details--subtitle {{$t('Retailers.RetailerBrandProfile.headQuarters')}}
                div {{ headquarters.streetAddressLine_1 }}
                div(v-if="headquarters.streetAddressLine_2") {{ headquarters.streetAddressLine_2}}
                div {{ headquarters.city }}, {{ headquarters.region }} {{ headquarters.postalCode }}
                div {{ countryName(headquarters.country.id) }}
            .retailer-brand-profile__details--container
              .retailer-brand-profile__details--subtitle {{$t('Retailers.RetailerBrandProfile.founded')}}
              div(v-if="brand.foundingDate") {{ brand.foundingDate.split('-')[0] }}
              div(v-else) N/A
            .retailer-brand-profile__details--container(v-if="brand && brand.addresses")
              div(v-for="warehouse in warehouseList")
                .retailer-brand-profile__details--subtitle {{$t('Retailers.RetailerBrandProfile.domesticWarehouseLocation')}}
                div(v-if="warehouse.city && warehouse.streetAddressLine_1")
                  div {{ warehouse.streetAddressLine_1 }}
                  div(v-if="warehouse.streetAddressLine_2") {{ warehouse.streetAddressLine_2}}
                    div {{ warehouse.city }}, {{ warehouse.region }} {{ warehouse.postalCode }}
                  div {{ countryName(warehouse.country.id) }}
                div(v-else) {{$t('Retailers.RetailerBrandProfile.na')}}
            .retailer-brand-profile__details--container
              .retailer-brand-profile__details--subtitle {{$t('Retailers.RetailerBrandProfile.curations')}}
              div(v-if="brand.tags.length && brand.curations") {{ brand.curations.map(tag => tag.description).join(", ") }}
              div(v-else) {{$t('Retailers.RetailerBrandProfile.na')}}
            .retailer-brand-profile__details--container(v-if="brand && brand.introDeckAsset")
              .retailer-brand-profile__details--subtitle {{$t('Retailers.RetailerBrandProfile.introDeck')}}
              div
               a(:href="brand.introDeckAsset.url" target="_blank") {{pdfFileName}}
               a-icon(type="file-pdf" style="margin-left: 6px;")

          .column
            .columns.is-multiline
              //- .column.is-12.retailer-brand-profile__details
                .retailer-brand-profile__details--title {{$t('Retailers.RetailerBrandProfile.terms')}}
                div
                  .retailer-brand-profile__details--subtitle {{$t('Retailers.RetailerBrandProfile.minimumOrderAmount')}}
                  .retailer-brand-profile__details-value(v-if="!brand.terms.minimumOrderAmountUsd") {{$t('Retailers.RetailerBrandProfile.na')}}
                  .retailer-brand-profile__details-value {{ displayMinimumAmount(brand) }}
                .retailer-brand-profile__details--container
                  .retailer-brand-profile__details--subtitle {{$t('Retailers.RetailerBrandProfile.minimumOrderQuantity')}}
                  .retailer-brand-profile__details-value(v-if="!brand.terms.minimumOrderQuantity") {{$t('Retailers.RetailerBrandProfile.na')}}
                  .retailer-brand-profile__details-value {{ displayMinimumQuantity(brand) }}
              .column.is-12.retailer-brand-profile__details
                .retailer-brand-profile__details--title {{$t('Retailers.RetailerBrandProfile.keyRetailers')}}
                span.retailer-brand-profile__details-value(v-if="!brand.keyRetailers.length") {{$t('Retailers.RetailerBrandProfile.none')}}
                span.retailer-brand-profile__details-value  {{ brand.keyRetailers.map(keyRetailer => keyRetailer.name).join(", ") }}
              .column.is-12.retailer-brand-profile__details
                .retailer-brand-profile__details--title {{$t('Retailers.RetailerBrandProfile.productCategories')}}
                span.retailer-brand-profile__details-value(v-if="!brand.productCategories") {{$t('Retailers.RetailerBrandProfile.none')}}
                span.retailer-brand-profile__details-value(v-if="brand.productCategories") {{ brand.productCategories.map(productCategory => productCategory.name).join(", ") }}
              .column.is-12.retailer-brand-profile__details
                .retailer-brand-profile__details--title {{$t('Retailers.RetailerBrandProfile.heroProducts')}}
                span.retailer-brand-profile__details-value(v-if="!brand.heroProducts.length") {{$t('Retailers.RetailerBrandProfile.none')}}
                span.retailer-brand-profile__details-value {{ brand.heroProducts.map(heroProduct => heroProduct.name).join(", ") }}
</template>

<script>
import ProductItem from "@/components/retailers/products/ProductItem"
import ProductsFilter from "@/components/filters/ProductsFilter"
import PriceRangeFilter from "@/components/filters/PriceRangeFilter"
import CategoriesFilter from "@/components/filters/CategoriesFilter.vue"
import SubcategoriesProductsFilter from "@/components/filters/SubcategoriesProductsFilter"
import MicroCategoriesProductsFilter from "@/components/filters/MicroCategoriesProductsFilter"
import Pagination from "@/components/Pagination"
import LogoOrPlaceholder from "@/components/cropper/OldLogoOrPlaceholder"
import BannerOrPlaceholder from "@/components/cropper/BannerOrPlaceholder"

export default {
  components: {
    ProductItem,
    ProductsFilter,
    PriceRangeFilter,
    CategoriesFilter,
    SubcategoriesProductsFilter,
    MicroCategoriesProductsFilter,
    Pagination,
    LogoOrPlaceholder,
    BannerOrPlaceholder
  },
  props: {
    brandId: { type: String, default: "" }
  },
  data() {
    return {
      brand: null,
      products: [],
      productPageData: {},
      query: { brand: this.brandId, _limit: "12" },
      currentTab: "profile",
      tagFilterItems: [],
      healthIngredientsTag: [],
      featuredTag: [],
      typeFilterItems: [],
      categoryFilterItems: [],
      subcategoryFilterItems: [],
      microCategoryFilterItems: [],
      currentMenuItem: "photosOverview",
      isEmptyCatalog: false,
      displayEmptyResult: false,
      isPaginationLoading: false,
      page: 1,
      pdfFileName: "IntroDeck.pdf"
    }
  },
  computed: {
    isResultsEmpty() {
      return !this.products.length && this.displayEmptyResult
    },
    productMaxPage() {
      return parseInt(this.$store.getters["product/productsSearchPageCount"])
    },
    productsMinPrice() {
      return this.$store.getters["product/productsSearchMinPrice"]
    },
    productsMaxPrice() {
      return this.$store.getters["product/productsSearchMaxPrice"]
    },
    activeStatuses() {
      return this.statusFilterItems.filter(item => item.checked)
    },
    activeTags() {
      return this.tagFilterItems.filter(item => item.checked)
    },
    activeHealthIngredientsTags() {
      return this.healthIngredientsTag.filter(item => item.checked)
    },
    activeFeaturedTags() {
      return this.featuredTag.filter(item => item.checked)
    },
    activeTypes() {
      return this.typeFilterItems.filter(item => item.checked)
    },
    activeCountries() {
      return this.countryFilterItems.filter(item => item.checked)
    },
    activeCategories() {
      return this.categoryFilterItems.filter(item => item.checked)
    },
    activeSubcategories() {
      if (!this.activeCategories.length) {
        return []
      }
      return this.subcategoryFilterItems.filter(item => item.checked)
    },
    activeMicroCategories() {
      if (!this.activeSubcategories.length) {
        return []
      }
      return this.microCategoryFilterItems.filter(item => item.checked)
    },
    activeFilterItems() {
      return [
        ...this.tagFilterItems,
        ...this.healthIngredientsTag,
        ...this.featuredTag,
        ...this.typeFilterItems,
        ...this.categoryFilterItems,
        ...this.activeSubcategories,
        ...this.activeMicroCategories
      ].filter(item => item.checked)
    },
    adderssInfo() {
      if (this.brand && this.brand.addresses) {
        return this.brand.addresses.filter(address => address.hq)
      } else {
        return []
      }
    },
    warehouseList() {
      if (this.brand && this.brand.addresses) {
        return this.brand.addresses.filter(address => address.defaultShipping && !address.hq)
      } else {
        return []
      }
    }
  },
  watch: {
    async brandId(value) {
      this.query = { brand: value, _limit: "12" }
      await this.fetchProducts()
      await this.$store.dispatch("brand/getBrand", value)
      this.brand = this.$store.getters["brand/brand"]
    },
    brand() {
      if (this.brand.introDeckAsset && this.brand.introDeckAsset.id) {
        this.$store
          .dispatch("asset/getAsset", this.brand.introDeckAsset.id)
          .then(() => {
            let data = this.$store.getters["asset/find"](this.brand.introDeckAsset.id)
            if (data) {
              this.pdfFileName = data.originalFileName
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    }
  },
  async created() {
    await this.$store.dispatch("reference/setReference")
    await this.fetchFilters()
    await this.fetchProducts()
    if (!this.products.length) {
      this.isEmptyCatalog = true
    }
    this.displayEmptyResult = true
    await this.$store.dispatch("brand/getBrand", this.brandId)
    this.brand = this.$store.getters["brand/brand"]
  },
  methods: {
    resetPagination() {
      delete this.query["_order-by"]
    },
    changeLimit() {
      this.page = 1
      this.resetPagination()
      this.fetchProducts()
    },
    canShowRange(products) {
      if (products.length === 1 && !this.query.msrp) {
        return false
      } else {
        return true
      }
    },
    nextPage() {
      this.page++
      this.fetchProducts()
    },
    previousPage() {
      if (this.page === 1) {
        return
      }
      this.page--
      this.fetchProducts()
    },
    isActiveTab(tab) {
      return this.currentTab === tab
    },
    toggleTab(tab) {
      this.currentTab = tab
    },
    isMenuItem(item) {
      return this.currentMenuItem === item
    },
    toggleMenuItem(item) {
      this.isEditModalOpen = true
      this.currentMenuItem = item
    },
    async option(option) {
      this.filterOption = option
    },
    setQueryKeyByFilterItems(activeFilterItems, key) {
      if (activeFilterItems && activeFilterItems.length !== 0) {
        this.query[key] = activeFilterItems.map(item => item.id).join(",")
        if (key == "sell-side-value") {
          if (this.filterOption == "All") {
            this.query[key] = "all(" + this.query[key] + ")"
          }
          if (this.filterOption == "Any") {
            this.query[key] = "any(" + this.query[key] + ")"
          }
        }
      } else {
        delete this.query[key]
      }
    },
    syncQueryWithFilters() {
      this.setQueryKeyByFilterItems(this.activeTags, "sell-side-tag")
      this.setQueryKeyByFilterItems(this.activeHealthIngredientsTags, "sell-side-value")
      this.setQueryKeyByFilterItems(this.activeFeaturedTags, "brand-curation")
      this.setQueryKeyByFilterItems(this.activeStatuses, "status")
      this.setQueryKeyByFilterItems(this.activeCountries, "country")
      this.setQueryKeyByFilterItems(this.activeCategories, "category")
      this.setQueryKeyByFilterItems(this.activeSubcategories, "subcategory")
      this.setQueryKeyByFilterItems(this.activeMicroCategories, "micro-category")
      this.setQueryKeyByFilterItems(this.activeTypes, "type")
    },
    filterProductsByPrice(min, max) {
      this.page = 1
      if (min && max && this.activeCategories.length) {
        this.query.msrp = `${min},${max}`
      } else {
        delete this.query.msrp
      }
      this.fetchProducts()
    },
    fetchDataWhenClickFilter() {
      this.page = 1
      this.fetchProducts()
    },
    flattenAddress(address) {
      if (!address.city || !address.region) {
        return "N/A"
      }

      return [
        address.streetAddressLine_1,
        address.city,
        address.region,
        address.postalCode,
        this.countryName(address.country.id)
      ]
        .filter(item => item)
        .join(", ")
    },
    countryName(id) {
      return this.$store.getters["reference/countryName"](id)
    },
    clientSizeName(id) {
      return this.$store.getters["reference/clientSizeName"](id)
    },

    async backToCategories() {
      this.categoryFilterItems.forEach(category => (category.checked = false))
      this.subcategoryFilterItems.forEach(subcategory => (subcategory.checked = false))
      this.microCategoryFilterItems.forEach(microCategory => (microCategory.checked = false))
      delete this.query.msrp
      try {
        await this.fetchProducts()
      } catch (error) {
        console.error(error)
      }
    },
    async fetchProducts() {
      this.isPaginationLoading = true
      this.syncQueryWithFilters()

      try {
        this.query["_limit"] = 12
        this.query["_index"] = this.page
        this.query["_sort-by"] = "created"
        this.query["_order-by"] = "next"
        await this.$store.dispatch("product/getProductsByPage", { params: this.query })
        this.productPageData = this.$store.getters["product/getProductPageData"]
        this.products = this.productPageData.products
      } catch (error) {
        this.products = []
        console.error(error)
      } finally {
        this.isPaginationLoading = false
      }
    },
    async fetchFilters() {
      this.statusFilterItems = this.$store.getters["reference/statusFilterItems"]
      this.countryFilterItems = this.$store.getters["reference/countryFilterItems"]
      this.healthIngredientsTag = this.$store.getters["reference/brandValue"]
      this.featuredTag = this.$store.getters["reference/tagHealthFilterItems"]
      //this.splitTags(this.tagFilterItems)
      this.typeFilterItems = this.$store.getters["reference/typeFilterItems"]
      this.categoryFilterItems = this.$store.getters["reference/categoryFilterItems"]
      this.categoryFilterItems.forEach(category => (category.checked = false))
      this.subcategoryFilterItems = this.$store.getters["reference/subcategoryFilterItems"]
      this.microCategoryFilterItems = this.$store.getters["reference/microCategoryFilterItems"]
    },
    splitTags(tagFilters) {
      for (var tags in tagFilters) {
        if (tagFilters[tags].name === "Cruelty-free") {
          this.healthIngredientsTag.push(tagFilters[tags])
        } else if (tagFilters[tags].name === "Paraben-free") {
          this.healthIngredientsTag.push(tagFilters[tags])
        } else if (tagFilters[tags].name === "Phthalate-free") {
          this.healthIngredientsTag.push(tagFilters[tags])
        } else if (tagFilters[tags].name === "Sulfate-free") {
          this.healthIngredientsTag.push(tagFilters[tags])
        } else if (tagFilters[tags].name === "Vegan") {
          this.healthIngredientsTag.push(tagFilters[tags])
        } else if (tagFilters[tags].name === "Organic") {
          this.healthIngredientsTag.push(tagFilters[tags])
        } else {
          this.featuredTag.push(tagFilters[tags])
        }
      }
    },
    displayMinimumAmount(brand) {
      if (brand.terms && brand.terms.minimumOrderAmountUsd) {
        return this.priceFormat(brand.terms.minimumOrderAmountUsd).replace(".00", "")
      }
      return ""
    },
    displayMinimumQuantity(brand) {
      if (brand.terms && brand.terms.minimumOrderQuantity) {
        return brand.terms.minimumOrderQuantity
      }
      return ""
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    },
    goToWebsite() {
      window.open("http://" + this.brand.website)
    },
    goToSocialMedia(socialAccount) {
      window.open("http://" + socialAccount.address)
    }
  }
}
</script>

<style lang="sass">
.banner-img
  width: 100%
  height: 100%
  display: none
.brand
  min-height: 80vh
.retailer-brand-profile
  margin: 0 auto
  padding-bottom: 250px
  color: #000000
  padding-left: 30px
  padding-right: 20px
  height: 100%
  .profile-style
    margin-bottom: 70px !important

  &__banner
    height: 280px
    margin-bottom: -35px
    background-color: #ffffff

  &__categories
    padding-top: 60px
    padding-bottom: 10px

    &--header
      font-size: 14px
      font-weight: 600

    &--back-to
      cursor: pointer
      font-size: 14px
      font-weight: 300
      padding-top: 10px

  &__filter-header
    font-size: 14px
    font-weight: 600
    text-transform: uppercase

  &__filter
    cursor: pointer
    margin: 10px 48px 0px 0px

  &__about
    padding: 55px 100px 0px 100px !important

  &__gallery-container
    padding-top: 55px

  &__gallery
    max-width: 920px
    margin: 0 auto

  &__overview
    margin-right: 40px
    font-size: 14px

  &__details-value
    font-size: 16px

  &__details
    border: 1px solid rgba(151,151,151,0.23)
    padding: 25px !important
    margin-bottom: 25px

    &--container
      margin-top: 30px

    &--title
      font-size: 18px
      letter-spacing: 0.25px
      padding-bottom: 20px

      &_text
        font-weight: 400

    &--subtitle
       font-size: 14px
       font-weight: bold

       &_link
         text-decoration: underline

  &__logo
    display: flex
    justify-content: center
    margin-inline-end: 10px
    margin-left: 35px
    height: 140px
    width: 140px
    border: 1px solid #E5E5E5
    background-color: #ffffff

  &__name
    font-size: 18px
    font-weight: bold
    letter-spacing: 0.25px
    padding-top: 50px

  &__description
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.25px

  &__website
    padding-top: 30px
    text-decoration: underline
    display: flex
    align-items: center
    a
      color: #161616

  &__message-button
    margin-left: auto
    height: 30px
    width: 120px

  &__edit
    cursor: pointer
    display: flex
    justify-content: flex-end
    margin-bottom: -25px
    color: #161616
    font-size: 14px
    letter-spacing: 0.16px
    text-decoration: underline
    position: relative

  &__country
    font-size: 14px

  &__tabs
    padding-top: 40px

  &__tab
    font-size: 14px
    color: #9B9B9B
    font-weight: 500
    width: 115px
    letter-spacing: 0.1px

  &__product
    max-width: 250px
    margin: 0 auto

  &__products
    padding-top: 30px

  &__icons
    margin-top: 50px
</style>
