<template>
  <a-table
    id="bf_package_plan_table"
    bordered
    size="middle"
    :pagination="false"
    :columns="columns"
    :data-source="datas"
  >
    <span slot="generalTitle" class="bf_talbe__title">
      BeautyFluent General
    </span>
    <span slot="preferredTitle" class="bf_talbe__title">
      BeautyFluent Preferred
    </span>
    <span slot="leftValue" slot-scope="text" class="bf_talbe__left-content">
      {{ text }}
    </span>
    <span slot="generalValue" slot-scope="text">
      <img v-if="text" src="@/assets/img/bf_can_use.svg" />
    </span>
    <span slot="preferredValue" slot-scope="text">
      <img v-if="text" src="@/assets/img/bf_can_use.svg" />
    </span>
    <template slot="footer">
      <a-row>
        <a-col :span="17"></a-col>
        <a-col :span="7" style="text-align: center;">
          <a-select
            v-model="selectedRetailer"
            class="bf_talbe__select"
            dropdown-class-name="bf_talbe__select_dropdown"
            placeholder="Select a Retailer"
            @change="changePreferredRetailer"
          >
            <a-select-option v-for="item in preferredRetailers" :key="item.retailerId">
              {{ item.retailerName }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row style="margin-top: 16px;">
        <a-col :span="11"></a-col>
        <a-col :span="6" style="text-align: center;">
          <a-button class="bf_talbe__btn" :disabled="hasGeneralPackage" @click="subscribGeneral"
            >Subscribe Now</a-button
          >
        </a-col>
        <a-col :span="7" style="text-align: center;">
          <a-button
            class="bf_talbe__btn"
            :disabled="isPreferredBtnDisabled"
            @click="subscribPreferred"
            >Subscribe Now</a-button
          >
        </a-col>
      </a-row>
    </template>
  </a-table>
</template>
<script>
export default {
  props: {
    hasGeneralPackage: { type: Boolean, default: () => false },
    existedPreferredRetailer: { type: Array, default: () => [] }
  },
  data() {
    return {
      isPreferredBtnDisabled: true,
      columns: [
        {
          dataIndex: "function",
          key: "function",
          width: "44%",
          scopedSlots: { customRender: "leftValue" }
        },
        {
          dataIndex: "isBFGeneralCan",
          key: "isBFGeneralCan",
          width: "28%",
          align: "center",
          slots: { title: "generalTitle" },
          scopedSlots: { customRender: "generalValue" }
        },
        {
          dataIndex: "isBFPreferCan",
          key: "isBFPreferCan",
          width: "28%",
          align: "center",
          slots: { title: "preferredTitle" },
          scopedSlots: { customRender: "preferredValue" }
        }
      ],
      datas: [
        {
          function: "All-Access Pass  to Landing Platform Features",
          isBFGeneralCan: true,
          isBFPreferCan: true
        },
        {
          function: "Barcode Scanning",
          isBFGeneralCan: true,
          isBFPreferCan: true
        },
        {
          function: "Digital Training Sheets",
          isBFGeneralCan: true,
          isBFPreferCan: true
        },
        {
          function: "Video Uploads",
          isBFGeneralCan: true,
          isBFPreferCan: true
        },
        {
          function: "Beauty Advisor Recruitment",
          isBFGeneralCan: false,
          isBFPreferCan: true
        },
        {
          function: "Beauty Advisor Training",
          isBFGeneralCan: false,
          isBFPreferCan: true
        },
        {
          function: "Direct Messaging with BAs",
          isBFGeneralCan: false,
          isBFPreferCan: true
        },
        {
          function: "Discoverability on App Homescreen",
          isBFGeneralCan: false,
          isBFPreferCan: true
        }
      ],
      selectedRetailer: undefined,
      preRetailer: undefined
    }
  },
  computed: {
    preferredRetailers() {
      let data = this.$store.getters["retailer/preferredRetailers"]
      if (data && data.length > 0) {
        if (this.existedPreferredRetailer && this.existedPreferredRetailer.length > 0) {
          for (let i = 0; i < this.existedPreferredRetailer.length; i++) {
            data = data.filter(
              item => item.retailerId !== this.existedPreferredRetailer[i].retailerId
            )
          }
        }
      }
      return data
    },
    generalRetailer() {
      return this.$store.getters["retailer/generalRetailer"]
    }
  },
  watch: {
    hasGeneralPackage(old, newValue) {
      console.log(
        `....package has hasGeneralPackage: ${this.hasGeneralPackage}, old: ${old}, new: ${newValue}`
      )
    }
  },
  created() {
    this.getPreferredRetailer()
    console.log(`....package has: ${this.hasGeneralPackage}`)
  },
  methods: {
    changePreferredRetailer() {
      if (this.selectedRetailer) {
        let data = this.preferredRetailers.filter(item => item.retailerId === this.selectedRetailer)
        if (data && data.length > 0) {
          this.preRetailer = data[0]
        }
        this.isPreferredBtnDisabled = false
      } else {
        this.isPreferredBtnDisabled = true
      }
    },
    subscribGeneral() {
      this.$emit("subscribe", true, this.generalRetailer)
    },
    subscribPreferred() {
      this.$emit("subscribe", false, this.preRetailer)
    },
    getPreferredRetailer() {
      this.$store.dispatch("retailer/getPreferredRetailers")
    }
  }
}
</script>
<style lang="scss" scoped>
.bf_talbe {
  &__left-content {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    padding-left: 20px;
  }
  &__title {
    font-family: "Niveau Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    color: #1a1818;
  }
  &__select {
    width: 68%;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
  }
  &__btn {
    background-color: #f15a42;
    opacity: 1;
    border-radius: 50px;
    font-family: "Niveau Grotesk";
    font-size: 18px;
    height: 57px;
    line-height: 57px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    width: 88%;
    border: none;
  }
}
</style>
<style lang="scss">
#bf_package_plan_table .ant-table-bordered .ant-table-thead > tr > th,
#bf_package_plan_table .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #cfe5cc;
}
#bf_package_plan_table
  .ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  padding: 26px 8px;
}
#bf_package_plan_table .ant-table-thead > tr > th {
  background: #cfe5cc;
  border-bottom: 1px solid #cfe5cc;
}
#bf_package_plan_table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #cfe5cc;
}
#bf_package_plan_table .ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #cfe5cc;
}
#bf_package_plan_table .ant-table-footer {
  background: rgba(207, 229, 204, 0.4);
}
#bf_package_plan_table .ant-select-selection {
  border: 1px solid #f15a42;
}
#bf_package_plan_table .ant-select-selection .ant-select-arrow-icon {
  color: #f15a42;
}
.bf_talbe__select_dropdown.ant-select-dropdown {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #8c8c8c;
}
.bf_talbe__select_dropdown
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.bf_talbe__select_dropdown
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background: #f15a42;
  color: white;
}
.bf_talbe__btn.ant-btn:hover,
.bf_talbe__btn.ant-btn:focus,
.bf_talbe__btn.ant-btn:active,
.bf_talbe__btn.ant-btn.active {
  background: #f15a42;
  color: white;
}
.bf_talbe__btn.ant-btn > span {
  text-transform: uppercase;
}
.bf_talbe__btn.ant-btn[disabled],
.bf_talbe__btn.ant-btn[disabled]:hover,
.bf_talbe__btn.ant-btn[disabled]:active,
.bf_talbe__btn.ant-btn[disabled]:focus {
  background: rgba(241, 90, 66, 0.4);
  color: white;
}
</style>
