<template>
  <div class="modal-search-order__main">
    <a-modal v-model="searchVisible" width="382px" :footer="null" :title="null">
      <div class="modal-search-order-content">
        <p class="search-first-param">Search Orders</p>
        <div>
          <a-input
            v-model="orderNumber"
            placeholder="Type to search for Reference ID or PO Number"
            style="width: 100%"
            @pressEnter="applyData"
          />
        </div>
        <div>
          <p>Search by {{ isBrand ? "Retailer" : "Brand" }}</p>
          <a-select
            v-model="searchRetailer"
            label-in-value
            mode="multiple"
            :filter-option="false"
            style="width: 100%"
            suffix-icon="search"
            :get-popup-container="triggerNode => triggerNode.parentNode"
            :placeholder="`Type to search by ${isBrand ? 'Retailer' : 'Brand'}`"
            @search="handleSelectValue"
            @change="handleSelectValue"
            @focus="handleSelectValue"
          >
            <a-select-option v-for="item in retailerSelectList" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div v-if="user === 3">
          <p>Search by Brand</p>
          <a-select
            v-model="searchBrand"
            label-in-value
            mode="multiple"
            :filter-option="false"
            style="width: 100%"
            suffix-icon="search"
            placeholder="Type to search by Brand"
            :get-popup-container="triggerNode => triggerNode.parentNode"
            @search="handleSelectBrandValue"
            @change="handleSelectBrandValue"
            @focus="handleSelectBrandValue"
          >
            <a-select-option v-for="item in adminToBrandList" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <p>Search Date Range</p>
        <p class="data-label-style">Starting Date*</p>
        <a-date-picker
          v-model="startDate"
          format="YYYY-MM-DD"
          style="width: 100%"
          :get-calendar-container="triggerNode => triggerNode.parentNode"
          @change="onChangeStartDate"
        />
        <!--        <span v-if="!startDate && showError" class="error-params">StartDate is required</span>-->
        <p class="data-label-style">Ending Date*</p>
        <a-date-picker
          v-model="endDate"
          format="YYYY-MM-DD"
          style="width: 100%"
          :get-calendar-container="triggerNode => triggerNode.parentNode"
          @change="onChangeEndDate"
        />
        <!--        <span v-if="!endDate && showError" class="error-params">EndDate is required</span>-->
        <p>Search by Status</p>
        <a-select
          v-model="selectStatus"
          style="width: 100%"
          placeholder="Please select"
          :get-popup-container="triggerNode => triggerNode.parentNode"
          @change="handleChangeSelect"
        >
          <!--          <span slot="suffixIcon"><a-icon type="close" /></span>-->
          <a-select-option v-for="state in newOrderStatus" :key="state.id">
            {{ state.name }}
          </a-select-option>
        </a-select>
        <p class="modal-button-style">
          <span
            class="apply-button"
            :style="{ 'background-color': applyButtonColor }"
            @click="applyData"
            >Apply</span
          >
        </p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { newOrderStatus } from "@/utils/const"
export default {
  name: "ModalSearchOrder",
  props: {
    brandOrRetailer: { type: Array, default: () => [] },
    adminToBrandInfo: { type: Array, default: () => [] },
    // status: { type: Array, default: () => [] },
    user: { type: Number }
  },
  data() {
    return {
      newOrderStatus,
      searchVisible: false,
      showError: false,
      orderNumber: null,
      startDate: null,
      searchRetailer: [],
      searchBrand: [],
      endDate: null,
      selectStatus: null,
      retailerSelectList: [],
      adminToBrandList: []
    }
  },
  computed: {
    applyButtonColor() {
      if (this.$route.path.includes("retailer")) {
        return "#FEC206"
      }
      return "#4a8a5d"
    },
    isBrand() {
      return !this.$route.path.includes("retailer")
    }
  },
  methods: {
    cancelDialog() {},
    openDialog() {
      this.searchVisible = true
      this.endDate = null
      this.startDate = null
      this.selectStatus = null
      this.orderNumber = null
      this.searchRetailer = []
      this.searchBrand = []
      this.retailerSelectList = JSON.parse(JSON.stringify(this.brandOrRetailer)).slice(0, 50)
      this.adminToBrandList = JSON.parse(JSON.stringify(this.adminToBrandInfo)).slice(0, 50)
    },
    onSearch() {},
    onChangeStartDate(value, dateStr) {
      console.log("start date", value, dateStr)
      this.startDate = dateStr
    },
    onChangeEndDate(value, dateStr) {
      console.log("end date", value, dateStr)
      this.endDate = dateStr
    },
    handleChangeSelect() {},
    applyData() {
      let params = {
        "order-date": [this.startDate, this.endDate],
        "reference-id": this.orderNumber,
        status: this.selectStatus
      }
      if (params["order-date"].length === 2) {
        params["order-date"] = params["order-date"].join(",")
      }
      if (!params.landingReferenceId) {
        delete params.landingReferenceId
      }
      if (!this.startDate && !this.endDate) {
        delete params["order-date"]
      }
      if (!params.status) {
        delete params.status
      }
      // if (!this.startDate || !this.endDate) {
      //   this.showError = true
      //   return
      // }
      // brand
      if (this.user === 1) {
        params["retailer-cptys"] =
          this.searchRetailer && this.searchRetailer.length !== 0
            ? this.searchRetailer.map(item => item.key).join(",")
            : undefined
      }
      // retailer
      if (this.user === 2) {
        params["brand-cptys"] =
          this.searchRetailer && this.searchRetailer.length !== 0
            ? this.searchRetailer.map(item => item.key).join(",")
            : undefined
      }
      // admin
      if (this.user === 3) {
        params["retailer-cptys"] =
          this.searchRetailer && this.searchRetailer.length !== 0
            ? this.searchRetailer.map(item => item.key).join(",")
            : undefined
        params["brand-cptys"] =
          this.searchBrand && this.searchBrand.length !== 0
            ? this.searchBrand.map(item => item.key).join(",")
            : undefined
      }
      const data = {
        ...this.$route.query,
        ...params,
        _index: 1
      }
      this.$emit("getOrderParams", data, params)
      this.searchVisible = false
    },
    handleSelectValue(value) {
      if (!value) {
        this.retailerSelectList = JSON.parse(JSON.stringify(this.brandOrRetailer)).slice(0, 50)
        return
      }
      console.log(666666, this.brandOrRetailer)
      console.log(777777, value)
      this.retailerSelectList = this.brandOrRetailer.filter(item => {
        const name = item.name.toUpperCase()
        const searchValue = value.toUpperCase()
        return name.includes(searchValue)
      })
    },
    handleSelectBrandValue(value) {
      if (!value) {
        this.adminToBrandList = JSON.parse(JSON.stringify(this.adminToBrandInfo)).slice(0, 50)
        return
      }
      this.adminToBrandList = this.adminToBrandInfo.filter(item => {
        const name = item.name.toUpperCase()
        const searchValue = value.toUpperCase()
        return name.includes(searchValue)
      })
    }
  }
}
</script>

<style lang="scss">
.modal-search-order-content {
  p {
    text-align: center;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    color: #000000;
    margin-bottom: 9px;
    margin-top: 20px;
  }
  .search-first-param {
    margin-top: 0;
  }
  .data-label-style {
    text-align: left;
  }
  .modal-button-style {
    margin-top: 60px;
  }
  .apply-button {
    padding: 5px 16px;
    border-radius: 2px;
    background-color: #4a8a5d;
    color: white;
    font-size: 14px;
    cursor: pointer;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .error-params {
    color: #b21f2d;
  }
}
</style>
