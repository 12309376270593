<template lang="pug">
layout
  .signup--section--hero-brand
    .signup--section--hero-brand--inner
      .columns
        .column.signup--section--hero-brand--content__wrapper
          p.signup--section--hero-brand__title Welcome
          p.signup--section--hero-brand__title.brands Brands!
          p.signup--section--hero-brand__content
            | Sign up for a <b>FREE 21-day trial</b> to unlock access to all of our sales tools with no commission fees.
        .column.signup--section--hero-brand--image__wrapper
          .image--right--group
            img.ellipse(src='@/assets/webflowAssets/Signup/brand_ellipse.svg' alt='Dots')
            img.dots--pattern(src='@/assets/webflowAssets/Signup/dots_pattern.svg' alt='Dots')
            img.dots--pattern--2(src='@/assets/webflowAssets/Signup/brand_dots.svg' alt='Dots')
            img.clean--precheck.clean--precheck--4(src='@/assets/webflowAssets/Signup/brand_image.svg' alt='Precheck 4')
  .signup--section--main
    .columns
      .column
        .signup--section--main--benefits
          div
            h2.heading--4(style="font-size: 40px;font-weight: 400") Platform Benefits Include:
            ul
              li
                img.circle--check(src='@/assets/webflowAssets/Signup/circle_check_solid.svg' alt='check')
                p
                  | Analyze your product ingredients against clean retailer standards
              li
                img.circle--check(src='@/assets/webflowAssets/Signup/circle_check_solid.svg' alt='check')
                p
                  | Create smarter training content in minutes
              li
                img.circle--check(src='@/assets/webflowAssets/Signup/circle_check_solid.svg' alt='check')
                p
                    | Access a network of 100+ leading beauty buyers
              li
                img.circle--check(src='@/assets/webflowAssets/Signup/circle_check_solid.svg' alt='check')
                p
                  | Upload your full product catalog so buyers can discover your assortment
              li
                img.circle--check(src='@/assets/webflowAssets/Signup/circle_check_solid.svg' alt='check')
                p
                  | Message potential buyers directly
              li
                img.circle--check(src='@/assets/webflowAssets/Signup/circle_check_solid.svg' alt='check')
                p
                  | Identify and learn about new retailer targets
              li
                img.circle--check(src='@/assets/webflowAssets/Signup/circle_check_solid.svg' alt='check')
                p
                  | Access retailer insights to tailor your pitches
            //</ul>
      .column
        .signup--section--main--form
          div(v-if="!isRegistered")
            sign-up(formTitle="Sign up for a free trial"
              submitLabel="Start Your Free Trial"
              namePlaceholder="Brand Name"
              websitePlaceholder="Brand Website"
              isCheckbox=true
              isBrand=true
              :brandStorageInfo="brandStorageInfo"
              @submitAction="handleSignUpForm")
          div(v-else-if="isAddPayment && isRegistered")
            Payment(@cancelPayment="cancelPayment" @submitPayment="submitPayment" :brandId="brandId")
          div(v-else-if="isSuccess")
            thankyou
</template>

<script>
import SignUp from '@/components/webflowComponent/SignUp'
import Thankyou from '@/components/webflowComponent/Thankyou'
import Payment from '@/components/webflowComponent/Payment'

export default {
  name: "BrandSignUp",
  components: {
    SignUp,
    Thankyou,
    Payment
  },
  data() {
    return {
      isRegistered: false,
      isAddPayment: false,
      isSuccess: false,
      brandInfo: {},
      sendInfo: {},
      brandStorageInfo: {},
      brandId: undefined
    }
  },
  computed: {
    paymentMethodsData() {
      return this.$store.getters["brandPayment/cardInfoList"]
    },
  },
  mounted(){
    this.brandId = this.$route.params ? this.$route.params.brandId : undefined
    console.log(this.brandId)
    if (this.brandId) {
      this.isAddPayment = true
      this.isRegistered = true
    }
    this.$store.dispatch("brandPayment/fetchCards", this.brandId)
  },
  methods: {
    handleSignUpForm(params) {
      this.sendInfo = {
        ...params
      }
      this.isRegistered = true
      this.isAddPayment = true
    },
    async handleSignUp(param) {
        this.$store
            .dispatch("brand/brandRegister", param)
            .then(response => {
              console.log(response)
              // this.brandInfo = response.data.brand
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
              'event': 'registrationComplete',
              'userId': response.data.user.id,
              'firstName': response.data.user.givenName,
              'lastName': response.data.user.familyName,
              'emailAddress': response.data.user.email,
              'userType': "Brand",
              })
              this.isSuccess = true
              this.isRegistered = true
              this.isAddPayment = false
            })
            .catch(err => {
              // if (err.response && err.response.data) {
              //   let errArrays = err.response.data.underlying
              //   let msg = ""
              //   for (let i = 0; i < errArrays.length; i++) {
              //     msg += errArrays[i].userMessage
              //     if (i < errArrays.length - 1) {
              //       msg += ","
              //     }
              //   }
              //   this.$message.error(msg)
              // } else {
              //   this.$message.error(err)
              // }
              this.$message.error(err.message)
            })
    },
    cancelPayment() {
      this.isAddPayment = false
      this.isRegistered = false
      this.brandStorageInfo = {
        ...this.sendInfo
      }
    },
    async submitToTrail(params) {
      let param = {
        id: this.brandId,
        ...params
      }
      console.log(param)
      await this.$store.dispatch("brand/updateBrandPartially", param)
      const param2 = {
        mode: "trial"
      }
      await this.$store.dispatch("brand/updateBrandSubscriptionNew", param2)
      await this.$store.dispatch("brand/getBrand", this.brandId)
      this.$router.push({
        name: "brandSettingsSubscription"
      })
    },
    async submitPayment(params) {
      console.log(this.brandId)
      if (this.brandId && this.brandId.length > 0) {
        this.submitToTrail(params)
      } else {
        let param = {
          ...this.sendInfo,
          brand: {
            ...this.sendInfo.brand,
            ...params
          }
        }
        await this.handleSignUp(param)
      }
      
      // this.isAddPayment = false
      // this.isRegistered = true
    }
  }
}
</script>

<style scoped lang="scss">
p {
  margin-bottom: 0;
}
.signup--section--hero-brand {
  background-color: #FFFBE7;
  height: 480px;
  padding: 40px;
  overflow-y: hidden;
}

.signup--section--hero-brand--inner {
  max-width: 1440px;
  margin: 0 auto;
}

.signup--section--hero-brand--content__wrapper {
  padding-left: 80px;
  padding-top: 80px;
}

.signup--section--hero-brand__title {
  font-family: Niveau Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  line-height: 60px;
  font-feature-settings: 'liga' off;
  color: #1A1818;
}

.signup--section--hero-brand__title.brands {
  color: #EE5E42;
}

.signup--section--hero-brand__title:nth-child(2) {
  margin-bottom: 40px;
}

.signup--section--hero-brand__content {
  max-width: 580px;
  font-family: Mr Eaves Mod OT;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 33px;
  color: #000000;
}

.signup--section--hero-brand--image__wrapper {
  position: relative;
}

.signup--section--hero-brand--image__wrapper .ellipse {
  position: absolute;
  top: 60px;
  right: 20px;
}

.signup--section--hero-brand--image__wrapper .dots--pattern {
  position: absolute;
  top: 40px;
  right: 60px;
}

.signup--section--hero-brand--image__wrapper .dots--pattern--2 {
  position: absolute;
  right: 420px;
  top: 230px;
}

.signup--section--hero-brand--image__wrapper .clean--precheck {
  position: absolute;
}

.signup--section--hero-brand--image__wrapper .clean--precheck--4 {
  right: 135px;
  top: 40px;
}

.signup--section--main--benefits {
  height: 100%;
  background-color: rgba(241, 90, 66, 0.2);
  font-family: Mr Eaves Mod OT;
}

.signup--section--main--benefits > div {
  padding: 80px 80px;
  max-width: 720px;
  margin-left: auto;
}

.signup--section--main--benefits h2 {
  margin-bottom: 40px;
  font-family: Mr Eaves Mod OT;
}

.signup--section--main--benefits li {
  font-family: Mr Eaves Mod OT;
  display: flex;
  align-items: flex-start;
  font-size: 20px;
  font-weight: 400;
  color: #1A1818;
}

.signup--section--main--benefits li:not(:last-child) {
  margin-bottom: 32px;
}

.signup--section--main--benefits li img {
  margin-right: 16px;
}

.signup--section--main--form > div {
  max-width: 720px;
  margin-right: auto;
  padding-top: 48px;
  padding-bottom: 80px;
  padding-left: 20px;
}

.signup--section--main--form > div section {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.signup--section--main--form > div section .form--title {
  text-align: left;
}

@media screen and (max-width: 1439px) {
  .signup--section--hero-brand--content__wrapper {
    padding-left: 12px;
  }

  .signup--section--main--benefits > div {
    padding: 80px 40px;
  }

  .signup--section--main--form {
    padding-right: 24px;
  }

  .signup--section--main--form > div {
    padding-left: 0;
  }
}

@media screen and (max-width: 1199px) {
  .signup--section--hero-brand--content__wrapper .signup--section--hero-brand__title {
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 55px;
  }

  .signup--section--hero-brand--image__wrapper .ellipse {
    position: absolute;
    top: 60px;
    right: 20px;
    width: 126.5px;
    height: 126.5px;
  }

  .signup--section--hero-brand--image__wrapper .dots--pattern {
    position: absolute;
    top: 40px;
    right: 60px;
    height: 96px;
    width: 127px;
  }

  .signup--section--hero-brand--image__wrapper .dots--pattern--2 {
    position: absolute;
    right: 250px;
    top: 230px;
    height: 96px;
    width: 127px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck {
    height: 66px;
    width: 222px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--1 {
    right: 100px;
    top: 50px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--2 {
    right: 203px;
    top: 135px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--3 {
    right: 100px;
    top: 220px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--4 {
    right: 255px;
    top: 305px;
  }
}

@media screen and (max-width: 1023px) {
  .signup--section--main > .columns {
    display: block;
    margin: 0;
  }

  .signup--section--main > .columns > .column {
    padding: 0;
  }

  .signup--section--main--benefits > div {
    padding: 40px 20px;
    max-width: none;
    max-width: 600px;
    margin: 0 auto;
  }

  .signup--section--main--benefits .heading--4 {
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 20px;
  }

  .signup--section--main--form {
    padding-right: 0;
  }

  .signup--section--main--form > div {
    max-width: none;
    padding: 0;
  }

  .signup--section--main--form section {
    margin: 0 auto !important;
  }
}

@media screen and (max-width: 991px) {
  .signup--section--hero-brand {
    height: auto;
    padding: 40px 20px;
  }

  .signup--section--hero-brand--inner > .columns {
    display: block;
    margin: 0;
  }

  .signup--section--hero-brand--content__wrapper {
    padding: 0;
    text-align: center;
  }

  .signup--section--hero-brand__content {
    max-width: 400px;
    margin: 0 auto;
    font-size: 25px;
    line-height: 28px;
  }

  .signup--section--hero-brand--image__wrapper {
    height: 400px;
    max-width: 400px;
    margin: 0 auto;
  }

  .signup--section--hero-brand--image__wrapper .dots--pattern {
    position: absolute;
    top: 40px;
    right: 60px;
    height: 96px;
    width: 127px;
  }

  .signup--section--hero-brand--image__wrapper .dots--pattern--2 {
    position: absolute;
    right: 250px;
    top: 285px;
    height: 96px;
    width: 127px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--1 {
    right: 32px;
    top: 50px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--2 {
    right: 145px;
    top: 135px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--3 {
    right: 32px;
    top: 220px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--4 {
    right: 145px;
    top: 305px;
  }
}
</style>