<template>
  <div class="wrapper">
    <div class="desktop-nav">
      <div class="nav-container">
        <div class="logo">
          <router-link to="/admin/main">
            <img src="~@/assets/img/logo/Logo.svg" alt="" />
          </router-link>
        </div>
        <!-- <div class="column quick-search">
          <RetailerQuickSearch ref="retailerQuickSearch" key="1" />
        </div> -->
        <ul class="menu-items">
          <li>
            <router-link to="/admin/marketplace">
              <a-icon class="retailer-icon-style" type="shop" />
              {{ $t("Components.Retailers.Header.marketPlace") }}
            </router-link>
          </li>
          <li>
            <a-dropdown placement="bottomCenter" overlay-class-name="admin-console-header-dropdown">
              <a-menu slot="overlay">
                <a-menu-item key="1">
                  <router-link to="/admin/brandEdit">
                    {{ $t("Components.Admin.Header.EditAdd") }}
                  </router-link>
                </a-menu-item>
              </a-menu>
              <router-link id="brandLink" to="/admin/brandSearch">
                <img id="adminBrands" class="retailer-icon-style" />
                {{ $t("Components.Admin.Header.Brands") }}
              </router-link>
            </a-dropdown>
          </li>
          <li>
            <a-dropdown placement="bottomCenter" overlay-class-name="admin-console-header-dropdown">
              <a-menu slot="overlay">
                <a-menu-item key="1">
                  <router-link to="/admin/retailerEdit">
                    {{ $t("Components.Admin.Header.EditAdd") }}
                  </router-link>
                </a-menu-item>
                <a-menu-item key="2">
                  <router-link to="/admin/margin">
                    Retailer Margin
                  </router-link>
                </a-menu-item>
              </a-menu>
              <router-link id="retailerLink" to="/admin/retailerSearch">
                <img id="adminRetailers" class="retailer-icon-style" />
                {{ $t("Components.Admin.Header.Retailers") }}
              </router-link>
            </a-dropdown>
          </li>
          <li>
            <router-link to="/admin/messaging">
              <a-badge
                v-if="unReadMessage > 0"
                :count="unReadMessage"
                :overflow-count="99"
                style="font-size:24px;"
              >
                <a-icon type="inbox" />
              </a-badge>
              <a-icon v-else class="retailer-icon-style" type="inbox" />
              <span :style="unReadMessage > 0 ? 'display:block;margin-top:-4px;' : ''">Inbox</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/admin/orders?tabId=1&status=pending,confirmed&_sort-by=created_at&_order-by=PREVIOUS&_limit=50"
            >
              <a-icon class="retailer-icon-style" type="line-chart" />
              {{ $t("Components.Brands.Header.orders") }}
            </router-link>
          </li>
          <li>
            <a-dropdown placement="bottomCenter" overlay-class-name="admin-console-header-dropdown">
              <a-menu slot="overlay">
                <a-menu-item key="1">
                  <router-link to="/admin/productEdit">
                    {{ $t("Components.Admin.Header.EditAdd") }}
                  </router-link>
                </a-menu-item>
              </a-menu>
              <router-link id="productLink" to="/admin/productSearch">
                <img id="adminProduct" class="retailer-icon-style" />
                <!-- <a-icon id="productImage" class="retailer-icon-style" type="gift" theme="filled" /> -->
                {{ $t("Components.Admin.Header.Products") }}
              </router-link>
            </a-dropdown>
          </li>
          <li>
            <router-link to="/admin/inventory">
              <a-icon class="retailer-icon-style" type="table" />
              {{ $t("Components.Brands.Header.inventory") }}
            </router-link>
          </li>
          <li>
            <router-link to="/admin/dirtyIngredient">
              <img id="adminCleanPreCheck" class="retailer-icon-style" />
              {{ $t("Components.Brands.Header.preCheck") }}
            </router-link>
          </li>
          <li>
            <router-link to="/admin/adminTraining?tab=0">
              <a-icon id="adminTrainingIcon" class="retailer-icon-style" type="bulb" />
              <span id="adminTrainingText">Training</span>
            </router-link>
          </li>
          <li>
            <router-link id="systemLink" to="/admin/system">
              <a-icon class="retailer-icon-style" type="setting" />
              System
            </router-link>
          </li>
          <!--          <li>-->
          <!--            <router-link to="/brand/messaging">-->
          <!--              <a-icon class="retailer-icon-style" type="inbox">-->
          <!--                {{$t('Components.Brands.Header.inbox')}}-->
          <!--              </a-icon>-->
          <!--            </router-link>-->
          <!--          </li>-->
          <li class="dropdown">
            <a href="#">
              <img src="@/assets/img/main-icons/user.svg" />
            </a>
            <div class="profile-menu">
              <a @click.prevent="signOut()">
                <img src="~@/assets/img/retailer-brand-profile/signout.svg" alt="" />
                <span style="color:#FF6132">
                  {{ $t("Components.Brands.Header.signOut") }}
                </span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import constants from "@/config"

export default {
  data() {
    return {
      topMenu: false,
      shopMenu: false,
      currentRoute: this.$route,
      accountLinks: [
        { name: "Dashboard", path: "/admin/dashboard" },
        { name: "Marketplace", path: "/admin/marketplace" },
        { name: "Clients", path: "/admin/clients" },
        { name: "Orders", path: "/admin/orders" },
        { name: "Training", path: "/admin/adminTraining" },
        { name: "Settings", path: "/admin/settings" }
      ],
      showPlan: false
    }
  },
  computed: {
    userName() {
      return this.$store.getters["user/name"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    unReadMessage() {
      return this.$store.getters["adminMessage/unReadMessageCount"]
    },
    isBrandPremium() {
      return (
        this.brand &&
        this.brand.subscriptions &&
        this.brand.subscriptions[0] &&
        this.brand.subscriptions[0].type.id == "premium"
      )
    },
    isAdminRetailerRoute() {
      return constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isAdminBrandrRoute() {
      return constants.adminBrandRoutes.includes(this.$route.name)
    },
    navigationalTags() {
      return this.$store.getters["reference/navigationalTags"]
    },
    categories() {
      return this.$store.getters["reference/categories"]
    },
    monthAgo() {
      const today = new Date()
      const monthAgoDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())

      return monthAgoDate.toISOString().split("T")[0]
    },
    showCart() {
      return constants.adminRetailerRoutes.includes(this.$route.name)
    },
    productsCount() {
      return this.$store.getters["retailer/cartProductsCount"]
    },
    isRetailerPending() {
      return this.$store.getters["retailer/isRetailerPending"]
    },
    isRetailerRejected() {
      return this.$store.getters["retailer/isRetailerRejected"]
    }
  },
  watch: {
    $route(to) {
      this.initHeaderIConColor(to)
    }
  },
  async created() {
    console.log(this.$firebaseMessaging)
    this.$firebaseMessaging.onMessage(msg => {
      console.log(msg)
    })
    this.$store.dispatch("adminMessage/getUnReadMessage")
    await this.setReference()
  },
  mounted() {
    this.initHeaderIConColor(this.$route)
  },
  methods: {
    ...mapActions("reference", ["setReference"]),
    ...mapActions("user", ["signOut"]),
    isWorkspacePages() {
      return [
        "adminSettings",
        "adminDashboard",
        "adminOrders",
        "adminClients",
        "adminAnalytics"
      ].includes(this.$route.name)
    },
    hideTopMenu() {
      this.topMenu = false
    },
    hideShopMenu() {
      this.shopMenu = false
    },
    shopMenuToggle() {
      this.shopMenu = true
      this.topMenu = false
    },
    toggle() {
      this.topMenu = !this.topMenu
      this.shopMenu = false
    },
    hide() {
      this.showPlan = false
    },
    initHeaderIConColor(to) {
      let brandLink = document.getElementById("brandLink")
      let retailerLink = document.getElementById("retailerLink")
      let productLink = document.getElementById("productLink")
      let brandLinkImage = document.getElementById("adminBrands")
      let retailerLinkImage = document.getElementById("adminRetailers")
      let productLinkImage = document.getElementById("adminProduct")
      let adminTrainingIcon = document.getElementById("adminTrainingIcon")
      let adminTrainingText = document.getElementById("adminTrainingText")
      if (
        to.path === "/admin/productEdit" ||
        to.path === "/admin/productSearch" ||
        to.path === "/admin/productSearch/result" ||
        to.path === "/admin/productSearch/arrival" ||
        to.path === "/admin/productSearch/best" ||
        to.path.toString().startsWith("/admin/product/")
      ) {
        this.activeLink(productLink)
        this.grayLink(retailerLink)
        this.grayLink(brandLink)

        this.activeOrDeActiveImage(productLinkImage, "active-product", "gray-product", true)
        this.activeOrDeActiveImage(brandLinkImage, "active-brand", "gray-brand", false)
        this.activeOrDeActiveImage(retailerLinkImage, "active-retailer", "gray-retailer", false)
      } else if (
        to.path === "/admin/brandEdit" ||
        to.path === "/admin/brandSearch" ||
        to.path.toString().startsWith("/admin/brand/")
      ) {
        this.grayLink(productLink)
        this.grayLink(retailerLink)
        this.activeLink(brandLink)
        this.activeOrDeActiveImage(productLinkImage, "active-product", "gray-product", false)
        this.activeOrDeActiveImage(brandLinkImage, "active-brand", "gray-brand", true)
        this.activeOrDeActiveImage(retailerLinkImage, "active-retailer", "gray-retailer", false)
      } else if (
        to.path === "/admin/retailerEdit" ||
        to.path === "/admin/retailerSearch" ||
        to.path === "/admin/margin" ||
        to.path.toString().startsWith("/admin/retailer/")
      ) {
        this.grayLink(productLink)
        this.grayLink(brandLink)
        this.activeLink(retailerLink)
        this.activeOrDeActiveImage(productLinkImage, "active-product", "gray-product", false)
        this.activeOrDeActiveImage(brandLinkImage, "active-brand", "gray-brand", false)
        this.activeOrDeActiveImage(retailerLinkImage, "active-retailer", "gray-retailer", true)
      } else {
        this.grayLink(productLink)
        this.grayLink(brandLink)
        this.grayLink(retailerLink)
        this.activeOrDeActiveImage(productLinkImage, "active-product", "gray-product", false)
        this.activeOrDeActiveImage(brandLinkImage, "active-brand", "gray-brand", false)
        this.activeOrDeActiveImage(retailerLinkImage, "active-retailer", "gray-retailer", false)
      }

      if (to.path.toString() === "/admin/adminTraining/") {
        adminTrainingIcon.style.setProperty("color", "#4a8a5d")
        adminTrainingText.style.setProperty("color", "#4a8a5d")
      } else if (to.path.toString().indexOf("adminTraining") < 0) {
        adminTrainingIcon.style.setProperty("color", "#595959")
        adminTrainingText.style.setProperty("color", "#595959")
        console.log("remove class")
      }
    },
    activeLink(element) {
      if (element) {
        element.style.setProperty("color", "#4a8a5d", "important")
      }
    },
    activeOrDeActiveImage(element, activeClassName, deActiveClassName, isActive) {
      if (element) {
        let newClassName = element.className
        if (newClassName && newClassName.length > 0) {
          newClassName = newClassName.split(" ")
        }
        if (!isActive) {
          newClassName = newClassName.filter(item => item.toString().indexOf(activeClassName) < 0)
          element.className = newClassName.join(" ")
          if (element.className.indexOf(deActiveClassName) < 0) {
            element.className += " "
            element.className += deActiveClassName
          }
        } else {
          newClassName = newClassName.filter(item => item.toString().indexOf(deActiveClassName) < 0)
          element.className = newClassName.join(" ")
          if (element.className.indexOf(activeClassName) < 0) {
            element.className += " "
            element.className += activeClassName
          }
        }
      }
    },
    grayLink(element) {
      if (element) {
        element.style.setProperty("color", "#4a4a4a", "important")
      }
    }
  }
}
</script>

<style scoped>
/* Font Configuration */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@font-face {
  font-family: "ProximaNova-Regular";
  src: url(~@/assets/fonts/ProximaNova-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Display-Regular";
  src: url(~@/assets/fonts/SFProDisplay-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Colors */

:root {
  --Gray-2: #fafafa;
  --Gray-3: #f5f5f5;
  --Gray-4: #e8e8e8;
  --Gray-5: #d9d9d9;
  --Gray-7: #8c8c8c;
  --Gray-8: #595959;
  --Gray-10: #000000;
  --Landing_Brand_Orange: #ff6132;
  --Landing_Green: #4a8a5d;
}

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "ProximaNova-Regular";
}

.wrapper {
  width: 100%;
  margin: 0 auto;
}

/* Navigation Style */

.quick-search {
  margin-left: 5%;
  margin-right: 1%;
}

.column {
  padding: 3px 0px 3px 0px;
}

.desktop-nav {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: "ProximaNova-Regular";
}

.desktop-nav .nav-container {
  position: relative;
  display: -webkit-flex;
  justify-content: space-between;
  max-width: 1197px;
  /*min-width: 954px;*/
  margin: auto;
  /*box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 0;*/
}
.desktop-nav .logo {
  margin-top: 13px;
  margin-bottom: 13px;
  margin-left: 60px;
}

.desktop-nav .menu-items {
  margin-top: 14px;
  margin-bottom: 5px;
  margin-right: 106px;
  display: -webkit-flex;
  list-style: none;
}

.desktop-nav .menu-items li {
  margin: 0 12px;
}

.desktop-nav .menu-items li a {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  text-decoration: none;
  color: var(--Gray-7);
}

.desktop-nav .menu-items li img {
  display: block;
  margin: auto;
}

.inventory {
  padding: 3px 1px;
}

.catalog {
  padding: 1px 3px;
}

.payments {
  padding: 1px 1px;
}

.cpcheck {
  padding-top: 1px;
  padding-bottom: 2px;
}

.desktop-nav .menu-items .dropdown .dropdown-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #8c8c8c;
  width: 36px;
  height: 36px;
  margin-top: 1px;
  margin-bottom: 0px;
}

/* Begin Dropdown */

.desktop-nav .menu-items li .profile-menu {
  display: none;
  position: absolute;
  margin-top: 48px;
  min-width: 99px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  margin-left: -28px;
  z-index: 5;
}

.desktop-nav .menu-items li .profile-menu a {
  display: block;
  padding: 4px;
  padding-left: 36px;
  font-family: "ProximaNova-Regular" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--Gray-8);
}

.desktop-nav .menu-items li .profile-menu a img {
  display: block;
  position: absolute;
  margin-left: -24px;
  margin-top: 2px;
}

.desktop-nav .menu-items li .profile-menu a:hover {
  background-color: var(--Gray-4);
}

.desktop-nav .menu-items .dropdown:hover .profile-menu {
  display: block;
}
.router-link-active {
  color: #4a8a5d !important;
}
.retailer-icon-style {
  display: block;
  font-size: 24px;
}
.adminTraining.retailer-icon-style {
  color: #4a8a5d;
}
.adminTraining {
  color: #4a8a5d;
}
#adminCleanPreCheck {
  content: url("~@/assets/img/clean-pre-check/clean_nav_gray.svg");
}
.router-link-active > #adminCleanPreCheck {
  content: url("~@/assets/img/clean-pre-check/clean_nav_green.svg");
}
.active-brand {
  content: url("~@/assets/img/icon/brand_nav_green.svg");
}
.gray-brand {
  content: url("~@/assets/img/icon/brand_nav_gray.svg");
}
.active-retailer {
  content: url("~@/assets/img/icon/retailer_nav_green.svg");
}
.gray-retailer {
  content: url("~@/assets/img/icon/retailer_nav_gray.svg");
}
.active-product {
  content: url("~@/assets/img/products/product_icon_on.png");
  width: 34px;
  height: 23px;
}
.gray-product {
  content: url("~@/assets/img/products/product_icon_off.png");
  width: 34px;
  height: 23px;
}
/* #adminBrands {
  content: url("~@/assets/img/icon/brand_nav_gray.svg");
}
.router-link-active > #adminBrands {
  content: url("~@/assets/img/icon/brand_nav_green.svg");
}
#adminRetailers {
  content: url("~@/assets/img/icon/retailer_nav_gray.svg");
}
.router-link-active > #adminRetailers {
  content: url("~@/assets/img/icon/retailer_nav_green.svg");
} */
.menu-icon-style {
  display: block;
  position: absolute;
  margin-left: -24px;
  margin-top: 2px;
  font-size: 14px;
}
</style>

<style lang="scss">
.admin-console-header-dropdown .ant-dropdown-menu-item:hover,
.admin-console-header-dropdown .ant-dropdown-menu-submenu-title:hover {
  background-color: #e2eeec !important;
}
</style>
