<template lang="pug">
.brand-pending
  .brand-pending__panel(v-show="!isRejected && !isAdditionalEmailVerificationSent")
    h1.brand-pending__title {{$t('Brands.BrandPending.welcome')}}
    p.brand-pending__paragraph.welcome-subheading.first-subheading {{$t('Brands.BrandPending.recievedRegistrationMessage')}}
    p.brand-pending__paragraph.welcome-subheading {{$t('Brands.BrandPending.whatHappensNext')}}
    p.brand-pending__paragraph
      img(v-if="isApproved" src="@/assets/img/icon/pending-check-success.svg")
      img(v-else src="@/assets/img/icon/pending-check.svg")
      .brand-pending__check(:class="{ 'brand-pending__check--passed': isApproved }") {{$t('Brands.BrandPending.brandApproval')}}
    p.brand-pending__paragraph.sub-section {{$t('Brands.BrandPending.contactUsMessage')}}
      a.pending-email(href="mailto:support@landingintl.com") support@landingintl.com
      span .
    p.brand-pending__paragraph
      img(v-if="isEmailVerified" src="@/assets/img/icon/pending-check-success.svg")
      img(v-else="isEmailVerified" src="@/assets/img/icon/pending-check.svg")
      .brand-pending__check(:class="{ 'brand-pending__check--passed': isEmailVerified }") {{$t('Brands.BrandPending.emailVerification')}}
    p.brand-pending__paragraph.sub-section {{$t('Retailers.RetailerVerifyEmail.emailVerificationMessage')}}
    template(v-if="!isEmailVerified")
      .brand-pending__divider
      .columns.is-mobile
        .column.is-narrow {{$t('Brands.BrandPending.emailReceivedQn')}}
        .column.has-text-centered
          a.brand-pending__contact-us(@click="resendEmail") {{$t('Brands.BrandPending.resendEmail')}}

  .brand-pending__panel(v-show="isAdditionalEmailVerificationSent")
    h1.brand-pending__title {{$t('Brands.BrandPending.verifyYourEmail')}}
    p.brand-pending__paragraph {{$t('Brands.BrandPending.confirmYourEmailAddress')}}
    .brand-pending__verification-sent-divider
    .has-text-centered
      router-link.brand-pending__contact-us(to="/login") {{$t('ok')}}

  .brand-pending__panel(v-show="isRejected")
    h1.brand-pending__title {{$t('Brands.BrandPending.letsTalk')}}
    p.brand-pending__paragraph {{$t('Brands.BrandPending.brandAccountNotApproved')}}
    .brand-pending__divider
    .has-text-centered
      a.brand-pending__contact-us(href="mailto:brands@landingintl.com") {{$t('Brands.BrandPending.contactUs')}}
</template>

<script>
import firebase from "@/services/firebase-service"
export default {
  data() {
    return {
      brand: null,
      isAdditionalEmailVerificationSent: false
    }
  },

  computed: {
    isEmailVerified() {
      return this.$store.getters["user/isEmailVerified"]
    },
    isRejected() {
      return this.brand.status.id === "rejected"
    },
    isApproved() {
      return this.brand.status.id !== "rejected" && this.brand.status.id !== "pending"
    },
    userEmail() {
      return this.$store.getters["user/email"]
    },
    brandStatus() {
      return this.brand && this.brand.status && this.brand.status.id
    }
  },
  watch: {
    async brandStatus() {
      this.checkBrandStatus()
    }
  },
  async created() {
    this.brand = this.$store.getters["brand/brand"]
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "firstLogin",
      userId: this.$store.getters["user/id"],
      firstName: this.$store.getters["user/givenName"],
      lastName: this.$store.getters["user/familyName"],
      emailAddress: this.$store.getters["user/email"],
      userType: "Brand"
    })
    await this.checkBrandStatus()
  },

  methods: {
    async sha256(email) {
      const msgBuffer = new TextEncoder("utf-8").encode(email)
      const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer)
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashHex = hashArray.map(b => ("00" + b.toString(16)).slice(-2)).join("")

      return hashHex
    },
    async isLegacyUser() {
      return (
        await firebase
          .database()
          .ref("legacy/users/" + (await this.sha256(this.userEmail)))
          .once("value", snapshot => {
            return snapshot.val()
          })
      ).toJSON()
    },
    async checkBrandStatus() {
      if (await this.isLegacyUser()) {
        this.$router.push({ name: "brandProfile" })
      } else if (this.brandStatus && this.brandStatus == "approved" && this.isEmailVerified) {
        this.$router.push({ name: "brandProfile" })
      }
    },
    async resendEmail() {
      try {
        await this.$store.dispatch("user/sendEmailVerifyLink", this.userEmail)
        this.isAdditionalEmailVerificationSent = true
      } catch (error) {
        console.error(error)
      }
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.$store.getters["brand/isBrandPending"] && to.name !== "login") {
      return
    }

    next()
  }
}
</script>

<style lang="sass">
.brand-pending
  display: flex
  min-height: 80vh
  justify-content: center
  align-items: center

  &__contact-us
    color: #008489

  &__panel
    padding: 24px
    padding-bottom: 20px
    min-height: 335px
    width: 362px
    border: 1px solid #E5E5E5
    border-radius: 2px
    background-color: #FFFFFF

  &__title
    color: #000000
    font-family: "Proxima"
    font-size: 16px
    letter-spacing: 0.44px

  &__paragraph
    margin-top: 16px
    color: #8D8D8D
    font-size: 14px
    letter-spacing: 0.25px

  &__divider
    border-bottom: 1px solid #e5e5e5
    margin: 40px 0px 25px 0px

  &__verification-sent-divider
    border-bottom: 1px solid #e5e5e5
    margin: 140px 0px 25px 0px

  &__link
    color: #008489
    cursor: pointer

  img
    float: left

  &__check
    color: #8D8D8D
    font-family: "Proxima"
    font-size: 14px
    letter-spacing: 0.25px
    font-weight: 500
    height: 20px
    line-height: 20px
    margin-left: 30px

    &--passed
      color: #008489
  .pending-email
    color: #4A90E2
  .welcome-subheading
    color: #000000
    margin: 0
  .first-subheading
    margin-top: 16px
  .sub-section
    margin-left: 30px
    margin-top: 10px
</style>
