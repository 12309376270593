<template>
  <div ref="addNewRetailerPart1" class="brand-training-retailer-add-dialog__main">
    <a-modal
      v-model="showModal"
      width="806px"
      :footer="null"
      :get-container="() => $refs.addNewRetailerPart1"
      @cancel="closeModal"
    >
      <p slot="title" class="modal-title-location">Step 1. Select A Retailer</p>
      <p class="step-content-text">
        Listed below are retailers who are eligible training pages. Pick one retailer you'd like to
        Share your training information to. The card you have on file will be charged.
      </p>
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
          hideDefaultSelections: true,
          type: 'radio'
        }"
        :scroll="{ y: 400 }"
        :pagination="false"
        :columns="columns"
        :data-source="retailerData"
        table-layout="fixed"
        :get-popup-container="() => $refs.addNewRetailerPart1"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
      </a-table>
      <div class="button-step-style">
        <a-button class="cancel-button-style" @click="closeDialog">Cancel</a-button>
        <a-button class="next-button-style" style="margin-left: 15px" @click="nextDialog"
          >Next</a-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { priceFormat } from "@/utils/validate"
export default {
  name: "BrandTrainingRetailerAddDialog",
  data() {
    return {
      showModal: false,
      selectedRowKeys: [],
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name"
        },
        {
          title: "Locations",
          dataIndex: "locationNumber",
          key: "locationNumber"
        },
        {
          title: "Territory",
          dataIndex: "territory",
          key: "territory"
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
          width: 250
        }
      ],
      retailerData: []
    }
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    }
    // retailerData() {
    //   let arr
    //   const list = this.brand.brandTrainingSubscriptions
    //   for (let i = 0; i < list.length; i+=) {
    //     const retailer = this.
    //   }
    // }
  },
  methods: {
    ...mapActions({
      // getSearchTrainingPro: "training/getSearchTrainingPro"
      getRetailer: "retailer/fetchRetailers"
    }),
    async getRetailerInfo() {
      const params = {
        status: "approved",
        "have-training-price": true
      }
      const list = await this.getRetailer({ params })
      this.retailerData = list.data.map(item => {
        item.key = item.id
        item.locationNumber =
          item.locations && item.locations.length !== 0 ? item.locations.length : 0
        item.territory = item.addresses[0].country.id.toUpperCase()
        item.price = `${priceFormat(item.trainingPrices[0].annuallyPrice) || 0}/${priceFormat(
          item.trainingPrices[0].quarterlyPrice
        ) || 0}/${priceFormat(item.trainingPrices[0].monthlyPrice) || 0}`
        return item
      })
    },
    async openDialog() {
      this.showModal = true
      await this.getRetailerInfo()
    },
    closeModal() {},
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    closeDialog() {
      this.showModal = false
      this.selectedRowKeys = []
    },
    nextDialog() {
      if (this.selectedRowKeys && this.selectedRowKeys.length === 0) {
        this.$message.warning("Select a retailer before proceeding to the next step")
        return
      }
      let selectInfo = []
      for (let i = 0; i < this.selectedRowKeys.length; i++) {
        const arr = this.retailerData.filter(item => this.selectedRowKeys[i] === item.key)
        selectInfo = selectInfo.concat(arr)
      }
      const step = 2
      this.$emit("updateStepStatus", step, selectInfo)
    }
  }
}
</script>

<style lang="scss">
.brand-training-retailer-add-dialog__main {
  p {
    margin-bottom: 0;
  }
  .modal-title-location {
    width: 100%;
    text-align: center;
  }
  .step-content-text {
    margin-bottom: 20px;
  }
  .button-step-style {
    text-align: right;
    margin-top: 35px;
    .cancel-button-style {
      width: 160px;
      color: white;
      background-color: rgba(242, 124, 89, 1);
    }
    .next-button-style {
      width: 160px;
      color: white;
      background-color: rgba(145, 211, 149, 1);
    }
  }
  .ant-table-thead > tr > th {
    background-color: #ffffff;
  }
  .ant-radio-wrapper {
    height: 16px;
  }
  .ant-radio {
    top: 0;
  }
  .ant-radio-wrapper::after {
    contain: none;
    display: none;
  }
}
</style>
