<template lang="pug">
.brand-new-product-base-form
  .field
    label.label.brand-new-product__label {{$t('Components.Brands.Products.ProductBaseForm.productName')}} *
      span.span-left
        BaseFormPopup(:text="$t('Components.Brands.Products.ProductBaseForm.productNameToolTip')")
    .control
      input.input.brand-new-product__input--fullwidth(
        v-model.trim="$v.product.name.$model"
        :class="{ 'is-danger': $v.product.name.$error }"
        @blur="$v.product.name.$touch()"
        v-bind:placeholder="$t('Components.Brands.Products.ProductBaseForm.productPlaceholder')"
      )
    p.help.is-danger(v-show="$v.product.name.$error && !$v.product.name.required") {{$t('Components.Brands.Products.ProductBaseForm.Validations.productNameRequired')}}
    p.help.is-danger(v-show="$v.product.name.$error && !$v.product.name.maxLength") {{$t('Components.Brands.Products.ProductBaseForm.Validations.characterExceeds100')}}

  .columns.is-marginless
    .column
      .brand-new-product__label {{$t('Components.Brands.Products.ProductBaseForm.categorization')}} *
    .column
      .brand-new-product__label(v-show="activeSubcategories.length")
    .column
      .brand-new-product__label(v-show="activeMicroCategories.length")
  .columns.is-marginless
    .column
      .brand-new-product__categories-container
        .brand-new-product__category-select {{$t('Components.Brands.Products.ProductBaseForm.selectCategory')}}
        .brand-new-product__category-element(:class="{ 'brand-new-product__category-element--selected': product.category.id === category.id }" v-for="category in categories" :key="category.id" @click="makeCategoryActive(category)") {{ category.name }}
      p.help.is-danger(v-show="$v.product.category && $v.product.category.id.$error && !$v.product.category.id.required") {{$t('Components.Brands.Products.ProductBaseForm.Validations.categoryRequired')}}
    .column
      .brand-new-product__categories-container
        .brand-new-product__category-select(v-show="activeSubcategories.length") {{$t('Components.Brands.Products.ProductBaseForm.selectSubCategory')}}
        .brand-new-product__category-element(:class="{ 'brand-new-product__category-element--selected': product.subcategory.id === subcategory.id }" v-show="activeSubcategories.length" :key="subcategory.id" v-for="subcategory in activeSubcategories" @click="makeSubcategoryActive(subcategory)") {{ subcategory.name }}
      p.help.is-danger(v-show="$v.product.subcategory && $v.product.subcategory.id.$error && !$v.product.subcategory.id.required") {{$t('Components.Brands.Products.ProductBaseForm.Validations.sucCategoryRequired')}}
    .column
      .brand-new-product__categories-container
        .brand-new-product__category-select(v-show="activeMicroCategories.length") {{$t('Components.Brands.Products.ProductBaseForm.selectMicroCategory')}}
        .brand-new-product__category-element(:class="{ 'brand-new-product__category-element--selected': product.microCategory.id === microCategory.id }" v-show="activeMicroCategories.length" v-for="microCategory in activeMicroCategories" :key="microCategory.id" @click="makeMicrocategoryActive(microCategory)") {{ microCategory.name }}
  .has-text-right.brand-new-product__category-optional(v-show="activeMicroCategories.length") {{$t('Components.Brands.Products.ProductBaseForm.optional')}}

  .columns.is-marginless
    .column
      .field
        .control
          label.label.brand-new-product__label {{$t('Components.Brands.Products.ProductBaseForm.type')}}
            span.span-left
              BaseFormPopup(:text="$t('Components.Brands.Products.ProductBaseForm.productTypeToolTip')")
          .select.brand-new-product__select
            select.brand-new-product__select(v-model="product.type.id")
              option(:value="null" :key="null") {{$t('Components.Brands.Products.ProductBaseForm.selectType')}}
              option(v-for="type in types" :value="type.id" :key="type.id") {{ type.name }}
    .column
      .field
        .control
          label.label.brand-new-product__label {{$t('Components.Brands.Products.ProductBaseForm.status')}} *
          .select.brand-new-product__select
            select.brand-new-product__select(
              v-model="$v.product.status.id.$model"
              :class="{ 'is-danger': $v.product.status && $v.product.status.id.$error }"
              @blur="$v.product.status.id.$touch()"
            )
              option(v-for="status in statuses" :value="status.id" :key="status.id") {{status.name }}
          p.help.is-danger(v-show="$v.product.status && $v.product.status.id.$error && !$v.product.status.id.required") {{$t('Components.Brands.Products.ProductBaseForm.Validations.statusRequired')}}

  .columns.is-marginless
    .column
      div
        span.brand-new-product__popup-label {{$t('Components.Brands.Products.ProductBaseForm.size')}} *
        span
          BaseFormPopup(:text="$t('Components.Brands.Products.ProductBaseForm.sizeToolTip')")
      .control.brand-new-product__popup-control
      .field.has-addons
        .control
          input.input.brand-new-product__size-input(
            v-model.trim="$v.product.size.$model"
            :class="{ 'is-danger': $v.product.size.$error }"
            @blur="$v.product.size.$touch()"
            v-bind:placeholder="$t('Components.Brands.Products.ProductBaseForm.size')"
          )
          p.help.is-danger(v-show="$v.product.size.$error && !$v.product.size.required") {{$t('Components.Brands.Products.ProductBaseForm.Validations.sizeRequired')}}
          p.help.is-danger(v-show="$v.product.size.$error && $v.product.size.required && !$v.product.sizeUnits.id.positiveNumber") {{$t('Components.Brands.Products.ProductBaseForm.Validations.sizeMustBePositiveIntegerOrFloat')}}
        .control
          .select.brand-new-product__size-select
            select(
              v-model="$v.product.sizeUnits.id.$model"
              :class="{ 'is-danger': $v.product.sizeUnits && $v.product.sizeUnits.id.$error }"
              @blur="$v.product.sizeUnits.id.$touch()"
            )
              option(v-for="size in productSizeUnits" :key="size.id" :value="size.id") {{ size.id }}
            p.help.is-danger(v-show="$v.product.sizeUnits && $v.product.sizeUnits.id.$error && !$v.product.sizeUnits.id.required") {{$t('Components.Brands.Products.ProductBaseForm.Validations.unitRequired')}}
    .column
      label.label.brand-new-product__label {{$t('Components.Brands.Products.ProductBaseForm.msrp')}} *
      .field.has-addons
        .control
          a.button $
        .control
          input.input.brand-new-product__msrp-input(
            v-model="$v.product.msrpUsd.$model"
            :class="{ 'is-danger': $v.product.msrpUsd.$error || msrpExceeds() }"
            @blur="$v.product.msrpUsd.$touch()"
            v-bind:placeholder="$t('Components.Brands.Products.ProductBaseForm.enterMSRP')"
          )
          //.input-errors(v-show="$v.product.msrpUsd.$error")
          p.help.is-danger(v-show="$v.product.msrpUsd.$error && !$v.product.msrpUsd.required") {{$t('Components.Brands.Products.ProductBaseForm.Validations.msrpRequired')}}
          p.help.is-danger(v-show="$v.product.msrpUsd.$error && $v.product.msrpUsd.required && !$v.product.msrpUsd.positiveNumber") {{$t('Components.Brands.Products.ProductBaseForm.Validations.msrpMustBePositiveIntegerOrFloat')}}
          p.help.is-danger(v-show="msrpExceeds()") {{$t('Components.Brands.Products.ProductBaseForm.Validations.msrpExceedErrormsg')}}

  //- .brand-new-product__sample-checkbox
  //-   span.brand-new-product__sample-checkbox-icon(@click="product.sample = !product.sample")
  //-     img(src="@/assets/img/checkbox.png")
  //-     img.brand-new-product__sample-checkbox-icon--checked(v-show="product.sample" src="@/assets/img/inputs/checked.png")
  //-   span.brand-new-product__sample-checkbox-text This is a sample-sized product

  .columns
    .column
      .field
        div
          span.brand-new-product__popup-label {{$t('Components.Brands.Products.ProductBaseForm.productId')}}
          span
            BaseFormPopup(:text="'Product ID is not SKU. It is for your internal use only.'")
        .control.brand-new-product__popup-control
          input.input.brand-new-product__input(
            v-model.trim="$v.product.brandSku.$model" type="text"
            v-bind:placeholder="$t('Components.Brands.Products.ProductBaseForm.enterProductId')"
            :class="{ 'is-danger': $v.product.brandSku.$error }"
            )
          p.help.is-danger(v-show="$v.product.brandSku.$error && !$v.product.brandSku.maxLength") {{$t('Components.Brands.Products.ProductBaseForm.Validations.characterExceeds50')}}
    .column
      .field
        label.label.brand-new-product__label {{$t('Components.Brands.Products.ProductBaseForm.barcode')}} *
        .control(v-for="barcode in $v.product.barcodes.$each.$iter")
          input.input.brand-new-product__input(
            v-model.trim="barcode.code.$model"
            :class="{ 'is-danger': barcode.$error || duplicatedBarcode }"
            v-bind:placeholder="$t('Components.Brands.Products.ProductBaseForm.enterBarcode')"
            @input="$emit('showDuplicateBarcode')"
          )
          //.input-errors(v-show="barcode.$error")
          p.help.is-danger(v-show="barcode.$error && !barcode.code.required") {{$t('Components.Brands.Products.ProductBaseForm.Validations.barCodeRequired')}}
          p.help.is-danger(v-show="!barcode.code.minLength || !barcode.code.maxLength") {{$t('Components.Brands.Products.ProductBaseForm.Validations.barcodeLengthConstraint')}}
          p.help.is-danger(v-show="!barcode.code.numeric") {{$t('Components.Brands.Products.ProductBaseForm.Validations.barcodeMustBeNumber')}}
          p.help.is-danger(v-show="duplicatedBarcode") {{$t('Components.Brands.Products.ProductBaseForm.Validations.duplicateBarcode')}}

  .field
    label.label.brand-new-product__label {{$t('Components.Brands.Products.ProductBaseForm.CountryOfOrigin')}} *
    .control
      .select
        select.brand-new-product__select--fullwidth(
          v-model="$v.product.countryOfOrigin.id.$model"
          :class="{ 'is-danger': $v.product.countryOfOrigin && $v.product.countryOfOrigin.id.$error }"
          @blur="$v.product.countryOfOrigin.id.$touch()"
        )
          option(v-for="country in countries" :key="country.id" :value="country.id") {{ country.name }}
      p.help.is-danger(v-show="$v.product.countryOfOrigin && $v.product.countryOfOrigin.id.$error && !$v.product.countryOfOrigin.id.required") {{$t('Components.Brands.Products.ProductBaseForm.Validations.countryRequired')}}
  .field
    label.label.brand-new-product__label {{$t('Components.Brands.Products.ProductBaseForm.productValue')}} *
    .control
      Multiselect(
        class="productValueMultiSelect"
        v-model="product.values",
        :class="{ 'is-danger': product.values && product.values.$error || invalidTagsCombination }",
        :options="sellSideTags",
        :multiple="true",
        placeholder="Select Product value"
        :close-on-select="true",
        label="name",
        track-by="id"
      )
        template(slot="option" slot-scope="props")
          span.brand-new-product__ingredients-option {{ props.option.name }}
    p.help.is-danger(v-if="$v.product.values && $v.product.values.$error && !$v.product.values.required") {{$t('Components.Brands.Products.ProductBaseForm.Validations.productValuesRequired')}}

  .field
    label.label.brand-new-product__label {{$t('Components.Brands.Products.ProductBaseForm.ingredients')}} *
      span(id="ingredientsDescTooltip").span-left
        BaseFormPopup(:text="$t('Components.Brands.Products.ProductBaseForm.ingredientsToolTip')")
    .control
      Multiselect(
        class="brand-new-product__ingredients-margin"
        v-model="product.newIngredients",
        :class="{ 'is-danger': product.newIngredients && isIngredientsRequiredError || invalidTagsCombination }",
        :options="ingredientsTagForFirst",
        :multiple="true",
        placeholder="Type to search"
        :searchable="true"
        :close-on-select="true",
        :loading="isLoadingIngredients"
        @search-change="searchIngredients"
        @input="afterDataChanged"
        label="name"
        track-by="id"
      )
        template(slot="option" slot-scope="props")
          a-tooltip.brand-new-product__tooltip(placement="topRight" v-if="props.option.name.length > ingredientsMaxTextlen" overlayClassName="brand-new-product__tooltip")
            template(slot="title")
              span {{props.option.name}}
            span.brand-new-product__ingredients-option {{ props.option.name }}
          span.brand-new-product__ingredients-option(v-else) {{ props.option.name }}
        template(slot="beforeList" slot-scope="props")
          a-row
            a-col(:span="20")
            a-col(:span="4")
              span.brand-new-product__ingredients-total {{`${searchIngredientPage.total} Results`}}
        template(slot="tag", slot-scope="{ option, remove }")
          a-tooltip(placement="topRight" v-if="option.name && option.name.length > ingredientsMaxTextlen" overlayClassName="brand-new-product__tooltip")
            template(slot="title")
              span {{option.name}}
            a-tag.brand-new-product__ingredients-tag(closable @close.prevent="remove(option)")
              span.brand-new-product__ingredients-tag-text {{option.name}}
          a-tag.brand-new-product__ingredients-tag(v-else closable @close.prevent="remove(option)")
            span.brand-new-product__ingredients-tag-text {{option.name}}
        template(slot="afterList" slot-scope="props" v-if="searchIngredientPage.curPage < searchIngredientPage.pages")
          .brand-new-product__ingredients-load-more
            span.brand-new-product__ingredients-load-more-text(@click="loadMoreIngredients") load more
            a-icon.brand-new-product__ingredients-load-more-text(type="redo")
        template(slot="clear" slot-scope="props")
          .brand-new-product__ingredients-search-clear
            a-icon(type="close" v-if="isShowClearAction")
    p.help.is-danger(v-if="isIngredientsRequiredError") {{$t('Components.Brands.Products.ProductBaseForm.Validations.ingredientsRequired')}}
  .field
    label.label.brand-new-product__label {{$t('Components.Brands.Products.ProductBaseForm.description')}} *
      span.span-left
        BaseFormPopup(:text="$t('Components.Brands.Products.ProductBaseForm.descriptionToolTip')")
    .control
      textarea.textarea(
        v-model.trim="$v.product.description.$model"
        :class="{ 'is-danger': $v.product.description && $v.product.description.$error }"
        @blur="$v.product.description.$touch()"
        v-bind:placeholder="$t('Components.Brands.Products.ProductBaseForm.descriptionPlaceholder')"
      )
    p.help.is-danger(v-if="$v.product.description && $v.product.description.$error && !$v.product.description.required") {{$t('Components.Brands.Products.ProductBaseForm.Validations.descriptionRequired')}}
  .field
    label.label.brand-new-product__label {{$t('Components.Brands.Products.ProductBaseForm.keyBenefits')}} *
      span.span-left
        BaseFormPopup(:text="$t('Components.Brands.Products.ProductBaseForm.keyBenefitsToolTip')")
    .control.key-benefits-style(v-for="(benefit,index) in $v.product.newBenefits.$each.$iter")
      textarea.textarea(
        v-model.trim="benefit.name.$model"
        :class="{ 'is-danger':$v.product.newBenefits && $v.product.newBenefits.$error }"
        @blur="$v.product.newBenefits.$touch()"
        v-bind:placeholder="keyBenefits[index]"
      )
    p.help.is-danger(v-if="$v.product.newBenefits && $v.product.newBenefits.$error && !$v.product.newBenefits.required") {{$t('Components.Brands.Products.ProductBaseForm.Validations.keyBenefitsRequired')}}
</template>

<script>
import Multiselect from "vue-multiselect"
import { mapGetters } from "vuex"
import { required, minLength, maxLength, numeric } from "vuelidate/lib/validators"
import BaseFormPopup from "@/components/brands/products/BaseFormPopup"
import { checkFirstLetter } from "@/utils/validate"
import { Icon, Tooltip, Row, Col, Spin } from "ant-design-vue"

const checkBenefits = values => {
  if (values && values.length > 0) {
    for (let i = 0; i < values.length; i++) {
      if (values[i].name && values[i].name.length > 0) {
        return true
      }
    }
  }
  return false
}
const checkBenefitFirstField = value => {
  if (value) {
    return checkFirstLetter(value)
  }
  return true
}
export default {
  components: {
    Multiselect,
    BaseFormPopup,
    "a-icon": Icon,
    "a-tooltip": Tooltip,
    "a-row": Row,
    "a-col": Col,
    "a-spin": Spin
  },
  validations: {
    product: {
      name: { required, maxLength: maxLength(100) },
      category: {
        id: { required }
      },
      subcategory: {
        id: { required }
      },
      status: {
        id: { required }
      },
      msrpUsd: {
        required,
        positiveNumber(msrpUsd) {
          return /^\d+(\.\d{1,2})?$/.test(msrpUsd)
        }
      },
      brandSku: { maxLength: maxLength(50) },
      size: {
        required,
        positiveNumber(size) {
          return /^(?![0.]+$)\d+(\.\d{1,4})?$/gm.test(size)
        }
      },
      sizeUnits: {
        id: { required }
      },
      countryOfOrigin: {
        id: { required }
      },
      barcodes: {
        $each: {
          code: {
            required,
            numeric,
            minLength: minLength(12),
            maxLength: maxLength(13)
          }
        }
      },
      description: { required },
      newBenefits: {
        checkBenefits,
        $each: {
          name: {
            checkBenefitFirstField
          }
        }
      },
      values: { required }
    }
  },
  props: {
    duplicatedBarcode: { type: Boolean },
    invalidTagsCombination: { type: Boolean },
    initialProduct: {
      type: Object,
      default() {
        return {
          brand: {
            id: this.$store.getters["user/businessId"]
          },
          category: { id: null, name: null, parent: { id: null, name: null } },
          subcategory: { id: null, name: null, parent: { id: null, name: null } },
          microCategory: { id: null, name: null, parent: { id: null, name: null } },
          status: { id: null },
          type: { id: null },
          name: null,
          brandSku: null,
          msrpUsd: null,
          size: null,
          sizeUnits: { id: null },
          description: null,
          newBenefits: [],
          benefits: null,
          ingredients: null,
          newIngredients: [],
          values: [],
          countryOfOrigin: {
            id: null
          },
          sample: false,
          barcodes: [{ code: null, active: true }],
          documents: [],
          images: [],
          tags: [],
          searchIngredientValue: ""
        }
      }
    }
  },
  data() {
    return {
      product: this.initialProduct,
      isLoadingIngredients: false,
      isShowClearAction: false,
      operateIngredientsIndex: -1,
      ingredientsMaxTextlen: 20,
      isIngredientsRequiredError: false
    }
  },
  computed: {
    ...mapGetters("reference", [
      "categories",
      "subcategories",
      "microCategories",
      "types",
      "countries",
      "statuses",
      "productSizeUnits",
      "productValues"
    ]),
    sellSideTags() {
      return this.$store.getters["reference/brandValue"].map(tag => {
        return { id: tag.id, name: tag.name }
      })
    },
    sellSideProductValues() {
      let data = this.$store.getters["reference/sell_side_tag"]
      if (data && data.length) {
        return data.map(tag => {
          return { id: tag.id, name: tag.name }
        })
      }
      return []
    },
    searchIngredientPage() {
      let data = this.$store.getters["product/ingredientsTag"]
      if (data) {
        return { total: data.total, pages: data.pages, curPage: data.index }
      }
      return { total: 0, pages: 0, curPage: 1 }
    },
    ingredientsTagForFirst() {
      let data = this.$store.getters["product/ingredientsTag"]
      let result = []
      if (data && data.list && data.list.length > 0) {
        let restData = data.list
        if (this.product.newIngredients && this.product.newIngredients.length > 0) {
          for (let i = 0; i < this.product.newIngredients.length; i++) {
            restData = restData.filter(item => {
              return item.privateId !== Number(this.product.newIngredients[i].id)
            })
          }
        }
        restData.map(item => {
          result.push({ id: item.privateId, name: item.name, classification: item.classification })
        })
      }
      result.unshift({ id: "-1", name: "Not Applicable", classification: "" })
      return result
    },
    ingredientsTag() {
      let data = this.$store.getters["product/ingredientsTag"]
      return data.list
    },
    keyBenefits() {
      return this.$t("Components.Brands.Products.ProductBaseForm.keyBenefitsPlaceholder")
    },
    activeSubcategories() {
      return this.subcategories.filter(
        subcategory => subcategory.parent.id === this.product.category.id
      )
    },
    activeMicroCategories() {
      if (
        this.product.subcategory &&
        this.product.subcategory.parent &&
        this.product.subcategory.parent.id !== this.product.category.id
      ) {
        return []
      }
      return this.microCategories.filter(
        microCategory => microCategory.parent.id === this.product.subcategory.id
      )
    },
    isNeedAddNew() {
      if (
        this.product.newIngredients !== undefined &&
        this.product.newIngredients.length &&
        this.product.newIngredients[0] !== undefined
      ) {
        if (
          this.product.newIngredients[0].id !== undefined &&
          this.product.newIngredients[0].id !== "-1"
        ) {
          return true
        }
        if (
          this.product.newIngredients[0].privateId !== undefined &&
          this.product.newIngredients[0].privateId !== "-1"
        ) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    msrpExceeds() {
      if (this.product.msrpUsd > 9999) {
        return true
      }
      return false
    },
    makeCategoryActive(category) {
      this.product.category = category
      if (
        this.product.subcategory.parent &&
        this.product.subcategory.parent.id !== this.product.category.id
      ) {
        return (this.product.microCategory = {}), (this.product.subcategory = {})
      }
      this.isIngredientsRequiredError = false
      this.$forceUpdate()
    },
    makeSubcategoryActive(subcategory) {
      if (this.product.subcategory.id === subcategory.id) {
        return (this.product.subcategory = {})
      }
      this.product.subcategory = subcategory
      if (
        this.product.microCategory.parent &&
        this.product.microCategory.parent.id !== this.product.subcategory.id
      ) {
        return (this.product.microCategory = {})
      }
      this.isIngredientsRequiredError = false
      this.$forceUpdate()
    },
    makeMicrocategoryActive(microcategory) {
      if (this.product.microCategory.id === microcategory.id) {
        return (this.product.microCategory = {})
      }

      this.product.microCategory = microcategory
      this.isIngredientsRequiredError = false
      this.$forceUpdate()
      this.isIngredientsRequiredError = false
      this.$forceUpdate()
    },
    tagNameById({ id }) {
      const tag = this.sellSideTags.find(tag => tag.id === id)
      return tag && tag.name
    },
    checkIngredient() {
      if (!this.product.newIngredients || this.product.newIngredients.length === 0) {
        if (this.product.subcategory && this.product.subcategory.name === "Accessories") {
          this.isIngredientsRequiredError = false
          return true
        }
        if (this.product.microCategory) {
          const name = this.product.microCategory.name
          if (
            name === "Accessories" ||
            name === "Gifts & Starter Kits" ||
            name === "Tools & Devices" ||
            name === "Bags & Accessories" ||
            name === "Makeup Brushes" ||
            name === "Sponges & Applicators" ||
            name === "Brushes & Combs" ||
            name === "Gifts & Starter Kits" ||
            name === "Hair Accessories" ||
            name === "Hot Tools" ||
            name === "Travel Tools & Accessories" ||
            name === "Anti-Aging Tools" ||
            name === "Cleansing Tools" ||
            name === "Facial Massage" ||
            name === "Travel Accessories"
          ) {
            this.isIngredientsRequiredError = false
            return true
          }
        }
        this.isIngredientsRequiredError = true
        return false
      }
      this.isIngredientsRequiredError = false
      return true
    },
    searchIngredients(value, id) {
      if (value && value.length > 0) {
        this.isShowClearAction = true
      } else {
        this.isShowClearAction = false
      }
      this.searchIngredientValue = value
      this.operateIngredientsIndex = id
      this.isLoadingIngredients = true
      this.$store
        .dispatch("product/getIngredientsTag", {
          ingredientName: value,
          page: 1
        })
        .finally(() => {
          this.isLoadingIngredients = false
        })
    },
    afterDataChanged(value) {
      console.log("...after data changed....")
      console.log(value)
      console.log(this.product.newIngredients)
      if (value && value.length) {
        let newAdded = value[value.length - 1]
        if (newAdded.id === "-1") {
          this.product.newIngredients = []
          this.product.newIngredients.push(newAdded)
        } else if (value.length > 1) {
          let preValue = value[value.length - 2]
          if (preValue.id === "-1") {
            this.product.newIngredients = []
            this.product.newIngredients.push(value[value.length - 1])
          }
        }
      }
    },
    loadMoreIngredients() {
      this.$store.dispatch("product/getIngredientsTag", {
        ingredientName: this.searchIngredientValue,
        page: this.searchIngredientPage.curPage + 1
      })
    },
    addNewIngredients() {
      if (!this.product.newIngredients) {
        this.product.newIngredients = []
      }
      this.product.newIngredients.push({ privateId: "0" })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="sass">
.span-left
  margin-left: 5px

.brand-new-product-base-form
  width: 600px

.brand-new-product
  &__label
    font-size: 14px
    font-weight: 500

  &__msrp-input
    width: 260px

  &__size-select
    width: 90px

  &__size-input
    width: 205px

  &__select, &__input
    width: 295px

    &--fullwidth
      width: 620px

  &__sample-checkbox
    margin-top: 10px
    margin-bottom: 10px
    position: relative

  &__categories-container
    font-size: 14px
    border: 1px solid #DDDDDD
    min-height: 220px
    max-height: 220px
    overflow-y: auto
    padding: 0px 2px 2px 2px

  &__category-select
    padding: 10px 20px 5px 10px
    color: #AFAFAF

  &__category-element
    padding: 7px 20px 7px 20px
    cursor: pointer

  &__category-element--selected
    background-color: #F5F6F8

  &__category-optional
    color: #DADADA
    font-size: 12px
    margin-right: 80px

  &__sample-checkbox-icon
    position: absolute
    left: 0
    cursor: pointer

  &__sample-checkbox-icon--checked
    position: absolute
    right: 3px
    top: 3px
    width: 18px
    height: 18px

  &__sample-checkbox-text
    margin-left: 25px

  &__popup-label
    font-size: 14px
    margin-right: 10px

  &__popup-control
    padding-top: 4px

  &__ingredients-margin
    margin-bottom: 10px
    display: inline-block !important
    width: 100% !important
  &__ingredients-delete
    margin-left: 10px
    cursor: pointer
  &__ingredients-load-more
    margin: 0 auto
    text-align: center
    padding-top: 6px
    padding-bottom: 6px
  &__ingredients-tag
    padding: 1px 4px !important
    padding-right: 2px !important
    background-color: #E2EEEC !important
    border-radius: 2px !important
    max-width: 170px !important
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    color: #262626 !important
    display: inline-block
    margin-left: 2px !important
    margin-right: 0px !important
    margin-bottom: 8px !important
  &__ingredients-tag-text
    font-size: 14px
    line-height: 17px
    height: 17px
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    display: inline-block
    max-width: 86%
    vertical-align: middle
    margin-bottom: 2px
  &__ingredients-close
    text-align: right !important
    margin-right: 14px
  &__ingredients-tag-remove
    width: 22px
    line-height: 22px
    cursor: pointer
    text-align: center
    font-size: 10px
    border-radius: 2px
  &__ingredients-option
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #262626
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    align-items: center
    display: block
    width: 100%
  &__ingredients-option:hover
    background: #E2EEEC !important
  &__ingredients-total
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 10px
    line-height: 12px
    color: #8C8C8C
  &__ingredients-load-more-text
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #12433D
    margin-right: 9px
  &__ingredients-load-more:hover
    background-color: #E2EEEC !important
  &__ingredients-add-action
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    text-decoration-line: underline
    color: #262626
    cursor: pointer
  &__ingredients-search-clear
    font-size: 10px
    z-index: 99
    position: absolute
    top: 10px
    right: 36px
  .key-benefits-style
    margin-bottom: 10px
.productValueMultiSelect .multiselect__option--highlight
  background: #E2EEEC !important
  color: #262626 !important
.productValueMultiSelect .multiselect__tag
  background: #E2EEEC !important
  border-radius: 2px !important
  color: #262626 !important
  margin-right: 2px !important
.productValueMultiSelect .multiselect__tag:hover
  background: #E2EEEC !important
  border-radius: 2px !important
  color: #262626 !important
.brand-new-product__ingredients-margin .multiselect__option--highlight
  background: #E2EEEC !important
  color: #262626 !important
.brand-new-product__ingredients-margin .multiselect__content
  width: 100%
.brand-new-product__tooltip .ant-tooltip-arrow::before
  background-color: #8C8C8C !important
.brand-new-product__tooltip .ant-tooltip-inner
  background-color: #8C8C8C !important
  border-radius: 0px !important
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 12px !important
  line-height: 15px
  min-height: 17px !important
  padding: 4px !important
.multiselect__input::-ms-clear
  display: inline
#ingredientsDescTooltip .base-form-popup .base-form-popuptext
  width: 400px
  padding: 2px
</style>
