import sellerClient from "@/api/seller-client"
import { formatTime, formatFullTime } from "@/utils/validate"
export default {
  namespaced: true,
  state: {
    list: [],
    seller: {},
    loginMessages: [],
    loginMessage: {}
  },
  mutations: {
    SET_SELLER_LIST(state, data) {
      state.list = data
      if (state.list && state.list.length > 0) {
        state.list.map(item => {
          item.locationId = item.location.locationId
          item.isChecked = false
          return item
        })
      }
    },
    SET_SELLER(state, data) {
      state.seller = data
    },
    SET_LOGIN_MESSAGES(state, data) {
      state.loginMessages = data
    },
    SET_LOGIN_MESSAGE(state, data) {
      state.loginMessage = data
    }
  },
  getters: {
    sellerList(state) {
      console.log(state.list)
      if (state.list && state.list.length > 0) {
        return state.list.map(item => {
          item["first"] = item.user.givenName
          item["last"] = item.user.familyName
          item["email"] = item.user.email
          item.createdAt = formatTime(item.createdAt)
          item["lastLoginStr"] = item.lastLoginAt ? formatFullTime(item.lastLoginAt) : ""
          return item
        })
      }
      return []
    },
    seller(state) {
      return state.seller
    },
    loginMessageList(state) {
      if (state.loginMessages && state.loginMessages.length > 0) {
        return state.loginMessages.map(item => {
          item["createdAtStr"] = formatTime(item.createdAt)
          item["startDateStr"] = formatTime(item.startDate)
          item["endDateStr"] = formatTime(item.endDate)
          item["isChecked"] = false
          item["invitedCount"] = item.invitedCount ? item.invitedCount : 0
          return item
        })
      }
      return []
    }
  },
  actions: {
    async getSellerList({ commit }, params) {
      try {
        const { data } = await sellerClient.searchSeller(params)
        commit("SET_SELLER_LIST", data)
        return data
      } catch (error) {
        if (error.response && error.response.status === 404) {
          commit("SET_SELLER_LIST", [])
        } else {
          return Promise.reject(error)
        }
      }
    },
    async getSellerById({ commit }, sellerId) {
      try {
        const { data } = await sellerClient.getSeller(sellerId)
        commit("SET_SELLER", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateASeller({ commit }, { sellerId, params }) {
      try {
        const { data } = await sellerClient.updateSeller(sellerId, params)
        commit("SET_SELLER", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateLoginMessage({ commit }, { id, params }) {
      try {
        const { data } = await sellerClient.updateLoginMessage(id, params)
        console.log(data)
        console.log(commit)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async createLoginMessage({ commit }, param) {
      try {
        const { data } = await sellerClient.createLoginMessage(param)
        commit("SET_LOGIN_MESSAGE", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteLoginMessage({ commit }, ids) {
      try {
        const { data } = await sellerClient.deleteLoginMessage(ids)
        console.log(data)
        console.log(commit)
        //commit("SET_LOGIN_MESSAGE", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getLoginMessage({ commit }, id) {
      try {
        const { data } = await sellerClient.getALoginMessage(id)
        commit("SET_LOGIN_MESSAGE", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async getStatisticsRetailer({}, retailerId) {
      const { data } = await sellerClient.getStatisticsRetailer(retailerId)
      return data
    },
    async searchLoginMessage({ commit }, params) {
      try {
        const { data } = await sellerClient.searchLoginMessage(params)
        commit("SET_LOGIN_MESSAGES", data)
        return data
      } catch (error) {
        if (error.response && error.response.status === 404) {
          commit("SET_LOGIN_MESSAGES", [])
        } else {
          return Promise.reject(error)
        }
      }
    }
  }
}
