<template>
  <div class="wrapper">
    <nav class="desktop-nav">
      <div class="nav-container">
        <div class="logo">
          <router-link to="/retailer/main">
            <img src="~@/assets/img/logo/Logo.svg" alt="" />
          </router-link>
        </div>
        <div class="column quick-search">
          <RetailerQuickSearch ref="retailerQuickSearch" key="1" :is-retailer="true" />
        </div>
        <ul class="menu-items">
          <li>
            <router-link to="/retailer/main">
              <a-icon class="retailer-icon-style" type="home" />
              {{ $t("Components.Brands.Header.home") }}
            </router-link>
          </li>
          <li>
            <router-link to="/retailer/products/all">
              <a-icon class="retailer-icon-style" type="shop" />
              {{ $t("Components.Retailers.Header.marketPlace") }}
            </router-link>
          </li>
          <li>
            <router-link
              to="/retailer/orders?status=pending,confirmed&_limit=50&_index=1&_order-by=PREVIOUS&_sort-by=created_at&tabId=1"
            >
              <a-icon class="retailer-icon-style" type="line-chart" />
              {{ $t("Components.Retailers.Header.orders") }}
            </router-link>
          </li>
          <li>
            <router-link to="/retailer/messaging?tab=received">
              <a-badge
                v-if="unReadMessage > 0"
                :count="unReadMessage"
                :overflow-count="99"
                style="font-size:24px;"
              >
                <a-icon type="inbox" />
              </a-badge>
              <a-icon v-else class="retailer-icon-style" type="inbox" />
              <span :style="unReadMessage > 0 ? 'display:block;margin-top:-4px;' : ''">{{
                $t("Components.Retailers.Header.inbox")
              }}</span>
            </router-link>
          </li>
          <li>
            <router-link to="/retailer/RetailerTraining/RetailerTrainingBrand">
              <a-icon class="retailer-icon-style" type="bulb" />
              Training
            </router-link>
          </li>
          <!--          <li>-->
          <!--            <router-link to="/retailer/messaging">-->
          <!--              <a-icon class="retailer-icon-style" type="inbox" />-->
          <!--              {{ $t("Components.Retailers.Header.inbox") }}-->
          <!--            </router-link>-->
          <!--          </li>-->
          <li class="cart-text-right-header">
            <router-link to="/retailer/cart">
              <a-icon class="retailer-icon-style" type="shopping-cart" />
              {{ $t("Components.Retailers.Header.cart") }}
              <i class="red-cart-style-header">{{ totalNumber }}</i>
              <!--              <a-badge count="5" :offset="[6, -46]" />-->
            </router-link>
          </li>
          <li>
            <router-link to="/retailer/clean?cpcmain=true">
              <img id="cleanPreCheck" class="retailer-icon-style" />
              {{ $t("Components.Retailers.Header.preCheck") }}
            </router-link>
          </li>
          <li class="dropdown" style="width: 54px">
            <a href="#">
              <img
                v-if="retailer.logoAsset.url"
                class="dropdown-icon-container"
                :src="retailer.logoAsset.url"
              />
              <img
                v-if="!retailer.logoAsset.url"
                class="dropdown-icon-container"
                :src="require('@/assets/img/default-logo-image.png')"
              />
            </a>
            <div class="profile-menu">
              <router-link to="/retailer/profile">
                <a-icon class="menu-icon-style" type="user" />
                {{ $t("Components.Retailers.Header.profile") }}
              </router-link>
              <router-link to="/retailer/settings">
                <a-icon class="menu-icon-style" type="setting" />
                {{ $t("Components.Retailers.Header.settings") }}
              </router-link>
              <a @click.prevent="signOut()">
                <img src="~@/assets/img/retailer-brand-profile/signout.svg" alt="" />
                <span style="color:#FF6132">
                  {{ $t("Components.Retailers.Header.signOut") }}
                </span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import firebase from "@/services/firebase-service"
import RetailerQuickSearch from "@/components/brands/retailers/RetailerQuickSearch"
export default {
  components: {
    RetailerQuickSearch
  },
  props: {
    showBrandDropdown: {
      type: Number
    }
  },
  data() {
    return {
      topMenu: false,
      shopMenu: false,
      isLegacy: false,
      accountLinks: [
        { name: "Profile", path: "/retailer/profile" },
        { name: "Orders", path: "/retailer/orders" },
        { name: "Settings", path: "/retailer/settings" }
      ],
      memberRemoved: false
    }
  },
  computed: {
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    unReadMessage() {
      return this.$store.getters["adminMessage/unReadMessageCount"]
    },
    isEmailVerified() {
      return this.$store.getters["user/isEmailVerified"]
    },
    isRetailerPending() {
      return this.retailer.status && this.retailer.status.id === "pending"
    },
    isRetailerRejected() {
      return this.retailer.status && this.retailer.status.id === "rejected"
    },
    isRetailerApproved() {
      return this.retailer.status && this.retailer.status.id === "approved" && this.isEmailVerified
    },
    navigationalTags() {
      return this.$store.getters["reference/navigationalTags"]
    },
    monthAgo() {
      const today = new Date()
      const monthAgoDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())

      return monthAgoDate.toISOString().split("T")[0]
    },
    productsCount() {
      return this.$store.getters["retailer/cartProductsCount"]
    },
    role() {
      return this.$store.getters["user/role"]
    },
    isWorkspacePages() {
      return ["retailerSettings", "retailerProfile", "retailerOrders", "retailerOrder"].includes(
        this.$route.name
      )
    },
    categories() {
      return this.$store.getters["reference/categories"]
    },
    userEmail() {
      return this.$store.getters["user/email"]
    },
    userId() {
      return this.$store.getters["user/id"]
    },
    cartProductGroups() {
      return this.$store.getters["retailer/cartProductGroups"]
    },
    totalNumber() {
      return this.$store.getters["retailer/CartTotalNumber"]
    }
  },
  watch: {
    async retailer() {
      this.isMemberRemoved()
    },
    async $route(to) {
      if (
        to.path === "/retailer/main" ||
        to.path === "/retailer/products/all" ||
        to.path === "/retailer/orders" ||
        to.path === "/retailer/messaging" ||
        to.path === "/retailer/cart" ||
        to.path === "/retailer/clean"
      ) {
        await this.$store.dispatch("retailer/fetchCart", {})
      }
    }
  },
  async mounted() {
    this.isMemberRemoved()
    this.$store.dispatch("adminMessage/getUnReadMessage")
    await this.$store.dispatch("reference/setReference")
    const retailerId = this.$store.getters["user/businessId"]
    await this.$store.dispatch("retailer/fetchRetailer", retailerId)
    if (await this.isLegacyUser()) {
      this.isLegacy = true
    }
  },
  methods: {
    async sha256(email) {
      const msgBuffer = new TextEncoder("utf-8").encode(email)
      const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer)
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashHex = hashArray.map(b => ("00" + b.toString(16)).slice(-2)).join("")

      return hashHex
    },
    async isLegacyUser() {
      return (
        await firebase
          .database()
          .ref("legacy/users/" + (await this.sha256(this.userEmail)))
          .once("value", snapshot => {
            return snapshot.val()
          })
      ).toJSON()
    },
    async isMemberRemoved() {
      for (var a in this.retailer.members) {
        if (this.retailer.members[a].user.id == this.userId) {
          if (this.retailer.members[a].membershipStatus.id == "removed") {
            this.memberRemoved = true
          }
        }
      }
    },
    signOut() {
      this.$store.dispatch("user/signOut")
      this.$router.push("/login")
    },
    hideTopMenu() {
      this.topMenu = false
    },
    toggle() {
      this.topMenu = !this.topMenu
      this.shopMenu = false
    },
    shopMenuToggle() {
      this.shopMenu = true
      this.topMenu = false
    },
    hideShopMenu() {
      this.shopMenu = false
    }
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@font-face {
  font-family: "ProximaNova-Regular";
  src: url(~@/assets/fonts/ProximaNova-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Display-Regular";
  src: url(~@/assets/fonts/SFProDisplay-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Colors */

:root {
  --Gray-3: #f5f5f5;
  --Gray-4: #e8e8e8;
  --Gray-5: #d9d9d9;
  --Gray-7: #8c8c8c;
  --Gray-8: #595959;
  --Gray-10: #000000;
  --Landing_Brand_Orange: #ff6132;
  --Gray-2: #fafafa;
  --Landing_Green: #4a8a5d;
}

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "ProximaNova-Regular";
}

.wrapper {
  width: 100%;
  margin: 0 auto;
}

/* Navigation Style */

.quick-search {
  margin-left: 5%;
  margin-right: 1%;
}

.column {
  padding: 3px 0px 3px 0px;
}

.desktop-nav {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: "ProximaNova-Regular";
}

.desktop-nav .nav-container {
  position: relative;
  display: -webkit-flex;
  justify-content: space-between;
  max-width: 1197px;
  margin: auto;
  /*box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 0;*/
}
.desktop-nav .menu-items li .ant-badge .ant-badge-multiple-words {
  padding: 0 1px;
}
.desktop-nav .logo {
  margin-top: 13px;
  margin-bottom: 13px;
  margin-left: 126px;
}

.desktop-nav .menu-items {
  margin-top: 20px;
  margin-bottom: 5px;
  margin-right: 106px;
  display: -webkit-flex;
  list-style: none;
}

.desktop-nav .menu-items li {
  margin: 0 12px;
}

.desktop-nav .menu-items li a {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  text-decoration: none;
  color: var(--Gray-7);
}

.desktop-nav .menu-items li img {
  display: block;
  margin: auto;
}

.inventory {
  padding: 3px 1px;
}

.catalog {
  padding: 1px 3px;
}

.payments {
  padding: 1px 1px;
}

.cpcheck {
  padding-top: 1px;
  padding-bottom: 2px;
}

.desktop-nav .menu-items .dropdown .dropdown-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #8c8c8c;
  width: 36px;
  height: 36px;
  margin-top: 1px;
  margin-bottom: 0px;
}

/* Begin Dropdown */

.desktop-nav .menu-items li .profile-menu {
  display: none;
  position: absolute;
  top: 0;
  margin-top: 48px;
  min-width: 99px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  margin-left: -28px;
  z-index: 5;
}

.desktop-nav .menu-items li .profile-menu a {
  display: block;
  padding: 4px;
  padding-left: 36px;
  font-family: "ProximaNova-Regular" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--Gray-8);
}

.desktop-nav .menu-items li .profile-menu a img {
  display: block;
  position: absolute;
  margin-left: -24px;
  margin-top: 2px;
}

.desktop-nav .menu-items li .profile-menu a:hover {
  background-color: var(--Gray-4);
}

.desktop-nav .menu-items .dropdown:hover .profile-menu {
  display: block;
}
.router-link-active {
  color: #4a8a5d !important;
}
.retailer-icon-style {
  display: block;
  font-size: 24px;
}
.retailer-icon-nav {
  display: block;
  font-size: 24px;
  margin-bottom: 4px !important;
}
#cleanPreCheck {
  content: url("~@/assets/img/clean-pre-check/clean_nav_gray.svg");
}
.router-link-active > #cleanPreCheck {
  content: url("~@/assets/img/clean-pre-check/clean_nav_green.svg");
}
.menu-icon-style {
  display: block;
  position: absolute;
  margin-left: -24px;
  margin-top: 2px;
  font-size: 14px;
}
.retailer-icon-nav {
  display: block;
  font-size: 24px;
  margin-bottom: 4px !important;
}
#cleanPreCheck {
  content: url("~@/assets/img/clean-pre-check/clean_nav_gray.svg");
}
.router-link-active > #cleanPreCheck {
  content: url("~@/assets/img/clean-pre-check/clean_nav_green.svg");
}
.menu-icon-style {
  display: block;
  position: absolute;
  margin-left: -24px;
  margin-top: 2px;
  font-size: 14px;
}
.menu-icon-style {
  display: block;
  position: absolute;
  margin-left: -24px;
  margin-top: 2px;
  font-size: 14px;
}
.cart-text-right-header {
  position: relative;
}
.red-cart-style-header {
  position: absolute;
  top: -7px;
  right: -7px;
  display: inline-block;
  /*padding: 2px;*/
  font-style: initial;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  color: white;
  background-color: #ff6132;
  font-size: 12px;
  text-align: center;
  font-family: "Proxima Nova", "Roboto", sans-serif;
}
</style>
