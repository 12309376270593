import axios from "@/api/axios"

export default {
  getBrands(params = {}) {
    return axios.get("/brands", { params })
  },
  getAllBrands(params = {}) {
    return axios.get("/brands", { params: { _limit: 9000, ...params } })
  },
  getBrand(brandId, params) {
    return axios.get(`/brands/${brandId}`, { params })
  },
  updateBrand(brand) {
    return axios.post(`/brands/${brand.id}`, JSON.stringify(brand))
  },
  updateBrandPartially(brand) {
    return axios.post(`/brands/${brand.id}?_skipNulls=true`, JSON.stringify(brand))
  },
  updateBrandPublic(brand) {
    return axios.put(`/brands/${brand.id}?_skipNulls=true`, JSON.stringify(brand))
  },
  updateTerms(terms, brandId) {
    return axios.post(`/brands/${brandId}/terms`, JSON.stringify(terms))
  },
  updateSocialAccounts(socialAccounts, brandId) {
    return axios.put(`/brands/${brandId}/social-accounts`, JSON.stringify(socialAccounts))
  },
  updateKeyRetailers(keyRetailers, brandId) {
    return axios.put(`/brands/${brandId}/key-retailers`, JSON.stringify(keyRetailers))
  },
  updateHeroProducts(heroProducts, brandId) {
    return axios.put(`/brands/${brandId}/hero-products`, JSON.stringify(heroProducts))
  },
  updateTags(tags, brandId) {
    return axios.put(`/brands/${brandId}/tags`, JSON.stringify(tags))
  },
  updateCurations(curation, brandId) {
    return axios.put(`/brands/${brandId}/curations`, JSON.stringify(curation))
  },
  getOrders() {
    return axios.get(`/orders`)
  },
  updateWireInstructions(wireInstructions, brandId) {
    return axios.put(`/brands/${brandId}/wire-instructions`, JSON.stringify(wireInstructions))
  },
  updatePendingTerms(pendingTerms, brandId) {
    return axios.post(`/brands/${brandId}/terms-pending`, JSON.stringify(pendingTerms))
  },
  updateSubscriptions(scopes, brandId) {
    return axios.put(`/brands/${brandId}/scopes`, JSON.stringify(scopes))
  },
  addMember(member, brandId) {
    return axios.post(`/brands/${brandId}/add-members`, JSON.stringify(member))
  },
  adminAddMember(member, brandId, params) {
    return axios.post(`/brands/${brandId}/add-members`, JSON.stringify(member), { params })
  },
  updateMemberStatus(member, brandId, params) {
    return axios.post(`/brands/${brandId}/update-members`, JSON.stringify(member), { params })
  },
  updateBrandSubscriptionPlan(brandId, params) {
    return axios.put(`/brands/${brandId}/subscription`, JSON.stringify(params))
  },
  updateBrandSubscriptionNew(brandId, mode) {
    return axios.post(`/brands/${brandId}/subscription/action?mode=${mode}`)
  },
  updateBrandCommission(brandId, params) {
    return axios.put(`/brands/${brandId}/commission`, JSON.stringify(params))
  },
  getBrandSubscriptionHistory(brandId) {
    return axios.get(`/brands/${brandId}/brandSubscriptionHistory`)
  },
  getBrandCleanPreCheck(brandId, currentPage) {
    return axios.get(`/brands/${brandId}/clean-pre-check?_limit=25&page-index=${currentPage}`)
  },
  changeOwner(brandId, param) {
    return axios.post(`/brands/${brandId}/change-owner`, JSON.stringify(param))
  },
  brandRegistration(param) {
    return axios.post(`/brands/registration`, JSON.stringify(param))
  },
  updateSubscriberExpired(id, param) {
    return axios.post(
      `/brands/${id}/subscription/action?mode=${param.mode}&expired-date=${param["expired-date"]}`
    )
  },
  getRetailerTerms(brandId) {
    return axios.get(`/brands/${brandId}/related-margins?_order-by=NEXT`)
  },
  uploadProducts(adminInfo, file) {
    return axios.post(`/products/upload${adminInfo}`, file)
  }
}
