import { render, staticRenderFns } from "./BrandBottomBanner.vue?vue&type=template&id=21d829d6&scoped=true&lang=pug&"
import script from "./BrandBottomBanner.vue?vue&type=script&lang=js&"
export * from "./BrandBottomBanner.vue?vue&type=script&lang=js&"
import style0 from "./BrandBottomBanner.vue?vue&type=style&index=0&id=21d829d6&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21d829d6",
  null
  
)

export default component.exports