<template lang="pug">
.retailer-search.landing_container
  .container__query
    .search-field
      //.search-item
        input.search-box(v-model="searchTerm" type="text" id = "searchBox" placeholder="Search for Retailers")
      .container__query-search
        .icon(@click="searchSure(searchValue)")
          svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
           path(d="M19.3187 18.0273L13.232 11.9406C14.1766 10.7195 14.6875 9.22656 14.6875 7.65625C14.6875 5.77656 13.9539 4.01406 12.6273 2.68516C11.3008 1.35625 9.53359 0.625 7.65625 0.625C5.77891 0.625 4.01172 1.35859 2.68516 2.68516C1.35625 4.01172 0.625 5.77656 0.625 7.65625C0.625 9.53359 1.35859 11.3008 2.68516 12.6273C4.01172 13.9562 5.77656 14.6875 7.65625 14.6875C9.22656 14.6875 10.7172 14.1766 11.9383 13.2344L18.025 19.3187C18.0428 19.3366 18.064 19.3508 18.0874 19.3604C18.1107 19.3701 18.1357 19.3751 18.1609 19.3751C18.1862 19.3751 18.2112 19.3701 18.2345 19.3604C18.2578 19.3508 18.279 19.3366 18.2969 19.3187L19.3187 18.2992C19.3366 18.2814 19.3508 18.2602 19.3604 18.2369C19.3701 18.2135 19.3751 18.1885 19.3751 18.1633C19.3751 18.138 19.3701 18.113 19.3604 18.0897C19.3508 18.0664 19.3366 18.0452 19.3187 18.0273ZM11.3688 11.3688C10.375 12.3602 9.05781 12.9062 7.65625 12.9062C6.25469 12.9062 4.9375 12.3602 3.94375 11.3688C2.95234 10.375 2.40625 9.05781 2.40625 7.65625C2.40625 6.25469 2.95234 4.93516 3.94375 3.94375C4.9375 2.95234 6.25469 2.40625 7.65625 2.40625C9.05781 2.40625 10.3773 2.95 11.3688 3.94375C12.3602 4.9375 12.9062 6.25469 12.9062 7.65625C12.9062 9.05781 12.3602 10.3773 11.3688 11.3688Z" fill="#BFBFBF")
        .inp
          a-input(v-model="searchValue" size="large" placeholder="Search for a Retailer")

    .location-tags
      .location-text Locations
      .tag-box
        RetailerTagBox(:items.sync="locationItems", @tagSwitch="fetchRetailers")
    .filters
      //- RetailersFilterButton(v-for="(item,index) in filterList" :key="index" :title="item.title" :ref="item.ref", :id="item.id", :iconName="item.iconName", :iconOnName="item.iconOnName", @filterClick="filterButtonClick")
      RetailersFilterButton(title="Retailer A-Z", ref="retailer_az", id="0", iconName="search-icon.svg", iconOnName="search-icon-on.svg", @filterClick="filterButtonClick")
      RetailersFilterButton(title="Channel Type", ref="channel_type", id="1", iconName="channel-type-icon.svg", iconOnName="channel-type-icon-on.svg", @filterClick="filterButtonClick")
      RetailersFilterButton(title="Location Type", ref="location_type", id="2", iconName="compass-icon.svg", iconOnName="compass-icon-on.svg", @filterClick="filterButtonClick")
      RetailersFilterButton(title="# of Locations", ref="num_locations", id="3", iconName="house-icon.svg", iconOnName="house-icon-on.svg", @filterClick="filterButtonClick")
      RetailersFilterButton(title="Suggested Retailers", ref="suggested_retailers", id="4", iconName="check-icon.svg", iconOnName="check-icon-on.svg", @filterClick="filterButtonClick")
      RetailersFilterButton(title="Other Type", ref="other_type", id="5", iconName="other-icon.svg", iconOnName="other-icon-on.svg", @filterClick="filterButtonClick")

  .container__display
    a-skeleton(:loading="loading")
      .retailer-items(v-if="showRetailerItems")
        .retailer-items-title
          .section-title {{cardSectionTitle}}
        .filter-tag-box
          RetailerTagBox(:items="selectedItems", @tagSwitch="fetchRetailers")
        RetailerCardView(:retailers="retailers")
      .retailer-directory(v-else)
        RetailersAZ
</template>
<script>
import RetailerCardView from "@/components/brands/retailers/RetailerCardView"
import RetailersAZ from "@/components/brands/retailers/RetailersAZ"
import RetailersFilterButton from "@/components/filters/RetailersFilterButton"
import RetailerTag from "@/components/brands/retailers/RetailerTag"
import RetailerTagBox from "@/components/brands/retailers/RetailerTagBox"
import { mapActions } from "vuex"
import Pagination from "@/components/Pagination"

export default {
  components: {
    RetailersFilterButton,
    RetailersAZ,
    RetailerTag,
    RetailerTagBox,
    RetailerCardView,
    Pagination
  },
  props: {
    resetFilters: { type: Boolean, default: false }
  },
  data() {
    return {
      searchValue: "",
      filterList: [
        {
          title: "Retailer A-Z",
          ref: "retailer_az",
          id: "0",
          iconName: "search-icon.svg",
          iconOnName: "search-icon-on.svg"
        },
        {
          title: "Channel Type",
          ref: "channel_type",
          id: "1",
          iconName: "channel-type-icon.svg",
          iconOnName: "channel-type-icon-on.svg"
        },
        {
          title: "Location Type",
          ref: "location_type",
          id: "2",
          iconName: "compass-icon.svg",
          iconOnName: "compass-icon-on.svg"
        },
        {
          title: "# of Locations",
          ref: "num_locations",
          id: "3",
          iconName: "house-icon.svg",
          iconOnName: "house-icon-on.svg"
        },
        {
          title: "Suggested Retailers",
          ref: "suggested_retailers",
          id: "4",
          iconName: "check-icon.svg",
          iconOnName: "check-icon-on.svg"
        },
        {
          title: "Other Type",
          ref: "other_type",
          id: "5",
          iconName: "other-icon.svg",
          iconOnName: "other-icon-on.svg"
        }
      ],
      loading: true,
      value: "111",
      page: 1,
      retailers: [],
      retailerFilterItems: [],
      countryFilterItems: [],
      companySizeFilterItems: [],
      locationItems: [],
      channelTypeItems: [],
      locationTypeItems: [],
      numRetailLocationItems: [],
      suggestedRetailersItems: [],
      otherTypeItems: [],
      selectedItems: [],
      sorts: [{ name: "Sort By" }],
      query: { _limit: "1000" },
      since: this.$route.query.since,
      isProductsComponent: false,
      displayEmptyResult: false,
      isLoading: false,
      showRetailerItems: true,
      locationTags: ["United States", "United Kingdom", "Canada", "Other"],
      cardSectionTitle: "All Retailers"
    }
  },
  computed: {
    searchTerm: {
      get() {
        return this.$store.state.retailerSearch.searchTerm
      },
      set(value) {
        this.$store.dispatch("retailerSearch/updateSearchTerm", value)
      }
    },

    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    isResultsEmpty() {
      return !this.retailers.length && this.displayEmptyResult
    },
    retailerMaxPage() {
      return parseInt(this.$store.getters["retailer/retailersSearchPageCount"])
    },
    retailerSearchTotal() {
      return this.$store.getters["retailer/retailersSearchTotalCount"]
    },
    activeCountries() {
      return this.locationItems.filter(item => item.checked)
    },
    activeChannelTypes() {
      return this.channelTypeItems.filter(item => item.checked)
    },
    activeLocationTypes() {
      return this.locationTypeItems.filter(item => item.checked)
    },
    activeNumLocations() {
      return this.numRetailLocationItems.filter(item => item.checked)
    },
    activeOtherTypes() {
      return this.otherTypeItems.filter(item => item.checked)
    },
    activeFilterItems() {
      return [
        ...this.locationItems,
        ...this.channelTypeItems,
        ...this.locationTypeItems,
        ...this.numRetailLocationItems,
        ...this.otherTypeItems
      ].filter(item => item.checked)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    /**
     * sure search
     */
    searchSure(value) {
      this.$store.dispatch("retailerSearch/updateSearchTerm", value)
    },
    async init() {
      this.loading = true
      Promise.all([this.getData(), this.fetchRetailers()])
        .then(res => {
          if (res) {
            this.loading = false
          }
        })
        .catch(e => {
          console.error(e)
        })
      this.handleUserSelection()
      if (this.isAdmin) {
        this.query["_jwt-c_id"] = this.$route.params.retailerId
        this.query["_jwt-c_type"] = "retailer"
      }
      if (sessionStorage.getItem("viewItem") == "product") {
        this.isProductsComponent = true
      }
      if (sessionStorage.getItem("viewNumber")) {
        this.query._limit = Number(sessionStorage.getItem("viewNumber"))
      }
    },
    async getData() {
      await this.setReference()
      await this.fetchFilters()
    },
    handleUserSelection() {
      if (this.$route.params.resetFilters) {
        this.resetActiveFilters()
      }
      this.since = this.$route.query.since
    },
    resetActiveFilters() {
      this.activeFilterItems.forEach(item => (item.checked = false))
    },
    canShowRange(products) {
      if (products.length === 1 && !this.query.msrp) {
        return false
      } else {
        return true
      }
    },
    ...mapActions("reference", ["setReference"]),

    disableFilterItemAndfetchRetailers(item) {
      item.checked = false
      this.fetchRetailers()
    },
    setQueryKeyByFilterItems(activeFilterItems, key) {
      if (activeFilterItems.length) {
        this.query[key] = activeFilterItems.map(item => item.id).join(",")
      } else {
        delete this.query[key]
      }
    },
    syncQueryWithFilters() {
      if (this.since) {
        this.query.since = this.since
      } else {
        delete this.query.since
      }

      //this.setQueryKeyByFilterItems(this.activeTags, "sell-side-tag")
      // this.setQueryKeyByFilterItems(this.activeRetailers, "id")
      this.setQueryKeyByFilterItems(this.activeCountries, "hq-country")
      this.setQueryKeyByFilterItems(this.activeChannelTypes, "channel-type")
      this.setQueryKeyByFilterItems(this.activeLocationTypes, "location-type")
      this.setQueryKeyByFilterItems(this.activeNumLocations, "location-group")
      this.setQueryKeyByFilterItems(this.activeOtherTypes, "other-type")

      // this.setQueryKeyByFilterItems(this.activeCompanySizes, "company-size")
    },

    async filterButtonClick(id, status) {
      console.log(id, status)
      if (status == "on") {
        // this can be compressed into the template section
        if (id != "0") {
          this.$refs.retailer_az.resetAllCheckedItems()
          this.showRetailerItems = true
        } else {
          this.showRetailerItems = !this.showRetailerItems
        }
        if (id != "1") {
          this.$refs.channel_type.resetAllCheckedItems()
        } else {
          this.cardSectionTitle = "Channel Type"
          this.selectedItems = this.channelTypeItems
        }
        if (id != "2") {
          this.$refs.location_type.resetAllCheckedItems()
        } else {
          this.cardSectionTitle = "Location Type"
          this.selectedItems = this.locationTypeItems
        }
        if (id != "3") {
          this.$refs.num_locations.resetAllCheckedItems()
        } else {
          this.cardSectionTitle = "# of Locations"
          this.selectedItems = this.numRetailLocationItems
        }
        if (id != "4") {
          this.$refs.suggested_retailers.resetAllCheckedItems()
        } else {
          this.cardSectionTitle = "Suggested Retailers"
          this.selectedItems = this.suggestedRetailersItems
        }
        if (id != "5") {
          this.$refs.other_type.resetAllCheckedItems()
        } else {
          this.cardSectionTitle = "Other Type"
          this.selectedItems = this.otherTypeItems
        }
      } else {
        this.selectedItems = []
        this.cardSectionTitle = "All Retailers"
        if (id == "0") {
          // this.$refs.retailer_az.resetAllCheckedItems()
          this.showRetailerItems = true
        }
        if (id == "1") {
          // this.$refs.channel_type.resetAllCheckedItems()
        }
        if (id == "2") {
          // this.$refs.location_type.resetAllCheckedItems()
        }
        if (id == "3") {
          // this.$refs.num_locations.resetAllCheckedItems()
        }
        if (id == "4") {
          // this.$refs.suggested_retailers.resetAllCheckedItems()
        }
        if (id == "5") {
          // this.$refs.other_type.resetAllCheckedItems()
        }
      }
      console.log(this.selectedItems)
      this.resetActiveFilters()
      this.fetchRetailers()
    },

    // fetchRetailers - Updates queries, retailer list, and pagination
    async fetchRetailers() {
      this.loading = true
      this.syncQueryWithFilters()
      await this.fetchRetailersForBrand()
      this.loading = false
      this.displayEmptyResult = true
    },

    // fetchRetailersForBrand - Fetches retailers from store with a query list
    async fetchRetailersForBrand() {
      this.isLoading = true

      try {
        await this.$store.dispatch("retailer/fetchRetailers", {
          params: { ...this.query, status: "approved" }
        })
        this.retailers = this.$store.getters["retailer/retailers"]
      } catch (error) {
        console.error(error)
        this.retailers = []
      } finally {
        this.isLoading = false
      }
    },

    async fetchFilters() {
      this.locationItems = this.$store.getters["reference/countryFilterItems"]
      this.locationItems.forEach(item => (item.checked = false))

      this.channelTypeItems = this.$store.getters["reference/retailerChannelTypeFilterItems"]
      this.channelTypeItems.forEach(item => (item.checked = false))

      this.locationTypeItems = this.$store.getters["reference/retailerLocationTypeFilterItems"]
      this.locationTypeItems.forEach(item => (item.checked = false))

      this.numRetailLocationItems = this.$store.getters[
        "reference/retailerLocationGroupFilterItems"
      ]
      this.numRetailLocationItems.forEach(item => (item.checked = false))

      this.otherTypeItems = this.$store.getters["reference/retailerOtherTypeItems"]
      this.otherTypeItems.forEach(item => (item.checked = false))

      await this.setRetailerFilterItems()
    },
    async setRetailerFilterItems() {
      try {
        await this.$store.dispatch("retailer/fetchAllRetailers", {
          params: { fields: "name,id", status: "approved" }
        })
        this.retailerFilterItems = this.$store.getters["retailer/retailers"].map(retailer => {
          return { checked: false, ...retailer }
        })
      } catch {
        this.retailerFilterItems = []
      }
    }
  }
}
</script>

<style lang="sass">
.retailer-search
  font-family: Proxima Nova
  display: flex
  position: relative !important
  width: 80%
  height: 100%
  align-items: top
  margin: auto
  padding-top: 50px
.container
  &__query
    position: absolute
    display: inline-block !important
    border: 1px solid gray
    border-radius: 14px
    border-color: #e6e6e6
    box-shadow: 1px 1px 4px 1px #d9d9d9
    width: 34%
    margin-top: 8px
    height: auto !important
    padding-bottom: 5px
    &-search
      display: flex
      align-items: center
      height: 60px
      padding:0 18px
      .ant-input
        border: none
      .inp
        flex:1
      .icon
        margin-right: 8px
        cursor: pointer
  &__display
    position: relative
    display: inline-block !important
    left: 35%
    width: 55%
    mid-width: 670px
    // top: 0
    height: auto
    min-height: 700px
    margin-left: 5px

.section-text
  display: flex
  width: 100%
  font-weight: 620
  font-size: 16px
  padding-top: 10px
  padding-bottom: 10px
  margin-left: 15px

.location-text
  font-size: 16px
  color:#262626
  margin-bottom: 9px

  &__icon
    display: flex
    width: 100%
    font-weight: 620
    font-size: 16px
    padding-top: 10px
    padding-bottom: 10px
    margin-left: 40px
    margin-top: 10px

.search-field
  display: block
  border-bottom: 1px solid #ffffff
  padding-top: 10px
  padding-bottom: 10px

.location-tags
  display: flex
  flex-wrap: wrap
  width: 100%
  border-top: 1px solid #D9D9D9
  padding: 24px 18px
.tag-box
  width: 100%
  display: flex
  // margin-left: 25px
  // height: 70px
  overflow-y: auto
  .tags
    margin: 0.25em
    display: inline-block !important
    float: left

.retailer-directory-toggle
  display: block
  width: 100%
  border: 1px solid #d4d4d4

  & :hover
    cursor: pointer


.filters
  display: block
  position: relative
  bottom: 0

.search-item
  text-align: center
  //padding-left: 10px
  // background: blue
  // width: 100%

.search-box
  width: 95%
  padding: 20px 20px 20px 36px
  border: 1px solid #dbdbdb
  //border-radius: 5px
  height: 25px
  margin: 10px 0px 10px 0px



.retailer-items
  // background-color: orange
  // height: 100%
  // margin-left: 42px


.retailer-directory
  // background-color: pink
  margin-left: 42px

.pagination-footer
  padding-top: 30px
  // background-color: green
  width: 100%
  height: 80px
  margin: 0 auto

.section-title
  display: float
  float: left
  height:100%
  width: 70%
  font-size: 48px !important
  font-weight: 700
  margin-bottom: 33px

.section-results
  display: float
  float: right
  bottom: 0%
  height:100%
  width: 20%
  font-size: 15px
  font-weight: 500

.filter-tag-box
  display: inline-block
  position: relative
  width: 782px

.retailer-items-title
  // height: 100px
  // background-color: blue
</style>
