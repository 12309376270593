<template lang="pug">
.retailer-pending
  .retailer-pending__panel(v-if="isRejected")
    h1.retailer-pending__title {{$t('Retailers.RetailerVerifyEmail.letsTalk')}}
    p.retailer-pending__paragraph {{$t('Retailers.RetailerVerifyEmail.retailerAccountNotApproved')}}
    .retailer-pending__verification-sent-divider
    .has-text-centered
      a.retailer-pending__contact-us(href="mailto:retailers@landingintl.com") {{$t('Retailers.RetailerVerifyEmail.contactUs')}}
  .retailer-pending__panel(v-if="isRetailerPending && !isAdditionalEmailVerificationSent")
    h1.retailer-pending__title {{$t('Retailers.RetailerVerifyEmail.welcome')}}
    p.retailer-pending__paragraph.welcome-subheading.first-subheading {{$t('Retailers.RetailerVerifyEmail.recievedRegistrationMessage')}}
    p.retailer-pending__paragraph.welcome-subheading {{$t('Retailers.RetailerVerifyEmail.whatHappensNext')}}
    p.retailer-pending__paragraph
      img(v-if="isApproved" src="@/assets/img/icon/pending-check-success.svg")
      img(v-else src="@/assets/img/icon/pending-check.svg")
      .retailer-pending__check(:class="{ 'retailer-pending__check--passed': isApproved }") {{$t('Retailers.RetailerVerifyEmail.retailerApproval')}}
    p.retailer-pending__paragraph.sub-section {{$t('Retailers.RetailerVerifyEmail.contactUsMessage')}}
      a.pending-email(href="mailto:support@landingintl.com") support@landingintl.com
      span .
    p.retailer-pending__paragraph
      img(v-if="isEmailVerified" src="@/assets/img/icon/pending-check-success.svg")
      img(v-else="isEmailVerified" src="@/assets/img/icon/pending-check.svg")
      .retailer-pending__check(:class="{ 'retailer-pending__check--passed': isEmailVerified }") {{$t('Retailers.RetailerVerifyEmail.emailVerification')}}
    p.retailer-pending__paragraph.sub-section {{$t('Retailers.RetailerVerifyEmail.emailVerificationMessage')}}
    template(v-if="!isEmailVerified")
      .retailer-pending__divider
      .columns.is-mobile
        .column.is-narrow {{$t('Retailers.RetailerVerifyEmail.emailReceivedQn')}}
        .column.has-text-centered
          a.retailer-pending__contact-us(@click="resendEmail") {{$t('Retailers.RetailerVerifyEmail.resendEmail')}}
  .retailer-pending__panel(v-show="isAdditionalEmailVerificationSent")
    h1.retailer-pending__title {{$t('Retailers.RetailerVerifyEmail.verifyYourEmail')}}
    p.retailer-pending__paragraph {{$t('Retailers.RetailerVerifyEmail.confirmYourEmailAddress')}}
    .retailer-pending__verification-sent-divider
    .has-text-centered
      router-link.retailer-pending__contact-us(to="/login") {{$t('Retailers.RetailerVerifyEmail.ok')}} 
</template>

<script>
import firebase from "@/services/firebase-service"
export default {
  data() {
    return {
      isAdditionalEmailVerificationSent: false
    }
  },

  computed: {
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    isEmailVerified() {
      return this.$store.getters["user/isEmailVerified"]
    },
    isRejected() {
      return this.retailer.status && this.retailer.status.id === "rejected"
    },
    isRetailerPending() {
      return (
        (this.retailer.status && this.retailer.status.id === "pending") || !this.isEmailVerified
      )
    },
    isApproved() {
      return this.retailer.status.id === "approved"
    },
    userEmail() {
      return this.$store.getters["user/email"]
    }
  },
  async created() {
    const retailerId = this.$route.params.retailerId
      ? this.$route.params.retailerId
      : this.$store.getters["user/businessId"]
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "firstLogin",
      userId: this.$store.getters["user/id"],
      firstName: this.$store.getters["user/givenName"],
      lastName: this.$store.getters["user/familyName"],
      emailAddress: this.$store.getters["user/email"],
      userType: "Retailer"
    })
    await this.$store.dispatch("retailer/fetchRetailer", retailerId)
    await this.checkRetailerStatus()
  },
  methods: {
    async sha256(email) {
      const msgBuffer = new TextEncoder("utf-8").encode(email)
      const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer)
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashHex = hashArray.map(b => ("00" + b.toString(16)).slice(-2)).join("")

      return hashHex
    },
    async isLegacyUser() {
      return (
        await firebase
          .database()
          .ref("legacy/users/" + (await this.sha256(this.userEmail)))
          .once("value", snapshot => {
            return snapshot.val()
          })
      ).toJSON()
    },
    async checkRetailerStatus() {
      if (await this.isLegacyUser()) {
        this.toMainPage()
      } else if (this.isApproved && this.isEmailVerified) {
        this.toMainPage()
      }
    },
    toMainPage() {
      if (this.$route.params.retailerId) {
        this.$router.push({ name: "adminRetailermain" })
      } else {
        this.$router.push({ name: "retailermain" })
      }
    },
    async resendEmail() {
      try {
        await this.$store.dispatch("user/sendEmailVerifyLink", this.userEmail)
        this.isAdditionalEmailVerificationSent = true
      } catch (error) {
        console.error(error)
      }
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.$store.getters["retailer/isRetailerPending"] && to.name !== "login") {
      return
    }

    next()
  }
}
</script>

<style lang="sass">
.retailer-pending
  display: flex
  min-height: 80vh
  justify-content: center
  align-items: center

  &__contact-us
    color: #008489

  &__panel
    padding: 24px
    padding-bottom: 20px
    min-height: 335px
    width: 362px
    border: 1px solid #E5E5E5
    border-radius: 2px
    background-color: #FFFFFF

  &__title
    color: #000000
    font-family: "Proxima"
    font-size: 16px
    letter-spacing: 0.44px

  &__paragraph
    margin-top: 16px
    color: #8D8D8D
    font-size: 14px
    letter-spacing: 0.25px

  &__divider
    border-bottom: 1px solid #e5e5e5
    margin: 40px 0px 25px 0px

  &__verification-sent-divider
    border-bottom: 1px solid #e5e5e5
    margin: 140px 0px 25px 0px

  &__link
    color: #008489
    cursor: pointer
  img
    float: left
  &__check
    color: #8D8D8D
    font-family: "Proxima"
    font-size: 14px
    letter-spacing: 0.25px
    font-weight: 500
    height: 20px
    line-height: 20px
    margin-left: 30px

    &--passed
      color: #008489
  .pending-email
    color: #4A90E2
  .welcome-subheading
    color: #000000
    margin: 0
  .first-subheading
    margin-top: 16px
  .sub-section
    margin-left: 30px
    margin-top: 10px
</style>
