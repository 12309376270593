var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand-settings-all"},[_c('a-row',[_c('img',{attrs:{"src":require("@/assets/img/icon/settings.svg")}}),_c('span',{staticClass:"brand-settings-all__top-title"},[_vm._v(_vm._s(_vm.$t("Brands.BrandSettings.settings")))])]),_c('a-row',{staticClass:"brand-settings-all__row"},[_c('a-col',{attrs:{"span":8}},[_c('div',{staticClass:"brand-settings-left"},[_c('div',{staticClass:"brand-settings-left__menu"},[_c('a-menu',{staticStyle:{"width":"340px"},attrs:{"mode":"inline","theme":"light","open-keys":_vm.openKeys},on:{"openChange":_vm.onOpenChange},model:{value:(_vm.defaultSelecttList),callback:function ($$v) {_vm.defaultSelecttList=$$v},expression:"defaultSelecttList"}},[_vm._l((_vm.typeButton),function(menu){return [(menu.isLogisticsActive || !menu.child)?_c('a-menu-item',{key:menu.id},[_c('router-link',{attrs:{"to":{ name: menu.route, params: { brandId: _vm.brand.id } }}},[_vm._v(_vm._s(menu.title))])],1):_c('a-sub-menu',{key:menu.id},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('span',[_vm._v(_vm._s(menu.title))])]),_vm._l((menu.child),function(child){return _c('a-menu-item',{key:child.id},[_c('router-link',{attrs:{"to":{
                      name:
                        child.id === 31 && !_vm.brand.terms.usesLandingLogistic
                          ? _vm.logisticRouterName
                          : child.route,
                      params: { brandId: _vm.brand.id }
                    }}},[_vm._v(" "+_vm._s(child.title)+" ")])],1)})],2)]})],2)],1)])]),_c('a-col',{attrs:{"span":16}},[_c('div',[_c('router-view')],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }