<template>
  <a-modal v-model="isShow" :footer="null" width="1200px;">
    <div class="beauty-fluent-step-three__main" style="padding: 50px 0">
      <div class="step-three-com-style">
        <div>
          <BFStep3BillingCard
            :is-brand="true"
            :billing-info="billingInfo"
            @sendBilling="sendBilling"
          ></BFStep3BillingCard>
          <BFStep3Payment
            ref="brandSubscribPaymeComp"
            :is-brand="true"
            :card-lists="cardLists"
            :default-card="defaultCard"
            @paymentSend="paymentSend"
            @chooseCreditCard="chooseCreditCard"
            @getPaymentType="getPaymentType"
          ></BFStep3Payment>
        </div>
        <div>
          <BFStep3Subscription
            :retailer-info="retailerInfo"
            :billing-info="billingInfo"
            :sub-total-amount="subTotalAmount"
            :saving-number="savingNumber"
            :sum-total="sumTotal"
            :existed-retailer="existedRetailer"
            :can-not-change-retailer="true"
            @promoCodeSend="promoCodeSend"
            @changeBilling="changeBilling"
            @changePromocode="changePromocode"
            @checkRadio="checkRadio"
            @updateRetailerInfo="updateRetailerInfo"
          ></BFStep3Subscription>
          <a-button class="submit-style" :loading="isLoading" @click="submit">
            SUBMIT&nbsp;YOUR&nbsp;SUBSCRIPTION
          </a-button>
          <p class="cancel-style" @click="clickToStepOne">CANCEL</p>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import BFStep3BillingCard from "@/components/training/__components/BFStep3BillingCard"
import BFStep3Payment from "@/components/training/__components/BFStep3Payment"
import BFStep3Subscription from "@/components/training/__components/BFStep3Subscription"
import { mapActions } from "vuex"
import AES from "@/common/AES"

const radioInfo = {
  creditCard: 1,
  transfer: 2,
  newCard: 3
}

export default {
  components: {
    BFStep3Payment,
    BFStep3Subscription,
    BFStep3BillingCard
  },
  data() {
    return {
      paymentParams: undefined,
      isShow: false,
      retailerData: undefined,
      retailerId: undefined,
      billingObj: undefined,
      couponCode: undefined,
      chooseCard: undefined,
      isPaymentFinal: false,
      checkRadioValue: false,
      paymentType: radioInfo.creditCard,
      brandId: undefined,
      retailerInfo: undefined,
      existedRetailer: [],
      billingInfo: [],
      isLoading: false
    }
  },
  computed: {
    brandInfo() {
      return this.$store.getters["brand/brand"]
    },
    cardLists() {
      // console.log(">>>>>>>卡列表", this.$store.getters["brandPayment/cardInfoList"])
      return this.$store.getters["brandPayment/cardInfoList"]
    },
    defaultCard() {
      // console.log("默认卡", this.$store.getters["brandPayment/defaultCard"])
      return this.$store.getters["brandPayment/defaultCard"]
    },
    chooseBillingInfo() {
      const chooseBilling = this.billingInfo.filter(item => item.isClick)
      return chooseBilling[0]
    },
    subTotalAmount() {
      const total = this.billingInfo.filter(item => item.name === "Annual")[0].price
      if (this.couponCode) {
        return (total - this.couponAmount).toFixed(2)
      }
      return total.toFixed(2)
    },
    savingNumber() {
      const info = this.billingInfo.filter(item => item.isClick)
      return (this.subTotalAmount * info[0].savingNum).toFixed(2)
    },
    sumTotal() {
      const number = (this.subTotalAmount - this.savingNumber).toFixed(2)
      if (this.chooseBillingInfo.name === "Quarter") {
        const num = Math.round((number / 4) * 100) / 100
        return num.toFixed(2)
      } else if (this.chooseBillingInfo.name === "Monthly") {
        const num = Math.round((this.subTotalAmount / 12) * 100) / 100
        return num.toFixed(2)
      } else {
        return number
      }
    }
  },
  methods: {
    ...mapActions({
      updatePaymentTrainingSub: "training/updatePaymentTrainingSubscription",
      getRetailersList: "training/getBrandTraining"
    }),
    initBillingInfo() {
      console.log(this.retailerInfo)
      this.billingInfo = [
        {
          id: 1,
          titleName: "MONTHLY PLAN",
          savings: "NO",
          hasSav: false,
          price: this.retailerInfo.monthlyPrice,
          actualPrice: this.retailerInfo.monthlyPrice,
          saveNumber: 0,
          unitText: "month",
          bgColor: "#FAC92C",
          number: "12 payments",
          hasSaveUp: false,
          isClick: false,
          hasSave: false,
          hasPopular: false,
          type:
            (this.referenceInfo && this.referenceInfo.filters(item => item.id === 3)[0].name) ||
            "monthly",
          name:
            (this.referenceInfo &&
              this.referenceInfo.filters(item => item.id === 3)[0].description) ||
            "Monthly",
          subscriptionTotalText: "Payment Due Today, \nand every month:",
          savingTxt: "",
          savingNum: 1,
          sumTotal: this.retailerInfo.monthlyPrice,
          subTotalAmount: this.retailerInfo.annuallyPrice,
          savingNumber: 0
        },
        {
          id: 2,
          titleName: "QUARTERLY PLAN",
          savings: "3%",
          hasSav: true,
          price: this.retailerInfo.quarterlyPrice,
          actualPrice: this.retailerInfo.quarterlyPrice - this.retailerInfo.quarterlyPrice * 0.03,
          unitText: "quarter",
          number: "4 payments",
          hasSaveUp: false,
          isClick: false,
          hasSave: true,
          hasPopular: false,
          bgColor: "#1B4793",
          saveNumber: this.retailerInfo.quarterlyPrice * 0.03,
          type:
            (this.referenceInfo && this.referenceInfo.filters(item => item.id === 2)[0].name) ||
            "quarterly",
          name:
            (this.referenceInfo &&
              this.referenceInfo.filters(item => item.id === 3)[0].description) ||
            "Quarter",
          subscriptionTotalText: "Payment Due Today, \nand every quarter:",
          savingTxt: "Quarterly Plan Savings - 3%",
          savingNum: 0.03,
          sumTotal:
            Math.round(
              ((this.retailerInfo.annuallyPrice - this.retailerInfo.annuallyPrice * 0.03) / 4) * 100
            ) / 100,
          subTotalAmount: this.retailerInfo.annuallyPrice,
          savingNumber: this.retailerInfo.annuallyPrice * 0.03
        },
        {
          id: 3,
          titleName: "YEARLY PLAN",
          savings: "5%",
          hasSav: true,
          price: this.retailerInfo.annuallyPrice,
          actualPrice: this.retailerInfo.annuallyPrice - this.retailerInfo.annuallyPrice * 0.05,
          number: "One-time payment",
          unitText: "year",
          hasSaveUp: false,
          isClick: false,
          hasSave: true,
          hasPopular: true,
          bgColor: "#F15A42",
          saveNumber: this.retailerInfo.annuallyPrice * 0.05,
          type:
            (this.referenceInfo && this.referenceInfo.filters(item => item.id === 1)[0].name) ||
            "annual",
          name:
            (this.referenceInfo &&
              this.referenceInfo.filters(item => item.id === 3)[0].description) ||
            "Annual",
          subscriptionTotalText: "Payment Due Today:",
          savingTxt: "Annual Plan Savings - 5%",
          savingNum: 0.05,
          sumTotal: this.retailerInfo.annuallyPrice - this.retailerInfo.annuallyPrice * 0.05,
          subTotalAmount: this.retailerInfo.annuallyPrice,
          savingNumber: this.retailerInfo.annuallyPrice * 0.05
        }
      ]
    },
    closeDialog() {
      this.billingInfo = []
      this.retailerData = undefined
      this.retailerId = undefined
      this.brandId = undefined
      this.isShow = false
      this.retailerInfo = undefined
      this.existedRetailer = []
      this.billingObj = undefined
      this.couponCode = undefined
      this.chooseCard = undefined
      this.isPaymentFinal = false
      this.checkRadioValue = false
      this.paymentType = radioInfo.creditCard
    },
    async initExistedRetailer() {
      let data = await this.getRetailersList(this.brandId)
      if (data && data.length > 0) {
        this.existedRetailer = data.filter(item => item.retailerId !== this.retailerId)
      }
    },
    openDialog(retailer, brandId) {
      this.retailerData = retailer
      this.retailerId = retailer.retailerId
      this.brandId = brandId
      this.isShow = true
      this.retailerInfo = this.$store.getters["retailer/preferredAndGeneralRetailers"].filter(
        item => item.retailerId === this.retailerId
      )[0]
      this.initBillingInfo()
      if (
        this.retailerData &&
        this.retailerData.data &&
        this.retailerData.data.subscriptionTerms &&
        this.retailerData.data.subscriptionTerms.id
      ) {
        this.billingInfo.map(item => {
          if (item.type === this.retailerData.data.subscriptionTerms.id) {
            item.isClick = true
            this.billingObj = {
              originalPrice: item.price,
              subscriptionTerms: {
                id: item.type
              }
            }
          }
          return item
        })
      }
      this.initExistedRetailer()
    },
    sendBilling(billing, list) {
      console.log(list)
      console.log(billing)
      this.changeBilling(undefined, list)
      this.billingObj = {
        originalPrice: billing.price,
        subscriptionTerms: {
          id: billing.type
        }
      }
    },
    changePromocode(code, amount) {
      this.couponCode = code
      this.couponAmount = amount
    },
    changeBilling(retailerInfo, list) {
      const binningList = list ? list : this.billingInfo
      const total = binningList.filter(item => item.name === "Annual")[0].price
      this.billingInfo = binningList.map(item => {
        if (item.type === "annual") {
          item.price = retailerInfo ? retailerInfo.annuallyPrice : item.price
          item.actualPrice = retailerInfo
            ? retailerInfo.annuallyPrice - retailerInfo.annuallyPrice * 0.05
            : item.actualPrice
          item.saveNumber = retailerInfo ? retailerInfo.annuallyPrice * 0.05 : item.saveNumber

          if (this.couponCode && this.couponAmount) {
            item.subTotalAmount = retailerInfo
              ? Number(retailerInfo.annuallyPrice - this.couponAmount).toFixed(2)
              : Number(total - this.couponAmount).toFixed(2)
            item.savingNumber = (item.subTotalAmount * item.savingNum).toFixed(2)
            item.sumTotal = (item.subTotalAmount - item.savingNumber).toFixed(2)
          } else {
            item.subTotalAmount = retailerInfo ? retailerInfo.annuallyPrice : total
            item.savingNumber = retailerInfo ? retailerInfo.annuallyPrice * 0.05 : total * 0.05
            item.sumTotal = retailerInfo
              ? retailerInfo.annuallyPrice - retailerInfo.annuallyPrice * 0.05
              : total - total * 0.05
          }
        }
        if (item.type === "quarterly") {
          item.price = retailerInfo ? retailerInfo.quarterlyPrice : item.price
          item.actualPrice = retailerInfo
            ? retailerInfo.quarterlyPrice - retailerInfo.quarterlyPrice * 0.03
            : item.actualPrice
          item.saveNumber = retailerInfo ? retailerInfo.quarterlyPrice * 0.03 : item.saveNumber

          if (this.couponCode && this.couponAmount) {
            item.subTotalAmount = retailerInfo
              ? Number(retailerInfo.annuallyPrice - this.couponAmount).toFixed(2)
              : Number(total - this.couponAmount).toFixed(2)
            item.savingNumber = (Number(item.subTotalAmount) * item.savingNum).toFixed(2)
            const number = Number(item.subTotalAmount - item.savingNumber).toFixed(2)
            item.sumTotal = Math.round((number / 4) * 100) / 100
          } else {
            item.subTotalAmount = retailerInfo ? retailerInfo.annuallyPrice : total
            item.savingNumber = retailerInfo ? retailerInfo.quarterlyPrice * 0.03 : total * 0.03
            const number = retailerInfo
              ? retailerInfo.annuallyPrice - retailerInfo.annuallyPrice * 0.03
              : item.subTotalAmount - item.savingNumber
            item.sumTotal = Math.round((number / 4) * 100) / 100
          }
        }
        if (item.type === "monthly") {
          item.price = retailerInfo ? retailerInfo.monthlyPrice : item.price
          item.actualPrice = retailerInfo ? retailerInfo.monthlyPrice : item.actualPrice
          item.saveNumber = 0

          if (this.couponCode && this.couponAmount) {
            item.subTotalAmount = retailerInfo
              ? Number(retailerInfo.annuallyPrice - this.couponAmount).toFixed(2)
              : Number(total - this.couponAmount).toFixed(2)
            item.savingNumber = (item.subTotalAmount * item.savingNum).toFixed(2)
            item.sumTotal = Math.round((item.subTotalAmount / 12) * 100) / 100
          } else {
            item.subTotalAmount = retailerInfo ? retailerInfo.annuallyPrice : total
            item.sumTotal = retailerInfo
              ? retailerInfo.monthlyPrice
              : Math.round((item.subTotalAmount / 12) * 100) / 100
            item.savingNumber = 0
          }
        }
        return item
      })
    },
    paymentSend(paymemtObj) {
      this.paymentParams = paymemtObj
    },
    chooseCreditCard(cardInfo) {
      this.chooseCard = cardInfo
    },
    getPaymentType(type, isFinal) {
      this.paymentType = type
      this.isPaymentFinal = isFinal
    },
    promoCodeSend(code, couponAmount) {
      this.couponCode = code
      this.couponAmount = couponAmount
      this.changeBilling()
    },
    checkRadio(isClick) {
      this.checkRadioValue = isClick
    },
    clickToStepOne() {
      this.closeDialog()
    },
    submit() {
      this.isLoading = true
      if (!this.checkValue()) {
        this.isLoading = false
        return
      }
      const billing = this.billingInfo.filter(item => item.isClick)[0]
      console.log(billing)
      const type =
        this.billingObj && this.billingObj.subscriptionTerms.id
          ? this.billingObj.subscriptionTerms.id
          : billing.type
      if (this.paymentType === radioInfo.creditCard) {
        this.sendBrandSubmitPayment(type, billing)
        return
      }
      if (this.paymentType === radioInfo.newCard) {
        this.sendAddNewCard(type)
        return
      }
      if (this.paymentType === radioInfo.transfer) {
        this.sendTransfer(type, billing)
        return
      }
      return
    },
    async sendBrandPayment(type, billing) {
      if (!this.chooseCard) {
        this.chooseCard = this.defaultCard
      }
      if (this.chooseCard.cardNumber) {
        if (this.checkIsAddAnotherRetailer()) {
          this.sendBrandSubmitPayment(type, billing)
        } else {
          const param = {
            retailerId: this.retailerId,
            couponCode: this.couponCode,
            termsId: type,
            cardNumber: AES.encrypt(this.chooseCard.cardNumber),
            isWireTransfer: false
          }
          this.doPayment(param)
        }
      } else {
        this.isLoading = false
        this.$message.error("Please add a credit card!")
      }
    },
    doPayment(param) {
      this.updateBrandPaymentSub({ brandId: this.brandInfo.id, param })
        .then(() => {
          this.submitSuccess()
        })
        .catch(err => {
          this.isLoading = false
          this.$message.error(err.message)
        })
    },
    async sendTransferPayment(type, billing) {
      if (this.checkIsAddAnotherRetailer()) {
        this.sendTransfer(type, billing)
      } else {
        let first = ""
        let last = ""
        let email = ""
        if (
          this.paymentParams &&
          this.paymentParams.brandWireTransferContacts &&
          this.paymentParams.brandWireTransferContacts.length > 0
        ) {
          first = this.paymentParams.brandWireTransferContacts[0].firstName
          last = this.paymentParams.brandWireTransferContacts[0].lastName
          email = this.paymentParams.brandWireTransferContacts[0].email
        }
        const param = {
          retailerId: this.retailerId,
          couponCode: this.couponCode,
          termsId: type,
          isWireTransfer: true,
          wireTransferFirstName: first,
          wireTransferLastName: last,
          wireTransferLastEmail: email
        }
        this.doPayment(param)
      }
    },
    async sendBrandSubmitPayment(type, billing) {
      if (!this.chooseCard) {
        this.chooseCard = this.defaultCard
      }
      // const number = this.paymentParams.brandCreditCardInformation && this.paymentParams.brandCreditCardInformation[0].cardNumber
      const payment = {
        // type: type,
        // cardNumber: AES.encrypt(this.chooseCard.cardNumber),
        // couponCode: this.couponCode,
        // retailerId: this.retailerId
        retailerId: this.retailerId,
        type: type,
        chargeCreditCard: {
          type: type,
          cardNumber: AES.encrypt(this.chooseCard.cardNumber),
          couponCode: this.couponCode,
          amount:
            this.billingObj && this.billingObj.originalPrice
              ? this.billingObj.originalPrice
              : billing.price
        },
        brand: {
          brandTrainingSubscriptions: [
            {
              wireTransfer: false
            }
          ]
        }
      }
      this.updatePaymentTrainingSub({ brandId: this.brandInfo.id, payment })
        .then(() => {
          this.submitSuccess()
        })
        .catch(err => {
          this.isLoading = false
          this.$message.error(err && err.message ? err.message : "Resubscribe failed.")
        })
    },
    sendAddNewCard(type) {
      const cardInfo = this.paymentParams
      this.$store
        .dispatch("brandPayment/updateCardInfo", { brandId: this.brandInfo.id, cardInfo })
        .then(async response => {
          if (response && response.length !== 0) {
            this.chooseCard = {
              cardNumber: cardInfo.cardNumber
            }
            //await this.sendBrandSubmitPayment(type)
            await this.sendBrandPayment(type)
            this.submitSuccess()
          }
        })
        .catch(err => {
          this.isLoading = false
          if (err.message) {
            this.$message.error(err.message)
          } else {
            this.$message.error(this.$t("Components.Brands.Payment.addCardFailed"))
          }
        })
    },
    async sendTransfer(type, billing) {
      const payment = {
        retailerId: this.retailerId,
        type: type,
        chargeCreditCard: {
          // type: type,
          // cardNumber: "",
          couponCode: this.couponCode,
          amount:
            this.billingObj && this.billingObj.originalPrice
              ? this.billingObj.originalPrice
              : billing.price
        },
        brand: {
          ...this.paymentParams,
          brandTrainingSubscriptions: [
            {
              wireTransfer: true
            }
          ]
        }
      }
      this.updatePaymentTrainingSub({ brandId: this.brandInfo.id, payment })
        .then(() => {
          this.submitSuccess()
        })
        .catch(err => {
          this.isLoading = false
          this.$message.error(err && err.message ? err.message : "Resubscribe failed.")
        })
    },
    submitSuccess() {
      this.$emit("submitSuccess")
      this.closeDialog()
    },
    updateRetailerInfo(retailerInfo) {
      this.retailerId = retailerInfo.retailerId
      this.changeBilling(retailerInfo)
      // this.$emit("updateRetailer", retailerInfo)
    },
    checkValue() {
      let isPaymentCardFilled = true
      if (this.paymentType === 3) {
        this.$refs["brandSubscribPaymeComp"].checkValue(isValid => {
          isPaymentCardFilled = isValid
        })
      }
      if (this.paymentType !== radioInfo.creditCard && !this.isPaymentFinal) {
        this.$message.error("Please check the payment method is available")
        return false
      }
      if (!this.checkRadioValue) {
        this.$message.error("Please accept the Terms & Conditions!")
        return false
      }
      return isPaymentCardFilled
    }
  }
}
</script>

<style scoped lang="scss">
.beauty-fluent-step-three__main {
  padding: 50px 120px 120px 120px;
  max-width: 1440px;
  margin: 0 auto;
  .step-three-com-style {
    display: flex;
    justify-content: space-between;
  }
  .step-title {
    width: 316px;
    text-align: center;
    margin: 20px auto 60px;
    display: flex;
    justify-content: space-between;
    position: relative;
    .step-line {
      height: 3px;
      width: 316px;
      background-color: #f15a42;
      position: absolute;
      top: 15px;
      left: 0;
      z-index: 1;
    }
  }
  .submit-style {
    width: 100%;
    line-height: 57px;
    height: 57px;
    text-align: center;
    border-radius: 50px;
    background-color: #f15a42;
    color: #ffffff;
    margin-top: 45px;
    font-size: 18px;
    font-family: DMSans;
    cursor: pointer;
  }
  .cancel-style {
    text-align: center;
    font-size: 18px;
    font-family: DMSans;
    cursor: pointer;
  }
  .step-icon-style {
    position: absolute;
    top: -25px;
    left: -5px;
    color: #1a1818;
    font-weight: bold;
    font-family: DMSans;
  }
  .billing-style {
    left: 130px;
  }
  .payment-style {
    left: 270px;
    color: #f27c59;
  }
}
</style>
