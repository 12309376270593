import { render, staticRenderFns } from "./AdminSystemPromoCodeHistory.vue?vue&type=template&id=2b72437c&scoped=true&"
import script from "./AdminSystemPromoCodeHistory.vue?vue&type=script&lang=js&"
export * from "./AdminSystemPromoCodeHistory.vue?vue&type=script&lang=js&"
import style0 from "./AdminSystemPromoCodeHistory.vue?vue&type=style&index=0&id=2b72437c&prod&lang=scss&scoped=true&"
import style1 from "./AdminSystemPromoCodeHistory.vue?vue&type=style&index=1&id=2b72437c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b72437c",
  null
  
)

export default component.exports