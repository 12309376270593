const constants = {
  orderStatus: {
    pending: "pending",
    confirmed: "confirmed",
    draft: "draft",
    canceled: "canceled",
    supplyShipped: "shipped-supply",
    warehouse: "warehouse",
    deliverShipped: "shipped-delivery",
    delivered: "delivered",
    paidByRetailer: "paid-by-retailer",
    paidToBrand: "paid-to-brand",
    deleted: "deleted",
    shipped: "shipped",
    invoiced: "invoiced"
  },
  adminRetailerRoutes: [
    "adminRetailerDashboard",
    "adminRetailerOrders",
    "adminRetailerSettings",
    "adminRetailerProfile",
    "adminRetailerProductsAll",
    "adminRetailerBrandProfile",
    "adminRetailerProduct",
    "adminRetailerProductsByCategory",
    "adminRetailerProductsNew",
    "adminRetailerProducts",
    "adminRetailerCart",
    "adminRetailerCheckout",
    "adminRetailerCheckoutSuccess",
    "adminRetailerOrder",
    "adminBrandDirectory"
  ],
  adminBrandRoutes: [
    "adminBrandProfile",
    "adminBrandSettings",
    "adminBrandMyAccount",
    "adminBrandSettingsSubscription",
    "adminBrandMember",
    "adminBrandTerms",
    "adminBrandCommission",
    "adminBrandPaymentDetails",
    "adminBrandUpdatePaymentDetails",
    "adminBrandPaymentHistory",
    "adminBrandCatalog",
    "adminBrandOrders",
    "adminBrandDashboard",
    "adminBrandOrder",
    "adminBrandProduct",
    "adminBrandRetailerProfile",
    "adminRetailerDirectory",
    "adminBrandRetailerSearch",
    "adminLandingTermsMain",
    "adminLandingTerms",
    "adminLandingTermsTF",
    "adminBrandSubscription",
    "adminRetailerOldOrderDetails",
    "adminBrandOldOrderDetails"
  ],
  brandIds: [
    "elGhdddRTRm9JH00ycafPA",
    "UaFBEKMdSY6Z1ZO4CQyq7w",
    "z4w1LAFiRI6LmIWBt6dyNQ",
    "udgcqUSRQ-G2FgJp_ipeLQ",
    "YxRvLKpqSVGTSAEuwryuAQ",
    "_YKfIjqkRJG8voRLFun2Gw"
  ],
  productIds: [
    "MEi-OvtTSP2dBeqs7208vg",
    "XDBc0bvJQESPK_JdbYDtcQ",
    "7JpnxxJFQ1WyxTvzjgc3Pw",
    "Iln501E6R2uJSSfQrzcCJg",
    "2k9xTP7BTWGt7fFXBDJi1w",
    "D7iV5KwbSLCbEmHdLbz_pw",
    "5LBKKC_FSBunXfwjPDGemg",
    "PvkYXiBkRPmSU2dVcy2jXQ"
  ],
  retailerIds: [
    "rRzPfdsoQrKWhevc9irf_w",
    "SE5hqql9TIuYZw_H887Gpg",
    "Lls1WkHOTYmQSLAPW4m_pQ",
    "NWYVi18wS1mnMBXZaJ8Jkg",
    "IpmLrodRTJyfuD10PRYCdA",
    "6wJUxbpGSBe3-XIdzzo3mA"
  ],
  subscriptionTerms: [
    { name: "Create a brand profile", basic: "✔", premium: "✔" },
    { name: "Upload product catalog", basic: "✔", premium: "✔" },
    { name: "Receive/manage orders", basic: "✔", premium: "✔" },
    { name: "Communicate with retailers on orders", basic: "✔", premium: "✔" },
    { name: "Viewable by the buyer network", basic: "✔", premium: "✔" },
    { name: "Allow buyers to request samples", basic: "✔", premium: "✔" },
    { name: "Appear in trend curations", basic: "✔", premium: "✔" },
    { name: "Message new buyers", basic: "", premium: "✔" },
    { name: "Access retailer profiles", basic: "", premium: "✔" },
    { name: "Manage & track shipments", basic: "", premium: "✔" },
    { name: "Manage inventory", basic: "", premium: "✔" },
    { name: "Track payments", basic: "", premium: "✔" },
    { name: "Add multiple users", basic: "", premium: "✔" },
    { name: "Create invoices & packing lists", basic: "", premium: "✔" },
    { name: "Buyer box eligibility", basic: "", premium: "✔" },
    { name: "Product training", basic: "", premium: "✔" },
    { name: "Access to market insights", basic: "", premium: "✔" },
    { name: "Uploaded products", basic: "20", premium: "Unlimited" },
    { name: "Annual Fee", basic: "FREE", premium: "$2,000" },
    { name: "% Transaction Fee on Orders", basic: "10%", premium: "5%" }
  ]
}

export default constants
